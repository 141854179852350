import { useQuery } from '@apollo/client'
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import { chain } from 'lodash'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Loader, Tab, TabView } from 'src/components'
import {
  formatPhone,
  getISODate,
  getServiceDurationInMinutes,
  getServiceHoursAndMinutes,
} from 'src/helpers'
import { DateUtil, useSdk } from 'src/sdk'
import { useValidationSchema } from 'src/sdk/useValidationSchema'
import { resourceGetGql } from 'src/state/gql'
import {
  ServiceInput,
  useAddServiceMutation,
  useClientUpdateMutation,
  useClientsAddMutation,
  useGetOrganizationByIdQuery,
  useGetServiceTypesQuery,
  useLocationGetQuery,
  usePrepaymentsGetStripeConnectAccountInfoQuery,
  usePrepaymentsSendPaymentNotificationV2Mutation,
} from 'src/state/graphql'
import {
  queryServiceTypes,
  searchClients,
  useInviteUser,
} from 'src/state/queries'
import { trpc } from 'src/trpc'
import { ThemeContext } from 'styled-components'
import { ServiceForm as ServiceForm2 } from 'views/Settings/Services/ServiceEditor/ServiceForm'
import {
  Container,
  FormWrapper,
  Modal,
  ModalWrapper,
} from '../Appointment/Appointment'
import { AppointmentHotelFooter } from '../CalendarHotel/components/AppointmentHotelFooter'
import { GroupAppointmentFormFields } from './components/GroupAppointmentFormFields'
import { GroupClientForm } from './components/GroupClientForm'

export const GroupAppointmentNew = (props: {
  onClose: () => void
  newEvent: {
    selectedHour: string
    selectedMinute: string
    selectedDayId?: string
    resourceId?: string | null
  }
}) => {
  const { orgId, locationId } = useParams<{
    orgId: string
    locationId: string
  }>()
  const { onClose, newEvent } = props
  const { isMobile, t, toast, appServices } = useSdk()

  const { ClientSchema, GroupAppointmentSchema, ServiceSchema } =
    useValidationSchema({
      serviceFieldNamePrefix: 'newService',
      clientFieldNamePrefix: 'newClient',
    })

  const [activeForm, setActiveForm] = useState<
    'appointment' | 'client' | 'service'
  >('appointment')

  const selectedTheme = useContext(ThemeContext)

  const [isBusy, setIsBusy] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [clientsSortBy, setClientsSortBy] = useState('')
  const [isNewClient, setIsNewClient] = useState(false)

  const formRef = useRef<any>(null)

  const { data: locationData, loading: locationLoading } = useLocationGetQuery({
    variables: {
      locationId,
    },
  })

  const resourceId = newEvent?.resourceId

  const { data: resourceData, loading: loadingResource } = useQuery(
    resourceGetGql,
    {
      variables: { id: newEvent?.resourceId },
      skip: !!resourceId === false,
    }
  )

  const { data: organizationData, loading: loadingOrgData } =
    useGetOrganizationByIdQuery({
      variables: { id: orgId },
    })

  const serviceTypesData = useGetServiceTypesQuery({
    variables: { locationId },
    fetchPolicy: 'network-only',
  })

  const location = locationData?.location?.get
  const org = organizationData?.organization?.get
  const resourcesData = location?.resources
  const serviceTypes = serviceTypesData.data?.serviceType?.search

  const { data: stripeConnectAccountData } =
    usePrepaymentsGetStripeConnectAccountInfoQuery({
      skip: !org?.stripeConnectAccountId,
    })

  const hasStripeChargesEnabled =
    stripeConnectAccountData?.prepayments?.getStripeConnectAccountInfo
      ?.hasChargesEnabled || false

  const hasInvoicingData =
    !!location?.prepaymentsConfig?.userId &&
    !!location?.prepaymentsConfig?.operatorId &&
    !!location?.prepaymentsConfig?.prepaymentsDeviceId &&
    !!location?.prepaymentsConfig?.prepaymentServiceId

  const hasPrepaymentCredentials =
    org?.prepaymentsProvider === 'STRIPE'
      ? hasStripeChargesEnabled && hasInvoicingData
      : org?.hasVivaMerchantId ||
        (false && org?.hasVivaApiKey) ||
        (false && hasInvoicingData)

  const hasPrepaymentsEnabled = location?.prepaymentsConfig?.prepaymentsEnabled

  const resources = useMemo(
    () => resourcesData?.filter(x => x.showInCalendar && x.kind === 'RESOURCE'),
    [resourcesData]
  )

  const flattenedServices = useMemo<
    NonNullable<NonNullable<typeof serviceTypes>[0]>['services']
  >(() => chain(serviceTypes).map('services').flatten().value(), [serviceTypes])

  const selectedResource = resourceId
    ? resourceData?.resource?.get
    : resources?.[0]

  //region MUTATIONS
  const [clientAddMutation, { loading: loadingAddClient }] =
    useClientsAddMutation()

  const [updateClient, { loading: loadingUpdateClient }] =
    useClientUpdateMutation()

  const [inviteUser] = useInviteUser()
  const [createNewService, { loading: loadingCreateService }] =
    useAddServiceMutation()
  const [sendPaymentNotificationMutation] =
    usePrepaymentsSendPaymentNotificationV2Mutation()

  const createGroupBookingMutation = trpc.groupBooking_create.useMutation()

  const sendPaymentNotification = async ({
    resourceBookingId,
    cancelAppointmentOnExpiredPaymentRequest,
  }) => {
    try {
      if (!resourceBookingId) return

      const results = await sendPaymentNotificationMutation({
        variables: {
          id: resourceBookingId,
          cancelAppointmentOnExpiredPaymentRequest,
        },
      })

      if (!results.errors?.length) {
      } else toast.danger(results?.errors?.[0]?.message)
    } catch (err) {
      console.log(err)
    } finally {
    }
  }

  const handleCreateGroupAppointment = async values => {
    try {
      setIsBusy(true)
      const durationMinutes = getServiceDurationInMinutes(values?.duration!)
      const startTimeUtc = moment(`${values.date} ${values.startTime?.id}`)
        .utc()
        .toDate()

      const endTimeUtc = moment(`${values.date} ${values.startTime?.id}`)
        .add(durationMinutes, 'minutes')
        .utc()
        .toDate()

      const service = flattenedServices?.find(x => x?.id === values?.service)

      const result = await createGroupBookingMutation.mutateAsync({
        serviceId: BigInt(values?.service),
        resourceId: BigInt(values?.resource?.id),
        onlineBookingAllowed: values?.onlineBookingAllowed || false,
        internalNote: values?.internalNote || '',
        startTimeUtc,
        endTimeUtc,
        capacity: Number(values?.capacity | 0),
        clientSlots:
          values?.clientSlots?.map(slot => {
            return {
              clientId: BigInt(slot?.client?.id),
              internalNote: slot?.internalNote || '',
              messageToClient: slot?.messageToClient || '',
              sendAppointmentEmail: slot?.sendAppointmentEmail || false,
              sendAppointmentSms: slot?.sendAppointmentSms || false,
            }
          }) || [],
      })

      const sendPaymentRequestPromises =
        result
          ?.filter(slot => {
            const slotConfig = values?.clientSlots?.find(
              x => x.client?.id === slot?.clientId?.toString()
            )
            return (
              slotConfig?.sendPaymentRequest &&
              hasPrepaymentCredentials &&
              !!values?.service &&
              service!.totalAmount > 0
            )
          })
          ?.map(slot => {
            const slotConfig = values?.clientSlots?.find(
              x => x.client?.id === slot?.clientId?.toString()
            )

            return sendPaymentNotification({
              resourceBookingId: slot?.id,
              cancelAppointmentOnExpiredPaymentRequest:
                slotConfig?.cancelAppointmentOnExpiredPaymentRequest || false,
            })
          }) || []

      await Promise.all(sendPaymentRequestPromises)

      toast.success('Termin je uspješno kreiran')
      onClose()
    } catch (err) {
      toast.danger('Došlo je do pogreške - ' + (err as any).message)
    } finally {
      setIsBusy(false)
    }
  }

  async function createClient(allValues) {
    try {
      setIsBusy(true)

      if (isNewClient) {
        const values = allValues?.newClient

        const clientResult = await clientAddMutation({
          variables: {
            input: {
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              language: org?.language || 'hr',
              city: values.city,
              comment: values.comment,
              mobilePhone: formatPhone(values.mobilePhone),
              gender: values.gender,
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              membershipExpirationDate: values.membershipExpirationDate
                ? moment(values.membershipExpirationDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              sendBookingEmail: true,
              sendNewsEmail: true,
              sendHelpEmail: true,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })
        if (values?.sendInviteViaEMail) {
          await inviteUser({
            variables: {
              input: {
                firstName: values.firstName?.trim(),
                lastName: values.lastName?.trim() || '',
                email: values.email,
                gender: values.gender || 'Unspecified',
                mobilePhone: formatPhone(values.mobilePhone),
                role: 'CLIENT',
                clientId: clientResult?.data?.clients?.add?.payload?.id!,
              },
            },
          })
        }

        if (!clientResult?.errors?.length) {
          toast.success(
            (t('translation.AppointmentModal.toast-newClientEntered'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          return clientResult?.data?.clients?.add?.payload
        } else {
          toast.danger(t('translation.AppointmentModal.toast-newClientError'))
        }
      } else {
        const values = allValues?.editedClient
        let clientResult = await updateClient({
          variables: {
            input: {
              id: values?.id,
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              city: values.city,
              comment: values.comment,
              gender: values.gender,
              mobilePhone: formatPhone(values.mobilePhone),
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              membershipExpirationDate: values.membershipExpirationDate
                ? moment(values.membershipExpirationDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })

        if (!clientResult.errors?.length) {
          toast.success(
            (t('translation.EditClient.toast-editClient'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )

          return clientResult?.data?.clients?.update?.payload
        } else {
          toast.danger(
            'Došlo je do pogreške - ' + clientResult?.errors[0]?.message
          )
        }
      }
    } finally {
      setIsBusy(false)
    }
  }

  async function createService(allValues) {
    try {
      setIsBusy(true)
      const values = allValues?.newService

      const input: ServiceInput = {
        color: values.color,
        description: values.description,
        name: values.name,
        bookingAllowed: org?.orgFeatures?.hasOnlineBooking
          ? values.bookingAllowed
          : false,
        hasBookingConfirmation: values.hasBookingConfirmation,
        price: values.price
          ? parseFloat(values.price?.toString().replace(',', '.'))
          : parseFloat('0'),
        durationMinutes: getServiceDurationInMinutes(values.durationMinutes),
        netDurationMinutes: Number(values.netDurationMinutes),
        serviceTypeId: values.type?.id,
        resources: values.resources?.map(x => x.id),
        professionId: values.profession?.id,
        locationId: locationId.toString(),
        resourceTypeId: values.resourceType?.id,
        vatRate: values.vatRate
          ? parseFloat((values.vatRate / 100).toString())
          : parseFloat('0'),
        serviceTypeCategoryId: values.serviceTypeCategoryId,
        discountValue: values?.discountValue
          ? Number(values?.discountValue?.toString().replace(',', '.'))
          : null,
        discountAmount: Number(values?.discountAmount || 0),
        discountType: values?.discountType,
        discountRate: Number(values?.discountRate || 0),
        totalAmount: Number(values?.totalAmount),
      }
      let serviceResult = await createNewService({
        variables: { input },
        refetchQueries: [
          {
            query: queryServiceTypes,
            variables: { locationId },
          },
        ],
      })

      if (serviceResult?.data) {
        appServices.toast.success(
          t('translation.AppointmentModal.toast-serviceCreated')
        )
        return serviceResult.data.services?.add?.payload
      }
    } finally {
      setIsBusy(false)
    }
  }

  function getOnNewClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      form.setFieldValue('newClient', null)
      setActiveForm('client')
      setIsNewClient(true)
      form.setFieldValue('newClient.firstName', '')
      form.setFieldValue('newClient.lastName', '')
      form.setFieldValue('newClient.email', '')
      form.setFieldValue('newClient.mobilePhone', '')
      form.setFieldValue('newClient.address', '')
      form.setFieldValue('newClient.zipCode', '')
      form.setFieldValue('newClient.city', '')
      form.setFieldValue('newClient.gender', '')
      form.setFieldValue('newClient.comment', '')
      form.setFieldValue('newClient.sendInviteViaEMail', false)
      form.setFieldValue('newClient.sendReminderEmail', false)
      form.setFieldValue('newClient.paymentRequired', true)
    }
  }
  // getOnEditClient -> NOT USED

  function getOnNewService(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      setActiveForm('service')
      form.setFieldValue('newService.description', '')
      form.setFieldValue('newService.color', selectedTheme.colors.primary)
      form.setFieldValue('newService.durationMinutes', 30)
      form.setFieldValue('newService.netDurationMinutes', 30)
      form.setFieldValue('newService.bookingAllowed', true)
      form.setFieldValue('newService.hasBookingConfirmation', true)
      form.setFieldValue('newService.vatRate', org?.vatStatus === 'Y' ? 25 : 0)
      form.setFieldValue('newService.price', 0)
      form.setFieldValue('newService.currencyId', org?.currency?.id)
      form.setFieldValue('newService.discountAmount', 0)
      form.setFieldValue('newService.discountRate', 0)
      form.setFieldValue('newService.totalAmount', 0)
      form.setFieldValue('newService.discountType', 'percent')
      form.setFieldValue('newService.resource', resources)
    }
  }

  const getSaveButtonLabel = () => {
    return t('translation.AppointmentModal.button-save')
  }

  const initialValues = {
    internalNote: '',
    date: newEvent?.selectedDayId,
    startTime: {
      id: newEvent?.selectedHour
        ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
        : moment().format('HH') + ':30',
      title: newEvent?.selectedHour
        ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
        : moment().format('HH') + ':30',
    },
    duration: getServiceHoursAndMinutes(location?.timeSlotDuration),
    endTime: getServiceHoursAndMinutes(
      getServiceDurationInMinutes(
        newEvent?.selectedHour
          ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
          : moment().format('HH') + ':30'
      ) + location?.timeSlotDuration
    ),
    endTimeUtc: moment(
      moment(getISODate())?.format('YYYY-MM-DD') +
        ' ' +
        getServiceHoursAndMinutes(
          getServiceDurationInMinutes(
            newEvent?.selectedHour
              ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
              : moment().format('HH') + ':30'
          ) + location?.timeSlotDuration
        )
    ).toISOString(),
    onlineBookingAllowed: false,
    resource: selectedResource,
  }

  const handleValueChange = async (name, value, values, form) => {
    if (name === 'service') {
      const service = flattenedServices?.find(x => x?.id === value)

      const duration = DateUtil.minutesToDurationString(
        service?.durationMinutes!
      )

      formRef?.current?.setFieldValue('duration', duration)
    }

    if (name?.startsWith('newClientSlot') && name?.endsWith('client')) {
      const service = flattenedServices?.find(x => x?.id === values?.service)

      if (
        hasPrepaymentCredentials &&
        hasPrepaymentsEnabled &&
        (service?.totalAmount || 0) > 0
      ) {
        form.setFieldValue(
          'newClientSlot.sendPaymentRequest',
          value?.paymentRequired
        )
      }

      form.setFieldValue(
        'newClientSlot.sendAppointmentEmail',
        location?.sendAppointmentEmailsToClients &&
          !!values?.newClientSlot?.client?.email
      )
      form.setFieldValue(
        'newClientSlot.sendAppointmentSms',
        location?.sendAppointmentSmsToClients &&
          !!values?.newClientSlot?.client?.mobilePhone
      )
    }
  }

  const handleSubmit = async (values, form) => {
    if (activeForm === 'appointment') {
      await handleCreateGroupAppointment(values)
    }

    if (activeForm === 'client') {
      const client = await createClient(values)
      formRef.current.setFieldValue('newClientSlot.client', client)
      formRef.current.setFieldValue(
        'newClientSlot.sendAppointmentEmail',
        location?.sendAppointmentEmailsToClients && !!client?.email
      )
      formRef.current.setFieldValue(
        'newClientSlot.sendAppointmentSms',
        location?.sendAppointmentSmsToClients && !!client?.mobilePhone
      )
      if (hasPrepaymentCredentials && hasPrepaymentsEnabled) {
        form.setFieldValue(
          'newClientSlot.sendPaymentRequest',
          client?.paymentRequired
        )
      }
      setActiveForm('appointment')
      /*  // for some reason form.setFieldValue('client', client) doesn't work properly
      formRef.current.setFieldValue('client', client)
      formRef.current.setFieldValue('services', values?.services)
      formRef.current.setFieldValue('internalNote', values?.internalNote)
      formRef.current.setFieldValue(
        'sendAppointmentEmail',
        values?.sendAppointmentEmail
      )
      if (!location?.sendAppointmentEmailsToClients) {
        form.setFieldValue('sendAppointmentEmail', false)
      } else {
        formRef.current.setFieldValue(
          'sendAppointmentEmail',
          values?.sendAppointmentEmail
        )
      }
      formRef.current.setFieldValue(
        'sendPaymentRequest',
        values?.sendPaymentRequest
      )
      setActiveForm('appointment') */
    }
    if (activeForm === 'service') {
      const service = await createService(values)

      form.setFieldValue('service', service?.id)
      form.setFieldValue(
        'duration',
        getServiceHoursAndMinutes(service?.durationMinutes)
      )
      form.setFieldValue('newService', undefined)

      setActiveForm('appointment')
    }
  }

  const isLoading =
    locationLoading || loadingOrgData || serviceTypesData.loading

  const isUpdating =
    loadingAddClient ||
    loadingCreateService ||
    loadingUpdateClient ||
    createGroupBookingMutation.isLoading

  useEffect(() => {
    const clientsSortBy = localStorage.getItem('clientsSortBy')
    if (clientsSortBy) {
      setClientsSortBy(clientsSortBy)
    } else setClientsSortBy('id_desc')
  }, [])

  return (
    <div>
      <Container>
        <Modal>
          <ModalWrapper>
            {isLoading ? (
              <Loader isComponent />
            ) : (
              <>
                <FormWrapper>
                  <Form
                    onValueChange={handleValueChange}
                    initialValues={initialValues}
                    validationSchema={
                      activeForm === 'client'
                        ? ClientSchema
                        : activeForm === 'appointment'
                        ? GroupAppointmentSchema
                        : activeForm === 'service'
                        ? ServiceSchema
                        : undefined
                    }
                    // enableReinitialize
                    validateOnChange
                    isInitialValid={true}
                    onSubmit={handleSubmit}
                  >
                    {form => {
                      const {
                        values,
                        errors,
                        setFieldValue,
                        validateForm,
                        touched,
                      } = form
                      formRef.current = form
                      //console.log({ values, errors })

                      const service = flattenedServices?.find(
                        x => x?.id === values?.service
                      )
                      const isPrepaymentDisabled =
                        !hasPrepaymentCredentials ||
                        !service ||
                        (service?.totalAmount || 0) <= 0

                      return (
                        <>
                          {(isBusy || isUpdating) && <Loader isComponent />}
                          {activeForm === 'appointment' ? (
                            <>
                              <TabView
                                selectedTabIndex={selectedTabIndex}
                                setSelectedTabIndex={setSelectedTabIndex}
                                isSingleTab={false}
                                hasFooter={false}
                              >
                                <Tab
                                  hasFooter={false}
                                  title={t(
                                    'translation.AppointmentModal.addAppointment'
                                  )}
                                >
                                  <GroupAppointmentFormFields
                                    form={form}
                                    location={location}
                                    serviceTypes={serviceTypes}
                                    org={org}
                                    onNewService={getOnNewService(form)}
                                    onNewClient={getOnNewClient(form)}
                                    isNew
                                    isPrepaymentDisabled={isPrepaymentDisabled}
                                    flattenedServices={flattenedServices}
                                  />
                                </Tab>
                              </TabView>
                            </>
                          ) : activeForm === 'client' ? (
                            <GroupClientForm
                              form={form}
                              isNewClient={true}
                              location={location}
                              org={org}
                              setActiveForm={setActiveForm as any}
                              fieldsNamePrefix={'newClient'}
                              hasPrepaymentCredentials={
                                hasPrepaymentCredentials
                              }
                            />
                          ) : activeForm === 'service' ? (
                            <ServiceForm2
                              form={form}
                              isNew={true}
                              hasOnlineBooking={
                                org?.orgFeatures?.hasOnlineBooking || false
                              }
                              isAppointment
                              onGoBack={() => {
                                form?.setFieldValue('newService', undefined)
                                setActiveForm('appointment')
                              }}
                              fieldsNamePrefix="newService"
                            />
                          ) : null}
                        </>
                      )
                    }}
                  </Form>
                </FormWrapper>
                <AppointmentHotelFooter
                  onSaveClick={() => {
                    formRef.current?.submitForm()
                  }}
                  saveButtonLabel={getSaveButtonLabel()}
                  onCloseClick={() => {
                    if (activeForm === 'client') {
                      setActiveForm('appointment')
                    } else if (activeForm === 'appointment') {
                      if (formRef?.current && formRef?.current?.dirty) {
                        if (
                          confirm(t('translation.Form.unsavedChangesMessage'))
                        ) {
                          formRef?.current?.resetForm({
                            values: formRef?.current?.values,
                          })
                          setTimeout(() => {
                            onClose()
                          }, 0)
                        }
                      } else {
                        onClose()
                      }
                    } else if (activeForm === 'service') {
                      formRef.current?.setFieldValue('newService', undefined)
                      setActiveForm('appointment')
                    }
                  }}
                />
              </>
            )}
          </ModalWrapper>
        </Modal>
      </Container>
    </div>
  )
}
