import styled from 'styled-components'
import { AvatarWrapper } from '../../../components/Avatar/Avatar'
import { device } from '../../../constants'

type TWorkHourNameProps = {
  isInactive?: boolean
  isBusy?: boolean
  isDisabled?: boolean
  noProfession?: boolean
}

export const WorkHourName = styled.span<TWorkHourNameProps>`
  flex: 2;
  font-size: 14px;
  color: ${props =>
    props.isInactive
      ? props.theme.colors.accent5
      : props.isBusy
      ? props.theme.colors.accent4
      : props.noProfession
      ? props.theme.colors.accent1
      : props.isDisabled
      ? props.theme.colors.grayDark
      : props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

type TWorkHourHintProps = {
  isInactive?: boolean
  isBusy?: boolean
  isDisabled?: boolean
}
export const WorkHourHint = styled.span<TWorkHourHintProps>`
  background: ${props =>
    props.isInactive
      ? props.theme.colors.accent5
      : props.isDisabled
      ? props.theme.colors.grayDark
      : props.theme.colors.accent4};
  padding: 2px 7px;
  font-size: 10px;
  border-radius: 4px;
  color: ${props => props.theme.colors.light};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
`

export const WorkHourRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const SelectRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${AvatarWrapper} {
    margin-right: 8px;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`

type TItemRowProps = {}
export const ItemRow = styled.div<TItemRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SelectOptionLabel = styled.span<{
  hasNoProfession?: boolean
  resourceBookingNotConfirmed?: boolean
  resourceBookingCanceled?: boolean
  isClientSelect?: boolean
}>`
  font-size: 14px;
  color: ${props =>
    props.hasNoProfession || props.resourceBookingNotConfirmed
      ? props.theme.colors.accent5
      : props.resourceBookingCanceled
      ? props.theme.colors.accent1
      : props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
  margin-right: 15px;
  flex-shrink: 0;

  @media ${device.tablet} {
    flex: initial;
    margin-right: ${props => (props.isClientSelect ? '10px' : '15px')};
    font-size: ${props => (props.isClientSelect ? '12px' : null)};
  }
`

export const ServiceName = styled.span<{
  hasNoProfession?: boolean
  resourceBookingNotConfirmed?: boolean
  resourceBookingCanceled?: boolean
  isServiceSelect?: boolean
}>`
  flex: 3;
  font-size: 14px;
  color: ${props =>
    props.hasNoProfession || props.resourceBookingNotConfirmed
      ? props.theme.colors.accent5
      : props.resourceBookingCanceled
      ? props.theme.colors.accent1
      : props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 500px;
  @media ${device.tablet} {
    min-width: unset;
    margin-right: ${props => (props.isServiceSelect ? '10px' : null)};
  }
`

export const SelectOptionWarning = styled.span`
  flex: 1;
  font-size: 14px;
  color: ${props => props.theme.colors.accent5};
  text-overflow: ellipsis;
  //overflow: hidden;
  white-space: nowrap;
  display: inline;
  margin-right: 5px;
  max-width: 20px;

  @media ${device.tablet} {
    flex: initial;
  }
`
export const ServiceCircle = styled.div`
  width: 1.714rem;
  height: 1.714rem;
  border-radius: 0.857rem;
  background: ${props => props.color};
  min-width: 1.714rem;
  margin-right: 12px;
  @media ${device.tablet} {
    width: 2px;
    min-width: unset;
    margin-right: 0;
  }
`
