import { createGlobalStyle } from 'styled-components'
import { fontFamily } from './components/style/fonts'
import { device } from './constants'
const GlobalStyles = createGlobalStyle<{ selectedTheme: any }>`

//hide Hubspot cookie banner
div#hs-eu-cookie-confirmation{display:none;}

.ql-container.ql-snow {
        border: 1px solid #dcdfe2;
            border-radius: 0px 0px 4px 4px;
}

.ql-editor {
    -webkit-user-select: text;

    &:focus {
        border: 1px solid #6a4ee1;
    box-shadow: 0px 0px 0px 3px #6b81ff;
    outline: none;
    -webkit-transition-property: border-width,border-color,box-shadow;
    transition-property: border-width,border-color,box-shadow;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    border-radius: 4px;
    }
}

.ql-toolbar.ql-snow {
    border: 1px solid #dcdfe2;
    font-family: 'Poppins, Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    border-radius: 4px 4px 0px 0px;
}

.wrapper {
  padding: 10px;
}

.form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.form-fields {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.label {
  display: inline-block;
  margin-bottom: 8px;
  font-weight: 600;
}

.form-error,
.field-error {
  margin-top: 15px;
  margin-bottom: 0;
  color: #f00;
  font-size: 13px;
  font-weight: 500;
}


.submit-button {
  display: block;
  width: 100%;
  margin-top: 30px;
  height: 55px;
  border: 0;
  background: #07f;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  border-radius: 8px;
}

.submit-button:not(:disabled):hover,
.submit-button:not(:disabled):focus {
  outline: 0;
  background: #0558b8;
}

@media (min-width: 321px) {
  .form-fields {
    grid-template-columns: 1fr 1fr;
  }

  .form-group--card-holder {
    grid-column: span 2;
  }

  .form-group--card-number {
    grid-column: span 2;
  }
}

@media (min-width: 480px) {
  .wrapper {
    padding: 20px;
  }

  .form {
    padding: 40px;
  }
}
  
.HW_badge_cont{
visibility:hidden!important;
width: 0px!important;
height: 0px!important;

}
.HW_badge {
  visibility:hidden!important;
  width: 0px!important;
height: 0px!important;
}
input[type='radio'], input[type='checkbox']{
margin-left: 2px;
-moz-appearance:initial;
width: 35px;
}
.rc-tooltip-inner{
@media ${device.tablet}{
border: none;
      height: 100vh;
    padding: 0px;
    width: 100vw;
}
}
.react-tel-input input {
min-height: 35px;
    width: 90%;
}
p {
margin-bottom: 0px;
}
  .react-tel-input {
    width: auto;
  }
  
:focus {
   @media ${device.tablet}{
    outline: none}
}
.rc-tooltip{
background: ${props => props.selectedTheme.colors.light};
    z-index: 999999999;
    @media ${device.tablet} {
      z-index: 2147483647;
    }
}

#beamerSelector {
display: none !important;
}

.rbc-time-gutter.rbc-time-column .rbc-timeslot-group div {
border-left: none !important;
}


.rc-tooltip-arrow-inner.sidebar{
    border-right-color: ${props => props.selectedTheme.colors.primary}; 
}

.rc-tooltip-arrow-inner.infoButton{
    border-top-color: ${props => props.selectedTheme.colors.text}; 
}

#react-select-portal-target {
 div {
z-index: 99999999999999;
}
}
.Toastify__toast-container {
border-radius: 10px;
z-index: 9999999999;
    width: auto;
    max-width: 420px;
    
    @media ${device.tablet}{
      max-width: 100%;
      width: 100%;
      border-radius: 0px;
    }
}

.Toastify__toast-body{
  @media ${device.tablet}{
     text-align: center;
    }
}

.Toastify__toast {
 @media ${device.tablet}{
     min-height: 50px;
    }
}

.Toastify__toast--success {
background: ${props => props.selectedTheme.colors.primary} !important; 
  border-radius: 10px !important;
    padding: 10px 20px !important;
    
    @media ${device.tablet}{
             border-radius: 0px !important;
    }
}

.Toastify__toast--error {
background: ${props => props.selectedTheme.colors.accent5} !important; 
  border-radius: 10px !important;
    padding: 10px 20px !important;
}

.Toastify__toast--default {
    border-radius: 10px !important;
    padding: 10px 20px !important;
}

[data-reach-tooltip] {
z-index: 999999999999999 !important;
}

html,
body {
    font-family: ${fontFamily};
    width: 100%;
    height: 100%;

    font-size: 14px;
    
     @media ${device.tablet}{
     overflow: hidden;
    user-select: none;
        &::-webkit-scrollbar {
      display: none;
    }
    }
}

.webkitScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}


.kiamaki {
height: 100%;
display: flex;

}

input {
font-family: ${fontFamily} !important;
}

a {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    
    :hover {
        text-decoration: none;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root {
    width: 100%;
    height: 100%;

}
.mbsc-mobiscroll .mbsc-fr-hdr, .mbsc-mobiscroll .mbsc-fr-btn {
  color: #6a4ee1;
}
.mbsc-mobiscroll .mbsc-sc-whl-l {
  border-top: 1px solid #6a4ee1;
  border-bottom: 1px solid #6a4ee1;
}
.mbsc-fr {
  z-index: 99999999999999;
}
.mbsc-fr-bubble {
  z-index: 99999999999999;
}

.rc-tooltip-appointment{
  opacity: 1 !important;
}

.webkitThinScrollbar {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
}

.webkitThinScrollbar2 {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
}

.checkboxTW {
position: relative;
  cursor: pointer;
  height: 40px;
  &[disabled] {
    &:before {
      background-color: #dbdfe2 !important;
    }
    :checked:before {
      background-color: #dbdfe2 !important;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: -1px;
    background-color: #fff;
    border: 1px solid #dbdfe2;
    border-radius: 4px;
  }
  :checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: -1px;
    background-color: #6a4ee1;
  }
  :checked:after {
    content: '';
    display: block;
    width: 10px;
    height: 20px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 7px;
    left: 15px;
  }
}

.collapsed {
  display: none;
}

.collapsed-group > div:not(.collapse-group-heading) {
  display: none;
}

.collapse-group-heading::after {
  flex-shrink: 0;
  width: 36px;
  height: 20px;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-19bqh2r'%3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-size: 1.25rem;
  background-position: center center;
  color: #333;
  transform: rotate(180deg);
  background-color: #fff;
}

.collapsed-group .collapse-group-heading::after {
  transform: rotate(0deg);
}

.collapse-group-heading {
  display: flex;
}


`

export default GlobalStyles
