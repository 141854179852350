import React, { useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import moment from 'moment'
import { getStatusColor, getStatusLabel } from 'src/helpers'
import { useSdk } from 'sdk'
import { useParams } from 'react-router'
import { useGetLocationLoading } from 'state/queries'
import { Appointment as AppointmentModal } from 'views/Appointment/Appointment'
import { ShoppingCart } from 'components/Icon/Icons/ShoppingCart'

const AppointmentCardGroupMobile = props => {
  const { event, org } = props
  const selectedTheme = useContext(ThemeContext)
  const { t } = useSdk()

  const [resourceBookingId, setResourceBookingId] = useState<any>()
  const handleOnCloseEvent = () => {
    setResourceBookingId(null)
  }

  return (
    <Appointment
      key={event?.id}
      onClick={() => {
        setResourceBookingId(event?.resourceBookingId || null)
      }}
    >
      <Date>
        <p>{moment(event?.date).format('DD')}</p>
        <span>{moment(event?.date).format('MMM')}</span>
      </Date>

      <Service>
        {event?.services?.map(service => (
          <p key={service?.id}>{service?.name}</p>
        ))}
        <ServiceSpan>
          {event?.startTime + ' - ' + event?.endTime} |{' '}
          <Status
            color={getStatusColor(event?.status, selectedTheme)}
            reason={event?.reason}
          >
            {getStatusLabel(event?.status, t, false, event?.reason)}
          </Status>
        </ServiceSpan>
        <ServiceSpan>
          {event?.services.reduce(
            (n, { durationMinutes }) => n + durationMinutes,
            0
          ) +
            ' ' +
            t('translation.ClientDetailsMobile.min-with') +
            ' ' +
            event?.assignedTo?.firstName +
            ' ' +
            event?.assignedTo?.lastName}
        </ServiceSpan>
        {event?.notes ? <Comment>{event?.notes}</Comment> : null}
      </Service>
      <ClientDetailsText>
        {event?.services.reduce((n, { price }) => n + price, 0) +
          ' ' +
          (org?.currency?.id ? org?.currency?.id : '')}
      </ClientDetailsText>

      {resourceBookingId ? (
        <AppointmentModal
          isDashboard={false}
          handleOnCloseEvent={handleOnCloseEvent}
          eventId={resourceBookingId}
        />
      ) : null}
      <AppointmentTypeContainer>
        {event?.isOnlineBooking ? (
          <ShoppingCart size="smaller" style={{ strokeWidth: 1.5 }} />
        ) : null}
      </AppointmentTypeContainer>
    </Appointment>
  )
}

export default AppointmentCardGroupMobile

export const Appointment = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  position: relative;
  :not(:last-of-type) {
    border-bottom: 1px solid #f0f0f0;
  }
`
export const Service = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
  p {
    font-size: 14px;
    font-weight: 500;
  }
`
export const ServiceSpan = styled.span`
  font-size: 12px;
  color: #555;
  line-height: 2;
`
export const Date = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  align-self: baseline;
  margin-right: 15px;
  margin-top: 1px;
  p {
    font-size: 14px;
  }
  span {
    font-size: 12px;
  }
`

export const Status = styled.div<{ reason?: string }>`
  display: inline;
  color: ${props =>
    props.reason === 'ORG_CANCELED' || props.reason === 'CLIENT_CANCELED'
      ? props.theme.colors.accent1
      : props.color};
  font-size: 11px;
  font-weight: 500;
`

export const ClientDetailsText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
`

export const Comment = styled.div`
  color: ${props => props.theme.colors.accent5};
  font-size: 12px;
  display: flex;
  align-items: center;
`

export const AppointmentTypeContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
`
