import React, { useContext } from 'react'
import { UpgradeImage } from './styles'
import { useSdk } from 'sdk'
import { useLocation } from 'react-router'
import { analyticsTrack } from '../../helpers'
import { CheckFilled } from 'components/Icon/Icons/CheckFilled'
import { useHeader } from 'mobile/Header'
import { MobileContext, UserContext } from 'src/Context'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { useACL } from 'src/sdk/acl'
import { Crisp } from 'crisp-sdk-web'

type UpgradePageProps = {
  header: string
  title: string
  text: string
  setOpenBillingModal: any
  list: string[]
  image: string
  isReportsPage?: boolean
  isAvailableOnAdvancedPlan?: boolean
  showComingSoonInsteadOfUpgrade?: boolean
  isGiftCardsPage?: boolean
  closeModal?: () => void
  isAppointmentModal?: boolean
  notAvailableMessage?: string
}
export const UpgradePage = (props: UpgradePageProps) => {
  const {
    header,
    title,
    text,
    setOpenBillingModal,
    list,
    image,
    isReportsPage,
    isAvailableOnAdvancedPlan = false,
    showComingSoonInsteadOfUpgrade = false,
    isGiftCardsPage,
    closeModal,
    isAppointmentModal,
    notAvailableMessage,
  } = props
  const { t, navigateTo } = useSdk()
  const { pathname } = useLocation()
  const isMobile = useContext(MobileContext)
  useHeader({
    content: header,
    onGoBack: navigateTo.locationSettings,
  })

  const { hasPermission } = useACL()
  return (
    <>
      {isAppointmentModal ? (
        <div className="flex items-center lg:mt-[40px] mt-auto justify-center m-[20px] flex-col h-[300px]">
          <div className="text-zoyya-grayDark text-center mb-[20px] lg:mt-[10px] mt-auto">
            {notAvailableMessage}
          </div>
          {hasPermission('ACCESS_BILLING') ? (
            <ButtonTW
              label={t('translation.App.upgradeYourPlan')}
              type="button"
              variant={'primary'}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()

                analyticsTrack('UPGRADE button click', { pathname })
                closeModal?.()
                setOpenBillingModal(true)
              }}
              name={'upgradeButton'}
              size={'large'}
            />
          ) : null}
        </div>
      ) : (
        <div className="grid h-full justify-center w-full overflow-auto no-scrollbar lg:grid-cols-[1fr_1.2fr] ">
          <div className="m-3 xs:m-8 pb-8 row-[2] lg:mx-[60px] lg:row-[unset] lg:my-auto">
            {!isMobile ? (
              <h1 className="mb-[25px] lg:mx-0 lg:text-[30px] font-semibold text-[24px] mx-[30px]">
                {title}
              </h1>
            ) : null}
            <div className="mb-8 text-center lg:text-left">{text}</div>
            <div className="lg:mb-[64px] mb-[30px]">
              {list?.map((item, index) => (
                <div className="flex mb-3" key={index}>
                  <div className="w-[24px] h-[24px] rounded-full bg-zoyya-primary mr-3 justify-center items-center flex shrink-0">
                    <CheckFilled size={'extraSmall'} color={'#fff'} />
                  </div>
                  <div key={index}>{item}</div>
                </div>
              ))}
            </div>
            <div className="flex items-center lg:mt-[40px] mt-auto">
              {showComingSoonInsteadOfUpgrade ? (
                <>
                  <div className="text-center font-semibold text-zoyya-primary uppercase text-xl w-full">
                    {t('translation.UpgradePage.coming-soon')}
                  </div>
                </>
              ) : hasPermission('ACCESS_BILLING') ? (
                <ButtonTW
                  label={
                    isAvailableOnAdvancedPlan
                      ? t('translation.UpgradePage.button-label-business-plan')
                      : t('translation.UpgradePage.button-label')
                  }
                  variant={'primary'}
                  onClick={() => {
                    analyticsTrack('UPGRADE button click', { pathname })
                    setOpenBillingModal(true)
                  }}
                  name={'upgradeButton'}
                  size={'large'}
                />
              ) : null}
            </div>
          </div>

          <div className={`lg:mt-24 rounded-xl mt-9`}>
            {isMobile ? (
              <h1 className="mb-[25px] lg:mx-0 lg:text-[30px] font-semibold text-[24px] mx-[30px]">
                {title}
              </h1>
            ) : null}
            <div>
              <img className="mb-8 ml-3 mt-3 w-full" src={image} />
            </div>
          </div>
        </div>
      )}
      <></>
    </>
  )
}
export const LoyaltyUpgradePage = (props: {
  setOpenBillingModal: any
  isAdvancedPlan: boolean
  hasFeatureFlag: boolean
  isTemplate?: boolean
}) => {
  const { setOpenBillingModal, isAdvancedPlan, hasFeatureFlag, isTemplate } =
    props
  const { t, navigateTo } = useSdk()
  const { pathname } = useLocation()
  const isMobile = useContext(MobileContext)
  const header = 'Program vjernosti'
  const title = header

  const image = isTemplate
    ? '/assets/loyalty-templates.png'
    : '/assets/loyalty.png'

  useHeader({
    content: header,
    onGoBack: navigateTo.locationSettings,
  })

  const { hasPermission } = useACL()

  return (
    <div className="grid h-full justify-center w-full overflow-auto no-scrollbar lg:grid-cols-[1fr_1.2fr] ">
      <div className="m-3 xs:m-8 pb-8 row-[2] lg:mx-[60px] lg:row-[unset] lg:my-auto">
        {!isMobile ? (
          <h1 className="mb-[25px] lg:mx-0 lg:text-[30px] font-semibold text-[24px] mx-[30px]">
            {title}
          </h1>
        ) : null}
        <div className="mb-8 text-center lg:text-left">
          {
            'Nagradite svoje odabrane, vjerne klijente pomoću članskih kartica s popustima. Popust može vrijediti za sve, određene ili pojedinačne usluge, a vi određujete trajanje pogodnosti.'
          }
        </div>
        <div className="lg:mb-[64px] mb-[30px]">
          <div className="flex mb-3">
            <div className="w-[24px] h-[24px] rounded-full bg-zoyya-primary mr-3 justify-center items-center flex shrink-0">
              <CheckFilled size={'extraSmall'} color={'#fff'} />
            </div>
            <div className="flex flex-col gap-1">
              <div className="font-medium">
                {'Privucite i zadržite klijente'}
              </div>
              <div className="text-sm text-zoyya-secondaryDarker">
                {
                  'Nagradite lojalnost ekskluzivnim popustima i potaknite ponovne dolaske.'
                }
              </div>
            </div>
          </div>
          <div className="flex mb-3">
            <div className="w-[24px] h-[24px] rounded-full bg-zoyya-primary mr-3 justify-center items-center flex shrink-0">
              <CheckFilled size={'extraSmall'} color={'#fff'} />
            </div>
            <div className="flex flex-col gap-1">
              <div className="font-medium">
                {'Fleksibilnost prilagođena vašem poslovanju'}
              </div>
              <div className="text-sm text-zoyya-secondaryDarker">
                {'Odredite koje usluge pokriva popust i koliko dugo traje.'}
              </div>
            </div>
          </div>
          <div className="flex mb-3">
            <div className="w-[24px] h-[24px] rounded-full bg-zoyya-primary mr-3 justify-center items-center flex shrink-0">
              <CheckFilled size={'extraSmall'} color={'#fff'} />
            </div>
            <div className="flex flex-col gap-1">
              <div className="font-medium">{'Jednostavno upravljanje'}</div>
              <div className="text-sm text-zoyya-secondaryDarker">
                {
                  'Sve postavke kartica prilagodite u nekoliko klikova - bez dodatnih komplikacija!'
                }
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center lg:mt-[40px] mt-auto">
          {!isAdvancedPlan && hasPermission('ACCESS_BILLING') ? (
            <ButtonTW
              label={t('translation.UpgradePage.button-label')}
              variant={'primary'}
              onClick={() => {
                analyticsTrack('UPGRADE button click', { pathname })
                setOpenBillingModal(true)
              }}
              name={'upgradeButton'}
              size={'large'}
            />
          ) : !hasFeatureFlag ? (
            <ButtonTW
              label={'Zatraži pristup programu vjernosti'}
              variant={'primary'}
              onClick={() => {
                Crisp.chat.open()
              }}
              name={'featureRequestButton'}
              size={'large'}
            />
          ) : null}
        </div>
      </div>

      <div className={`lg:mt-24 rounded-xl mt-9`}>
        {isMobile ? (
          <h1 className="mb-[25px] lg:mx-0 lg:text-[30px] font-semibold text-[24px] mx-[30px]">
            {title}
          </h1>
        ) : null}
        <div>
          <img className="mb-8 ml-3 mt-3 w-full" src={image} />
        </div>
      </div>
    </div>
  )
}

export const AttachmentsUpgradePage = ({ isAppointmentModal, closeModal }) => {
  const { t } = useSdk()

  const title = t('translation.AttachmentsUpgradePage.title')
  const image = t('translation.AttachmentsUpgradePage.imageUrl')

  const text = t('translation.AttachmentsUpgradePage.description')
  const list = [
    t('translation.AttachmentsUpgradePage.bullet1'),
    t('translation.AttachmentsUpgradePage.bullet2'),
    t('translation.AttachmentsUpgradePage.bullet3'),
  ]
  const { pathname } = useLocation()
  const isMobile = useContext(MobileContext)

  const { setOpenBillingModal } = useContext(UserContext) as {
    setOpenBillingModal: React.Dispatch<React.SetStateAction<boolean>>
  }
  const { hasPermission } = useACL()

  return isAppointmentModal ? (
    <>
      <div className="flex items-center lg:mt-[40px] mt-auto justify-center m-[0px] flex-col h-[300px]">
        <div className="text-zoyya-grayDark mb-[20px] lg:mt-[10px] mt-auto">
          {t('translation.AttachmentsUpgradePage.attachments-not-available')}
        </div>
        {hasPermission('ACCESS_BILLING') ? (
          <ButtonTW
            label={t('translation.App.upgradeYourPlan')}
            type="button"
            variant={'primary'}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              analyticsTrack('UPGRADE button click', { pathname })
              closeModal()
              setOpenBillingModal(true)
            }}
            name={'upgradeButton'}
            size={'large'}
          />
        ) : null}
      </div>
    </>
  ) : (
    <div className="grid lg:grid-cols-[1fr_800px] h-full justify-center w-full overflow-auto no-scrollbar min-[1440px]:grid-cols-[1fr_1.2fr] ">
      <div className="m-3 xs:m-8 pb-8 row-[2] lg:mx-[60px] lg:row-[unset] lg:my-auto">
        {!isMobile ? (
          <h1 className="mb-[25px] lg:mx-0 lg:text-[30px] font-semibold text-[24px] mx-[30px]">
            {title}
          </h1>
        ) : null}
        <div className="mb-8 text-center lg:text-left">{text}</div>
        <div className="lg:mb-[64px] mb-[30px]">
          {list.map((item, index) => (
            <div className="flex mb-3" key={index}>
              <div className="w-[24px] h-[24px] rounded-full bg-zoyya-primary mr-3 justify-center items-center flex shrink-0">
                <CheckFilled size={'extraSmall'} color={'#fff'} />
              </div>
              <div key={index}>{item}</div>
            </div>
          ))}
        </div>
        <div className="flex items-center lg:mt-[40px] mt-auto">
          {hasPermission('ACCESS_BILLING') ? (
            <ButtonTW
              label={t('translation.App.upgradeYourPlan')}
              variant={'primary'}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                analyticsTrack('UPGRADE button click', { pathname })
                setOpenBillingModal(true)
              }}
              name={'upgradeButton'}
              size={'large'}
            />
          ) : null}
        </div>
      </div>

      <div className="lg:mt-24 rounded-xl mt-9 lg:shadow-xs">
        {isMobile ? (
          <h1 className="mb-[25px] lg:mx-0 lg:text-[30px] font-semibold text-[24px] mx-[30px]">
            {title}
          </h1>
        ) : null}

        <div>
          <UpgradeImage src={image} />
        </div>
      </div>
    </div>
  )
}
type TChangelogUpgradePageProps = {
  isAppointmentModal?: boolean
  closeModal?: () => void
  isLocationChangeLog?: boolean
}
export const ChangelogUpgradePage = (props: TChangelogUpgradePageProps) => {
  const { isAppointmentModal, closeModal, isLocationChangeLog = false } = props
  const { t } = useSdk()
  const { pathname } = useLocation()
  const { setOpenBillingModal } = useContext(UserContext) as {
    setOpenBillingModal: React.Dispatch<React.SetStateAction<boolean>>
  }
  const { hasPermission } = useACL()

  return isAppointmentModal ? (
    <>
      <div className="flex items-center lg:mt-[40px] mt-auto justify-center m-[20px] flex-col h-[300px]">
        <div className="text-zoyya-grayDark mb-[20px] lg:mt-[10px] mt-auto">
          {isLocationChangeLog
            ? t(
                'translation.LocationChangelogUpgradePage.changelog-not-available'
              )
            : t('translation.ChangelogUpgradePage.changelog-not-available')}
        </div>

        {hasPermission('ACCESS_BILLING') ? (
          <ButtonTW
            label={t('translation.App.upgradeYourPlan')}
            type="button"
            variant={'primary'}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              analyticsTrack('UPGRADE button click', { pathname })
              closeModal?.()
              setOpenBillingModal(true)
            }}
            name={'upgradeButton'}
            size={'large'}
          />
        ) : null}
      </div>
    </>
  ) : (
    <>
      <div className="flex items-center lg:mt-[40px] mt-auto justify-center m-[20px] flex-col h-[300px]">
        <div className="text-zoyya-grayDark mb-[20px] lg:mt-[10px] mt-auto">
          {isLocationChangeLog
            ? t(
                'translation.LocationChangelogUpgradePage.changelog-not-available'
              )
            : t('translation.ChangelogUpgradePage.changelog-not-available')}
        </div>
        {hasPermission('ACCESS_BILLING') ? (
          <ButtonTW
            label={t('translation.App.upgradeYourPlan')}
            type="button"
            variant={'primary'}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              analyticsTrack('UPGRADE button click', { pathname })
              closeModal?.()
              setOpenBillingModal(true)
            }}
            name={'upgradeButton'}
            size={'large'}
          />
        ) : null}
      </div>
    </>
  )
}
type TPrepaymentUpgradePageProps = {
  isAppointmentModal?: boolean
  closeModal?: () => any
  footerContent?: any
  complexBooking?: boolean
}
export const PrepaymentsUpgradePage = (props: TPrepaymentUpgradePageProps) => {
  const { isAppointmentModal, closeModal, footerContent, complexBooking } =
    props
  const { t } = useSdk()
  const { pathname } = useLocation()
  const isMobile = useContext(MobileContext)
  const { setOpenBillingModal } = useContext(UserContext) as {
    setOpenBillingModal: React.Dispatch<React.SetStateAction<boolean>>
  }
  const { hasPermission } = useACL()

  return isAppointmentModal ? (
    <>
      <div
        className={`flex items-center lg:mt-[40px] mt-auto flex-col justify-center m-[20px] ${
          complexBooking ? 'h-full' : 'h-[300px]'
        }`}
      >
        <div className="text-zoyya-grayDark mb-[20px]">
          {t('translation.PrepaymentsUpgradePage.prepayments-not-available')}
        </div>
        {hasPermission('ACCESS_BILLING') ? (
          <ButtonTW
            label={t('translation.App.upgradeYourPlan')}
            type="button"
            variant={'primary'}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              analyticsTrack('UPGRADE button click', { pathname })
              closeModal?.()
              setOpenBillingModal(true)
            }}
            name={'upgradeButton'}
            size={'large'}
          />
        ) : null}
      </div>
    </>
  ) : (
    <>
      <div
        className={`flex items-center flex-col justify-center m-[20px] h-full `}
      >
        <div className="text-zoyya-grayDark mb-[20px]">
          {t('translation.PrepaymentsUpgradePage.prepayments-not-available')}
        </div>
        {hasPermission('ACCESS_BILLING') ? (
          <ButtonTW
            label={t('translation.App.upgradeYourPlan')}
            type="button"
            variant={'primary'}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              analyticsTrack('UPGRADE button click', { pathname })
              setOpenBillingModal(true)
            }}
            name={'upgradeButton'}
            size={'large'}
          />
        ) : null}
      </div>
      {isMobile && footerContent ? footerContent : null}
    </>
  )
}

type TClientRemindersUpgradePage = {
  closeModal?: () => void
}
export const ClientRemindersUpgradePage = (
  props: TClientRemindersUpgradePage
) => {
  const { closeModal } = props
  const { t } = useSdk()
  const { pathname } = useLocation()
  const { setOpenBillingModal } = useContext(UserContext) as {
    setOpenBillingModal: React.Dispatch<React.SetStateAction<boolean>>
  }
  const { hasPermission } = useACL()

  return (
    <>
      <div className="flex items-center lg:mt-[40px] mt-auto justify-center m-[20px] flex-col h-[300px]">
        <div className="text-zoyya-grayDark text-center mb-[20px] lg:mt-[10px] mt-auto">
          {t('translation.ClientReminders.upgrade-page-text')}
        </div>
        {hasPermission('ACCESS_BILLING') ? (
          <ButtonTW
            label={t('translation.App.upgradeYourPlan')}
            type="button"
            variant={'primary'}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              analyticsTrack('UPGRADE button click', { pathname })
              closeModal?.()
              setOpenBillingModal(true)
            }}
            name={'upgradeButton'}
            size={'large'}
          />
        ) : null}
      </div>
    </>
  )
}

type TClientProductsUpgradePage = {
  closeModal?: () => void
}
export const ClientProductsUpgradePage = (
  props: TClientProductsUpgradePage
) => {
  const { closeModal } = props
  const { t } = useSdk()
  const { pathname } = useLocation()
  const { setOpenBillingModal } = useContext(UserContext) as {
    setOpenBillingModal: React.Dispatch<React.SetStateAction<boolean>>
  }
  const { hasPermission } = useACL()

  return (
    <>
      <div className="flex items-center lg:mt-[40px] justify-center m-[20px] flex-col h-[300px]">
        <div className="text-zoyya-grayDark text-center mb-[20px] lg:mt-[10px] mt-auto">
          {t('translation.ClientProductsList.upgrade-message')}
        </div>
        {hasPermission('ACCESS_BILLING') ? (
          <ButtonTW
            label={t('translation.App.upgradeYourPlan')}
            type="button"
            variant={'primary'}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()

              analyticsTrack('UPGRADE button click', { pathname })
              closeModal?.()
              setOpenBillingModal(true)
            }}
            name={'upgradeButton'}
            size={'large'}
          />
        ) : null}
      </div>
    </>
  )
}
