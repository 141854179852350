import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg<any>`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Wallet = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m1.3 1.758 13.294 3.319h0A4.05 4.05 0 0 1 17.5 8.805v12h0a2.272 2.272 0 0 1-2.92 2.312L3.424 20.493h0A3.985 3.985 0 0 1 .5 16.805v-13h0a3.01 3.01 0 0 1 3-3h17a3.01 3.01 0 0 1 3 3v11h0a3.01 3.01 0 0 1-3 3h-3M9.5 3.81h10"></path>
        <path d="M14.418 12.39a2 2 0 1 1-2.828 2.83 2 2 0 0 1 2.828-2.83M17.5 8.81h2"></path>
      </g>
    </StyledIcon>
  )
}
