import { useEffect, useState } from 'react'

export function useHealthCheck() {
  const [healthStatus, setHealthStatus] = useState<{
    status: string
    message: string
  }>({
    status: 'OK',
    message: '',
  })

  const healthCheck = async () => {
    try {
      const response = await fetch(import.meta.env.VITE_HEALTH_CHECK_API_URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.status === 200 && response.ok) {
        const { b2b_web } = await response.json()

        if (
          healthStatus?.status !== b2b_web?.status ||
          healthStatus.message !== b2b_web?.message
        )
          setHealthStatus(b2b_web)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    healthCheck()
  }, [])

  useEffect(() => {
    const id = setInterval(() => {
      healthCheck()
    }, 30000)

    return () => {
      clearInterval(id as any)
    }
  }, [healthCheck])

  return { status: healthStatus.status, message: healthStatus.message }
}
