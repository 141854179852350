import React from 'react'
import { ErrorContainer, ErrorText, ErrorTitle } from './style'
import { Translation } from 'react-i18next'

type TErrorProps = {
  message?: string
}

export const UnderMaintenance = (props: TErrorProps) => {
  const { message } = props

  return (
    <Translation>
      {(t, { i18n }) => (
        <ErrorContainer>
          <img
            style={{
              paddingBottom: 60,
            }}
            src={'/assets/under-maintenance.png'}
            alt="Glass"
          />
          <ErrorTitle>{t('translation.UnderMaintenance.title')}</ErrorTitle>
          <ErrorText
            style={{
              marginBottom: 0,
            }}
          >
            {t('translation.UnderMaintenance.label-message')}
          </ErrorText>
          <ErrorText>
            {t('translation.UnderMaintenance.label-message-thanks')}
          </ErrorText>
          <ErrorText>{message}</ErrorText>
        </ErrorContainer>
      )}
    </Translation>
  )
}
