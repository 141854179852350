import {
  FieldArray,
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import { findIndex, memoize } from 'lodash'
import moment from 'moment'
import React, { useContext, useEffect, useMemo } from 'react'
import { BsFillPersonFill } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import { Add } from 'src/components/Icon/Icons/Add'
import { CheckBox } from 'src/components/Icon/Icons/CheckBox'
import { ChevronDown } from 'src/components/Icon/Icons/ChewronDownLight'
import { Trash } from 'src/components/Icon/Icons/Trash'
import { WarningTriangle } from 'src/components/Icon/Icons/WarningTriangle'
import { Tooltip2 } from 'src/components/Tooltip2/Tooltip2'
import { getHoursInDay, resolveFullName } from 'src/helpers'
import { getCurrentLanguage } from 'src/localization'
import { useSdk } from 'src/sdk'
import { useACL } from 'src/sdk/acl'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { FieldColumn, FieldRow } from 'src/sdk/tw/Form'
import {
  GetOrganizationByIdQuery,
  GetServiceTypesQuery,
  LocationGetQuery,
  useGetSmsBalanceQuery,
  useResourceBookingLocationScheduleLazyQuery,
} from 'src/state/graphql'
import { HeaderTitle, Row } from 'src/styles'
import { trpc } from 'src/trpc'
import { DatePicker } from 'src/views/Appointment/components/DatePicker'
import { ThemeContext } from 'styled-components'
import { FormField, Loader } from '../../../components'
import { GroupAddClientFormFields } from './GroupAddClientFormFields'
import { GroupBookingResourceSelect } from './GroupBookingResourceSelect'
import { GroupBookingStartTimeSelect } from './GroupBookingStartTimeSelect'
import { GroupSlotStatusPresenter } from './GroupSlotStatusPresenter'
import { GroupBookingDurationSelect } from './GroupBookingDurationSelect'
import { GroupBookingServiceSelect } from './GroupBookingServiceSelect'

type TServiceTypes = NonNullable<
  NonNullable<GetServiceTypesQuery['serviceType']>
>['search']

type TGroupAppointmentFormFields = {
  form: FormikSharedConfig<{}> &
    FormikState<any> &
    FormikHelpers<any> &
    FormikHandlers &
    FormikComputedProps<any> &
    FormikRegistration & { submitForm: () => Promise<any> }
  onNewService: () => void
  onNewClient: () => void
  location: NonNullable<NonNullable<LocationGetQuery['location']>>['get']
  org: NonNullable<NonNullable<GetOrganizationByIdQuery['organization']>>['get']
  serviceTypes: TServiceTypes
  isNew: boolean
  isPrepaymentDisabled: boolean
  className?: string
  setActiveForm?: (val: any) => any
  onCancelAppointmentClick?: () => void
  flattenedServices: NonNullable<NonNullable<TServiceTypes>[0]>['services']
}

export const GroupAppointmentFormFields = (
  props: TGroupAppointmentFormFields
) => {
  const {
    form,
    onNewService,
    onNewClient,
    location,
    org,
    className,
    isNew,
    serviceTypes,
    setActiveForm,
    onCancelAppointmentClick,
    isPrepaymentDisabled,
    flattenedServices,
  } = props
  const { values } = form
  const { orgId, locationId } = useParams<any>()
  const { isMobile, t } = useSdk()
  const durationOptions = getHoursInDay(location?.timeSlotDuration || 15)
  const { hasPermission } = useACL()
  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')

  const selectedTheme = useContext(ThemeContext)
  const showAddClientForm = form?.values?.showAddClientForm

  const setShowAddClientForm = value => {
    if (value) {
      form?.setFieldValue('showAddClientForm', true)
    } else {
      form?.setFieldValue('showAddClientForm', false)
    }
  }
  const { data: startTimesData, isLoading: startTimesLoading } =
    trpc.startTimeGet.useQuery({
      orgId: orgId,
      locationId: locationId,
      timeSlotDuration: location?.timeSlotDuration || 15,
      resourceBookingServiceId: values?.resourceBookingServiceId
        ? BigInt(values?.resourceBookingServiceId)
        : undefined,
      employeeId: values?.resource?.id || null,
      date: moment(values?.date).format('YYYY-MM-DD'),
    })

  const { data: smsBalanceData, loading: smsBalanceLoading } =
    useGetSmsBalanceQuery({ fetchPolicy: 'network-only' })

  const smsBalance = smsBalanceData?.payment?.getSmsBalance?.balance || 0

  const [
    resourceBookingLocationScheduleQuery,
    { data: locationScheduleData, loading: locationScheduleLoading },
  ] = useResourceBookingLocationScheduleLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const locationSchedule =
    locationScheduleData?.resourceBooking.locationSchedule
  //endregion
  const resourcesData = location?.resources

  const resources = useMemo(
    () => resourcesData?.filter(x => x.showInCalendar && x.kind === 'RESOURCE'),
    [resourcesData]
  )
  //region GET TIME SLOTS & WORKING HOURS
  const getTimeSlotState = memoize(
    (date, selectedHour, selectedEmployee) => {
      let isBusy = false
      let isInactive = false
      const dateMoment = moment(date)
      const dateStr = dateMoment.format('YYYY-MM-DD')
      const employeeSchedule = locationSchedule?.filter(
        x => x?.resource?.id === selectedEmployee
      )

      // @ts-ignore
      const employeeSlots = employeeSchedule?.reduce((slots, schedule) => {
        // @ts-ignore
        return [...slots, ...schedule?.timeSlots]
      }, [])

      // @ts-ignore
      for (let slot of employeeSlots) {
        if (
          moment(dateStr + ' ' + selectedHour).isBetween(
            dateStr + ' ' + slot.startTime,
            dateStr + ' ' + slot.endTime,
            null,
            '[)'
          )
        ) {
          if (slot.kind === 'OFF') {
            isInactive = true
            isBusy = false
          } else if (slot.kind === 'BUSY') {
            isBusy = true
            isInactive = false
          } else {
            isInactive = false
            isBusy = false
          }
          break
        }
      }

      return { isBusy, isInactive }
    },
    (date, selectedHour, selectedEmployee) =>
      `${date}-${selectedHour}-${selectedEmployee}`
  )

  const workingResources = memoize(
    (date, startTime) => {
      if (!locationSchedule?.[0]) {
        return
      }
      return [...(resources || [])]
        ?.map(resource => {
          const { isInactive, isBusy } = getTimeSlotState(
            date,
            startTime?.toString(),
            resource.id
          )
          // if we are watching slots for the current appointment, we dont set up isBusy flag
          const dateMoment = moment(date)
          const dateStr = dateMoment.format('YYYY-MM-DD')

          const isCurrentAppointmentSlot = form?.values?.id
            ? moment(dateStr + ' ' + startTime).isBetween(
                dateStr + ' ' + form?.values?.originalAppointmentStartTime,
                dateStr + ' ' + form?.values?.originalAppointmentEndTime,
                null,
                '[)'
              ) && resource?.id == form?.values?.originalAppointmentResourceId
            : isBusy

          return {
            ...resource,
            isInactive: isCurrentAppointmentSlot ? false : isInactive,
            isBusy: isCurrentAppointmentSlot ? false : isBusy,
          }
        })
        ?.sort((a, b) => +a?.isBusy - +b?.isBusy)
        ?.sort((a, b) => +a?.isInactive - +b?.isInactive)
    },
    (date, startTime) => `${date}-${startTime}`
  )

  const onClosePopup = () => {
    form.setFieldValue('newClientSlot', undefined)
    setShowAddClientForm(false)
  }
  const onAddClientButtonClick = () => {
    form.setFieldValue('newClientSlot.client', null)
    form.setFieldValue(
      'newClientSlot.sendAppointmentEmail',
      location?.sendAppointmentEmailsToClients
    )
    form.setFieldValue(
      'newClientSlot.sendAppointmentSms',
      location?.sendAppointmentSmsToClients
    )
    setShowAddClientForm(true)
  }

  const onEditClientSlotButtonClick = (clientSlot: any) => {
    if (isNew) {
      form.setFieldValue('newClientSlot.client', clientSlot?.client)
      form.setFieldValue(
        'newClientSlot.sendAppointmentEmail',
        clientSlot?.sendAppointmentEmail
      )
      form.setFieldValue(
        'newClientSlot.sendAppointmentSms',
        clientSlot?.sendAppointmentSms
      )
      form.setFieldValue('newClientSlot.internalNote', clientSlot?.internalNote)
      form.setFieldValue(
        'newClientSlot.messageToClient',
        clientSlot?.messageToClient
      )
      setShowAddClientForm(true)
    } else {
      if (clientSlot?.resourceBookingId) {
        // setActiveForm to another value, show booking details
        form.setFieldValue('clientSlotInEdit', clientSlot)
        if (clientSlot?.posPaymentRequest?.status === 'PAID') {
          form.setFieldValue(
            'clientSlotInEdit.shouldRefundPaymentRequest',
            clientSlot?.status === 'DRAFT'
              ? true
              : !moment().isAfter(
                  clientSlot?.posPaymentRequest?.paymentRequestDueDate
                )
          )
        }
        setActiveForm?.('clientSlot')
      } else {
        form.setFieldValue('newClientSlot.client', clientSlot?.client)
        form.setFieldValue(
          'newClientSlot.sendAppointmentEmail',
          clientSlot?.sendAppointmentEmail
        )
        form.setFieldValue(
          'newClientSlot.sendAppointmentSms',
          clientSlot?.sendAppointmentSms
        )
        form.setFieldValue(
          'newClientSlot.internalNote',
          clientSlot?.internalNote
        )
        form.setFieldValue(
          'newClientSlot.messageToClient',
          clientSlot?.messageToClient
        )
        setShowAddClientForm(true)
      }
    }
  }

  useEffect(() => {
    if (!form?.values?.date) return
    resourceBookingLocationScheduleQuery({
      variables: {
        dateFrom: moment(form?.values?.date).format('YYYY-MM-DD'),
        dateTo: moment(form?.values?.date).format('YYYY-MM-DD'), // moment(date).add(1, 'day').format('YYYY-MM-DD'),
        locationId,
      },
    })
  }, [locationId, resourceBookingLocationScheduleQuery, form?.values?.date])
  const canceledSlots = (form?.values?.clientSlots || [])?.filter(
    x => x?.status === 'canceled'
  )
  const otherSlots = (form?.values?.clientSlots || [])
    ?.filter(x => !x?.status || x?.status !== 'canceled')
    ?.sort((a, b) => {
      return (
        (a.resourceBookingId || 0)?.toString() -
        (b.resourceBookingId || 0)?.toString()
      )
    })

  const clientSlots = [...otherSlots, ...canceledSlots]

  const activeClientSlotsCount =
    clientSlots?.filter(slot => {
      return slot?.resourceBookingId ? slot?.status !== 'canceled' : true
    })?.length || 0

  return (
    <div className={`py-3 px-4 lg:py-6 lg:px-8 ${className || ''}`}>
      {startTimesLoading || smsBalanceLoading ? <Loader isComponent /> : null}
      <Row className="mb-4 pb-4  lg:flex-row justify-between">
        <div
          className={`flex max-md:w-full max-md:self-start max-md:flex-row max-md:items-center cursor-pointer`}
        >
          <DatePicker
            isAbsolute
            view={'day'}
            setDate={date => {
              form?.setFieldValue('date', date)
            }}
            isHeaderPicker
            selectedDayId={values?.date}
            isDisabled={!canEditAppointment}
            dontGoToCalendarAfterMonthChange
            inputComponent={
              <HeaderTitle>
                <span
                  data-cy={'appointmentDate'}
                  className="text-justify !text-2xl mr-1"
                >
                  {moment(values.date)
                    .locale(getCurrentLanguage())
                    .format('dddd, DD.MM.YYYY.')}
                </span>
                {canEditAppointment ? (
                  <ChevronDown color={'grayDark'} isChevron />
                ) : null}
              </HeaderTitle>
            }
          />
        </div>
        {!isNew ? (
          <div>
            <ButtonTW
              variant="orangeOutline"
              label="Otkaži"
              size="small"
              onClick={onCancelAppointmentClick}
              type="button"
              icon={<Trash size="smaller" stroke={1.6} />}
            />
          </div>
        ) : null}
      </Row>

      <FieldRow>
        <FieldColumn>
          <FormField.Checkbox
            label={t('translation.AppointmentGroup.booking')}
            name="onlineBookingAllowed"
            type={'checkbox'}
            disabled={Number(values?.capacity || 0) <= 0 || !canEditAppointment}
            helpText={
              Number(values?.capacity || 0) <= 0
                ? 'Kapacitet mora biti veći od 0 za online naručivanje'
                : t('translation.AppointmentGroup.onlineBookingHint')
            }
          />
        </FieldColumn>
        <div className="flex lg:justify-end justify-center">
          {Number(form?.values?.capacity || 0) > 0 ? (
            <div className="gap-2 bg-zoyya-background text-zoyya-primary  font-medium lg:text-xl text-md p-2 rounded-lg border-1 flex items-center">
              <BsFillPersonFill />
              <span>
                {`${activeClientSlotsCount}/${form?.values?.capacity}`}
              </span>
            </div>
          ) : null}
        </div>
      </FieldRow>
      <FieldRow style={{ alignItems: 'flex-end' }}>
        <FieldColumn className="lg:min-w-[70%]">
          <GroupBookingServiceSelect
            isDisabled={!canEditAppointment}
            employee={values?.resource}
            label={'Usluga'}
            name="service"
            onNewService={onNewService}
            services={serviceTypes || []}
            currency={org?.currency?.id!}
          />
        </FieldColumn>
        <FieldColumn>
          <FormField.Text
            label={t('translation.AppointmentGroup.capacity')}
            name="capacity"
            disabled={!canEditAppointment}
          />
        </FieldColumn>
      </FieldRow>
      {/*  <FieldRow>
                                  
                                </FieldRow> */}
      <FieldRow>
        <FieldColumn>
          <FieldColumn>
            <GroupBookingStartTimeSelect
              name={`startTime`}
              isDisabled={!canEditAppointment}
              workHours={startTimesData?.slice(0, -1)}
            />
          </FieldColumn>
        </FieldColumn>
        <FieldColumn>
          <GroupBookingDurationSelect
            isDisabled={!canEditAppointment}
            duration={durationOptions}
            name="duration"
            label={t('translation.NewClientContainer.label-duration')}
          />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn>
          <GroupBookingResourceSelect
            label={t('translation.AppointmentGroup.resource')}
            name="resource"
            isDisabled={!canEditAppointment}
            employees={
              workingResources(
                form?.values?.date,
                form?.values?.startTime?.id
              ) || []
            }
          />
        </FieldColumn>
      </FieldRow>
      <FieldArray name="clientSlots">
        {({ push, replace, remove }) => (
          <div>
            <div className="flex justify-between items-end mt-8">
              <div className="font-medium flex gap-2 items-center leading-5">
                <span>{'Klijenti'}</span>
              </div>
              {canEditAppointment ? (
                <ButtonTW
                  label="Dodaj"
                  variant="primaryOutline"
                  size="small"
                  icon={<Add size="smaller" />}
                  onClick={onAddClientButtonClick}
                />
              ) : null}
            </div>

            <div className="my-2 flex flex-col gap-2 p-2 border-1 border-zoyya-grayLight rounded-md lg:max-h-[280px] overflow-auto min-h-[125px]">
              {clientSlots?.length ? (
                clientSlots?.map((clientSlot, idx) => {
                  return (
                    <div
                      className={`p-2 flex items-center gap-2 ${
                        !isNew && !clientSlot?.resourceBookingId
                          ? 'bg-zoyya-lightBackground'
                          : 'bg-zoyya-grayLighter'
                      } rounded-md border-1 transition-all hover:border-zoyya-primary/70 cursor-pointer`}
                      onClick={() => {
                        onEditClientSlotButtonClick(clientSlot)
                      }}
                      key={`${
                        clientSlot?.resourceBookingId || 'new'
                      }-${clientSlot?.client?.id}`}
                    >
                      <div className=" rounded-full overflow-hidden border-1 border-white shrink-0">
                        {clientSlot?.client?.defaultAvatarUrl &&
                        !clientSlot?.client?.defaultAvatarUrl?.endsWith(
                          'null'
                        ) ? (
                          <img
                            src={clientSlot?.client.defaultAvatarUrl}
                            className="w-[35px] h-[35px] rounded-full"
                          ></img>
                        ) : (
                          <div className="w-[35px] h-[35px] bg-zoyya-background flex items-center justify-center text-zoyya-primary text-sm font-medium ">
                            {clientSlot?.client?.firstName &&
                            clientSlot?.client?.lastName
                              ? clientSlot?.client?.firstName
                                  .charAt(0)
                                  .toUpperCase() +
                                clientSlot?.client?.lastName
                                  .charAt(0)
                                  .toUpperCase()
                              : clientSlot?.client?.firstName
                              ? clientSlot?.client?.firstName
                                  .charAt(0)
                                  .toUpperCase()
                              : 'NN'}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col gap-[2px] flex-1">
                        <div className="flex justify-between items-center">
                          <div className="flex-1 lg:max-w-[70%] max-w-[60%] lg:gap-2 gap-1 flex flex-wrap">
                            <span>{resolveFullName(clientSlot?.client)}</span>
                            {clientSlot?.client?.status === 'BLOCKED' ? (
                              <div className="bg-zoyya-accent5 pt-0.5 px-1 text-sm rounded-sm uppercase text-white w-fit flex items-center">
                                {t('translation.ClientsList.label-blocked')}
                              </div>
                            ) : null}
                          </div>
                          {clientSlot?.resourceBookingId ? (
                            <>
                              <GroupSlotStatusPresenter
                                slot={clientSlot}
                                onlyText
                              />
                            </>
                          ) : clientSlot?.sendPaymentRequest ||
                            clientSlot?.sendAppointmentEmail ||
                            clientSlot?.sendAppointmentSms ? (
                            <div className="flex justify-between pr-2 text-zoyya-secondaryDark gap-2">
                              <span className="text-sm flex gap-1 items-center">
                                <CheckBox
                                  size="extraSmall"
                                  color="green"
                                  className="mb-[2px]"
                                />
                                <span>
                                  {clientSlot?.sendPaymentRequest
                                    ? 'Zatraži predujam'
                                    : clientSlot?.sendAppointmentEmail &&
                                      clientSlot?.sendAppointmentSms
                                    ? 'Pošalji email i SMS'
                                    : clientSlot?.sendAppointmentEmail
                                    ? 'Pošalji email'
                                    : 'Pošalji SMS'}
                                </span>
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="text-sm text-zoyya-secondaryDark flex gap-1 items-center">
                          {clientSlot?.client?.mailboxStatus === 'DISABLED' ? (
                            <p className="line-clamp-1 ">
                              <Tooltip2
                                helpText={
                                  clientSlot?.client?.mailboxDisabledReason?.includes(
                                    'spamreport'
                                  )
                                    ? t(
                                        'translation.NewClientContainer.email-spamreported'
                                      )
                                    : t(
                                        'translation.NewClientContainer.email-blocked'
                                      )
                                }
                              >
                                <span>
                                  <WarningTriangle
                                    color="orange"
                                    size="size14"
                                  />{' '}
                                </span>
                              </Tooltip2>
                            </p>
                          ) : null}
                          <span>
                            {`${clientSlot?.client?.email || ''}${
                              clientSlot?.client?.mobilePhone
                                ? ` | ${clientSlot?.client?.mobilePhone}`
                                : ''
                            }`}
                          </span>
                        </div>
                        {clientSlot?.client?.membershipExpirationDate ? (
                          <div className="flex text-sm gap-1">
                            <span className="text-zoyya-text">
                              {'Članstvo vrijedi do:'}
                            </span>
                            <span
                              className={
                                moment().isAfter(
                                  moment(
                                    clientSlot?.client?.membershipExpirationDate
                                  )
                                )
                                  ? 'text-zoyya-accent5/80'
                                  : 'text-zoyya-text/80'
                              }
                            >
                              {`${moment(
                                clientSlot?.client?.membershipExpirationDate
                              ).format('DD.MM.YYYY.')}${
                                moment().isAfter(
                                  moment(
                                    clientSlot?.client?.membershipExpirationDate
                                  )
                                )
                                  ? ' - ISTEKLO'
                                  : ''
                              }`}
                            </span>
                          </div>
                        ) : null}
                        {clientSlot?.internalNote ? (
                          <div className="flex text-sm gap-1">
                            <span className="text-zoyya-text">
                              {'Napomena:'}
                            </span>
                            <span className="text-zoyya-text/80 italic">
                              {clientSlot?.internalNote}
                            </span>
                          </div>
                        ) : null}
                      </div>
                      {isNew || !clientSlot?.resourceBookingId ? (
                        <div
                          className="justify-self-end  p-2 bg-white border-1 rounded-md cursor-pointer hover:bg-zoyya-grayLighter"
                          onClick={e => {
                            e.stopPropagation()
                            const idxToRemove = values?.clientSlots?.findIndex(
                              x =>
                                x.client?.id === clientSlot?.client?.id &&
                                !x?.resourceBookingId
                            )

                            remove(idxToRemove)
                          }}
                        >
                          <Trash
                            size="smaller"
                            //color={selectedTheme.colors.accent1}
                            className="text-zoyya-accent5"
                            stroke={1.5}
                          />
                          {/* <ButtonTW icon={} size="small" variant="secondary" /> */}
                        </div>
                      ) : null}
                    </div>
                  )
                })
              ) : (
                <div className="text-zoyya-secondaryDark flex items-center justify-center flex-1 w-full h-full">
                  {'Nema upisanih klijenata'}
                </div>
              )}
            </div>
            {showAddClientForm ? (
              <GroupAddClientFormFields
                form={form}
                onNewClient={onNewClient}
                onClose={onClosePopup}
                isPrepaymentDisabled={isPrepaymentDisabled}
                org={org}
                location={location}
                smsBalance={smsBalance || 0}
                service={
                  form?.values?.service
                    ? flattenedServices?.find(
                        x => x?.id === form?.values?.service
                      )
                    : null
                }
                onAdd={() => {
                  const idxInEdit = findIndex(
                    form?.values?.clientSlots,
                    (x: any) =>
                      x.client?.id ===
                        form?.values?.newClientSlot?.client?.id &&
                      !x?.resourceBookingId
                  )
                  if (idxInEdit >= 0) {
                    replace(idxInEdit, {
                      client: form?.values?.newClientSlot?.client,
                      internalNote: form?.values?.newClientSlot?.internalNote,
                      messageToClient:
                        form?.values?.newClientSlot?.messageToClient,
                      sendAppointmentEmail:
                        form?.values?.newClientSlot?.sendAppointmentEmail,
                      sendAppointmentSms:
                        form?.values?.newClientSlot?.sendAppointmentSms,
                      sendPaymentRequest:
                        form?.values?.newClientSlot?.sendPaymentRequest,
                      cancelAppointmentOnExpiredPaymentRequest:
                        form?.values?.newClientSlot
                          ?.cancelAppointmentOnExpiredPaymentRequest,
                    })
                  } else {
                    push({
                      client: form?.values?.newClientSlot?.client,
                      internalNote: form?.values?.newClientSlot?.internalNote,
                      messageToClient:
                        form?.values?.newClientSlot?.messageToClient,
                      sendAppointmentEmail:
                        form?.values?.newClientSlot?.sendAppointmentEmail,
                      sendAppointmentSms:
                        form?.values?.newClientSlot?.sendAppointmentSms,
                      sendPaymentRequest:
                        form?.values?.newClientSlot?.sendPaymentRequest,
                      cancelAppointmentOnExpiredPaymentRequest:
                        form?.values?.newClientSlot
                          ?.cancelAppointmentOnExpiredPaymentRequest,
                    })
                  }

                  form.setFieldValue('newClientSlot', undefined)
                }}
              />
            ) : null}
          </div>
        )}
      </FieldArray>

      <div
        style={{
          marginTop: !isMobile ? 10 : 0,
          paddingTop: !isMobile ? 10 : 5,
        }}
      >
        <FormField.TextArea
          label={t('translation.AppointmentModal.placeholderText')}
          placeholder={t('translation.Appointment.vidljivo-samo-zaposlenicima')}
          readOnly={!canEditAppointment}
          disabled={!canEditAppointment}
          name="internalNote"
          rows={2}
        />
      </div>
    </div>
  )
}
