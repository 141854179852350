import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ServiceWorkerProvider, useServiceWorker } from './useServiceWorker'
import { ServiceWorkerMessage } from './components/ServiceWorkerMessage/ServiceWorkerMessage'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Buffer } from 'buffer'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { UnderMaintenance } from './components/ErrorBoundary/UnderMaintenance'
import { useHealthCheck } from './sdk/useHealthCheck'

Bugsnag.start({
  apiKey: '3119eaab29a9dd112a9925cd7913ab17',
  plugins: [new BugsnagPluginReact()],
  appVersion: import.meta.env.VITE_VERSION_NUMBER,
  releaseStage: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE || 'development',
  enabledReleaseStages: ['production', 'staging', 'test', 'development'],
  onError: event => {
    const user = localStorage.getItem('user')
    if (user) {
      const userData = JSON.parse(user)
      event.setUser(
        userData?.id,
        userData?.email,
        `${userData?.firstName} ${userData?.lastName}`
      )
    }
  },
})
const BugSnagErrorBoundary = Bugsnag!
  .getPlugin('react')!
  .createErrorBoundary(React)

//patch for Vite bundler, polyfill for Buffer. We need it for the crypto library to extract certificate information
window.Buffer = Buffer

const AppComponent = () => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker()

  const healthCheck = useHealthCheck()

  return healthCheck.status === 'OK' ? (
    <>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        newestOnTop={false}
        draggable
        pauseOnHover
        closeOnClick={false}
        closeButton={false}
        pauseOnFocusLoss={false}
        autoClose={3000}
      />
      <App />
      {isUpdateAvailable && <ServiceWorkerMessage onClick={updateAssets} />}
    </>
  ) : (
    <UnderMaintenance message={healthCheck?.message} />
  )
}

ReactDOM.render(
  <ServiceWorkerProvider>
    <DndProvider backend={HTML5Backend}>
      <BugSnagErrorBoundary
        FallbackComponent={ErrorBoundary}
        onError={error => {
          console.log(error)
        }}
      >
        <AppComponent />
      </BugSnagErrorBoundary>
    </DndProvider>
  </ServiceWorkerProvider>,

  document.getElementById('root')
)
