import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Button } from 'components'
import { findIndex } from 'lodash'
import { MobileContext } from 'src/Context'
import {
  changeServiceDurations,
  formatPriceNumberWithDelimiter,
  getServiceDurationInMinutes,
  getUserAvatar,
} from '../../../helpers'
import { useSdk } from 'sdk'
import { icons } from 'components'
import { TServiceItemProps, TServiceProps } from './Service'
import { useACL } from 'src/sdk/acl'
import { useGetResourceQuery } from '../../../state/graphql'
import { Gift } from 'src/components/Icon/Icons/Gift'

type TServiceViewProps = {
  item: TServiceItemProps
  remove: (arg0: number) => void
  index: number
  replace: (arg0: number, arg1: TServiceItemProps) => void
  getEndTime: (arg0: TServiceItemProps) => any
  flattenedServices: TServiceProps[]
  employees: any[]
  resources: any[]
  selectedServices: any[]
  setIndexInEdit: (arg0: number) => void
  setServiceInEdit: (arg0: TServiceItemProps) => void
  values: any
  setIsMenuOpen: (arg0: boolean) => void
  timeSlotDuration: number
  setFieldValue: (arg0: string, arg1: any) => void
}
export const ServiceCard = (props: TServiceViewProps) => {
  const {
    item,
    remove,
    index,
    replace,
    getEndTime,
    flattenedServices,
    employees,
    resources,
    selectedServices,
    setIndexInEdit,
    setServiceInEdit,
    values,
    timeSlotDuration,
    setIsMenuOpen,
    setFieldValue,
  } = props
  const service = flattenedServices?.find(x => x.id === item.service)
  const resource = resources?.find(x => x.id === item.resourceId)

  const { data: resourceData, loading: loadingResourceData } =
    useGetResourceQuery({
      variables: { id: item.employee },
      fetchPolicy: 'no-cache',
    })
  const employee = resourceData?.resource?.get

  const selectedTheme = useContext(ThemeContext)
  const isMobile = useContext(MobileContext)
  const { t } = useSdk()

  const { hasPermission } = useACL()
  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')

  const isStatusDraft = values.status === 'DRAFT'

  return (
    <div className="flex relative">
      <div
        className={`ml-0 mr-0 w-[6px] mb-[20px] rounded lg:-ml-[14px] lg:mr-[10px]`}
        style={{ backgroundColor: service?.color }}
      />

      <div
        className={`flex flex-col relative lg:px-[20px] rounded w-[100%] mb-[20px] bg-zoyya-lighterBackground py-[10px] lg:border-solid lg:border lg:border-zoyya-grayDarker md:border-none md:overflow-hidden pr-[5px] ${
          isMobile && !item?.isNew && !isStatusDraft
            ? 'px-[10px] pr-[10px]'
            : 'px-[15px] pr-[15px]'
        }`}
        onClick={e => {
          if (isMobile) {
            if (!canEditAppointment) return

            const idxInEdit = findIndex(selectedServices, {
              id: item.id,
            })
            const serviceInEdit = selectedServices.find(x => x.id === item.id)
            setIndexInEdit(idxInEdit)
            setServiceInEdit(serviceInEdit)
            e.preventDefault()
            e.stopPropagation()
            if (values?.status === 'canceled') return

            setIsMenuOpen(true)
            replace(idxInEdit, { ...item, isEdit: true })
          }
        }}
      >
        {!isMobile ? (
          <div>
            {service?.name && (
              <div className="flex justify-between w-[100%] items-start gap-[20px] mb-[10px]">
                <div className="flex flex-col items-start">
                  <p className="text-[16px] lg:text-base font-medium leading-6 text-zoyya-text flex min-w-[70px] items-start">
                    {item?.quantity > 1 ? (
                      <span className="mr-2">{`${item?.quantity} x `}</span>
                    ) : null}
                    <span>{service?.name}</span>

                    {item?.missingProfession ? (
                      <icons.WarningTriangle
                        style={{
                          margin: '0 10px',
                          minWidth: 16,
                          minHeight: 16,
                        }}
                        size={'size16'}
                        color={selectedTheme.colors.accent1}
                      />
                    ) : null}
                  </p>

                  {item?.giftCard ? (
                    <div className="flex justify-between items-center mt-2 mb-1">
                      <div className="flex items-center">
                        <Gift size="size16" stroke={1.3} className="mr-1" />
                        <p className="text-sm text-zoyya-text">
                          {item?.giftCard?.giftCardType === 'MEMBER_CARD'
                            ? `
                      ${item?.giftCard?.name} | ${item?.giftCard
                        ?.code} | -${formatPriceNumberWithDelimiter(
                        item?.discountAmount
                      )} ${item?.currencyId}`
                            : `
                      ${item?.giftCard?.name} | ${item?.giftCard
                        ?.code} | -${formatPriceNumberWithDelimiter(
                        item?.giftCardAmount
                      )} ${item?.currencyId}`}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="flex flex-col items-end lg:min-w-[100px]">
                  <p className="text-[16px] lg:text-base font-medium leading-6 text-zoyya-text shrink-0">
                    {`${formatPriceNumberWithDelimiter(
                      item?.totalAmount / item?.quantity
                    )} ${values?.currencyId}`}
                  </p>

                  {item?.discountValue ? (
                    <p className="text-sm text-zoyya-secondaryDark line-through -mb-3">{`${formatPriceNumberWithDelimiter(
                      item?.price
                    )} ${item?.currencyId}`}</p>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="w-[100%] flex justify-between items-baseline flex-row lg:items-center mb-3">
              {service?.name ? (
                <div className="flex justify-between w-[100%] items-start">
                  <p className="lg:text-base text-md font-medium leading-6 text-zoyya-text flex min-w-[70px] items-start mr-[10px]">
                    {service?.name + ''}
                    {item?.missingProfession ? (
                      <icons.WarningTriangle
                        style={{
                          margin: '0 10px',
                          minWidth: 16,
                          minHeight: 16,
                        }}
                        size={'size16'}
                        color={selectedTheme.colors.accent1}
                      />
                    ) : null}
                  </p>

                  <div className="flex flex-col items-end shrink-0">
                    <p className="lg:text-base text-md font-medium leading-6 text-zoyya-text flex min-w-[70px] text-end shrink-0">
                      {`${formatPriceNumberWithDelimiter(
                        item?.totalAmount / item?.quantity
                      )} ${values?.currencyId}`}
                    </p>
                    {item?.discountValue ? (
                      <p className="text-sm text-zoyya-secondaryDark line-through -mb-3">{`${formatPriceNumberWithDelimiter(
                        item?.price
                      )} ${item?.currencyId}`}</p>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="w-[100%]" />
              )}

              {canEditAppointment &&
              isMobile &&
              !item?.isNew &&
              !isStatusDraft ? (
                <Button
                  buttonType={'text'}
                  hasIconOnly={true}
                  iconComponent={<icons.Cross size={'extraSmall'} stroke={4} />}
                  onClick={event => {
                    event.preventDefault()
                    event.stopPropagation()
                    if (item?.original?.id && item?.original?.giftCard?.id) {
                      const deletedServices = values?.deletedServices || []
                      setFieldValue('deletedServices', [
                        ...deletedServices,
                        item?.original,
                      ])
                    }
                    remove(index)
                    if (
                      !values?.services?.filter(
                        x => x?.service && x?.id !== index
                      ).length
                    )
                      setFieldValue('sendPaymentRequest', false)
                  }}
                  size={'small'}
                  style={{
                    position: 'relative',
                    left: 5,
                    height: 'auto',
                    top: 1,
                  }}
                />
              ) : null}
            </div>
            {item?.giftCard ? (
              <div className="w-[100%] flex justify-between items-baseline flex-row lg:items-center mb-3">
                <div className="flex justify-between w-[100%] items-start">
                  <div className="flex justify-between items-center mt-2 mb-1">
                    <div className="flex items-center">
                      <Gift size="size16" stroke={1.3} className="mr-1" />
                      <p className="text-sm text-zoyya-text">
                        {item?.giftCard?.giftCardType === 'MEMBER_CARD'
                          ? `${item?.giftCard?.name} | ${item?.giftCard?.code} | -${item?.discountAmount} ${item?.currencyId}`
                          : `${item?.giftCard?.name} | ${item?.giftCard?.code} | -${item?.giftCardAmount} ${item?.currencyId}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}

        <div className="flex lg:items-center justify-between w-[100%]  md:items-baseline flex-col lg:flex-row">
          <div className="flex items-center justify-between">
            <div className="text-zoyya-text w-[100px]">
              {item.startTime?.id + ' - ' + getEndTime(item)}
            </div>
            <div className="flex items-center ml-[10px] w-[130px] justify-between">
              {canEditAppointment ? (
                <button
                  disabled={item?.durationMinutes === 0}
                  onClick={event => {
                    event.stopPropagation()
                    changeServiceDurations(
                      '-',
                      item,
                      selectedServices,
                      timeSlotDuration,
                      replace
                    )
                  }}
                  type="button"
                  className={`w-[30px] h-[30px] rounded-full bg-zoyya-grayDarker ${
                    item?.durationMinutes === 0
                      ? 'opacity-[0.3]'
                      : 'opacity-[1]'
                  }`}
                >
                  <span className="text-[20px]">-</span>
                </button>
              ) : null}
              <p>{item?.durationMinutes} min</p>
              {canEditAppointment ? (
                <button
                  disabled={
                    getServiceDurationInMinutes(
                      selectedServices[selectedServices?.length - 1]?.endTime
                    ) >=
                      getServiceDurationInMinutes('24:00') - timeSlotDuration &&
                    getServiceDurationInMinutes(item?.startTime?.title) > 0
                  }
                  onClick={event => {
                    event.stopPropagation()
                    changeServiceDurations(
                      '+',
                      item,
                      selectedServices,
                      timeSlotDuration,
                      replace
                    )
                  }}
                  type="button"
                  className={`w-[30px] h-[30px] rounded-full bg-zoyya-grayDarker ${
                    getServiceDurationInMinutes(
                      selectedServices[selectedServices?.length - 1]?.endTime
                    ) >=
                      getServiceDurationInMinutes('24:00') - timeSlotDuration &&
                    getServiceDurationInMinutes(item?.startTime?.title) > 0
                      ? 'opacity-[0.3]'
                      : 'opacity-[1]'
                  }`}
                >
                  <span className="text-[20px]">+</span>
                </button>
              ) : null}
            </div>
          </div>

          <div
            className={`flex items-center ${
              canEditAppointment ? 'lg:w-[170px] lg:mr-[20px]' : ''
            } ml-0 mr-0 mt-[5px] lg:ml-[auto]`}
          >
            {getUserAvatar(employee, true, isMobile ? 'small' : 'medium')}
            <div className="md:leading-[18px] flex flex-col ml-[10px]">
              <p
                className={`${
                  !canEditAppointment ? '' : 'max-w-[117px]'
                } text-ellipsis whitespace-nowrap overflow-hidden`}
              >
                {!employee?.nickName ? employee?.name : employee?.nickName}
              </p>
              <div className="flex items-center gap-[5px]">
                {resource ? (
                  <icons.Resources size="smaller" color="#5c5c5c" />
                ) : null}
                <p
                  className={`${
                    !canEditAppointment ? '' : 'max-w-[117px]'
                  } text-ellipsis whitespace-nowrap overflow-hidden text-zoyya-text font-light text-[13px]`}
                >
                  {resource?.firstName}
                </p>
              </div>
            </div>
          </div>

          {canEditAppointment ? (
            <>
              {values?.status === 'canceled' ? null : !isMobile ? (
                <>
                  {!item?.isNew && !isStatusDraft ? (
                    <Button
                      buttonType={'text'}
                      hasIconOnly={true}
                      iconComponent={
                        <icons.Cross
                          size={'extraSmall'}
                          color={'#a0a0a0'}
                          stroke={4}
                        />
                      }
                      onClick={e => {
                        e.preventDefault()

                        if (
                          item?.original?.id &&
                          item?.original?.giftCard?.id
                        ) {
                          const deletedServices = values?.deletedServices || []
                          setFieldValue('deletedServices', [
                            ...deletedServices,
                            item?.original,
                          ])
                        }

                        remove(index)
                        if (
                          !values?.services?.filter(
                            x => x?.service && x?.id !== index
                          ).length
                        )
                          setFieldValue('sendPaymentRequest', false)
                      }}
                      size={'small'}
                      style={{ height: 'unset' }}
                    />
                  ) : null}

                  <Button
                    label={t('translation.Service.izmjeni')}
                    buttonType={'text'}
                    hasIconOnly={true}
                    tooltip={t('translation.Service.izmjeni-uslugu')}
                    iconComponent={
                      <icons.Edit
                        color={'#a0a0a0'}
                        size={'smaller'}
                        stroke={2}
                      />
                    }
                    size={'small'}
                    onClick={() => {
                      const idxInEdit = findIndex(selectedServices, {
                        id: item.id,
                      })
                      const serviceInEdit = selectedServices.find(
                        x => x.id === item.id
                      )
                      replace(idxInEdit, { ...item, isEdit: true })
                      setIndexInEdit(idxInEdit)
                      setServiceInEdit(serviceInEdit)
                    }}
                    style={{ height: 'unset' }}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}
