import {
  Button,
  ButtonWithConfirmation,
  FormContentContainer,
  FormField,
  icons,
} from 'components'
import Warning from 'components/Icon/Icons/Warning'
import {
  FormikComputedProps,
  FormikErrors,
  FormikHandlers,
  FormikHelpers,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import React, { useContext, useEffect } from 'react'
import { WarningTriangle } from 'src/components/Icon/Icons/WarningTriangle'
import { MessagingButtons } from 'src/components/MessagingButtons/MessagingButtons'
import { PlanUpgradeField } from 'src/components/PlanUpgradeField/PlanUpgradeField'
import { UserContext } from 'src/Context'
import { formatPhone } from 'src/helpers'
import { useACL } from 'src/sdk/acl'
import { useAppServices } from 'src/sdk/appServices'
import { useGenders } from 'src/sdk/helperHooks'
import {
  GetOrganizationByIdQuery,
  LocationGetQuery,
  useCountryAllQuery,
} from 'src/state/graphql'
import { useInviteUser } from 'src/state/queries'
import { trpc } from 'src/trpc'
import {
  EventContainer,
  EventDateTitle,
} from 'src/views/Appointment/UpdateStatus'
import {
  GridRow,
  InputWrapper,
  MultipleRows,
  WarningContainer,
} from 'src/views/Clients/ClientForm'
import { FieldColumn, FieldRow } from 'src/views/Settings/styles'
import styled from 'styled-components'
import { device } from '../../../constants'
import { useSdk } from '../../../sdk'

type TGroupClientFormProps = {
  isNewClient: boolean
  form: FormikSharedConfig<{}> &
    FormikState<any> &
    FormikHelpers<any> &
    FormikHandlers &
    FormikComputedProps<any> &
    FormikRegistration & { submitForm: () => Promise<any> }
  setActiveForm: React.Dispatch<
    React.SetStateAction<
      | `clientSlot_${string}`
      | 'appointment'
      | 'client'
      | 'service'
      | 'paymentNotification'
    >
  >
  location: NonNullable<NonNullable<LocationGetQuery['location']>>['get']
  org: NonNullable<NonNullable<GetOrganizationByIdQuery['organization']>>['get']
  hasPrepaymentCredentials: boolean
  refetchData?: () => any
  fieldsNamePrefix?: string
}
export function GroupClientForm(props: TGroupClientFormProps) {
  const {
    isNewClient,
    form,
    setActiveForm,
    refetchData,
    location,
    org,
    fieldsNamePrefix = '',
    hasPrepaymentCredentials,
  } = props
  const genders = useGenders()

  const { isMobile } = useSdk()
  const { data: countriesData } = useCountryAllQuery()
  const { toast, t } = useSdk()

  const namePrefix = fieldsNamePrefix ? `${fieldsNamePrefix}.` : ''
  const values = fieldsNamePrefix
    ? form?.values[fieldsNamePrefix] || {}
    : form?.values || {}

  const user = values?.user || values?.User || null

  const errors = (
    fieldsNamePrefix ? form?.errors[fieldsNamePrefix] || {} : form?.errors || {}
  ) as Record<string, FormikErrors<string>>

  const isEmailEmpty = !values?.email || !!errors?.email
  const isSmsEmpty = !values?.mobilePhone || !!errors?.mobilePhone

  const { hasPermission } = useACL()

  const canAccessPhone = hasPermission('ACCESS_CLIENT_PHONE')
  const canAccessEmail = hasPermission('ACCESS_CLIENT_EMAIL')

  const { setOpenBillingModal } = useContext<any>(UserContext)

  const clientActivateEmail = trpc.client_activateEmail.useMutation()
  const clientUpdateStatus = trpc.client_updateStatus.useMutation()

  const onSendInviteClick = async e => {
    e.preventDefault()
    e.stopPropagation()
    await sendInvitation()
  }

  const handleActivateEmail = async () => {
    try {
      const result = await clientActivateEmail.mutateAsync({
        clientId: BigInt(values?.id),
        email: values?.email,
      })

      toast.success(t('translation.EditClient.toast-email-activated'))
      await refetchData?.()
      setActiveForm('appointment')
    } catch (err) {
      toast.danger('Došlo je do pogreške - ' + (err as any)?.message)
    }
  }

  const handleBlock = async () => {
    try {
      const result = await clientUpdateStatus.mutateAsync({
        clientId: BigInt(values?.id),
        status: values?.status === 'BLOCKED' ? 'ACTIVE' : 'BLOCKED',
      })

      toast.success(
        values?.status === 'ACTIVE'
          ? t('translation.EditClient.toast-client-status-blocked')
          : t('translation.EditClient.toast-client-status-unblocked')
      )
      await refetchData?.()
      setActiveForm('appointment')
    } catch (err) {
      toast.danger('Došlo je do pogreške - ' + (err as any)?.message)
    }
  }

  const blockButton = () => {
    return (
      <FieldRow>
        <FieldColumn
          style={{ justifyContent: 'flex-end', marginBottom: '15px' }}
        >
          <ButtonWithConfirmation
            buttonLabel={
              values?.status === 'BLOCKED'
                ? t('translation.NewClientContainer.label-unblock')
                : t('translation.NewClientContainer.label-block')
            }
            buttonIcon={
              values?.status === 'BLOCKED' ? (
                <icons.Add size="smaller" stroke={2} />
              ) : (
                <icons.Cross size="smaller" stroke={2} />
              )
            }
            withConfirm
            onConfirm={() => handleBlock()}
            confirmationMessage={
              values?.status === 'BLOCKED'
                ? t('translation.NewClientContainer.title-confirmClientUnblock')
                : t('translation.NewClientContainer.title-confirmClientBlock')
            }
          />
        </FieldColumn>
      </FieldRow>
    )
  }

  useEffect(() => {
    if (isEmailEmpty) {
      form.setFieldValue(`${namePrefix}paymentRequired`, false)
    } else {
      if (!isNewClient) {
        form.setFieldValue(
          `${namePrefix}paymentRequired`,
          values?.paymentRequired
        )
      } else {
        form.setFieldValue(`${namePrefix}paymentRequired`, true)
      }
    }
  }, [isEmailEmpty])

  const isMailboxDisabled = values?.mailboxStatus === 'DISABLED'
  const mailboxDisabledReason = values?.mailboxDisabledReason

  const appServices = useAppServices()
  const [inviteUser] = useInviteUser()

  async function sendInvitation() {
    await inviteUser({
      variables: {
        input: {
          firstName: values?.firstName,
          lastName: values?.lastName,
          email: values?.email ? values?.email?.trim() : '',
          gender: values?.gender || 'Unspecified',
          mobilePhone: formatPhone(values?.mobilePhone),
          role: 'CLIENT',
          clientId: values?.id,
        },
      },
    })
    appServices.toast.success(
      t('translation.EditClient.toast-employeeInvited', {
        firstName: values?.firstName,
        lastName: values?.lastName,
      })
    )
  }

  const showSendInviteButton = !!values?.email && !user?.id && !errors.email

  return (
    <>
      <FormContentContainer>
        <HeaderRow>
          {isNewClient ? (
            <EventDateTitle>
              {t('translation.AppointmentModal.title-newClient')}
            </EventDateTitle>
          ) : (
            <EventDateTitle>
              {t('translation.EditClient.editClientDetails-label')}
            </EventDateTitle>
          )}
        </HeaderRow>
        {/* @ts-ignore */}
        <EventContainer clientDetails={true} className="!overflow-auto">
          <>
            {!isMobile ? (
              <GridRow oneColumn={true}>
                {/* {isFromClientList ? (
                  <SettingsAccountAvatar
                    style={{
                      width: '180px',
                      height: '180px',
                      maxWidth: '180px',
                      maxHeight: '180px',
                      marginRight: 0,
                    }}
                    data-cy="settings-avatar-image"
                    src={
                      user?.avatarUrl && !user?.avatarUrl?.endsWith('null')
                        ? user?.avatarUrl
                        : user?.google_profilePicUrl ||
                          user?.fb_profilePicUrl ||
                          '/assets/default-avatar.svg'
                    }
                  />
                ) : null} */}
                <InputWrapper>
                  <FieldRow>
                    <FieldColumn>
                      <FormField.Text
                        autoFocus={!values?.firstName}
                        label={t(
                          'translation.AppointmentModal.label-clientFirstName'
                        )}
                        name={`${namePrefix}firstName`}
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <FormField.Text
                        label={t(
                          'translation.AppointmentModal.label-clientLastName'
                        )}
                        name={`${namePrefix}lastName`}
                      />
                    </FieldColumn>

                    {org?.subscriptionPlan === 'advanced' ? (
                      <FieldColumn>
                        <FormField.DateInput
                          label={t(
                            'translation.NewClientContainer.label-membershipExpirationDate'
                          )}
                          displayFormat="DD.MM.YYYY."
                          name={`${namePrefix}membershipExpirationDate`}
                        />
                      </FieldColumn>
                    ) : (
                      <PlanUpgradeField
                        fullWidthButton
                        title={t(
                          'translation.NewClientContainer.label-membershipExpirationDate'
                        )}
                        text={t(
                          'translation.LocationOnLineBookingSettings.label-only-advanced-plan'
                        )}
                        handleClick={() => setOpenBillingModal(true)}
                      />
                    )}
                  </FieldRow>
                  <FieldRow>
                    {canAccessEmail ? (
                      <FieldColumn>
                        <FormField.Text
                          label="Email"
                          name={`${namePrefix}email`}
                          helpText={t(
                            'translation.NewClientContainer.tooltip-clientEmail'
                          )}
                        />
                      </FieldColumn>
                    ) : null}

                    {canAccessPhone ? (
                      <FieldColumn>
                        <FormField.PhoneInput
                          label={t(
                            'translation.AppointmentModal.label-mobilePhone'
                          )}
                          placeholder={t(
                            'translation.AppointmentModal.placeholder-mobilePhone'
                          )}
                          helpText={t(
                            'translation.NewClientContainer.tooltip-clientMobilePhone'
                          )}
                          name={`${namePrefix}mobilePhone`}
                        />
                      </FieldColumn>
                    ) : null}
                  </FieldRow>
                  <FieldRow style={{ alignItems: 'flex-start' }}>
                    <FieldColumn>
                      {isMailboxDisabled ? (
                        <WarningContainer className="flex items-center">
                          <WarningTriangle
                            className="text-zoyya-accent5"
                            size={'smaller'}
                          />
                          <div className="ml-2 text-[13px] text-zoyya-accent5">
                            {mailboxDisabledReason?.includes('spamreport')
                              ? t(
                                  'translation.NewClientContainer.email-spamreported'
                                )
                              : t(
                                  'translation.NewClientContainer.email-blocked'
                                )}
                          </div>

                          {mailboxDisabledReason &&
                          !mailboxDisabledReason?.includes('spamreport') ? (
                            <div className="mt-2 ml-auto">
                              <Button
                                style={{ width: 'fit-content' }}
                                size={'smaller'}
                                type="button"
                                label={t(
                                  'translation.NewClientContainer.activate-email'
                                )}
                                buttonType={'secondary'}
                                onClick={handleActivateEmail}
                              />
                            </div>
                          ) : null}
                        </WarningContainer>
                      ) : (isNewClient && !isEmailEmpty) ||
                        (user?.emailNotificationSettings?.sendReminderEmail &&
                          !isEmailEmpty) ||
                        (user?.emailNotificationSettings?.sendReminderEmail &&
                          !isEmailEmpty) ||
                        (!user && !user && !isEmailEmpty) ? (
                        <MultipleRows>
                          {showSendInviteButton &&
                            !user?.id &&
                            (!isNewClient ? (
                              <Button
                                style={{ width: 'fit-content' }}
                                label={t(
                                  'translation.NewClientContainer.label-sendInviteToEmail'
                                )}
                                buttonType={'secondary'}
                                onClick={onSendInviteClick}
                              />
                            ) : (
                              <FormField.Checkbox
                                label={t(
                                  'translation.NewClientContainer.label-sendInviteToEmail'
                                )}
                                name={`${namePrefix}sendInviteViaEMail`}
                                type={'checkbox'}
                                helpText={t(
                                  'translation.NewClientContainer.sendInviteToEmail-helpText'
                                )}
                              />
                            ))}
                          <FormField.Checkbox
                            label={t(
                              'translation.NewClientContainer.label-send-appointment-email'
                            )}
                            name={`${namePrefix}sendReminderEmail`}
                            type={'checkbox'}
                            helpText={t(
                              'translation.Notifications.email-notifications-hint'
                            )}
                          />
                        </MultipleRows>
                      ) : (!!user || !!user) &&
                        (user?.emailNotificationSettings?.sendReminderEmail ===
                          false ||
                          user?.emailNotificationSettings?.sendReminderEmail ===
                            false) &&
                        !isNewClient ? (
                        <WarningContainer>
                          <Warning color={'orange'} size={'smaller'} />
                          <i style={{ marginLeft: '8px' }}>
                            {t(
                              'translation.NewClientContainer.label-client-disabled-email-warning'
                            )}
                          </i>
                        </WarningContainer>
                      ) : null}
                    </FieldColumn>
                    <FieldColumn>
                      {(isNewClient && !isSmsEmpty) ||
                      (user?.emailNotificationSettings?.sendReminderSms &&
                        !isSmsEmpty) ||
                      (user?.emailNotificationSettings?.sendReminderSms &&
                        !isSmsEmpty) ||
                      (!user && !user && !isSmsEmpty) ? (
                        <FormField.Checkbox
                          label={t(
                            'translation.NewClientContainer.label-send-appointment-sms'
                          )}
                          name={`${namePrefix}sendReminderSms`}
                          type={'checkbox'}
                          helpText={t(
                            'translation.Notifications.sms-notifications-hint'
                          )}
                        />
                      ) : (!!user || !!user) &&
                        (user?.emailNotificationSettings?.sendReminderSms ===
                          false ||
                          user?.emailNotificationSettings?.sendReminderSms ===
                            false) &&
                        !isNewClient ? (
                        <WarningContainer>
                          <Warning color={'orange'} size={'smaller'} />
                          <i style={{ marginLeft: '8px' }}>
                            {t(
                              'translation.NewClientContainer.label-client-disabled-sms-warning'
                            )}
                          </i>
                        </WarningContainer>
                      ) : null}
                    </FieldColumn>
                  </FieldRow>
                </InputWrapper>
              </GridRow>
            ) : (
              <>
                <FieldRow
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    marginBottom: '14px',
                  }}
                >
                  {/* 
                  {isFromClientList ? (
                    <SettingsAccountAvatar
                      style={{
                        width: '100px',
                        height: '100px',
                        minWidth: '100px',
                        minHeight: '100px',
                      }}
                      data-cy="settings-avatar-image"
                      src={
                        user?.avatarUrl && !user?.avatarUrl?.endsWith('null')
                          ? user?.avatarUrl
                          : user?.google_profilePicUrl ||
                            user?.fb_profilePicUrl ||
                            '/assets/default-avatar.svg'
                      }
                    />
                  ) : null} */}

                  <FieldColumn style={{ marginBottom: '0px' }}>
                    <FormField.Text
                      autoFocus={!values?.firstName}
                      label={t(
                        'translation.AppointmentModal.label-clientFirstName'
                      )}
                      name={`${namePrefix}firstName`}
                      autoCapitalize="on"
                    />
                  </FieldColumn>
                </FieldRow>

                <FieldRow>
                  <FieldColumn>
                    <FormField.Text
                      label={t(
                        'translation.AppointmentModal.label-clientLastName'
                      )}
                      name={`${namePrefix}lastName`}
                      autoCapitalize="on"
                    />
                  </FieldColumn>
                </FieldRow>

                {org?.subscriptionPlan === 'advanced' ? (
                  <FieldColumn>
                    <FormField.DateInput
                      label={t(
                        'translation.NewClientContainer.label-membershipExpirationDate'
                      )}
                      displayFormat="DD.MM.YYYY."
                      name={`${namePrefix}membershipExpirationDate`}
                    />
                  </FieldColumn>
                ) : (
                  <PlanUpgradeField
                    fullWidthButton
                    title={t(
                      'translation.NewClientContainer.label-membershipExpirationDate'
                    )}
                    text={t(
                      'translation.LocationOnLineBookingSettings.label-only-advanced-plan'
                    )}
                    handleClick={() => setOpenBillingModal(true)}
                  />
                )}

                <FieldRow style={{ alignItems: 'flex-start' }}>
                  {canAccessEmail ? (
                    <FieldColumn>
                      <FormField.Text
                        label="Email"
                        name={`${namePrefix}email`}
                        helpText={t(
                          'translation.NewClientContainer.tooltip-clientEmail'
                        )}
                      />
                    </FieldColumn>
                  ) : null}
                  <FieldColumn>
                    {isMailboxDisabled ? (
                      <WarningContainer className="flex items-center">
                        <WarningTriangle
                          className="text-zoyya-accent5"
                          size={'smaller'}
                        />
                        <div className="ml-2 text-[13px] text-zoyya-accent5">
                          {mailboxDisabledReason?.includes('spamreport')
                            ? t(
                                'translation.NewClientContainer.email-spamreported'
                              )
                            : t('translation.NewClientContainer.email-blocked')}
                        </div>

                        {mailboxDisabledReason &&
                        !mailboxDisabledReason?.includes('spamreport') ? (
                          <div className="mt-2 ml-auto">
                            <Button
                              style={{ width: 'fit-content' }}
                              size={'smaller'}
                              type="button"
                              label={'Aktiviraj email'}
                              buttonType={'secondary'}
                              onClick={handleActivateEmail}
                            />
                          </div>
                        ) : null}
                      </WarningContainer>
                    ) : (isNewClient && !isEmailEmpty) ||
                      (user?.emailNotificationSettings?.sendReminderEmail &&
                        !isEmailEmpty) ||
                      (user?.emailNotificationSettings?.sendReminderEmail &&
                        !isEmailEmpty) ||
                      (!user && !user && !isEmailEmpty) ? (
                      <MultipleRows>
                        {showSendInviteButton &&
                          (!isNewClient ? (
                            <Button
                              style={{ width: 'fit-content' }}
                              label={t(
                                'translation.NewClientContainer.label-sendInviteToEmail'
                              )}
                              buttonType={'secondary'}
                              onClick={onSendInviteClick}
                            />
                          ) : (
                            <FormField.Checkbox
                              label={t(
                                'translation.NewClientContainer.label-sendInviteToEmail'
                              )}
                              name={`${namePrefix}sendInviteViaEMail`}
                              type={'checkbox'}
                              helpText={t(
                                'translation.NewClientContainer.sendInviteToEmail-helpText'
                              )}
                            />
                          ))}
                        <FormField.Checkbox
                          label={t(
                            'translation.NewClientContainer.label-send-appointment-email'
                          )}
                          name={`${namePrefix}sendReminderEmail`}
                          type={'checkbox'}
                          helpText={t(
                            'translation.Notifications.email-notifications-hint'
                          )}
                        />
                      </MultipleRows>
                    ) : (!!user || !!user) &&
                      (user?.emailNotificationSettings?.sendReminderEmail ===
                        false ||
                        user?.emailNotificationSettings?.sendReminderEmail ===
                          false) &&
                      !isNewClient ? (
                      <WarningContainer>
                        <Warning color={'orange'} size={'smaller'} />
                        <i style={{ marginLeft: '8px' }}>
                          {t(
                            'translation.NewClientContainer.label-client-disabled-email-warning'
                          )}
                        </i>
                      </WarningContainer>
                    ) : null}
                  </FieldColumn>
                </FieldRow>

                <FieldRow>
                  {canAccessPhone ? (
                    <FieldColumn>
                      <FormField.PhoneInput
                        label={t(
                          'translation.AppointmentModal.label-mobilePhone'
                        )}
                        placeholder={t(
                          'translation.AppointmentModal.placeholder-mobilePhone'
                        )}
                        helpText={t(
                          'translation.NewClientContainer.tooltip-clientMobilePhone'
                        )}
                        name={`${namePrefix}mobilePhone`}
                      />
                    </FieldColumn>
                  ) : null}
                  <FieldColumn>
                    {(isNewClient && !isSmsEmpty) ||
                    (user?.emailNotificationSettings?.sendReminderSms &&
                      !isSmsEmpty) ||
                    (user?.emailNotificationSettings?.sendReminderSms &&
                      !isSmsEmpty) ||
                    (!user && !user && !isSmsEmpty) ? (
                      <FormField.Checkbox
                        label={t(
                          'translation.NewClientContainer.label-send-appointment-sms'
                        )}
                        name={`${namePrefix}sendReminderSms`}
                        type={'checkbox'}
                        helpText={t(
                          'translation.Notifications.sms-notifications-hint'
                        )}
                      />
                    ) : (!!user || !!user) &&
                      (user?.emailNotificationSettings?.sendReminderSms ===
                        false ||
                        user?.emailNotificationSettings?.sendReminderSms ===
                          false) &&
                      !isNewClient ? (
                      <WarningContainer>
                        <Warning color={'orange'} size={'smaller'} />
                        <i style={{ marginLeft: '8px' }}>
                          {t(
                            'translation.NewClientContainer.label-client-disabled-sms-warning'
                          )}
                        </i>
                      </WarningContainer>
                    ) : null}
                  </FieldColumn>
                </FieldRow>
                {canAccessPhone && values?.mobilePhone && !isSmsEmpty ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginBottom: 10,
                    }}
                  >
                    <MessagingButtons
                      phoneNumber={values?.mobilePhone}
                    ></MessagingButtons>
                  </div>
                ) : null}
              </>
            )}

            <FieldRow>
              <FieldColumn>
                <FormField.Select
                  label={t('translation.NewClientContainer.label-gender')}
                  name={`${namePrefix}gender`}
                  parseValue={val => genders.find(e => e.id === val)}
                  formatValue={val => val?.id}
                  options={genders}
                  isSearchable={false}
                />
              </FieldColumn>

              <FieldColumn>
                <FormField.Text
                  label={t('translation.OrganizationDesktop.label-vatid')}
                  name={`${namePrefix}vatId`}
                  helpText={t('translation.NewClientContainer.clientVatId')}
                />
              </FieldColumn>
              <FieldColumn>
                <FormField.DateInput
                  label={t('translation.NewClientContainer.label-dob')}
                  name={`${namePrefix}birthDate`}
                  displayFormat="DD.MM.YYYY."
                />
              </FieldColumn>
            </FieldRow>
            <FieldRow style={{ alignItems: 'flex-end' }}>
              <FieldColumn>
                <FormField.Select
                  label={t('translation.LocationGeneralInfo.label-country')}
                  name={`${namePrefix}country`}
                  formatOptionLabel={e => e.countryName}
                  options={countriesData?.country?.all as any[]}
                  getOptionLabel={e => e.countryName}
                />
              </FieldColumn>
              <FieldColumn>
                <FormField.Text
                  label={t('translation.LocationGeneralInfo.label-address')}
                  name={`${namePrefix}address`}
                  helpText={t(
                    'translation.LocationGeneralInfo.tooltip-info-address'
                  )}
                />
              </FieldColumn>
            </FieldRow>
            <FieldRow style={{ alignItems: 'flex-end' }}>
              <FieldColumn>
                <FormField.Text
                  label={t('translation.LocationGeneralInfo.label-zip-code')}
                  name={`${namePrefix}zipCode`}
                />
              </FieldColumn>
              <FieldColumn>
                <FormField.Text
                  label={t('translation.LocationGeneralInfo.label-city')}
                  name={`${namePrefix}city`}
                />
              </FieldColumn>
            </FieldRow>
            <FieldRow>
              <FieldColumn>
                <FormField.TextArea
                  rows={2}
                  label={t('translation.NewClientContainer.label-comment')}
                  name={`${namePrefix}comment`}
                  placeholder={t(
                    'translation.NewClientContainer.placeholder-note'
                  )}
                />
              </FieldColumn>
            </FieldRow>

            <FieldRow>
              <FieldColumn>
                <FormField.Checkbox
                  disabled={!values?.email || !hasPrepaymentCredentials}
                  label={t(
                    'translation.NewClientContainer.label-paymentRequired'
                  )}
                  name={`${namePrefix}paymentRequired`}
                  type={'checkbox'}
                  helpText={t(
                    'translation.NewClientContainer.label-paymentRequired-helpText'
                  )}
                />
              </FieldColumn>
            </FieldRow>

            {!isNewClient ? blockButton() : null}
          </>
        </EventContainer>
      </FormContentContainer>
    </>
  )
}

type THeaderRowProps = {}
const HeaderRow = styled.div<THeaderRowProps>`
  display: flex;
  align-items: center;
  background: #f4f4ff;
  height: 60px;
  margin-left: -20px;
  margin-top: -20px;
  margin-bottom: 12px;
  width: calc(100% + 40px);
  padding: 20px;
  svg {
    margin-right: 20px;
    cursor: pointer;
  }

  @media ${device.tablet} {
    margin-left: -5px;
    margin-top: -10px;
  }
`
