import { useQuery } from '@apollo/client'
import { differenceInMinutes, format } from 'date-fns'
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import { chain } from 'lodash'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, FormField, Loader, Tab, TabView, ToolTip } from 'src/components'
import {
  formatPhone,
  getISODate,
  getServiceDurationInMinutes,
  getServiceHoursAndMinutes,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import { DateUtil, useSdk } from 'src/sdk'
import { useACL } from 'src/sdk/acl'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { FieldColumn, FieldRow } from 'src/sdk/tw/Form'
import { useValidationSchema } from 'src/sdk/useValidationSchema'
import { resourceGetGql } from 'src/state/gql'
import {
  ServiceInput,
  useAddServiceMutation,
  useClientUpdateMutation,
  useClientsAddMutation,
  useGetOrganizationByIdQuery,
  useGetServiceTypesQuery,
  useGetSmsBalanceQuery,
  useLocationGetQuery,
  usePosActiveJournalGetQuery,
  usePrepaymentsGetStripeConnectAccountInfoQuery,
  usePrepaymentsResendPaymentNotificationMutation,
  usePrepaymentsSendPaymentNotificationV2Mutation,
} from 'src/state/graphql'
import {
  queryServiceTypes,
  searchClients,
  useInviteUser,
} from 'src/state/queries'
import { trpc } from 'src/trpc'
import { ThemeContext } from 'styled-components'
import { ServiceForm as ServiceForm2 } from 'views/Settings/Services/ServiceEditor/ServiceForm'
import * as Yup from 'yup'
import {
  Container,
  FormWrapper,
  Modal,
  ModalWrapper,
} from '../Appointment/Appointment'
import { AppointmentHotelFooter } from '../CalendarHotel/components/AppointmentHotelFooter'
import { PopupContainer } from '../CashRegister/NewReceipt/styles'
import { GroupAppointmentFormFields } from './components/GroupAppointmentFormFields'
import { GroupClientForm } from './components/GroupClientForm'
import { GroupClientSlotDetails } from './components/GroupClientSlotDetails'
import { ClientDetails } from '../AppointmentModal/components/ClientDetails'
import { PosReceiptStore, usePosStore } from 'src/state/local/posReceiptStore'
import { NewReceipt } from '../CashRegister/NewReceipt/NewReceipt'
import { AppointmentHotelPaymentNotification } from '../CalendarHotel/components/AppointmentHotelPaymentNotification'

export const GroupAppointmentEdit = (props: {
  onClose: () => void
  id: bigint
  clientSlotBookingId?: bigint
}) => {
  const { orgId, locationId } = useParams<{
    orgId: string
    locationId: string
  }>()
  const { onClose, id, clientSlotBookingId } = props
  const { isMobile, t, toast, appServices, navigateTo } = useSdk()
  const {
    data: appointment,
    isLoading: loadingAppointment,
    refetch: refetchAppointment,
    isRefetching: refetchingAppointment,
  } = trpc.groupBooking_find.useQuery({
    id,
    locationId: BigInt(locationId),
    orgId,
  })
  const [
    initialClientSlotInEditBookingId,
    setInitialClientSlotInEditBookingId,
  ] = useState(clientSlotBookingId)
  const [activeForm, setActiveForm] = useState<
    | 'appointment'
    | 'client'
    | 'service'
    | `clientSlot_${string}`
    | 'paymentNotification'
  >(clientSlotBookingId ? 'clientSlot_preview' : 'appointment')

  const cancelSlotSchema = Yup.object().shape({
    clientSlotInEdit: Yup.object().shape({
      cancelGroupClientBookingReason: Yup.string().required(
        t('translation.AppointmentModal.cancelReason')
      ),
    }),
  })

  const selectedTheme = useContext(ThemeContext)
  const { hasPermission } = useACL()
  const [isBusy, setIsBusy] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [clientsSortBy, setClientsSortBy] = useState('')
  const [isNewClient, setIsNewClient] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)

  const { ClientSchema, GroupAppointmentSchema, ServiceSchema } =
    useValidationSchema({
      serviceFieldNamePrefix: 'newService',
      clientFieldNamePrefix: isNewClient ? 'newClient' : 'editedClient',
    })

  const formRef = useRef<any>(null)
  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')
  const canAccessInvoicing = hasPermission('ACCESS_INVOICING')

  // INVOICING DATA
  const { selectClient, addItem, resetReceipt, setReceiptResourceBookingId } =
    PosReceiptStore

  const [selectedReceipt, setSelectedReceipt] = useState<string | null>(null)
  const [isIssueReceipt, setIsIssueReceipt] = useState(false)

  const selectedPosDevice = usePosStore(store => store.pos.selectedPosDevice)
  const { data: journalData, loading: journalLoading } =
    usePosActiveJournalGetQuery({
      variables: {
        input: {
          fisLocationId: selectedPosDevice?.fisLocationId as any,
          fisDeviceId: selectedPosDevice?.fisDeviceId as any,
        },
      },
      skip: !selectedPosDevice?.fisLocationId,
    })
  const journal = journalData?.posActiveJournal_get

  const issueReceiptButtonDisabled =
    journal?.status === 'CLOSED' ||
    !moment(getISODate(journal?.date)).isSame(getISODate(new Date())) ||
    !journal

  // INVOICING DATA END

  const { data: locationData, loading: locationLoading } = useLocationGetQuery({
    variables: {
      locationId,
    },
  })

  const resourceId = appointment?.resourceId?.toString()

  const { data: resourceData, loading: loadingResource } = useQuery(
    resourceGetGql,
    {
      variables: { id: appointment?.resourceId },
      skip: !!resourceId === false,
    }
  )

  const { data: organizationData, loading: loadingOrgData } =
    useGetOrganizationByIdQuery({
      variables: { id: orgId },
    })

  const serviceTypesData = useGetServiceTypesQuery({
    variables: { locationId },
    fetchPolicy: 'network-only',
  })
  const { data: smsBalanceData, loading: smsBalanceLoading } =
    useGetSmsBalanceQuery({ fetchPolicy: 'network-only' })

  const location = locationData?.location?.get
  const org = organizationData?.organization?.get
  const resourcesData = location?.resources
  const serviceTypes = serviceTypesData.data?.serviceType?.search
  const smsBalance = smsBalanceData?.payment?.getSmsBalance?.balance || 0

  const { data: stripeConnectAccountData } =
    usePrepaymentsGetStripeConnectAccountInfoQuery({
      skip: !org?.stripeConnectAccountId,
    })

  const hasStripeChargesEnabled =
    stripeConnectAccountData?.prepayments?.getStripeConnectAccountInfo
      ?.hasChargesEnabled || false

  const hasInvoicingData =
    !!location?.prepaymentsConfig?.userId &&
    !!location?.prepaymentsConfig?.operatorId &&
    !!location?.prepaymentsConfig?.prepaymentsDeviceId &&
    !!location?.prepaymentsConfig?.prepaymentServiceId

  const hasPrepaymentCredentials =
    org?.prepaymentsProvider === 'STRIPE'
      ? hasStripeChargesEnabled && hasInvoicingData
      : org?.hasVivaMerchantId ||
        (false && org?.hasVivaApiKey) ||
        (false && hasInvoicingData)

  const hasPrepaymentsEnabled = location?.prepaymentsConfig?.prepaymentsEnabled

  const resources = useMemo(
    () => resourcesData?.filter(x => x.showInCalendar && x.kind === 'RESOURCE'),
    [resourcesData]
  )

  const flattenedServices = useMemo<
    NonNullable<NonNullable<typeof serviceTypes>[0]>['services']
  >(() => chain(serviceTypes).map('services').flatten().value(), [serviceTypes])

  const selectedResource = resourceId
    ? resourceData?.resource?.get
    : resources?.[0]

  //region MUTATIONS
  const [clientAddMutation, { loading: loadingAddClient }] =
    useClientsAddMutation()

  const [updateClient, { loading: loadingUpdateClient }] =
    useClientUpdateMutation()

  const [inviteUser] = useInviteUser()

  const [createNewService, { loading: loadingCreateService }] =
    useAddServiceMutation()

  const [
    resendPaymentNotificationMutation,
    { loading: loadingResendPaymentNotificationMutation },
  ] = usePrepaymentsResendPaymentNotificationMutation()
  const [
    sendPaymentNotificationMutation,
    { loading: loadingSendPaymentNotificationMutation },
  ] = usePrepaymentsSendPaymentNotificationV2Mutation()

  const updateGroupBookingMutation = trpc.groupBooking_update.useMutation()
  const cancelGroupBookingMutation = trpc.groupBooking_cancel.useMutation()
  const cancelSlotMutation = trpc.groupBooking_cancelSlot.useMutation()
  const confirmSlotMutation = trpc.groupBooking_confirmSlot.useMutation()
  const concludeSlotMutation = trpc.groupBooking_concludeSlot.useMutation()
  const editSlotInternalNoteMutation =
    trpc.groupBooking_updateNotes.useMutation()

  const sendPaymentNotification = async ({
    resourceBookingId,
    cancelAppointmentOnExpiredPaymentRequest,
    isResend = false,
    showSuccessToast = true,
  }) => {
    try {
      if (!resourceBookingId) return

      const results = isResend
        ? await resendPaymentNotificationMutation({
            variables: {
              id: resourceBookingId,
            },
          })
        : await sendPaymentNotificationMutation({
            variables: {
              id: resourceBookingId,
              cancelAppointmentOnExpiredPaymentRequest,
            },
          })

      if (!results.errors?.length) {
        if (showSuccessToast) {
          toast.success(t('translation.AppointmentModal.paymentRequest-sent'))
        }
      } else toast.danger(results?.errors?.[0]?.message)
      if (!isResend) {
        await refetchAppointment()
      }
    } catch (err) {
      console.log(err)
    } finally {
    }
  }

  const handleCancelGroupAppointment = async values => {
    try {
      const result = await cancelGroupBookingMutation.mutateAsync({
        id: appointment?.id!,
        messageToClients: values?.messageToClients || '',
        sendAppointmentEmail: values?.sendAppointmentEmail || false,
        sendAppointmentSms: values?.sendAppointmentSms || false,
        shouldRefundPaymentRequest: values?.shouldRefundPaymentRequest,
      })

      toast.success('Termin je uspješno otkazan')
      refetchAppointment()
      onClose()
    } catch (err) {
      toast.danger('Došlo je do pogreške - ' + (err as any).message)
    }
  }

  const handleUpdateGroupAppointment = async values => {
    try {
      setIsBusy(true)

      const durationMinutes = getServiceDurationInMinutes(values?.duration!)
      const startTimeUtc = moment(`${values.date} ${values.startTime?.id}`)
        .utc()
        .toDate()

      const endTimeUtc = moment(`${values.date} ${values.startTime?.id}`)
        .add(durationMinutes, 'minutes')
        .utc()
        .toDate()

      const service = flattenedServices?.find(x => x?.id === values?.service)

      const result = await updateGroupBookingMutation.mutateAsync({
        id: appointment?.id!,
        serviceId: BigInt(values?.service),
        resourceId: BigInt(values?.resource?.id),
        onlineBookingAllowed: values?.onlineBookingAllowed || false,
        internalNote: values?.internalNote || '',
        startTimeUtc,
        endTimeUtc,
        capacity: Number(values?.capacity | 0),
        clientSlots:
          values?.clientSlots?.map(slot => {
            return {
              resourceBookingId: slot?.resourceBookingId
                ? BigInt(slot?.resourceBookingId)
                : null,
              clientId: BigInt(slot?.client?.id),
              internalNote: slot?.internalNote || '',
              messageToClient: slot?.messageToClient || '',
              sendAppointmentEmail: slot?.sendAppointmentEmail || false,
              sendAppointmentSms: slot?.sendAppointmentSms || false,
            }
          }) || [],
      })

      const sendPaymentRequestPromises =
        result
          ?.filter(slot => {
            const slotConfig = values?.clientSlots?.find(
              x => x.client?.id === slot?.clientId?.toString()
            )
            return (
              slotConfig?.sendPaymentRequest &&
              hasPrepaymentCredentials &&
              !!values?.service &&
              service!.totalAmount > 0
            )
          })
          ?.map(slot => {
            const slotConfig = values?.clientSlots?.find(
              x => x.client?.id === slot?.clientId?.toString()
            )

            return sendPaymentNotification({
              resourceBookingId: slot?.id,
              cancelAppointmentOnExpiredPaymentRequest:
                slotConfig?.cancelAppointmentOnExpiredPaymentRequest || false,
              isResend: false,
              showSuccessToast: false,
            })
          }) || []

      await Promise.all(sendPaymentRequestPromises)

      toast.success('Termin je uspješno ažuriran')
      refetchAppointment()
      onClose()
    } catch (err) {
      toast.danger('Došlo je do pogreške - ' + (err as any).message)
    } finally {
      setIsBusy(false)
    }
  }

  const handleSubmitCancelSlot = async values => {
    await cancelSlotMutation.mutateAsync({
      locationId: BigInt(locationId),
      orgId,
      resourceBookingId: BigInt(values?.clientSlotInEdit.resourceBookingId),
      reason: values.clientSlotInEdit.cancelGroupClientBookingReason,
      sendEmail: values.clientSlotInEdit.sendEmail || false,
      sendSms: values.clientSlotInEdit.sendSms || false,
      meessageToClient: values.clientSlotInEdit?.messageToClient,
      shouldRefundPaymentRequest:
        values.clientSlotInEdit?.shouldRefundPaymentRequest,
    })
    await refetchAppointment()

    setActiveForm('appointment')
  }
  const handleSubmitConfirmSlot = async values => {
    await confirmSlotMutation.mutateAsync({
      locationId: BigInt(locationId),
      orgId,
      resourceBookingId: BigInt(values?.clientSlotInEdit.resourceBookingId),
      sendEmail: values.clientSlotInEdit.sendEmail,
      sendSms: values.clientSlotInEdit.sendSms || false,
      meessageToClient: values.clientSlotInEdit?.messageToClient,
    })
    await refetchAppointment()

    setActiveForm('appointment')
  }

  const handleSubmitConcludeSlot = async values => {
    await concludeSlotMutation.mutateAsync({
      locationId: BigInt(locationId),
      orgId,
      resourceBookingId: BigInt(values?.clientSlotInEdit.resourceBookingId),
      requestReviewByEmail:
        values.clientSlotInEdit.requestReviewByEmail || false,
      requestReviewBySms: values.clientSlotInEdit.requestReviewBySms || false,
      meessageToClient: values.clientSlotInEdit?.messageToClient,
    })
    await refetchAppointment()

    setActiveForm('appointment')
  }
  const handleSubmitEditSlotInternalNote = async values => {
    await editSlotInternalNoteMutation.mutateAsync({
      locationId: BigInt(locationId),
      orgId,
      resourceBookingId: BigInt(values?.clientSlotInEdit.resourceBookingId),
      notes: values.clientSlotInternalNote,
    })
    await refetchAppointment()

    setActiveForm('appointment')
  }

  async function createClient(allValues) {
    try {
      setIsBusy(true)

      if (isNewClient) {
        const values = allValues?.newClient

        const clientResult = await clientAddMutation({
          variables: {
            input: {
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              language: org?.language || 'hr',
              city: values.city,
              comment: values.comment,
              mobilePhone: formatPhone(values.mobilePhone),
              gender: values.gender,
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              membershipExpirationDate: values.membershipExpirationDate
                ? moment(values.membershipExpirationDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              sendBookingEmail: true,
              sendNewsEmail: true,
              sendHelpEmail: true,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })
        if (values?.sendInviteViaEMail) {
          await inviteUser({
            variables: {
              input: {
                firstName: values.firstName?.trim(),
                lastName: values.lastName?.trim() || '',
                email: values.email,
                gender: values.gender || 'Unspecified',
                mobilePhone: formatPhone(values.mobilePhone),
                role: 'CLIENT',
                clientId: clientResult?.data?.clients?.add?.payload?.id!,
              },
            },
          })
        }

        if (!clientResult?.errors?.length) {
          toast.success(
            (t('translation.AppointmentModal.toast-newClientEntered'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          return clientResult?.data?.clients?.add?.payload
        } else {
          toast.danger(t('translation.AppointmentModal.toast-newClientError'))
        }
      } else {
        const values = allValues?.editedClient
        let clientResult = await updateClient({
          variables: {
            input: {
              id: values?.id,
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              city: values.city,
              comment: values.comment,
              gender: values.gender,
              mobilePhone: formatPhone(values.mobilePhone),
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              membershipExpirationDate: values.membershipExpirationDate
                ? moment(values.membershipExpirationDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })

        if (!clientResult.errors?.length) {
          toast.success(
            (t('translation.EditClient.toast-editClient'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          await refetchAppointment()

          return clientResult?.data?.clients?.update?.payload
        } else {
          toast.danger(
            'Došlo je do pogreške - ' + clientResult?.errors[0]?.message
          )
        }
      }
    } finally {
      setIsBusy(false)
    }
  }
  async function createService(allValues) {
    try {
      setIsBusy(true)
      const values = allValues?.newService

      const input: ServiceInput = {
        color: values.color,
        description: values.description,
        name: values.name,
        bookingAllowed: org?.orgFeatures?.hasOnlineBooking
          ? values.bookingAllowed
          : false,
        hasBookingConfirmation: values.hasBookingConfirmation,
        price: values.price
          ? parseFloat(values.price?.toString().replace(',', '.'))
          : parseFloat('0'),
        durationMinutes: getServiceDurationInMinutes(values.durationMinutes),
        netDurationMinutes: Number(values.netDurationMinutes),
        serviceTypeId: values.type?.id,
        resources: values.resources?.map(x => x.id),
        professionId: values.profession?.id,
        locationId: locationId.toString(),
        resourceTypeId: values.resourceType?.id,
        vatRate: values.vatRate
          ? parseFloat((values.vatRate / 100).toString())
          : parseFloat('0'),
        serviceTypeCategoryId: values.serviceTypeCategoryId,
        discountValue: values?.discountValue
          ? Number(values?.discountValue?.toString().replace(',', '.'))
          : null,
        discountAmount: Number(values?.discountAmount || 0),
        discountType: values?.discountType,
        discountRate: Number(values?.discountRate || 0),
        totalAmount: Number(values?.totalAmount),
      }
      let serviceResult = await createNewService({
        variables: { input },
        refetchQueries: [
          {
            query: queryServiceTypes,
            variables: { locationId },
          },
        ],
      })

      if (serviceResult?.data) {
        appServices.toast.success(
          t('translation.AppointmentModal.toast-serviceCreated')
        )
        return serviceResult.data.services?.add?.payload
      }
    } finally {
      setIsBusy(false)
    }
  }
  const handleIssueReceipt = async values => {
    setIsBusy(true)
    //await handleUpdateHotelAppointment(values, true, false)
    const clientSlot = values?.clientSlotInEdit

    resetReceipt()
    setSelectedReceipt(null)

    if (clientSlot?.client) {
      selectClient(clientSlot?.client)
    }

    setReceiptResourceBookingId(clientSlot!.resourceBookingId.toString())

    const bookingService = appointment?.resourceBookingServices[0]

    const serviceType = serviceTypes?.find(
      x =>
        x?.services?.some(
          y => y?.id === bookingService?.Service?.id?.toString()
        )
    )

    addItem({
      resourceBookingServiceId:
        clientSlot?.ResourceBookingServices[0]?.id?.toString(),
      price: +(bookingService?.price || 0),
      quantity: +(bookingService?.quantity || 1),
      itemDescription: bookingService?.Service?.name || '',
      serviceId: bookingService?.Service?.id?.toString(),
      vatRate:
        roundNumberWithTwoDecimals(
          Number(bookingService?.Service?.vatRate) * 100
        ) || 0,
      serviceType: serviceType?.name,
      finalPrice: bookingService?.totalAmount,
      total: bookingService?.totalAmount,
      discountType: bookingService?.discountType,
      discountAmount: +(bookingService?.discountAmount || 0),
      discountRate: roundNumberWithTwoDecimals(
        +(bookingService?.discountRate || 0) * 100
      ),
    })

    if (clientSlot?.posPaymentRequest?.status === 'PAID') {
      const prepaymentServiceId =
        location?.prepaymentsConfig?.prepaymentServiceId

      const prepaymentServiceType = serviceTypes?.find(x => {
        return x?.services?.find(y => y?.id === prepaymentServiceId)
      })

      const prepaymentServiceRecord = prepaymentServiceType?.services?.find(
        x => x?.id === prepaymentServiceId
      )

      if (prepaymentServiceRecord) {
        addItem({
          quantity: -1,
          discountAmount: 0,
          discountRate: 0,
          price: Number(clientSlot.posPaymentRequest.amount!),
          total: -Number(clientSlot.posPaymentRequest.amount!),
          finalPrice: -Number(clientSlot.posPaymentRequest.amount!),
          serviceId: prepaymentServiceRecord.id,
          paymentRequestId: clientSlot.posPaymentRequest.id.toString(),
          paymentRequestReceiptNo: clientSlot.posPaymentRequest.receiptNo,
          itemDescription: prepaymentServiceRecord.name,
          vatRate:
            roundNumberWithTwoDecimals(
              Number(prepaymentServiceRecord?.vatRate)
            ) || 0,
        })
      }
    }

    setInitialClientSlotInEditBookingId(clientSlot?.resourceBookingId)
    setIsIssueReceipt(true)
    setIsBusy(false)
  }

  function getOnNewClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      form.setFieldValue('newClient', null)
      setActiveForm('client')
      setIsNewClient(true)
      form.setFieldValue('newClient.firstName', '')
      form.setFieldValue('newClient.lastName', '')
      form.setFieldValue('newClient.email', '')
      form.setFieldValue('newClient.mobilePhone', '')
      form.setFieldValue('newClient.address', '')
      form.setFieldValue('newClient.zipCode', '')
      form.setFieldValue('newClient.city', '')
      form.setFieldValue('newClient.gender', '')
      form.setFieldValue('newClient.comment', '')
      form.setFieldValue('newClient.sendInviteViaEMail', false)
      form.setFieldValue('newClient.sendReminderEmail', false)
      form.setFieldValue('newClient.paymentRequired', true)
    }
  }
  function getOnEditClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      setIsNewClient(false)
      setActiveForm('client')
      console.log(form?.values)
      form.setFieldValue(
        'editedClient.id',
        form?.values?.clientSlotInEdit?.client?.id
      )
      form.setFieldValue(
        'editedClient.firstName',
        form?.values?.clientSlotInEdit?.client?.firstName
      )
      form.setFieldValue(
        'editedClient.lastName',
        form?.values?.clientSlotInEdit?.client?.lastName
      )
      form.setFieldValue(
        'editedClient.email',
        form?.values?.clientSlotInEdit?.client?.email
      )
      form.setFieldValue(
        'editedClient.mobilePhone',
        form?.values?.clientSlotInEdit?.client?.mobilePhone
      )
      form.setFieldValue(
        'editedClient.address',
        form?.values?.clientSlotInEdit?.client?.address
      )
      form.setFieldValue(
        'editedClient.zipCode',
        form?.values?.clientSlotInEdit?.client?.zipCode
      )
      form.setFieldValue(
        'editedClient.city',
        form?.values?.clientSlotInEdit?.client?.city
      )
      form.setFieldValue(
        'editedClient.gender',
        form?.values?.clientSlotInEdit?.client?.gender
      )
      form.setFieldValue(
        'editedClient.comment',
        form?.values?.clientSlotInEdit?.client?.comment
      )
      form.setFieldValue(
        'editedClient.birthDate',
        form?.values?.clientSlotInEdit?.client?.birthDate
      )
      form.setFieldValue(
        'editedClient.membershipExpirationDate',
        form?.values?.clientSlotInEdit?.client?.membershipExpirationDate
      )
      form.setFieldValue(
        'editedClient.vatId',
        form?.values?.clientSlotInEdit?.client?.vatId
      )
      form.setFieldValue(
        'editedClient.country',
        form?.values?.clientSlotInEdit?.client?.country
      )
      form.setFieldValue(
        'editedClient.sendReminderEmail',
        form?.values?.clientSlotInEdit?.client?.sendReminderEmail
      )
      form.setFieldValue(
        'editedClient.sendReminderSms',
        form?.values?.clientSlotInEdit?.client?.sendReminderSms
      )
      form.setFieldValue(
        'editedClient.sendBookingEmail',
        form?.values?.clientSlotInEdit?.client?.sendBookingEmail
      )
      form.setFieldValue(
        'editedClient.sendNewsEmail',
        form?.values?.clientSlotInEdit?.client?.sendNewsEmail
      )
      form.setFieldValue(
        'editedClient.sendHelpEmail',
        form?.values?.clientSlotInEdit?.client?.sendHelpEmail
      )
      form.setFieldValue(
        'editedClient.paymentRequired',
        form?.values?.clientSlotInEdit?.client?.paymentRequired
      )
      form.setFieldValue(
        'editedClient.status',
        form?.values?.clientSlotInEdit?.client?.status
      )
    }
  }

  function getOnNewService(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      setActiveForm('service')
      form.setFieldValue('newService.description', '')
      form.setFieldValue('newService.color', selectedTheme.colors.primary)
      form.setFieldValue('newService.durationMinutes', 30)
      form.setFieldValue('newService.netDurationMinutes', 30)
      form.setFieldValue('newService.bookingAllowed', true)
      form.setFieldValue('newService.hasBookingConfirmation', true)
      form.setFieldValue('newService.vatRate', org?.vatStatus === 'Y' ? 25 : 0)
      form.setFieldValue('newService.price', 0)
      form.setFieldValue('newService.currencyId', org?.currency?.id)
      form.setFieldValue('newService.discountAmount', 0)
      form.setFieldValue('newService.discountRate', 0)
      form.setFieldValue('newService.totalAmount', 0)
      form.setFieldValue('newService.discountType', 'percent')
      form.setFieldValue('newService.resource', resources)
    }
  }

  const getSaveButtonLabel = () => {
    return activeForm === 'paymentNotification'
      ? !hasPrepaymentCredentials
        ? t('translation.AppointmentModal.button-goToPrepayments')
        : formRef.current.values.clientSlotInEdit?.posPaymentRequest?.status ===
          'PENDING_PAYMENT'
        ? t('translation.AppointmentModal.resendPaymentNotification')
        : t('translation.AppointmentModal.sendPaymentNotification')
      : t('translation.AppointmentModal.button-save')
  }

  function formatDuration(minutes: number): string {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    return `${hours.toString().padStart(2, '0')}:${remainingMinutes
      .toString()
      .padStart(2, '0')}`
  }

  const handleValueChange = async (name, value, values, form) => {
    if (name === 'service') {
      const service = flattenedServices?.find(x => x?.id === value)

      const duration = DateUtil.minutesToDurationString(
        service?.durationMinutes!
      )

      formRef?.current?.setFieldValue('duration', duration)
    }
    if (name === 'clientSlotInEdit') {
      // to prevent dirty flag from just opening a slot
      formRef?.current?.resetForm({ values: formRef?.current?.values })
    }
  }

  const handleSubmit = async (values, form) => {
    if (activeForm === 'appointment') {
      await handleUpdateGroupAppointment(values)
    }
    if (activeForm === 'clientSlot_cancel') {
      await handleSubmitCancelSlot(values)
    }
    if (activeForm === 'clientSlot_confirm') {
      await handleSubmitConfirmSlot(values)
    }
    if (activeForm === 'clientSlot_conclude') {
      await handleSubmitConcludeSlot(values)
    }
    if (activeForm === 'clientSlot_editInternalNote') {
      await handleSubmitEditSlotInternalNote(values)
    }
    if (activeForm === 'paymentNotification') {
      await sendPaymentNotification({
        resourceBookingId: values?.clientSlotInEdit?.resourceBookingId,
        cancelAppointmentOnExpiredPaymentRequest:
          values?.clientSlotInEdit?.cancelAppointmentOnExpiredPaymentRequest,
        showSuccessToast: true,
        isResend:
          values?.clientSlotInEdit?.posPaymentRequest &&
          values?.clientSlotInEdit?.posPaymentRequest?.status ===
            'PENDING_PAYMENT',
      })
      setActiveForm('appointment')
    }
    if (activeForm === 'client') {
      const client = await createClient(values)
      formRef.current.setFieldValue('newClientSlot.client', client)
      formRef.current.setFieldValue(
        'newClientSlot.sendAppointmentEmail',
        location?.sendAppointmentEmailsToClients && !!client?.email
      )
      formRef.current.setFieldValue(
        'newClientSlot.sendAppointmentSms',
        location?.sendAppointmentSmsToClients && !!client?.mobilePhone
      )
      if (hasPrepaymentCredentials && hasPrepaymentsEnabled) {
        form.setFieldValue(
          'newClientSlot.sendPaymentRequest',
          client?.paymentRequired
        )
      }
      setActiveForm('appointment')
    }
    if (activeForm === 'service') {
      const service = await createService(values)

      form.setFieldValue('service', service?.id)
      form.setFieldValue(
        'duration',
        getServiceHoursAndMinutes(service?.durationMinutes)
      )
      form.setFieldValue('newService', undefined)

      setActiveForm('appointment')
    }
  }

  const isLoading =
    loadingAppointment ||
    locationLoading ||
    loadingOrgData ||
    loadingResource ||
    serviceTypesData.loading ||
    refetchingAppointment

  const isUpdating =
    loadingAddClient ||
    loadingUpdateClient ||
    loadingCreateService ||
    updateGroupBookingMutation.isLoading ||
    cancelSlotMutation.isLoading ||
    concludeSlotMutation.isLoading ||
    editSlotInternalNoteMutation.isLoading ||
    confirmSlotMutation.isLoading
  cancelGroupBookingMutation.isLoading || isBusy

  const clientSlotInEdit =
    isLoading || !appointment || !initialClientSlotInEditBookingId
      ? null
      : appointment?.occupiedSlots?.find(
          slot => slot.id === initialClientSlotInEditBookingId
        )

  const initialValues =
    isLoading || !appointment
      ? {}
      : {
          id: appointment.id?.toString(),
          internalNote: appointment.internalNote,
          date: format(appointment.date!, 'yyyy-MM-dd'),
          startTime: {
            id: format(appointment.startTime!, 'HH:mm'),
            title: format(appointment.startTime!, 'HH:mm'),
          },

          endTime: format(appointment.endTime!, 'HH:mm'),
          endTimeUtc: moment(appointment.endTime).toISOString(),
          service: appointment.service?.id?.toString(),
          /* sendAppointmentEmail: location?.sendAppointmentEmailsToClients,
    sendAppointmentSms: location?.sendAppointmentSmsToClients, */
          duration: formatDuration(
            differenceInMinutes(appointment.endTime, appointment.startTime)
          ),
          onlineBookingAllowed: appointment.onlineBookingAllowed,
          capacity: appointment.capacity,
          resource: selectedResource,
          status: appointment?.status,
          clientSlots: appointment.occupiedSlots?.map(slot => {
            return {
              ...slot,
              resourceBookingId: slot?.id,
              status: slot?.status,
              reason: slot?.reason,
              client: slot.Client,
              internalNote: slot.internalNote,
              messageToClient: slot.message,
            }
          }),
          originalAppointmentStartTime: format(appointment.startTime!, 'HH:mm'),
          originalAppointmentEndTime: format(appointment.endTime!, 'HH:mm'),
          originalAppointmentResourceId: selectedResource?.id,
          resourceBookingServiceId: appointment?.resourceBookingServiceId,
          clientSlotInEdit: clientSlotInEdit
            ? {
                ...clientSlotInEdit,
                resourceBookingId: clientSlotInEdit?.id,
                status: clientSlotInEdit?.status,
                reason: clientSlotInEdit?.reason,
                client: clientSlotInEdit.Client,
                internalNote: clientSlotInEdit.internalNote,
                messageToClient: clientSlotInEdit.message,
              }
            : undefined,
        }

  useEffect(() => {
    const clientsSortBy = localStorage.getItem('clientsSortBy')
    if (clientsSortBy) {
      setClientsSortBy(clientsSortBy)
    } else setClientsSortBy('id_desc')
  }, [])

  const trpcContext = trpc.useContext()

  useEffect(() => {
    if (activeForm === 'appointment') {
      setSelectedTabIndex(0)
    }
  }, [activeForm])

  useEffect(() => {
    trpcContext.groupBooking_find.invalidate()
  }, [])

  if (isIssueReceipt) {
    return (
      <NewReceipt
        setOpenNewReceiptModal={setIsIssueReceipt}
        isAppointment
        handleOnCloseEvent={() => {
          refetchAppointment()
          setIsIssueReceipt(false)
          setActiveForm('appointment')
        }}
        setIsIssueReceipt={setIsIssueReceipt}
        setSelectedReceipt={setSelectedReceipt}
        selectedReceipt={selectedReceipt}
      />
    )
  }

  return (
    <div>
      <Container>
        <Modal>
          <ModalWrapper>
            <></>

            {isLoading ? (
              <Loader isComponent />
            ) : (
              <>
                <FormWrapper>
                  <Form
                    onValueChange={handleValueChange}
                    initialValues={initialValues}
                    validationSchema={
                      activeForm === 'client'
                        ? ClientSchema
                        : activeForm === 'appointment'
                        ? GroupAppointmentSchema
                        : activeForm === 'clientSlot_cancel'
                        ? cancelSlotSchema
                        : activeForm === 'service'
                        ? ServiceSchema
                        : undefined
                    }
                    // enableReinitialize
                    isInitialValid={true}
                    onSubmit={handleSubmit}
                    validateOnChange={true}
                  >
                    {form => {
                      const {
                        values,
                        errors,
                        setFieldValue,
                        validateForm,
                        touched,
                        dirty,
                      } = form
                      formRef.current = form
                      //console.log({ values, errors })
                      const service = flattenedServices?.find(
                        x => x?.id === values?.service
                      )
                      const isPrepaymentDisabled =
                        !hasPrepaymentCredentials ||
                        !service ||
                        (service?.totalAmount || 0) <= 0

                      return (
                        <>
                          {(isBusy || isUpdating) && <Loader isComponent />}

                          {activeForm === 'appointment' ? (
                            <>
                              <TabView
                                selectedTabIndex={selectedTabIndex}
                                setSelectedTabIndex={setSelectedTabIndex}
                                isSingleTab={false}
                                hasFooter={false}
                              >
                                <Tab
                                  hasFooter={false}
                                  title={t(
                                    'translation.Appointment.title-view-appointment'
                                  )}
                                >
                                  {(isBusy || isUpdating) && (
                                    <Loader isComponent />
                                  )}
                                  <GroupAppointmentFormFields
                                    form={form}
                                    location={location}
                                    serviceTypes={serviceTypes}
                                    org={org}
                                    onNewService={getOnNewService(form)}
                                    onNewClient={getOnNewClient(form)}
                                    isNew={false}
                                    setActiveForm={setActiveForm}
                                    isPrepaymentDisabled={isPrepaymentDisabled}
                                    onCancelAppointmentClick={() =>
                                      setShowCancelModal(true)
                                    }
                                    flattenedServices={flattenedServices}
                                  />
                                </Tab>
                              </TabView>
                            </>
                          ) : activeForm === 'client' ? (
                            <GroupClientForm
                              form={form}
                              isNewClient={isNewClient}
                              location={location}
                              org={org}
                              setActiveForm={setActiveForm}
                              fieldsNamePrefix={
                                isNewClient ? 'newClient' : 'editedClient'
                              }
                              refetchData={refetchAppointment}
                              hasPrepaymentCredentials={
                                hasPrepaymentCredentials
                              }
                            />
                          ) : activeForm?.startsWith('clientSlot') ? (
                            <TabView
                              selectedTabIndex={selectedTabIndex}
                              setSelectedTabIndex={setSelectedTabIndex}
                              isSingleTab={false}
                              hasFooter={false}
                            >
                              <Tab
                                hasFooter={false}
                                title={t(
                                  'translation.Appointment.title-view-appointment'
                                )}
                              >
                                <GroupClientSlotDetails
                                  form={form}
                                  location={location}
                                  org={org}
                                  setActiveForm={setActiveForm}
                                  isPrepaymentDisabled={isPrepaymentDisabled}
                                  service={
                                    flattenedServices?.find(
                                      x => x?.id === values?.service
                                    )!
                                  }
                                />
                              </Tab>

                              <Tab
                                hasFooter={false}
                                title={
                                  isMobile
                                    ? t(
                                        'translation.AppointmentModal.label-client'
                                      )
                                    : t(
                                        'translation.AppointmentModal.clientDetails'
                                      )
                                }
                              >
                                {/* <ClientDetailsGroup
                                  initialValues={values}
                                  onEdit={() => {}}
                                /> */}

                                <ClientDetails
                                  initialValues={{
                                    ...values,
                                    client: values?.clientSlotInEdit?.client,
                                    currencyId: org?.currency?.id,
                                  }}
                                  setActiveForm={setActiveForm}
                                  isNew={isNewClient}
                                  onEdit={getOnEditClient(form)}
                                  isGroup={true}
                                />
                              </Tab>
                            </TabView>
                          ) : activeForm === 'service' ? (
                            <ServiceForm2
                              form={form}
                              isNew={true}
                              hasOnlineBooking={
                                org?.orgFeatures?.hasOnlineBooking || false
                              }
                              isAppointment
                              onGoBack={() => {
                                form?.setFieldValue('newService', undefined)
                                setActiveForm('appointment')
                              }}
                              fieldsNamePrefix="newService"
                            />
                          ) : activeForm === 'paymentNotification' ? (
                            <AppointmentHotelPaymentNotification
                              flattenedServices={flattenedServices}
                              totalServicesPrice={service?.totalAmount || 0}
                              location={location}
                              values={values}
                              isBusy={
                                loadingSendPaymentNotificationMutation ||
                                loadingResendPaymentNotificationMutation
                              }
                              org={org}
                              hasPrepaymentsCredentials={
                                hasPrepaymentCredentials
                              }
                              closeModal={onClose}
                              stripeAccountInfo={
                                stripeConnectAccountData?.prepayments
                                  ?.getStripeConnectAccountInfo
                              }
                              hasInvoicingData={hasInvoicingData}
                              fieldNamePrefix="clientSlotInEdit"
                              isGroup
                            />
                          ) : null}
                        </>
                      )
                    }}
                  </Form>
                </FormWrapper>
                <AppointmentHotelFooter
                  onSaveClick={() => {
                    formRef.current?.submitForm()
                  }}
                  hideSaveButton={
                    !canEditAppointment || activeForm?.startsWith('clientSlot')
                  }
                  saveButtonLabel={getSaveButtonLabel()}
                  closeButtonLabel={
                    activeForm?.startsWith('clientSlot') ? 'Natrag' : undefined
                  }
                  showIssueReceiptButton={
                    !isMobile &&
                    activeForm?.startsWith('clientSlot') &&
                    org?.subscriptionPlan === 'advanced' &&
                    formRef?.current?.values?.clientSlotInEdit?.status !==
                      'DRAFT' &&
                    canAccessInvoicing &&
                    !isLoading
                  }
                  issueReceiptButtonProps={{
                    label:
                      formRef?.current?.values?.clientSlotInEdit
                        ?.paymentStatus === 'PAID'
                        ? 'Ponovi izdavanje računa'
                        : 'Izdaj račun',
                    onClick: () => {
                      issueReceiptButtonDisabled
                        ? navigateTo.posScreen({ locationId, orgId })
                        : handleIssueReceipt(formRef?.current?.values)
                    },
                    isLoading: isBusy,
                  }}
                  onCloseClick={e => {
                    e.stopPropagation()
                    if (activeForm === 'client') {
                      setActiveForm('appointment')
                    } else if (activeForm === 'appointment') {
                      if (formRef?.current && formRef?.current?.dirty) {
                        if (
                          confirm(t('translation.Form.unsavedChangesMessage'))
                        ) {
                          formRef?.current?.resetForm({
                            values: formRef?.current?.values,
                          })
                          setTimeout(() => {
                            onClose()
                          }, 0)
                        }
                      } else {
                        onClose()
                      }
                    } else if (activeForm?.startsWith('clientSlot')) {
                      formRef.current?.setFieldValue(
                        'clientSlotInEdit',
                        undefined
                      )
                      setActiveForm('appointment')
                    } else if (activeForm === 'service') {
                      formRef.current?.setFieldValue('newService', undefined)
                      setActiveForm('appointment')
                    } else if (activeForm === 'paymentNotification') {
                      setActiveForm('appointment')
                    }
                  }}
                />
              </>
            )}

            {showCancelModal ? (
              <PopupContainer>
                {cancelGroupBookingMutation.isLoading ? (
                  <Loader isComponent />
                ) : null}
                <div className="w-10/12 h-fit rounded-lg overflow-hidden">
                  <Form
                    initialValues={{
                      sendAppointmentEmail:
                        location?.sendAppointmentEmailsToClients,
                      sendAppointmentSms: location?.sendAppointmentSmsToClients,
                      shouldRefundPaymentRequest: false,
                    }}
                    isInitialValid={true}
                    onSubmit={handleCancelGroupAppointment}
                  >
                    {cancelForm => {
                      const shouldShowRefundCheckbox =
                        formRef?.current?.values?.clientSlots?.some(
                          slot =>
                            slot?.posPaymentRequest &&
                            slot?.posPaymentRequest?.status === 'PAID'
                        )
                      console.log(formRef?.current?.values)
                      return (
                        <div className="  bg-white rounded-lg flex flex-col overflow-auto p-4 ">
                          <h2 className="font-medium text-xl text-center w-full my-2">
                            Potvrdi otkaz termina
                          </h2>
                          <div className="flex flex-col lg:flex-row mt-6 w-full">
                            {formRef?.current?.values?.clientSlots?.some(
                              slot => !!slot?.client?.email
                            ) ? (
                              <FieldRow style={{ flex: 1 }}>
                                <FieldColumn>
                                  <FormField.Checkbox
                                    label={t(
                                      'translation.UpdateStatusModal.sendClientEmail'
                                    )}
                                    name="sendAppointmentEmail"
                                    type={'checkbox'}
                                    helpText={
                                      'Želiš li da Zoyya emailom obavijesti klijente o otkazu ovog termina?'
                                    }
                                  />
                                </FieldColumn>
                              </FieldRow>
                            ) : null}

                            {formRef?.current?.values?.clientSlots?.some(
                              slot => !!slot?.client?.mobilePhone
                            ) && !smsBalanceLoading ? (
                              <FieldRow style={{ flex: 1 }}>
                                <FieldColumn>
                                  {smsBalance > 0 ? (
                                    <FormField.Checkbox
                                      label={t(
                                        'translation.UpdateStatusModal.sendClientSms'
                                      )}
                                      name="sendAppointmentSms"
                                      disabled={smsBalance <= 0}
                                      type={'checkbox'}
                                      helpText={
                                        'Želiš li da Zoyya SMS-om obavijesti klijente o otkazu ovog termina?'
                                      }
                                    />
                                  ) : (
                                    <ToolTip
                                      label={t(
                                        'translation.Appointment.no-sms-available'
                                      )}
                                    >
                                      <FormField.Checkbox
                                        label={t(
                                          'translation.UpdateStatusModal.sentClientSms'
                                        )}
                                        name="sendAppointmentSms"
                                        disabled={smsBalance <= 0}
                                        type={'checkbox'}
                                        helpText={t(
                                          'translation.UpdateStatusModal.sendSmsHintNew'
                                        )}
                                      />
                                    </ToolTip>
                                  )}
                                </FieldColumn>
                              </FieldRow>
                            ) : null}
                          </div>

                          {shouldShowRefundCheckbox ? (
                            <FormField.Checkbox
                              helpText={t(
                                'translation.UpdateStatusModal.refund-checkbox-help'
                              )}
                              label={t(
                                'translation.UpdateStatusModal.refund-checkbox-label'
                              )}
                              type={'checkbox'}
                              name={'shouldRefundPaymentRequest'}
                            />
                          ) : null}
                          {formRef?.current?.values?.clientSlots?.some(
                            slot => !!slot?.client?.email
                          ) && cancelForm?.values?.sendAppointmentEmail ? (
                            <div
                              style={{
                                marginTop: !isMobile ? 10 : 5,
                                paddingTop: !isMobile ? 0 : 5,
                              }}
                            >
                              <FormField.TextArea
                                label={'Poruka klijentima'}
                                placeholder={
                                  'Vidljivo klijentima u emailu o otkazu termina'
                                }
                                name="messageToClients"
                                rows={2}
                              />
                            </div>
                          ) : null}

                          <div className="flex justify-end mt-4">
                            <div className="flex gap-2">
                              <ButtonTW
                                label={t(
                                  'translation.AppointmentModal.button-cancelText'
                                )}
                                variant="secondary"
                                type="button"
                                onClick={() => setShowCancelModal(false)}
                              />

                              <ButtonTW
                                label={'Otkaži termin'}
                                variant="primary"
                                type="submit"
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  </Form>
                </div>
              </PopupContainer>
            ) : null}
          </ModalWrapper>
        </Modal>
      </Container>
    </div>
  )
}
