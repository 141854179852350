import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormField } from '../../../components'
import { useSdk } from '../../../sdk'
import { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import { WorkHourRow } from '../../AppointmentModal/styles'
import transparentize from 'polished/lib/color/transparentize'
import { ThemeContext } from 'styled-components'
import { ItemRow, WorkHourHint, WorkHourName } from './styles'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { icons } from 'components/Icon'

const IndicatorsContainer = props => {
  return (
    <div style={{ cursor: 'pointer' }}>
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

const MobileOption = props => {
  const { t } = useTranslation()
  const ref = useRef()
  const isSelected = props.selectProps.value?.id === props.data?.id

  useEffect(() => {
    // @ts-ignore
    props.isSelected && ref?.current && ref?.current.scrollIntoView()
  }, [props])

  return (
    <WorkHourRow>
      <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
        {isSelected && <icons.CheckMarkIcon color="grayDarker" />}
      </span>
      <WorkHourName
        isInactive={props.data?.isInactive}
        isBusy={props.data?.isBusy}
      >
        {props.data?.id?.toString()}
      </WorkHourName>

      {props.data?.isInactive ? (
        <WorkHourHint
          isInactive={props.data?.isInactive}
          isBusy={props.data?.isBusy}
        >
          {t('translation.StartTimeSelect.label-off-duty')}
        </WorkHourHint>
      ) : props.data?.isBusy ? (
        <WorkHourHint
          isInactive={props.data?.isInactive}
          isBusy={props.data?.isBusy}
        >
          {t('translation.StartTimeSelect.label-busy')}
        </WorkHourHint>
      ) : null}
    </WorkHourRow>
  )
}
const Option = props => {
  const ref = useRef()
  const { t } = useTranslation()
  const isSelected = props.selectProps.value?.id === props.data?.id
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const selectedTheme = useContext(ThemeContext)
  useEffect(() => {
    if (!isScrolled) {
      // @ts-ignore
      isSelected && ref?.current.scrollIntoView()
      setIsScrolled(true)
    }
  }, [props, isScrolled])

  return (
    <components.Option {...props} innerRef={ref}>
      <WorkHourRow>
        <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <icons.CheckMarkIcon color="grayDarker" />}
        </span>

        <WorkHourName>{props.data?.id?.toString()}</WorkHourName>

        {props.data?.isInactive ? (
          <Tooltip
            label={t('translation.EmployeeSelect.offDuty')}
            style={{ marginLeft: 'auto' }}
          >
            <icons.WarningTriangle
              size={'small'}
              color={selectedTheme.colors.accent5}
            />
          </Tooltip>
        ) : props.data?.isBusy ? (
          <Tooltip
            label={t('translation.StartTimeSelect.label-busy')}
            style={{ marginLeft: 'auto' }}
          >
            <icons.WarningTriangle
              size={'small'}
              color={selectedTheme.colors.accent4}
            />
          </Tooltip>
        ) : null}
      </WorkHourRow>
    </components.Option>
  )
}
const SingleValue = props => {
  const { t } = useTranslation()
  const selectedTheme = useContext(ThemeContext)

  return (
    <components.SingleValue {...props}>
      <ItemRow>
        <WorkHourName isDisabled={props.isDisabled}>
          {props.data?.id?.toString()}
        </WorkHourName>

        {props.data?.isInactive ? (
          <Tooltip
            label={t('translation.EmployeeSelect.offDuty')}
            style={{ marginLeft: 'auto' }}
          >
            <icons.WarningTriangle
              size={'small'}
              color={selectedTheme.colors.accent5}
            />
          </Tooltip>
        ) : props.data?.isBusy ? (
          <Tooltip
            label={t('translation.StartTimeSelect.label-busy')}
            style={{ marginLeft: 'auto' }}
          >
            <icons.WarningTriangle
              size={'small'}
              color={selectedTheme.colors.accent4}
            />
          </Tooltip>
        ) : null}
      </ItemRow>
    </components.SingleValue>
  )
}

const selectComponents = {
  IndicatorsContainer,
  Option,
  MobileOption,
  SingleValue,
}

type TStartTimeSelect = {
  name: string
  workHours: any
  isDisabled: boolean
  hasPlusMinusButtons: boolean
  minusBtnDisabled: boolean
  minusBtnOnClick: any
  plusBtnDisabled: boolean
  plusBtnOnClick: any
  index?: any
  label?: string
  hideLabel?: boolean
  hideChevron?: boolean
  hideSeparator?: boolean
  selectMinWidth?: string
}

export const StartTimeSelect = (props: TStartTimeSelect) => {
  const {
    workHours,
    index,
    isDisabled,
    hasPlusMinusButtons,
    minusBtnDisabled,
    minusBtnOnClick,
    plusBtnDisabled,
    plusBtnOnClick,
    name,
    hideLabel,
    hideChevron,
    hideSeparator,
    label,
    selectMinWidth,
  } = props
  const { t } = useSdk()

  const selectedTheme = useContext(ThemeContext)
  const selectStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: 'pointer',
        fontWeight: isSelected ? 500 : 400,
        backgroundColor: isDisabled
          ? null
          : isFocused
          ? transparentize(0.9, selectedTheme.colors.primary)
          : null,
        ':active': {
          ...styles[':active'],
          backgroundColor: transparentize(0.9, selectedTheme.colors.primary),
        },
      }
    },
    menu: styles => ({
      ...styles,
      minWidth: '130px',
    }),
    singleValue: styles => ({
      ...styles,
      width: '100%',
    }),
  }

  return (
    <FormField.Select
      closeMenuOnSelect
      label={
        hideLabel
          ? undefined
          : label
          ? label
          : t('translation.StartTimeSelect.pocinje')
      }
      hideSelectedOptions={false}
      options={workHours}
      name={name || `services[${index}].startTime`}
      styles={selectStyles}
      isClearable={false}
      isDisabled={isDisabled}
      components={selectComponents}
      parseValue={val => {
        return workHours?.find(e => e?.id === val?.id)
      }}
      hideChevron={hideChevron}
      hideSeparator={hideSeparator}
      placeholder={t('translation.StartTimeSelect.label-select')}
      isSearchable={true}
      hasPlusMinusButtons={hasPlusMinusButtons}
      minusBtnDisabled={minusBtnDisabled}
      minusBtnOnClick={minusBtnOnClick}
      plusBtnDisabled={plusBtnDisabled}
      plusBtnOnClick={plusBtnOnClick}
      selectMinWidth={selectMinWidth || '110px'}
    />
  )
}
