import { toBlob } from 'html-to-image'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import 'react-color-palette/css'
import { useParams } from 'react-router-dom'
import { Button, Loader } from 'src/components'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import { ArrowLeft } from 'src/components/Icon/Icons/ArrowLeft'
import {
  getGiftCardValidLabel,
  useOnClickOutsideWithoutTriggeringAction,
} from 'src/helpers'
import { useSdk } from 'src/sdk'
import { trpc } from 'src/trpc'
import {
  PopupContainer,
  ServiceSelect,
} from 'src/views/CashRegister/NewReceipt/styles'
import { getDefaultTextComponents } from './GiftCardImageEditorModal'

const MobileGiftCardImagePicker = ({
  handleOnCloseEvent,
  giftCardTemplate,
  refetch,
  locationName,
  currency,
}) => {
  const params = useParams<any>()
  const { orgId, locationId } = params
  const { appServices } = useSdk()

  const giftCardImageConfig = JSON.parse(
    giftCardTemplate?.giftCardImageConfig || 'null'
  )

  const [isBusy, setIsBusy] = useState(false)
  const [imageSrc, setImageSrc] = useState<any>(
    giftCardImageConfig?.baseImageId
      ? `${
          import.meta.env.VITE_API_URL
        }/api/giftCardTemplate/${giftCardTemplate?.id}/${
          giftCardImageConfig.baseImageId
        }`
      : ''
  )

  const { t } = useSdk()

  const [showTextComponents, setShowTextComponents] = useState(
    !!giftCardImageConfig?.fullImageId
  )

  const [searchValue, setSearchValue] = useState('')
  const [filterText, setFilterText] = useState<string>('')

  const [existingDesignsTab, setExistingDesignsTab] = useState<
    'existingTemplates' | 'zoyyaTemplates'
  >('existingTemplates')

  const {
    data: zoyyaTemplates,
    isLoading: zoyyaTemplatesLoading,
    refetch: refetchZoyyaTemplates,
  } = trpc.giftCard_getZoyyaTemplates.useQuery({
    orgId,
    locationId: BigInt(locationId),
  })

  const { data: templatesData, isLoading: templatesLoading } =
    trpc.giftCardTemplate_findMany.useQuery({
      orgId: orgId,
      locationId: BigInt(locationId),
      voucherType: ['GIFTCARD', 'VOUCHER'],
      searchTerm: searchValue,
    })

  const templates = templatesData?.filter(x => !!x.giftCardImageConfig)

  const [textComponents, setTextComponents] = useState(
    giftCardImageConfig
      ? giftCardImageConfig?.textComponents?.map(x =>
          x.type === 'code' ? { ...x, text: 'ZOYYA-KOD' } : x
        )
      : getDefaultTextComponents({ giftCardTemplate, locationName, currency })
  )

  const handleSave = async (baseImage: Blob, textComponentsInput: any[]) => {
    if (!imageSrc) return

    try {
      // full image with text
      /* setTextComponents(
        prevTextComponents =>
          prevTextComponents?.map(x => ({
            ...x,
            isVisible: x.type === 'code' ? false : x.isVisible,
          }))
      ) */
      /*  setTextComponents(
        textComponentsInput?.map(x => ({
          ...x,
          isVisible: x.type === 'code' ? false : x.isVisible,
        }))
      ) */

      const blob = await toBlob(document.getElementById('gf-image')!, {
        width: 649,
        height: 409,
        pixelRatio: 1,
        quality: 1,
      })

      const formData = new FormData()

      formData.append(
        'textConfig',
        JSON.stringify({ textComponents: textComponentsInput })
      )
      formData.append('files', blob as Blob)
      formData.append('files', baseImage as Blob)

      const response = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/api/upload/${orgId}/${giftCardTemplate?.id}/giftCardTemplateImageUpload`,
        {
          method: 'POST',
          body: formData,
          credentials: 'include',
        }
      )
      const result = await response.json()

      if (result.error) {
        appServices.toast.danger('Došlo je do pogreške')
      } else {
        appServices.toast.success('Slika uspješno spremljena')
        refetch()
        handleOnCloseEvent()
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsBusy(false)
    }
  }

  const onSelectFromExistingDesigns = async (templateId, imageConfig) => {
    setIsBusy(true)
    setImageSrc(
      `${import.meta.env.VITE_API_URL}/api/giftCardTemplate/${templateId}/${
        imageConfig.baseImageId
      }`
    )
    setShowTextComponents(false)

    const blob = await toBlob(document.getElementById('gf-image')!, {
      width: 649,
      height: 409,
      pixelRatio: 1,
      quality: 1,
    })

    setShowTextComponents(true)
    const finalTextComponents = imageConfig?.textComponents?.map(comp => {
      if (comp.type === 'expirationDate') {
        return {
          ...comp,
          text: `${
            giftCardTemplate?.voucherType === 'MEMBER_CARD'
              ? 'Člansku karticu'
              : 'Poklon bon'
          } potrebno je iskoristiti u roku od ${getGiftCardValidLabel(
            giftCardTemplate.validAmount,
            giftCardTemplate.validIterator as 'day' | 'week' | 'month'
          )} od izdavanja`,
        }
      } else if (comp.type === 'value') {
        return {
          ...comp,
          isVisible: giftCardTemplate.voucherType === 'GIFTCARD',
          text:
            giftCardTemplate?.voucherType === 'GIFTCARD'
              ? `${giftCardTemplate?.value || 0} ${currency}`
              : '',
        }
      } else if (comp.type === 'title') {
        return {
          ...comp,

          text: locationName,
        }
      } else if (comp.type === 'code') {
        return {
          ...comp,

          text: 'ZOYYA-KOD',
        }
      } else return comp
    })

    setTextComponents(finalTextComponents)
    await handleSave(blob!, finalTextComponents)
  }

  const onSelectFromZoyyaDesigns = async template => {
    const templateTextComponents = template.textConfiguration
      ? JSON.parse(template.textConfiguration)
      : getDefaultTextComponents({
          giftCardTemplate,
          locationName,
          currency,
        })
    setIsBusy(true)
    setShowTextComponents(false)

    setImageSrc(template?.imageUrl)

    setTextComponents(
      templateTextComponents?.map(x => ({ ...x, isVisible: false }))
    )

    const blob = await toBlob(document.getElementById('gf-image')!, {
      width: 649,
      height: 409,
      pixelRatio: 1,
      quality: 1,
    })

    setShowTextComponents(true)
    const finalTextComponents = templateTextComponents?.map(comp => {
      if (comp.type === 'expirationDate') {
        return {
          ...comp,
          text: `${
            giftCardTemplate?.voucherType === 'MEMBER_CARD'
              ? 'Člansku karticu'
              : 'Poklon bon'
          } potrebno je iskoristiti u roku od ${getGiftCardValidLabel(
            giftCardTemplate.validAmount,
            giftCardTemplate.validIterator as 'day' | 'week' | 'month'
          )} od izdavanja`,
          color: !template?.textConfiguration ? '#333333' : comp.color,
        }
      } else if (comp.type === 'value') {
        return {
          ...comp,
          isVisible: giftCardTemplate.voucherType === 'GIFTCARD',
          color: !template?.textConfiguration ? '#333333' : comp.color,
          text:
            giftCardTemplate?.voucherType === 'GIFTCARD'
              ? `${giftCardTemplate?.value || 0} ${currency}`
              : '',
        }
      } else if (comp.type === 'title') {
        return {
          ...comp,
          color: !template?.textConfiguration ? '#333333' : comp.color,
          text: locationName,
        }
      } else if (comp.type === 'code') {
        return {
          ...comp,
          color: !template?.textConfiguration ? '#333333' : comp.color,
          text: 'ZOYYA-KOD',
        }
      } else {
        return {
          ...comp,
          color: !template?.textConfiguration ? '#333333' : comp.color,
        }
      }
    })
    setTextComponents(finalTextComponents)

    await handleSave(blob!, finalTextComponents)
  }

  const wrapperRef = useRef<any>(null)

  const debouncedFiltering = useCallback(
    debounce(text => {
      setSearchValue(text)
    }, 250),
    []
  )

  useEffect(() => {
    if (existingDesignsTab === 'zoyyaTemplates') return
    debouncedFiltering(filterText)
  }, [filterText, debouncedFiltering, existingDesignsTab])

  useOnClickOutsideWithoutTriggeringAction(wrapperRef, () =>
    handleOnCloseEvent()
  )

  return (
    <PopupContainer>
      {isBusy ? <Loader isComponent /> : null}
      <div
        className="w-11/12 h-5/6 bg-white rounded-lg flex flex-col overflow-hidden"
        ref={wrapperRef}
      >
        <div className="flex items-center">
          <Button
            type="button"
            buttonType={'text'}
            onClick={handleOnCloseEvent}
            size="small"
            iconComponent={<ArrowLeft />}
            style={{
              paddingLeft: 15,
              paddingRight: 0,
              margin: '0 5px',
            }}
          />
          <ServiceSelect
            placeholder={t('translation.ServicesList.label-search')}
            onChange={e => setFilterText(e.target.value)}
            value={filterText}
            autoFocus={false}
            style={{
              marginLeft: 10,
              marginBottom: 0,
            }}
          ></ServiceSelect>
        </div>
        <div className="flex text-center mt-4">
          <div
            className={`cursor-pointer py-2 flex-1 ${
              existingDesignsTab === 'existingTemplates'
                ? 'border-b-2   border-zoyya-primary text-zoyya-primary'
                : 'border-zoyya-border'
            }`}
            onClick={() => setExistingDesignsTab('existingTemplates')}
          >
            {'Postojeći predlošci'}
          </div>
          <div
            className={`cursor-pointer flex-1  py-2 ${
              existingDesignsTab === 'zoyyaTemplates'
                ? 'border-b-2  border-zoyya-primary text-zoyya-primary'
                : 'border-zoyya-border'
            }`}
            onClick={() => setExistingDesignsTab('zoyyaTemplates')}
          >
            {'Zoyya predlošci'}
          </div>
        </div>

        <div className="overflow-auto px-3 flex-1 relative py-2 flex flex-col gap-2">
          {existingDesignsTab === 'existingTemplates' ? (
            templatesLoading ? (
              <Loader isComponent />
            ) : templates?.length ? (
              <>
                {templates?.map((template, pageIdx) => {
                  const imageConfig = JSON.parse(
                    template?.giftCardImageConfig || 'null'
                  )
                  return (
                    <div
                      key={template?.id.toString()}
                      onClick={async () =>
                        await onSelectFromExistingDesigns(
                          template.id,
                          imageConfig
                        )
                      }
                      className="flex h-[180px] w-full border rounded-md p-2 gap-2 cursor-pointer shadow-sm hover:bg-zoyya-background/30"
                    >
                      <div>
                        <img
                          className="lg:w-[260px] lg:min-w-[260px] lg:max-w-[260px] w-[150px] max-w-[150px] min-w-[150px] h-[164px]  object-contain rounded-sm"
                          src={`${
                            import.meta.env.VITE_API_URL
                          }/api/giftCardTemplate/${template?.id}/${
                            imageConfig.fullImageId
                          }`}
                        />
                      </div>
                      <div className="flex flex-col gap-1">
                        <div className=" font-medium">{template.name}</div>
                        <div className="text-sm text-zoyya-text/60">
                          {template.voucherType === 'GIFTCARD'
                            ? 'Predložak poklon bona'
                            : 'Predložak paketa'}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <EmptyList title={'Nema spremljenih predložaka'} />
            )
          ) : zoyyaTemplatesLoading ? (
            <Loader isComponent />
          ) : zoyyaTemplates?.length ? (
            <>
              {zoyyaTemplates?.map((template, pageIdx) => {
                return (
                  <div
                    key={template?.id.toString()}
                    onClick={async () => {
                      await onSelectFromZoyyaDesigns(template)
                    }}
                    className="flex h-[180px] w-full border rounded-md p-2 gap-2 cursor-pointer shadow-sm hover:bg-zoyya-background/30"
                  >
                    <div>
                      <img
                        className="lg:w-[260px] lg:min-w-[260px] lg:max-w-[260px] w-[150px] max-w-[150px] min-w-[150px] h-[164px]  object-contain rounded-sm"
                        src={template.imageUrl}
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className=" font-medium">{template.title}</div>
                      {template.description ? (
                        <div className="text-sm text-zoyya-text/60 line-clamp-6">
                          {template.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <EmptyList title={'Nema dostupnih Zoyya predložaka'} />
          )}
        </div>
        <div className="absolute top-0 -left-[9999px]">
          <div
            className={`relative ${!imageSrc ? 'border' : ''} rounded-[35px]`}
            id="gf-image"
            style={{
              width: '649px',
              height: '409px',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <div
              draggable={false}
              style={{
                backgroundImage: `url(${imageSrc || '/assets/noImage.svg'})`,
                backgroundSize: imageSrc ? 'cover' : 'contain',
                //backgroundOrigin: 'border-box',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '100%',
                //cursor: 'move',
                position: 'absolute',
              }}
            />
            {showTextComponents
              ? textComponents.map(textData => (
                  <TextComponent textData={textData} key={textData.type} />
                ))
              : null}
          </div>
        </div>
      </div>
    </PopupContainer>
  )
}
const TextComponent = ({ textData }) => {
  if (!textData.isVisible) return null
  return (
    <div
      id={`text-editor-${textData.id}`}
      className="absolute z-10"
      style={{
        top: textData.position.y,
        left: textData.position.x,
      }}
    >
      <div
        style={{
          fontSize: textData.fontSize,
          color: textData.color,
          fontFamily: textData.fontFamily,
          fontWeight: 300,
          letterSpacing: 0.15,
        }}
        id={`text-${textData.id}`}
      >
        {textData.text}
      </div>
    </div>
  )
}

export default MobileGiftCardImagePicker
