import React, { useCallback, useContext } from 'react'
import { FormField } from '../../../components'
import { components } from 'react-select'
import { CheckMarkIcon } from '../../../components/Icon/Icons/Checkmark'
import { ThemeContext } from 'styled-components'
import transparentize from 'polished/lib/color/transparentize'
import { getServiceHoursAndMinutes } from '../../../helpers'
import { useTranslation } from 'react-i18next'
import { SelectOptionLabel, SelectRow } from './styles'

const Option = props => {
  const isSelected = props.selectProps.value?.id === props.data?.id
  return (
    <components.Option {...props}>
      <SelectRow>
        <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <CheckMarkIcon color="grayDarker" />}
        </span>
        <SelectOptionLabel>{props.data.title}</SelectOptionLabel>
      </SelectRow>
    </components.Option>
  )
}
const MobileOption = props => {
  const isSelected = props.selectProps.value?.id === props.data?.id
  return (
    <SelectRow>
      <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
        {isSelected && <CheckMarkIcon color="grayDarker" />}
      </span>
      <SelectOptionLabel>{props.data.title}</SelectOptionLabel>
    </SelectRow>
  )
}
const IndicatorsContainer = props => {
  return (
    <div style={{ cursor: 'pointer' }}>
      <components.IndicatorsContainer {...props} />
    </div>
  )
}
const selectComponents = {
  IndicatorsContainer,
  Option,
  MobileOption,
}

type TDurationSelect = {
  duration: any
  isDisabled: boolean
  name: string
  label: string
}

export const GroupBookingDurationSelect = (props: TDurationSelect) => {
  const { duration, isDisabled, label, name } = props
  const selectedTheme = useContext(ThemeContext)

  const selectStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: 'pointer',
        fontWeight: isSelected ? 500 : 400,
        backgroundColor: isDisabled
          ? null
          : isFocused
          ? transparentize(0.9, selectedTheme.colors.primary)
          : null,
        ':active': {
          ...styles[':active'],
          backgroundColor: transparentize(0.9, selectedTheme.colors.primary),
        },
      }
    },
    menu: styles => ({
      ...styles,
      minWidth: '130px',
    }),
  }

  const getServiceHOurs = useCallback(val => {
    return typeof val === 'string' && !val.includes(':')
      ? getServiceHoursAndMinutes(val)
      : val
  }, [])

  const { t } = useTranslation()
  return (
    <FormField.Select
      closeMenuOnSelect
      hideSelectedOptions={false}
      options={duration}
      styles={selectStyles}
      label={label}
      isDisabled={isDisabled}
      isClearable={false}
      components={selectComponents}
      placeholder={t('translation.DurationSelect.select')}
      formatValue={val => val?.id}
      parseValue={val => {
        const parsed = duration.find(e => e?.id === val)
        return parsed
      }}
      name={name}
      isSearchable={true}
      selectMinWidth={'110px'}
    />
  )
}
