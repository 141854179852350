import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  formatPriceNumberWithDelimiter,
  getChangelogEventDescription,
  getGiftCardStatusTWColor,
  getISODate,
  getUserAvatar,
  printRatingStars,
  resolveFullName,
} from 'src/helpers'
import { t } from 'src/localization'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { User } from 'src/state/graphql'
import { RouterOutput } from 'src/trpc'
import Calendar from '../Icon/Icons/Calendar'
import { useSdk } from 'src/sdk'
import { format } from 'date-fns'

const getRoleLabel = (
  role: string,
  t: (key: string) => string,
  orgRoles:
    | {
        id: bigint
        orgId: string | null
        role: string
        roleTitle: any
      }[]
    | undefined
) => {
  const roleExists = orgRoles?.find(x => x.role === role)
  if (roleExists) return roleExists.roleTitle

  return t('translation.helpers.client')
}

type ChangelogItemProps = {
  log: {
    event: string
    user: any
    meta: any
  } & {
    [key: string]: any | undefined
  }
  orgRoles: RouterOutput['getOrgRoles']
  hideGoToCalendarButton?: boolean
}

export const ChangelogItem = React.forwardRef(
  (props: ChangelogItemProps, ref: any) => {
    const { log, orgRoles, hideGoToCalendarButton = false } = props

    const { navigateTo, isMobile } = useSdk()
    const { t } = useTranslation()

    return (
      <div className="p-2 pl-0 pr-0 w-full" ref={ref}>
        <div className=" w-full">
          <div className="border bg-white rounded-b rounded-md p-4 flex flex-col justify-between leading-normal mx-2 break-words">
            <div className="mb-4">
              <div className="flex justify-between">
                <div>
                  <p className="text-sm text-gray-600 flex items-center">
                    {t('translation.AppointmentChangelog.event')}
                  </p>
                  <div className="text-gray-900 font-semibold text-lg mb-2">
                    {getChangelogEventDescription(log, t)}
                  </div>
                </div>

                <div>
                  <p className="text-sm text-gray-600 flex items-center justify-end">
                    {t('translation.RezervationsDesktop.title-time')}
                  </p>
                  <div className="text-gray-900 font-semibold text-lg mb-2">
                    {`${moment(log?.eventTime).format(
                      t('regionalFormats.date.momentFormatDayLongDate')
                    )} ${moment(log?.eventTime).format('HH:mm')}`}
                  </div>
                </div>
              </div>
            </div>

            {getLogAdditionalData(log)}

            {log?.user && log?.event !== 'EMAIL_SENT' ? (
              <div className="flex justify-between">
                <div className="flex items-center">
                  {getUserAvatar(
                    log?.user as any,
                    true,
                    isMobile ? 'small' : 'medium'
                  )}
                  <div className="text-md ml-[10px]">
                    <p className="text-gray-900 leading-none">
                      {resolveFullName(log?.user, false)}
                    </p>
                    <p className="text-gray-600 text-sm">
                      {getRoleLabel(log?.user?.role as any, t, orgRoles)}
                    </p>
                  </div>
                </div>

                {log?.resourceBookingId && !hideGoToCalendarButton ? (
                  <div>
                    <ButtonTW
                      label={isMobile ? '' : 'Idi na termin'}
                      variant="primaryOutline"
                      size="small"
                      icon={<Calendar size={'size16'} />}
                      onClick={() => {
                        const startTime = moment(
                          log?.ResourceBooking?.startTime
                        ).format('HH:mm')

                        if (log.ResourceBooking?.kind === 'GROUP_SLOT') {
                          navigateTo.openGroupAppointment({
                            date: getISODate(log.ResourceBooking?.date),
                            appointmentId: log?.resourceBookingId,
                            view: 'day',
                            selectedHour: startTime.split(':')[0],
                            selectedMinute: startTime.split(':')[1],
                          })
                        } else {
                          navigateTo.editAppointmentInDay({
                            date: getISODate(log.ResourceBooking?.date),
                            appointmentId: log.resourceBookingId,
                            view: 'day',
                            selectedHour: startTime.split(':')[0],
                            selectedMinute: startTime.split(':')[1],
                          })
                        }
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            {!(log?.user && log?.event !== 'EMAIL_SENT') &&
            log?.resourceBookingId &&
            !hideGoToCalendarButton ? (
              <div className=" ml-auto flex justify-between mt-2">
                {' '}
                <ButtonTW
                  label={isMobile ? '' : 'Idi na termin'}
                  variant="primaryOutline"
                  size="small"
                  icon={<Calendar size={'size16'} />}
                  onClick={() => {
                    const startTime = moment(
                      log?.ResourceBooking?.startTime
                    ).format('HH:mm')

                    if (log.ResourceBooking?.kind === 'GROUP_SLOT') {
                      navigateTo.openGroupAppointment({
                        date: getISODate(log.ResourceBooking?.date),
                        appointmentId: log?.appointmentId,
                        view: 'day',
                        selectedHour: startTime.split(':')[0],
                        selectedMinute: startTime.split(':')[1],
                      })
                    } else {
                      navigateTo.editAppointmentInDay({
                        date: getISODate(log.ResourceBooking?.date),
                        appointmentId: log.resourceBookingId,
                        view: 'day',
                        selectedHour: startTime.split(':')[0],
                        selectedMinute: startTime.split(':')[1],
                      })
                    }
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
)

function getLogAdditionalData(log: {
  event: string
  user: any
  meta: any
  [key: string]: any | undefined
}) {
  switch (log?.event) {
    case 'GIFTCARD_USED':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Oznaka: '}</span>
              <span className="text-md ">{log?.meta?.giftCard?.code}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Klijent: '}</span>
              <span className="text-md ">
                {resolveFullName(log?.meta?.giftCard?.client)}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Status: '}</span>
              <span
                className={`text-md font-medium ${getGiftCardStatusTWColor(
                  log?.meta?.giftCard?.status
                )}`}
              >
                {t(
                  `translation.GiftCardList.status-single-${log?.meta?.giftCard?.status?.toLowerCase()}`
                ).toUpperCase()}
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Vrijedi do: '}</span>
              <span className="text-md ">
                {log?.meta?.giftCard?.validTo
                  ? moment(log?.meta?.giftCard?.validTo).format('DD.MM.YYYY')
                  : ''}
              </span>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Opis: '}</span>
              <div
                className="text-md "
                dangerouslySetInnerHTML={{
                  __html: log?.meta?.giftCard?.description,
                }}
              ></div>
            </div>
            {log?.meta?.giftCard?.giftCardType === 'MEMBER_CARD' ? (
              <>
                <div className="mt-1">
                  <span className="font-semibold text-md">{'Usluge: '}</span>
                  <ul className=" mt-1 list-inside list-disc text-sm">
                    {log?.meta?.giftCard?.items?.map(item => {
                      return (
                        <li>{`${item?.name} - ${formatPriceNumberWithDelimiter(
                          Number(item?.discountRate) * 100,
                          false
                        )}% popusta`}</li>
                      )
                    })}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="mt-1">
                  <span className="font-semibold text-md">
                    {'Ukupna vrijednost: '}
                  </span>
                  <span className="text-md ">
                    {`${formatPriceNumberWithDelimiter(
                      log?.meta?.giftCard?.value
                    )} ${log?.meta?.currency}${
                      log?.meta?.giftCard?.giftCardType === 'VOUCHER' &&
                      log?.meta?.giftCard?.value !== log?.meta?.giftCardPrice
                        ? ` (neto: ${log?.meta?.giftCard?.price} ${log?.meta?.currency})`
                        : ''
                    }`}
                  </span>
                </div>
                {log?.meta?.used ? (
                  <div className="mt-1">
                    <span className="font-semibold text-md">
                      {'Iskorišteno: '}
                    </span>
                    <span className="text-md ">
                      {`${formatPriceNumberWithDelimiter(
                        log?.meta?.used || 0
                      )} ${log?.meta?.currency}`}
                    </span>
                  </div>
                ) : null}
                <div className="mt-1">
                  <span className="font-semibold text-md">
                    {'Preostala vrijednost: '}
                  </span>
                  <span className="text-md ">
                    {`${formatPriceNumberWithDelimiter(
                      log?.meta?.giftCard?.balance || 0
                    )} ${log?.meta?.currency}`}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )

    case 'GIFTCARD_REMOVED':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Oznaka: '}</span>
              <span className="text-md ">{log?.meta?.giftCard?.code}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Klijent: '}</span>
              <span className="text-md ">
                {resolveFullName(log?.meta?.giftCard?.client)}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Status: '}</span>
              <span
                className={`text-md font-medium ${getGiftCardStatusTWColor(
                  log?.meta?.giftCard?.status
                )}`}
              >
                {t(
                  `translation.GiftCardList.status-single-${log?.meta?.giftCard?.status?.toLowerCase()}`
                ).toUpperCase()}
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Vrijedi do: '}</span>
              <span className="text-md ">
                {log?.meta?.giftCard?.validTo
                  ? moment(log?.meta?.giftCard?.validTo).format('DD.MM.YYYY')
                  : ''}
              </span>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Opis: '}</span>
              <div
                className="text-md "
                dangerouslySetInnerHTML={{
                  __html: log?.meta?.giftCard?.description,
                }}
              ></div>
            </div>
            {log?.meta?.giftCard?.giftCardType === 'MEMBER_CARD' ? (
              <>
                <div className="mt-1">
                  <span className="font-semibold text-md">{'Usluge: '}</span>
                  <ul className=" mt-1 list-inside list-disc text-sm">
                    {log?.meta?.giftCard?.items?.map(item => {
                      return (
                        <li>{`${item?.name} - ${formatPriceNumberWithDelimiter(
                          Number(item?.discountRate) * 100,
                          false
                        )}% popusta`}</li>
                      )
                    })}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="mt-1">
                  <span className="font-semibold text-md">
                    {'Ukupna vrijednost: '}
                  </span>
                  <span className="text-md ">
                    {`${formatPriceNumberWithDelimiter(
                      log?.meta?.giftCard?.value
                    )} ${log?.meta?.currency}${
                      log?.meta?.giftCard?.giftCardType === 'VOUCHER' &&
                      log?.meta?.giftCard?.value !== log?.meta?.giftCardPrice
                        ? ` (neto: ${log?.meta?.giftCard?.price} ${log?.meta?.currency})`
                        : ''
                    }`}
                  </span>
                </div>
                {log?.meta?.used ? (
                  <div className="mt-1">
                    <span className="font-semibold text-md">
                      {'Iskorišteno: '}
                    </span>
                    <span className="text-md ">
                      {`${formatPriceNumberWithDelimiter(
                        log?.meta?.used || 0
                      )} ${log?.meta?.currency}`}
                    </span>
                  </div>
                ) : null}
                <div className="mt-1">
                  <span className="font-semibold text-md">
                    {'Preostala vrijednost: '}
                  </span>
                  <span className="text-md ">
                    {`${formatPriceNumberWithDelimiter(
                      log?.meta?.giftCard?.balance || 0
                    )} ${log?.meta?.currency}`}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )

    case 'EMAIL_SENT':
      return (
        <>
          <div className="italic -mt-4">{log?.note}</div>
          <div className="mt-2">
            <span className="font-semibold text-md">
              {t('translation.AppointmentChangelog.to') + ' '}
            </span>
            <span className="text-md">{log?.meta?.to}</span>
          </div>
        </>
      )

    case 'SMS_SENT':
      return (
        <>
          <div className="italic -mt-4">{log?.note}</div>
          <div className="mt-2">
            <span className="font-semibold text-md">
              {t('translation.AppointmentChangelog.to') + ' '}
            </span>
            <span className="text-md">{log?.meta?.to}</span>
          </div>

          <div className="mt-2">
            <span className="font-semibold text-md">
              {t('translation.AppointmentChangelog.smsBody') + ' '}
            </span>
            <span className="text-md italic">{log?.meta?.body}</span>
          </div>
        </>
      )
    case 'FISCALIZATION_REQUEST':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Broj računa: '}</span>
              <span className="text-md ">{log?.meta?.racun?.RacunBroj}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Iznos: '}</span>
              <span className="text-md ">
                {formatPriceNumberWithDelimiter(
                  log?.meta?.racun?.RacunUkupnoIznos
                ) + ' EUR'}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Način plaćanja: '}
              </span>
              <span className="text-md ">
                {
                  { K: 'Kartica', G: 'Gotovina', O: 'Ostalo' }[
                    log?.meta?.racun?.NacinPlacanjaOznaka
                  ]
                }
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Porezi: '}</span>
              <span className="text-md ">
                {log?.meta?.racun?.Porezi?.map(porez => {
                  return `${
                    porez.PorezPosto
                  }% (${formatPriceNumberWithDelimiter(porez.PorezIznos)} EUR)`
                }).join(', ')}
              </span>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">
                {'OIB naplatnog uređaja: '}
              </span>
              <span className="text-md ">{log?.meta?.racun?.FirmaOIB}</span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'OIB operatera: '}</span>
              <span className="text-md ">{log?.meta?.racun?.OperatorOIB}</span>
            </div>
            {/* <div className="mt-1">
                <span className="font-semibold text-md">{'JIR: '}</span>
                <span className="text-md ">{log?.meta?.jir}</span>
              </div>
  
              <div className="mt-1">
                <span className="font-semibold text-md">{'ZKI: '}</span>
                <span className="text-md ">{log?.meta?.zki}</span>
              </div>
  
              <div className="mt-1">
                <span className="font-semibold text-md">{'QR: '}</span>
                <span className="text-md ">{log?.meta?.qr}</span>
              </div> */}
          </div>
        </div>
      )
    case 'FISCALIZATION_SUCCESS':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Broj računa: '}</span>
              <span className="text-md ">{log?.meta?.racun?.RacunBroj}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Iznos: '}</span>
              <span className="text-md ">
                {formatPriceNumberWithDelimiter(
                  log?.meta?.racun?.RacunUkupnoIznos
                ) + ' EUR'}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Način plaćanja: '}
              </span>
              <span className="text-md ">
                {
                  { K: 'Kartica', G: 'Gotovina', O: 'Ostalo' }[
                    log?.meta?.racun?.NacinPlacanjaOznaka
                  ]
                }
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Porezi: '}</span>
              <span className="text-md ">
                {log?.meta?.racun?.Porezi?.map(porez => {
                  return `${
                    porez.PorezPosto
                  }% (${formatPriceNumberWithDelimiter(porez.PorezIznos)} EUR)`
                }).join(', ')}
              </span>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">
                {'OIB naplatnog uređaja: '}
              </span>
              <span className="text-md ">{log?.meta?.racun?.FirmaOIB}</span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'OIB operatera: '}</span>
              <span className="text-md ">{log?.meta?.racun?.OperatorOIB}</span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'JIR: '}</span>
              <span className="text-md ">{log?.meta?.jir}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'ZKI: '}</span>
              <span className="text-md ">{log?.meta?.zki}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'QR: '}</span>
              <span className="text-md ">{log?.meta?.qr}</span>
            </div>
          </div>
        </div>
      )
    case 'PAYMENT_NOTIFICATION_PAID':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Klijent: '}</span>
              <span className="text-md ">
                {log?.meta?.paidPaymentRequest?.clientName}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Email klijenta: '}
              </span>
              <span className="text-md ">
                {log?.meta?.paidPaymentRequest?.clientEmail}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Iznos: '}</span>
              <span className="text-md ">
                {formatPriceNumberWithDelimiter(
                  log?.meta?.paidPaymentRequest?.amount
                ) + ' EUR'}
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Link za plaćanje: '}
              </span>
              <span className="text-md ">
                {log?.meta?.paidPaymentRequest?.paymentRequestUrl}
              </span>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Opis zahtjeva za plaćanje: '}
              </span>
              <span
                className="text-md"
                dangerouslySetInnerHTML={{
                  __html:
                    log?.meta?.paidPaymentRequest?.paymentRequestDescription ||
                    '<div> </div>',
                }}
              ></span>
            </div>
          </div>
        </div>
      )
    case 'PAYMENT_NOTIFICATION_CREATED':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Klijent: '}</span>
              <span className="text-md ">{log?.meta?.clientName}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Email klijenta: '}
              </span>
              <span className="text-md ">{log?.meta?.clientEmail}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Iznos: '}</span>
              <span className="text-md ">
                {formatPriceNumberWithDelimiter(log?.meta?.amount) + ' EUR'}
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Link za plaćanje: '}
              </span>
              <a className="text-md ">{log?.meta?.paymentRequestUrl}</a>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Opis zahtjeva za plaćanje: '}
              </span>
              <span
                className="text-md"
                dangerouslySetInnerHTML={{
                  __html:
                    log?.meta?.paymentRequestDescription || '<div> </div>',
                }}
              ></span>
            </div>
          </div>
        </div>
      )
    case 'TIP_FISCALIZATION_REQUEST':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Broj računa: '}</span>
              <span className="text-md ">{log?.meta?.racun?.RacunBroj}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Iznos računa: '}</span>
              <span className="text-md ">
                {formatPriceNumberWithDelimiter(
                  log?.meta?.racun?.RacunUkupnoIznos
                ) + ' EUR'}
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Iznos napojnice: '}
              </span>
              <span className="text-md ">
                {formatPriceNumberWithDelimiter(
                  log?.meta?.racun?.Napojnica?.IznosNapojnice
                ) + ' EUR'}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Način plaćanja računa: '}
              </span>
              <span className="text-md ">
                {
                  { K: 'Kartica', G: 'Gotovina', O: 'Ostalo' }[
                    log?.meta?.racun?.NacinPlacanjaOznaka
                  ]
                }
              </span>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Način plaćanja napojnice: '}
              </span>
              <span className="text-md ">
                {
                  { K: 'Kartica', G: 'Gotovina', O: 'Ostalo' }[
                    log?.meta?.racun?.Napojnica?.NacinPlacanjaNapojniceOznaka
                  ]
                }
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Firma OIB: '}</span>
              <span className="text-md ">{log?.meta?.racun?.FirmaOIB}</span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Operator OIB: '}</span>
              <span className="text-md ">{log?.meta?.racun?.OperatorOIB}</span>
            </div>
          </div>
        </div>
      )
    case 'TIP_FISCALIZATION_SUCCESS':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Broj računa: '}</span>
              <span className="text-md ">{log?.meta?.racun?.RacunBroj}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Iznos računa: '}</span>
              <span className="text-md ">
                {formatPriceNumberWithDelimiter(
                  log?.meta?.racun?.RacunUkupnoIznos
                ) + ' EUR'}
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Iznos napojnice: '}
              </span>
              <span className="text-md ">
                {formatPriceNumberWithDelimiter(
                  log?.meta?.racun?.Napojnica?.IznosNapojnice
                ) + ' EUR'}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Način plaćanja računa: '}
              </span>
              <span className="text-md ">
                {
                  { K: 'Kartica', G: 'Gotovina', O: 'Ostalo' }[
                    log?.meta?.racun?.NacinPlacanjaOznaka
                  ]
                }
              </span>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">
                {'Način plaćanja napojnice: '}
              </span>
              <span className="text-md ">
                {
                  { K: 'Kartica', G: 'Gotovina', O: 'Ostalo' }[
                    log?.meta?.racun?.Napojnica?.NacinPlacanjaNapojniceOznaka
                  ]
                }
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Firma OIB: '}</span>
              <span className="text-md ">{log?.meta?.racun?.FirmaOIB}</span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Operator OIB: '}</span>
              <span className="text-md ">{log?.meta?.racun?.OperatorOIB}</span>
            </div>
          </div>
        </div>
      )
    case 'GIFTCARD_CREATED':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Naziv: '}</span>
              <span className="text-md">{log?.meta?.giftCard?.name}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Oznaka: '}</span>
              <span className="text-md ">{log?.meta?.giftCard?.code}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Klijent: '}</span>
              <span className="text-md ">
                {resolveFullName(log?.meta?.giftCard?.client)}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Status: '}</span>
              <span
                className={`text-md font-medium ${getGiftCardStatusTWColor(
                  log?.meta?.giftCard?.status
                )}`}
              >
                {t(
                  `translation.GiftCardList.status-single-${log?.meta?.giftCard?.status?.toLowerCase()}`
                ).toUpperCase()}
              </span>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Vrijedi do: '}</span>
              <span className="text-md ">
                {log?.meta?.giftCard?.validTo
                  ? moment(log?.meta?.giftCard?.validTo).format('DD.MM.YYYY')
                  : ''}
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Opis: '}</span>
              <div
                className="text-md "
                dangerouslySetInnerHTML={{
                  __html: log?.meta?.giftCard?.description,
                }}
              ></div>
            </div>
            {log?.meta?.giftCard?.giftCardType === 'MEMBER_CARD' ? (
              <>
                <div className="mt-1">
                  <span className="font-semibold text-md">{'Usluge: '}</span>
                  <ul className=" mt-1 list-inside list-disc text-sm">
                    {log?.meta?.giftCard?.items?.map(item => {
                      return (
                        <li>{`${item?.name} - ${formatPriceNumberWithDelimiter(
                          Number(item?.discountRate) * 100,
                          false
                        )}% popusta`}</li>
                      )
                    })}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="mt-1">
                  <span className="font-semibold text-md">
                    {'Ukupna vrijednost: '}
                  </span>
                  <span className="text-md ">
                    {`${formatPriceNumberWithDelimiter(
                      log?.meta?.giftCard?.value
                    )} ${log?.meta?.currency}${
                      log?.meta?.giftCard?.giftCardType === 'VOUCHER' &&
                      log?.meta?.giftCard?.value !== log?.meta?.giftCardPrice
                        ? ` (neto: ${log?.meta?.giftCard?.price} ${log?.meta?.currency})`
                        : ''
                    }`}
                  </span>
                </div>
                {log?.meta?.used ? (
                  <div className="mt-1">
                    <span className="font-semibold text-md">
                      {'Iskorišteno: '}
                    </span>
                    <span className="text-md ">
                      {`${formatPriceNumberWithDelimiter(
                        log?.meta?.used || 0
                      )} ${log?.meta?.currency}`}
                    </span>
                  </div>
                ) : null}
                <div className="mt-1">
                  <span className="font-semibold text-md">
                    {'Preostala vrijednost: '}
                  </span>
                  <span className="text-md ">
                    {`${formatPriceNumberWithDelimiter(
                      log?.meta?.giftCard?.balance || 0
                    )} ${log?.meta?.currency}`}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )
    case 'GIFTCARD_UPDATED':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Naziv: '}</span>
              <span className="text-md">{log?.meta?.giftCard?.name}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Oznaka: '}</span>
              <span className="text-md ">{log?.meta?.giftCard?.code}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Klijent: '}</span>
              <span className="text-md ">
                {resolveFullName(log?.meta?.giftCard?.client)}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Status: '}</span>
              <span
                className={`text-md font-medium ${getGiftCardStatusTWColor(
                  log?.meta?.giftCard?.status
                )}`}
              >
                {t(
                  `translation.GiftCardList.status-single-${log?.meta?.giftCard?.status?.toLowerCase()}`
                ).toUpperCase()}
              </span>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Vrijedi do: '}</span>
              <span className="text-md ">
                {log?.meta?.giftCard?.validTo
                  ? moment(log?.meta?.giftCard?.validTo).format('DD.MM.YYYY')
                  : ''}
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Opis: '}</span>
              <div
                className="text-md "
                dangerouslySetInnerHTML={{
                  __html: log?.meta?.giftCard?.description,
                }}
              ></div>
            </div>
            {log?.meta?.giftCard?.giftCardType === 'MEMBER_CARD' ? (
              <>
                <div className="mt-1">
                  <span className="font-semibold text-md">{'Usluge: '}</span>
                  <ul className=" mt-1 list-inside list-disc text-sm">
                    {log?.meta?.giftCard?.items?.map(item => {
                      return (
                        <li>{`${item?.name} - ${formatPriceNumberWithDelimiter(
                          Number(item?.discountRate) * 100,
                          false
                        )}% popusta`}</li>
                      )
                    })}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="mt-1">
                  <span className="font-semibold text-md">
                    {'Ukupna vrijednost: '}
                  </span>
                  <span className="text-md ">
                    {`${formatPriceNumberWithDelimiter(
                      log?.meta?.giftCard?.value
                    )} ${log?.meta?.currency}${
                      log?.meta?.giftCard?.giftCardType === 'VOUCHER' &&
                      log?.meta?.giftCard?.value !== log?.meta?.giftCardPrice
                        ? ` (neto: ${log?.meta?.giftCard?.price} ${log?.meta?.currency})`
                        : ''
                    }`}
                  </span>
                </div>
                {log?.meta?.used ? (
                  <div className="mt-1">
                    <span className="font-semibold text-md">
                      {'Iskorišteno: '}
                    </span>
                    <span className="text-md ">
                      {`${formatPriceNumberWithDelimiter(
                        log?.meta?.used || 0
                      )} ${log?.meta?.currency}`}
                    </span>
                  </div>
                ) : null}
                <div className="mt-1">
                  <span className="font-semibold text-md">
                    {'Preostala vrijednost: '}
                  </span>
                  <span className="text-md ">
                    {`${formatPriceNumberWithDelimiter(
                      log?.meta?.giftCard?.balance || 0
                    )} ${log?.meta?.currency}`}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )
    case 'GIFTCARD_EMAIL_SENT_TO_CLIENT':
      return (
        <div className="flex  mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="italic -mt-3 mb-3">
              {log?.meta?.giftCard?.client?.email}
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Naziv: '}</span>
              <span className="text-md">{log?.meta?.giftCard?.name}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Oznaka: '}</span>
              <span className="text-md ">{log?.meta?.giftCard?.code}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Klijent: '}</span>
              <span className="text-md ">
                {resolveFullName(log?.meta?.giftCard?.client)}
              </span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Status: '}</span>
              <span
                className={`text-md font-medium ${getGiftCardStatusTWColor(
                  log?.meta?.giftCard?.status
                )}`}
              >
                {t(
                  `translation.GiftCardList.status-single-${log?.meta?.giftCard?.status?.toLowerCase()}`
                ).toUpperCase()}
              </span>
            </div>
          </div>

          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="mt-1">
              <span className="font-semibold text-md">{'Vrijedi do: '}</span>
              <span className="text-md ">
                {log?.meta?.giftCard?.validTo
                  ? moment(log?.meta?.giftCard?.validTo).format('DD.MM.YYYY')
                  : ''}
              </span>
            </div>
            <div className="mt-1">
              <span className="font-semibold text-md">{'Opis: '}</span>
              <div
                className="text-md "
                dangerouslySetInnerHTML={{
                  __html: log?.meta?.giftCard?.description,
                }}
              ></div>
            </div>
            {log?.meta?.giftCard?.giftCardType === 'MEMBER_CARD' ? (
              <>
                <div className="mt-1">
                  <span className="font-semibold text-md">{'Usluge: '}</span>
                  <ul className=" mt-1 list-inside list-disc text-sm">
                    {log?.meta?.giftCard?.items?.map(item => {
                      return (
                        <li>{`${item?.name} - ${formatPriceNumberWithDelimiter(
                          Number(item?.discountRate) * 100,
                          false
                        )}% popusta`}</li>
                      )
                    })}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="mt-1">
                  <span className="font-semibold text-md">
                    {'Ukupna vrijednost: '}
                  </span>
                  <span className="text-md ">
                    {`${formatPriceNumberWithDelimiter(
                      log?.meta?.giftCard?.value
                    )} ${log?.meta?.currency}${
                      log?.meta?.giftCard?.giftCardType === 'VOUCHER' &&
                      log?.meta?.giftCard?.value !== log?.meta?.giftCardPrice
                        ? ` (neto: ${log?.meta?.giftCard?.price} ${log?.meta?.currency})`
                        : ''
                    }`}
                  </span>
                </div>
                {log?.meta?.used ? (
                  <div className="mt-1">
                    <span className="font-semibold text-md">
                      {'Iskorišteno: '}
                    </span>
                    <span className="text-md ">
                      {`${formatPriceNumberWithDelimiter(
                        log?.meta?.used || 0
                      )} ${log?.meta?.currency}`}
                    </span>
                  </div>
                ) : null}
                <div className="mt-1">
                  <span className="font-semibold text-md">
                    {'Preostala vrijednost: '}
                  </span>
                  <span className="text-md ">
                    {`${formatPriceNumberWithDelimiter(
                      log?.meta?.giftCard?.balance || 0
                    )} ${log?.meta?.currency}`}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )
    case 'APPOINTMENT_REVIEWED':
      return (
        <div className="flex flex-col mb-4 ">
          <div className="-mt-3">
            {printRatingStars(log?.meta?.reviewRating)}
          </div>

          {log?.meta?.reviewComment ? (
            <div className="mt-2">
              <span className="font-semibold text-md">{'Komentar:' + ' '}</span>
              <span className="text-md">{log?.meta?.reviewComment}</span>
            </div>
          ) : null}
        </div>
      )
    case 'CLIENT_MERGED':
      return (
        <div className="flex mb-4 mt-2">
          <div className="w-[50%] min-w-[50%] max-w-[50%] mr-2">
            <div className="italic -mt-3 mb-3">{log?.note}</div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Ime i prezime: '}</span>
              <span className="text-md">{resolveFullName(log?.meta)}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Email: '}</span>
              <span className="text-md ">{log?.meta?.email || '-'}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Telefon: '}</span>
              <span className="text-md ">{log?.meta?.mobilePhone || '-'}</span>
            </div>

            <div className="mt-1">
              <span className="font-semibold text-md">{'Datum rođenja: '}</span>
              <span className="text-md ">
                {log?.meta?.birthDate
                  ? format(log?.meta?.birthDate, 'dd.MM.yyyy.')
                  : '-'}
              </span>
            </div>
          </div>
        </div>
      )
  }
}
