import React, { useContext } from 'react'
import { FormField } from 'components'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import { CheckMarkIcon } from 'components/Icon/Icons/Checkmark'
import { getUserAvatar, resolveFullName } from 'src/helpers'
import { ThemeContext } from 'styled-components'
import transparentize from 'polished/lib/color/transparentize'
import Tooltip from 'components/Tooltip/Tooltip'
import { WarningTriangle } from 'components/Icon/Icons/WarningTriangle'
import {
  ItemRow,
  SelectRow,
  WorkHourHint,
  WorkHourName,
} from 'views/Appointment/components/styles'
import { MobileContext } from 'src/Context'
import { t } from 'src/localization'

type Props = {
  employees: any[]
  filterOptions?: any
  name: string
  isDisabled: boolean
  label: string
}
export const GroupBookingResourceSelect = (props: Props) => {
  const { employees, filterOptions, name, isDisabled, label } = props
  const isMobile = useContext(MobileContext)
  const formatOptionLabel = e => {
    return resolveFullName(e, true)
  }

  const Option = props => {
    const { t } = useTranslation()
    const isSelected = props.selectProps.value?.id === props.data?.id

    return (
      <components.Option
        {...props}
        isDisabled={props?.data?.disabled}
        selectOption={option => {
          if (option?.disabled) {
            return null
          }
          return option
        }}
      >
        <SelectRow>
          <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
            {isSelected && <CheckMarkIcon color="grayDarker" />}
          </span>
          {getUserAvatar(props.data, true, 'smaller')}
          <WorkHourName>{resolveFullName(props.data, true)}</WorkHourName>
          {props.data.isInactive ? (
            <WorkHourHint isInactive={props.data.isInactive}>
              {t('translation.EmployeeSelect.offDuty')}
            </WorkHourHint>
          ) : null}
          {props.data.isBusy ? (
            <WorkHourHint isBusy={props.data.isBusy}>
              {t('translation.StartTimeSelect.label-busy')}
            </WorkHourHint>
          ) : null}
        </SelectRow>
      </components.Option>
    )
  }

  const SingleValue = props => {
    const { t } = useTranslation()

    const currentOption = props?.options?.find(
      opt => opt?.id === props?.data?.id
    )

    // because between rerenders sometimes isInactive prop is undefined
    const isInactive = props?.data?.isInactive ?? currentOption?.isInactive
    const isBusy = props?.data?.isBusy ?? currentOption?.isBusy

    return (
      <components.SingleValue {...props}>
        <ItemRow>
          <WorkHourName>{resolveFullName(props.data, true)}</WorkHourName>

          {isInactive ? (
            <Tooltip
              label={t('translation.EmployeeSelect.offDuty')}
              style={{ marginLeft: 'auto' }}
            >
              <WarningTriangle
                size={'small'}
                color={selectedTheme.colors.accent5}
              />
            </Tooltip>
          ) : null}
          {isBusy ? (
            <Tooltip
              label={t('translation.EmployeeSelect.zauzet')}
              style={{ marginLeft: 'auto' }}
            >
              <WarningTriangle
                size={'small'}
                color={selectedTheme.colors.accent4}
              />
            </Tooltip>
          ) : null}
        </ItemRow>
      </components.SingleValue>
    )
  }

  const MobileOption = props => {
    const { t } = useTranslation()
    const isSelected = props.selectProps.value?.id === props.data?.id

    return (
      <SelectRow>
        <span className="mr-[6px] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <CheckMarkIcon color="grayDarker" />}
        </span>
        <div>{getUserAvatar(props.data, true, 'medium')}</div>
        <WorkHourName isInactive={props.data.isInactive}>
          {resolveFullName(props.data, true)}
        </WorkHourName>
        {props.data.isInactive ? (
          <WorkHourHint isInactive={props.data.isInactive}>
            {t('translation.EmployeeSelect.offDuty')}
          </WorkHourHint>
        ) : null}
        {props.data.isBusy ? (
          <WorkHourHint isBusy={props.data.isBusy}>
            {t('translation.EmployeeSelect.zauzet')}
          </WorkHourHint>
        ) : null}
      </SelectRow>
    )
  }

  const selectComponents = {
    Option,
    MobileOption,
    SingleValue,
  }

  const selectedTheme = useContext(ThemeContext)
  const selectStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      boxShadow: 'none',
      minWidth: '200px',
    }),
    singleValue: styles => ({
      ...styles,
      width: '100%',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: 'pointer',
        backgroundColor: isDisabled
          ? null
          : isFocused
          ? transparentize(0.9, selectedTheme.colors.primary)
          : null,
        fontWeight: isSelected ? 500 : 400,
        ':active': {
          ...styles[':active'],
          backgroundColor: transparentize(0.9, selectedTheme.colors.primary),
        },
      }
    },
  }

  return (
    <FormField.Select
      label={label}
      options={employees?.sort(
        (a, b) => a.isInactive - b.isInactive || a.isLocked - b.isLocked
      )}
      closeMenuOnSelect
      hideSelectedOptions={false}
      components={selectComponents}
      styles={selectStyles}
      isDisabled={isDisabled}
      formatOptionLabel={formatOptionLabel}
      placeholder={t('translation.EmployeeSelect.selectEmployee')}
      isClearable={false}
      isSearchable={true}
      filterOption={filterOptions}
      name={name}
    />
  )
}
