import React, { useRef, useState } from 'react'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import { FormPageContainer, FormContainer, ListBodyWrapper } from 'src/styles'
import {
  Button,
  DeleteButton,
  Form,
  FormField,
  FormFooterButtonsContainer,
  icons,
  Loader,
} from 'components'
import {
  Exact,
  Scalars,
  useBusinessPartnerSearchQuery,
  useWmsOrderDeleteMutation,
  WmsOrderGetQuery,
  WmsOrderListDocument,
} from 'state/graphql'
import { useSdk } from 'sdk'
import { FormFieldsWrapper } from 'components/Form/Form'
import { useParams } from 'react-router'
import * as Yup from 'yup'
import { ApolloQueryResult, useQuery } from '@apollo/client'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import { formatPriceNumberWithDelimiter } from 'src/helpers'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { ProductForm } from './ProductForm'
import { concat } from 'lodash'
import { useLocation } from 'react-router-dom'
import { SettingsHeader, SettingsLabel } from 'src/styles'
import { getAuthToken } from 'sdk/Auth'
import { environment } from 'sdk/environment'
import { TypeWarehouse } from './EditGoodsReceived'

const validationSchemaGoodsRemoved = t =>
  Yup.object().shape({
    orderDate: Yup.string().required('Datum ulaza je obavezan').nullable(),
    warehouse: Yup.object().nullable().required('Odaberite skladište'),
  })

const validationSchemaGoodsReceived = t =>
  Yup.object().shape({
    orderDate: Yup.string().required('Datum ulaza je obavezan').nullable(),
    invoiceNo: Yup.string().required('Broj ulaznog dokumenta je obavezan'),
    supplier: Yup.object().nullable().required('Dobavljač je obavezan'),
    handlingCosts: Yup.string().matches(
      /^-?(?:\d+)(?:(\.|,)\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    warehouse: Yup.object().nullable().required('Odaberite skladište'),
  })

export type TypeOrderItem = {
  id: string
  inQty: number
  listDiscountRate: number
  listPrice: number
  outQty: number
  product: {
    code: string
    id: string
    name: string
    uom: string
  }
  productId: string
  purchasePrice: number
  retailMarginRate: number
  retailPrice: number
  uom: string
  vatRate?: number | null | undefined
  purchaseAmount?: number | null
  retailAmount?: number | null
  unitMargin?: number | null | undefined
  retailMarginAmount?: number | null | undefined
  unitVatAmount?: number | null
}

type TypeGoodsReceivedForm = {
  initialValues: {
    orderDate: string
    invoiceNo?: string | null
    refNo?: string | null
    supplierId: string
    supplier?: {
      id: string
      name: string
    } | null
    handlingCosts?: number | 0
    items: TypeOrderItem[] | []
    warehouse?: TypeWarehouse | null
    allWarehouses?: TypeWarehouse[] | []
  }
  onSubmit: (arg0: any, arg1: any, arg2: boolean) => void
  refetchData?: (
    variables?:
      | Partial<
          Exact<{
            id: Scalars['BigInt']
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<WmsOrderGetQuery>>
}

export const GoodsReceivedForm = (props: TypeGoodsReceivedForm) => {
  const { onSubmit, initialValues, refetchData } = props
  const { t, navigateTo, appServices } = useSdk()
  let formRef = useRef<any>()
  let innerFormRef = useRef<any>()
  const params = useParams<{
    orderId: string
    orgId: string
    locationId: string
  }>()
  const { orgId, orderId, locationId } = params
  const { pathname } = useLocation()

  const isGoodsRemovedNew = pathname.endsWith(`/wms/goodsRemoved/new`)
  const isGoodsRemovedEdit = pathname.includes(`/wms/goodsRemoved/edit`)
  const [showProductsModal, setShowProductsModal] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)

  const [showPDFPreview, setShowPDFPreview] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const tableRef = useRef<any>()

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const org = organizationData?.organization?.get

  const {
    data: businessPartnersData,
    loading: businessPartnersLoading,
    fetchMore: fetchMoreBusinessPartners,
  } = useBusinessPartnerSearchQuery({
    variables: {
      input: {
        fetch: {
          limit: 50,
          cursor: null,
        },
      },
    },
  })

  const businessPartners = businessPartnersData?.businessPartner_search || []

  const [deleteOrderMutation, { loading: loadingDeleteOrder }] =
    useWmsOrderDeleteMutation()

  const handleDeleteOrder = async () => {
    let result = await deleteOrderMutation({
      variables: {
        id: Number(orderId),
      },
      refetchQueries: [
        {
          query: WmsOrderListDocument,
          variables: {
            input: {
              searchTerm: '',
              documentType: pathname.includes(`/wms/goodsReceived`)
                ? 'GOODS_RECEIVED'
                : 'GOODS_RELEASED',
              fetch: {
                cursor: null,
                limit: 40,
              },
            },
          },
        },
      ],
    })

    appServices
      .handleMutationResult(
        result,
        pathname.includes(`/wms/goodsReceived`)
          ? 'Ulaz je uspješno obrisan'
          : 'Izlaz je uspješno obrisan'
      )
      .onSuccess(() => {
        pathname.includes(`/wms/goodsReceived`)
          ? navigateTo.goodsReceived(params)
          : navigateTo.goodsReleased(params)
      })
  }
  const iframeHeight = tableRef?.current?.clientHeight - 10

  if (loadingOrgData || businessPartnersLoading) return <Loader isComponent />

  return (
    <>
      <SettingsHeader>
        {!showPDFPreview ? (
          <SettingsLabel>
            {pathname.endsWith(`/wms/goodsReceived/new`)
              ? 'Novi ulaz'
              : isGoodsRemovedNew
              ? 'Novi izlaz'
              : isGoodsRemovedEdit
              ? `Izlaz broj: ${orderId}`
              : `Ulaz broj: ${orderId}`}
          </SettingsLabel>
        ) : null}
        {pathname.includes(`/wms/goodsReceived/edit`) ? (
          <Button
            onClick={e => {
              e.preventDefault()
              setShowPDFPreview(prevState => !prevState)
            }}
            buttonType={showPDFPreview ? 'text' : 'secondaryLink'}
            size={'medium'}
            label={showPDFPreview ? t('translation.App.label-back') : 'Ispis'}
            iconComponent={
              showPDFPreview ? (
                <icons.ArrowLeft size={'small'} />
              ) : (
                <icons.PDF size={'smaller'} />
              )
            }
            name={'downloadOrderPDF'}
            style={{ marginRight: '20px' }}
          />
        ) : null}
      </SettingsHeader>
      {showPDFPreview ? (
        <ListBodyWrapper id={'list-body-wrapper'} ref={tableRef}>
          <iframe
            onLoad={() => {
              setIsLoading(false)
            }}
            style={{
              height: isLoading ? '0px' : `${iframeHeight}px`,
              width: isLoading ? '0px' : '100%',
              border: 'none',
            }}
            title="Cash balance"
            src={`${
              import.meta.env.VITE_CASH_REGISTER_API
            }?authorization=${getAuthToken()}&x-orgid=${orgId}&x-locationid=${locationId}&reportId=primka&orderId=${orderId}${
              environment.REACT_APP_ENV === 'production'
                ? '&environment=production'
                : ''
            }`}
          ></iframe>
        </ListBodyWrapper>
      ) : (
        <>
          <FormPageContainer>
            <Form
              onSubmit={values =>
                onSubmit(values, innerFormRef?.current, !!selectedItem)
              }
              initialValues={initialValues}
              validationSchema={
                isGoodsRemovedNew || isGoodsRemovedEdit
                  ? validationSchemaGoodsRemoved(t)
                  : validationSchemaGoodsReceived(t)
              }
            >
              {form => {
                formRef.current = form

                const itemsPrice = form?.values?.items?.length
                  ? form?.values?.items?.reduce((acc, curr) => {
                      return (
                        acc +
                        ((isGoodsRemovedEdit || isGoodsRemovedNew
                          ? curr?.retailPrice
                          : curr?.purchasePrice) * curr?.inQty || 0)
                      )
                    }, 0)
                  : 0

                return (
                  <FormFieldsWrapper>
                    <FormContainer>
                      <FieldRow>
                        <FieldColumn>
                          <FormField.Select
                            label={
                              isGoodsRemovedEdit || isGoodsRemovedNew
                                ? 'Poslovni partner'
                                : 'Dobavljač'
                            }
                            name="supplier"
                            options={businessPartners || []}
                            parseValue={val => {
                              return businessPartners.find(
                                e => e.id === val?.id
                              )
                            }}
                          />
                        </FieldColumn>

                        <FieldColumn className="gap-[14px]">
                          {!isGoodsRemovedEdit && !isGoodsRemovedNew ? (
                            <FormField.Text
                              label={'Broj ulaznog dokumenta'}
                              name="invoiceNo"
                            />
                          ) : null}
                          <FormField.Text label={'Interni broj'} name="refNo" />
                        </FieldColumn>
                      </FieldRow>

                      <FieldRow>
                        <FieldColumn>
                          <FormField.Select
                            label={'Skladište'}
                            name="warehouse"
                            options={initialValues?.allWarehouses || []}
                            isDisabled={
                              pathname.includes(`/wms/goodsReceived/edit`) ||
                              isGoodsRemovedEdit
                            }
                          />
                        </FieldColumn>

                        <FieldColumn className="gap-[14px]">
                          <FormField.DateInput
                            label={
                              pathname.includes(`/wms/goodsRemoved`)
                                ? 'Datum izlaza'
                                : 'Datum ulaza'
                            }
                            name="orderDate"
                            displayFormat="DD.MM.YYYY."
                          />
                          {!isGoodsRemovedEdit && !isGoodsRemovedNew ? (
                            <FormField.Text
                              label={'Dodatni troškovi'}
                              name="handlingCosts"
                            />
                          ) : null}
                        </FieldColumn>
                      </FieldRow>

                      <div className="flex justify-between items-center my-3">
                        <p className="font-medium ">Proizvodi</p>
                      </div>

                      <div className="bg-zoyya-grayLighter rounded-sm border border-zoyya-border pr-3 pl-[1.143rem]">
                        <div className="rounded-md w-full">
                          <div className="w-full flex  py-2">
                            <p className="w-40 text-sm text-zoyya-secondaryDark">
                              Šifra
                            </p>
                            <p className="mr-auto text-sm text-zoyya-secondaryDark">
                              Naziv
                            </p>
                            <p className="w-40 text-center text-sm text-zoyya-secondaryDark">
                              Količina
                            </p>
                            <p className="w-40 text-center text-sm text-zoyya-secondaryDark">
                              Jedinica mjere
                            </p>
                            <p className="w-40 text-right text-sm text-zoyya-secondaryDark">
                              {isGoodsRemovedEdit || isGoodsRemovedNew
                                ? 'Prodajna cijena'
                                : 'Nabavna cijena'}
                            </p>
                            <p className="w-40 text-right text-sm text-zoyya-secondaryDark">
                              Iznos
                            </p>
                            <div className="w-20"></div>
                          </div>

                          {form?.values?.items?.map(x => {
                            return selectedItem?.id === x?.id ? (
                              <ProductForm
                                key={x?.id}
                                initialValues={x}
                                showProductsModal={showProductsModal}
                                setShowProductsModal={setShowProductsModal}
                                setSelectedItem={setSelectedItem}
                                outerFormRef={formRef}
                                innerFormRef={innerFormRef}
                                refetchData={refetchData}
                              />
                            ) : (
                              <div
                                key={x?.id}
                                className="w-full flex py-2 border-t border-zoyya-border "
                              >
                                <p className="w-40">{x?.product?.code}</p>
                                <p className="mr-auto">{x?.product?.name}</p>
                                <p className="w-40 text-center">{x?.inQty}</p>
                                <p className="w-40 text-center">
                                  {x.uom === 'PC'
                                    ? t('translation.ProductList.uom-pc')
                                    : x.uom}
                                </p>
                                <p className="w-40 text-right">
                                  {formatPriceNumberWithDelimiter(
                                    isGoodsRemovedEdit || isGoodsRemovedNew
                                      ? x?.retailPrice
                                      : x?.purchasePrice
                                  ) +
                                    ' ' +
                                    org?.currency?.id}
                                </p>
                                <p className="w-40 text-right">
                                  {formatPriceNumberWithDelimiter(
                                    (isGoodsRemovedEdit || isGoodsRemovedNew
                                      ? x?.retailPrice
                                      : x?.purchasePrice) * x?.inQty
                                  ) +
                                    ' ' +
                                    org?.currency?.id}
                                </p>
                                <div
                                  className="w-20 flex justify-center cursor-pointer"
                                  onClick={() => {
                                    form.setFieldValue(
                                      'items',
                                      form.values?.items?.filter(
                                        x => x?.id !== 'new'
                                      )
                                    )
                                    setSelectedItem(x)
                                  }}
                                >
                                  <icons.Edit size="smaller" />
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      {!selectedItem ? (
                        <div className="my-10 flex justify-between">
                          <div className="min-w-[250px] mt-[40px] lg:mt-0 pl-[1.143rem] mr-20">
                            {!pathname.includes(`/wms/goodsRemoved`) ? (
                              <>
                                <div className="flex justify-between">
                                  <span>Stavke:</span>
                                  <span className="font-medium ml-[20px]">
                                    {`${formatPriceNumberWithDelimiter(
                                      itemsPrice
                                    )} ${org?.currency?.id}`}
                                  </span>
                                </div>

                                <div className="flex justify-between">
                                  <span>Dodatni troškovi:</span>
                                  <span className="font-medium ml-[20px]">{`${formatPriceNumberWithDelimiter(
                                    !!form.errors?.handlingCosts
                                      ? 0
                                      : parseFloat(
                                          form?.values.handlingCosts
                                            ?.toString()
                                            .replace(',', '.') || 0
                                        )
                                  )} ${org?.currency?.id}`}</span>
                                </div>

                                <div className="w-[100%] my-[10px] mx-[0] border-t-[1px] border-zoyya-outline" />
                              </>
                            ) : null}

                            <div className="flex justify-between">
                              <span className="font-medium">Ukupno:</span>
                              <span
                                style={{ fontWeight: 500 }}
                              >{`${formatPriceNumberWithDelimiter(
                                itemsPrice +
                                  parseFloat(
                                    !!form.errors?.handlingCosts
                                      ? 0
                                      : form?.values.handlingCosts
                                          ?.toString()
                                          .replace(',', '.') || 0
                                  )
                              )} ${org?.currency?.id}`}</span>
                            </div>
                          </div>

                          <ButtonTW
                            label="+ Dodaj proizvod"
                            variant="primaryOutline"
                            className="flex-0"
                            onClick={() => {
                              const newItem = {
                                id: 'new',
                                inQty: 1,
                                listDiscountRate: 0,
                                listPrice: null,
                                outQty: 0,
                                product: null,
                                productId: null,
                                purchasePrice: null,
                                retailMarginRate: 0,
                                retailPrice: null,
                                vatRate: 0,
                                // TODO: sequenc
                                // sequence:
                                //   form.values?.items[form.values?.items - 1]
                                //     .sequence - 1,
                                // TODO: default uom
                                uom: 'PC',
                              }

                              form.setFieldValue(
                                'items',
                                form.values?.items?.length
                                  ? concat(form.values?.items, newItem)
                                  : [newItem]
                              )
                              setSelectedItem(newItem)
                              setShowProductsModal(true)
                            }}
                          />
                        </div>
                      ) : null}

                      {!pathname.endsWith(`/wms/goodsReceived/new`) &&
                      !isGoodsRemovedNew &&
                      !form?.values?.items?.length ? (
                        <DeleteButton
                          withConfirm
                          onConfirm={handleDeleteOrder}
                          confirmationMessage={
                            pathname.includes(`/wms/goodsReceived`)
                              ? 'Sigurno želiš obrisati ulaz?'
                              : 'Sigurno želiš obrisati izlaz?'
                          }
                        />
                      ) : null}

                      <FormFooterButtonsContainer>
                        <Button
                          footerButton
                          buttonType={'text'}
                          name="closeForm"
                          type={'button'}
                          label={t(
                            'translation.ResourceEditorForm.button-close'
                          )}
                          onClick={() => {
                            isGoodsRemovedEdit || isGoodsRemovedNew
                              ? navigateTo.goodsReleased(params)
                              : navigateTo.goodsReceived(params)
                          }}
                        />
                        <Button
                          type={'button'}
                          onClick={e => {
                            e.preventDefault()

                            selectedItem?.id &&
                            Object.keys(innerFormRef?.current?.errors).length
                              ? appServices.toast.danger(
                                  'Ispravno ispunite formu proizvoda'
                                )
                              : formRef.current.submitForm()

                            setTimeout(() => {
                              if (
                                Object.values(formRef?.current?.errors)?.length
                              )
                                Object.values(formRef?.current?.errors).forEach(
                                  element => {
                                    appServices.toast.danger(element)
                                  }
                                )
                            }, 500)
                          }}
                          buttonType={'primary'}
                          name="saveChanges"
                          label={t(
                            'translation.ResourceEditorForm.button-save'
                          )}
                        />
                      </FormFooterButtonsContainer>
                    </FormContainer>
                  </FormFieldsWrapper>
                )
              }}
            </Form>
          </FormPageContainer>
        </>
      )}
    </>
  )
}
