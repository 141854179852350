import React, { useContext } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useMutateService } from 'state/queries'
import { ThemeContext } from 'styled-components'
import { ServiceEditorForm } from './ServiceEditorForm'
import { useTranslation } from 'react-i18next'
import { useAppServices } from 'sdk/appServices'
import { useSdk } from 'sdk'
import { searchServiceTypesGql } from 'state/gql'
import { useQuery } from '@apollo/client'
import { Loader } from 'components'
import { getServiceDurationInMinutes, serialize } from 'src/helpers'
import { useHeader } from 'mobile/Header'
import { useResourceSearchQuery, ServiceInput } from 'state/graphql'

type NewServiceProps = {
  setShowAddServiceForm?: (boolean) => any
  hasOnlineBooking?: boolean
  setOpenBillingModal?: boolean
}

const NewService = (props: NewServiceProps) => {
  const { hasOnlineBooking, setOpenBillingModal, setShowAddServiceForm } = props
  const { pathname } = useLocation()
  const selectedTheme = useContext(ThemeContext)
  let { typeId, selectedLocationId } = useParams<{
    typeId: string
    selectedLocationId: string
  }>()
  const { data: resourcesData } = useResourceSearchQuery()
  const resources = resourcesData?.resource?.search

  const { data: serviceTypesData, loading } = useQuery(searchServiceTypesGql, {
    variables: { locationId: selectedLocationId },
  })

  const serviceTypes = serviceTypesData?.serviceType?.search

  const defaultService = {
    description: '',
    color: selectedTheme.colors.primary,
    durationMinutes: 30,
    netDurationMinutes: 30,
    bookingAllowed: true,
    hasBookingConfirmation: true,
    vatRate: 25,
    price: 0,
    resources,
    type:
      typeId && typeId !== '*'
        ? { id: typeId }
        : serviceTypes && serviceTypes[0]
        ? { id: serviceTypes[0]?.id }
        : null,
    discountValue: null,
    discountAmount: 0,
    discountType: 'percent',
    discountRate: 0,
    totalAmount: 0,
  }

  const { t } = useTranslation()

  const appServices = useAppServices()
  const [newService] = useMutateService(selectedLocationId)
  const { navigateTo } = useSdk()
  const handleSubmit = async values => {
    const input: ServiceInput = {
      id: values.id,
      color: values.color,
      description: serialize(values.description),
      name: values.name,
      bookingAllowed: hasOnlineBooking ? values.bookingAllowed : false,
      hasBookingConfirmation: values.hasBookingConfirmation,
      price: values.price
        ? parseFloat(values.price?.toString().replace(',', '.'))
        : parseFloat('0'),
      durationMinutes: getServiceDurationInMinutes(values.durationMinutes),
      netDurationMinutes: Number(values.netDurationMinutes),
      serviceTypeId: values.type?.id,
      resources: values.resources?.map(x => x.id),
      professionId: values.profession?.id,
      locationId: selectedLocationId,
      resourceTypeId: values.resourceType?.id,
      vatRate: values.vatRate
        ? parseFloat((values.vatRate / 100).toString())
        : parseFloat('0'),
      serviceTypeCategoryId: values?.serviceTypeCategoryId,
      discountValue: values?.discountValue
        ? Number(values?.discountValue?.toString().replace(',', '.'))
        : null,
      discountAmount: Number(values?.discountAmount || 0),
      discountType: values?.discountType,
      discountRate: Number(values?.discountRate || 0),
      totalAmount: Number(values?.totalAmount),
    }

    let result = await newService({
      variables: { input },
      refetchQueries: [
        {
          query: searchServiceTypesGql,
          variables: { locationId: selectedLocationId },
        },
      ],
    })

    appServices
      .handleMutationResult(
        result,
        t('translation.NewService.toast-service-created')
      )
      .onSuccess(() => {
        pathname.includes(`/giftCardPackageTemplates`)
          ? setShowAddServiceForm?.(false)
          : navigateTo.servicesList({
              selectedLocationId,
              typeId: typeId && typeId !== '*' ? typeId : serviceTypes[0]?.id,
            })
      })
  }
  useHeader({
    content: t('translation.ServicesList.label-new-service'),
    onGoBack: navigateTo.servicesList,
  })
  return loading ? (
    <Loader />
  ) : (
    <ServiceEditorForm
      onSubmit={handleSubmit}
      initialValues={defaultService}
      isNew={true}
      setOpenBillingModal={setOpenBillingModal}
      hasOnlineBooking={hasOnlineBooking}
      setShowAddServiceForm={setShowAddServiceForm}
    />
  )
}

export default NewService
