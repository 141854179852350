import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router'
import TemplateForm from './TemplateForm'
import { trpc } from 'src/trpc'
import { useHeader } from 'src/mobile/Header'
import { useSdk } from 'src/sdk'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import { useQuery } from '@apollo/client'
import {
  useLocationGetQuery,
  usePrepaymentsGetStripeConnectAccountInfoQuery,
} from 'src/state/graphql'
import { roundNumberWithTwoDecimals, serialize } from 'src/helpers'

export const NewTemplate = () => {
  const params = useParams<{
    selectedLocationId: string
    orgId: string
    type: string
  }>()
  const { selectedLocationId, orgId, type } = params
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const { navigateTo, appServices } = useSdk()
  const { pathname } = useLocation()
  useHeader({
    content: 'Novi predložak',
    onGoBack: () =>
      pathname.includes('loyaltyTemplates')
        ? navigateTo.listMemberCardTemplates(params)
        : navigateTo.listTemplates(params),
  })

  const { data: locationData, loading: loadingLocationData } =
    useLocationGetQuery({
      variables: { locationId: selectedLocationId },
      fetchPolicy: 'network-only',
    })
  const location = locationData?.location?.get

  const createGiftCardTemplateMutation =
    trpc.giftCardTemplate_create.useMutation()

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const org = organizationData?.organization?.get

  const {
    data: stripeConnectAccountData,
    loading: loadingStripeConnectAccountData,
  } = usePrepaymentsGetStripeConnectAccountInfoQuery({
    skip: !org?.stripeConnectAccountId,
  })
  const hasStripeChargesEnabled =
    stripeConnectAccountData?.prepayments?.getStripeConnectAccountInfo
      ?.hasChargesEnabled

  const hasInvoicingData =
    location?.prepaymentsConfig?.userId &&
    location?.prepaymentsConfig?.operatorId &&
    location?.prepaymentsConfig?.prepaymentsDeviceId

  const hasOnlinePaymentsEnabled =
    org?.prepaymentsProvider === 'STRIPE'
      ? hasStripeChargesEnabled && hasInvoicingData
      : org?.hasVivaMerchantId && org?.hasVivaApiKey && hasInvoicingData

  const createTemplate = async values => {
    const price = parseFloat(values?.price?.toString().replace(',', '.') || '0')

    const res = await createGiftCardTemplateMutation.mutateAsync({
      orgId,
      locationId: BigInt(selectedLocationId),
      name: values?.name,
      voucherType:
        type === 'giftcard'
          ? 'GIFTCARD'
          : type === 'voucher'
          ? 'VOUCHER'
          : 'MEMBER_CARD',
      validAmount: Number(values?.validAmount),
      validIterator: values?.validIterator?.id,
      price,
      voucherTerms: serialize(values?.voucherTerms),
      voucherDesc: serialize(values?.voucherDesc),
      isTransferable: values?.isTransferable,
      singleUse: values?.singleUse,
      value: parseFloat(values?.value?.toString().replace(',', '.') || '0'),
      discountRate:
        roundNumberWithTwoDecimals(
          parseFloat(values?.discountRate.toString().replace(',', '.') || 0)
        ) / 100,
      vatRate: org?.vatStatus === 'Y' ? Number(values?.vatRate) / 100 : 0,
      onlinePurchaseEnabled:
        hasOnlinePaymentsEnabled && price >= 10
          ? values?.onlinePurchaseEnabled
          : false,
    })
    return res
  }

  const handleSubmit = async values => {
    try {
      setIsBusy(true)

      const res = await createTemplate(values)
      if (res) {
        appServices.toast.success('Predložak je uspješno kreiran')
        type === 'member_card'
          ? navigateTo.editMemberCardTemplate({ id: res.id })
          : navigateTo.editTemplate({ id: res.id })
      } else appServices.toast.danger('Predložak nije uspješno kreiran')
    } catch (e) {
      appServices.toast.danger('Predložak nije uspješno kreiran')
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <TemplateForm
      initialValues={{
        name: '',
        validAmount: '90',
        validIterator: 'day',
        value: '0',
        price: '0',
        discountRate: '0',
        discountRateCheckbox: false,
        voucherTerms: '',
        voucherDesc: '',
        isTransferable: true,
        singleUse: false,
        items: [],
        vatRate: 25,
        onlinePurchaseEnabled: false,
      }}
      location={location}
      hasVat={org?.vatStatus === 'Y'}
      onSubmit={handleSubmit}
      loading={isBusy || loadingLocationData || loadingStripeConnectAccountData}
      currency={org?.currency?.id}
      hasOnlinePaymentsEnabled={hasOnlinePaymentsEnabled}
      createTemplate={createTemplate}
    />
  )
}

export default NewTemplate
