import React, { CSSProperties, ReactElement, useState } from 'react'
import Text from '../Text/Text'
import { Eye } from 'components/Icon/Icons/Eye'
import { EyeDisabled } from 'components/Icon/Icons/EyeDisabled'
import { Warning } from '../Icon'
import { grayDark } from '../style/colors'
import { Info } from '../Icon/Icons/Info'
import ToolTip from 'components/Tooltip/Tooltip'
import { Tooltip2 } from '../Tooltip2/Tooltip2'
import {
  AIGenerateButton,
  TAIGenerateButtonProps,
} from '../AIGenerateButton/AIGenerateButton'

type TProps = {
  label?: string | ReactElement<any>
  fieldName?: string
  children?: any
  error?: any
  style?: any
  labelStyle?: CSSProperties
  name?: string
  labelAlignment: 'vertical' | 'horizontal'
  type?: any
  icon?: any
  helpText?: any
  helpLink?: any
  html?: any
  warningPosition?: boolean
  showGenerateButton?: boolean
  generateButtonProps?: TAIGenerateButtonProps
}

export const FieldContainer = (props: TProps) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <div
      style={Object.assign(
        {
          display: 'flex',
          flex: 1,
          flexDirection:
            props.labelAlignment === 'horizontal' ? 'row-reverse' : 'column',
          justifyContent:
            props.labelAlignment === 'horizontal' ? 'flex-end' : 'initial',
          alignItems:
            props.labelAlignment === 'horizontal' ? 'center' : 'stretch',
          width: props.labelAlignment === 'horizontal' ? '100%' : 'initial',
          zIndex: props.type === 'richText' ? 1 : null,
        },
        props.style || {}
      )}
    >
      <div
        dangerouslySetInnerHTML={
          props.html ? { __html: props.html } : undefined
        }
      >
        {props.label || props.showGenerateButton ? (
          <Text
            style={{
              marginBottom: props.type === 'checkbox' ? '0px' : '5px',
              display: 'flex',
              marginRight: props.labelAlignment === 'horizontal' ? '16px' : '0',
              whiteSpace: 'break-spaces',
            }}
            type="label"
            htmlFor={props.fieldName}
          >
            <span
              style={{
                marginRight: props.type === 'checkbox' ? '20px' : 'initial',
              }}
            >
              {props.label}
            </span>
            {props.helpText || props.showGenerateButton ? (
              <div className="ml-auto flex justify-between gap-2">
                {props.showGenerateButton && props.generateButtonProps ? (
                  <AIGenerateButton {...props.generateButtonProps} />
                ) : null}

                {props.helpText ? (
                  <Tooltip2 {...props}>
                    <div className="flex items-center text-zoyya-secondaryDark">
                      <Info size={'smaller'} />
                    </div>
                  </Tooltip2>
                ) : null}
              </div>
            ) : null}
          </Text>
        ) : null}
      </div>
      <div
        style={{
          flexGrow: props.labelAlignment === 'horizontal' ? 0 : 1,
          marginRight:
            props.type === 'checkbox'
              ? '15px'
              : props.labelAlignment === 'horizontal'
              ? '40px'
              : '0',
          position: 'relative',
          display: props.labelAlignment === 'horizontal' ? 'flex' : 'initial',
          alignItems:
            props.labelAlignment === 'horizontal' ? 'center' : 'initial',
        }}
      >
        {props.icon && (
          <div
            style={{
              position: 'absolute',
              left: '10px',
              zIndex: 1,
              top: '13px',
            }}
          >
            {props.icon}
          </div>
        )}
        {React.cloneElement(props.children, {
          hasError: props.error,
          hasIcon: !!props.icon,
          type: props.type === 'password' && showPassword ? 'text' : props.type,
        })}
        {props.type === 'password' && (
          <div className="absolute right-[10px] top-[10px] cursor-pointer">
            {showPassword ? (
              <Eye color={grayDark} onClick={() => setShowPassword(false)} />
            ) : (
              <EyeDisabled
                color={grayDark}
                onClick={() => setShowPassword(true)}
              />
            )}
          </div>
        )}
        {props.error && !props.html && props.type !== 'password' ? (
          <div
            className={`flex top-[calc(50%-8px)] absolute z-8 items-center justify-center min-w-[28px] w-[28px] text-zoyya-red ${
              props.warningPosition
                ? 'left-[calc(100%-76px)]'
                : 'left-[calc(100%-56px)]'
            }`}
          >
            {' '}
            <ToolTip label={props.error}>
              <Warning
                size="smaller"
                data-cy={`valerr_${props.fieldName}`}
                data-valerr={props.error}
              />
            </ToolTip>
          </div>
        ) : null}
      </div>
    </div>
  )
}

FieldContainer.defaultProps = {
  width: '100%',
  labelAlignment: 'vertical',
}
