import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button } from 'components'
import { useParams } from 'react-router'
import { useSdk } from 'sdk'
import { useHeader } from 'mobile/Header'
import { DataTable } from 'src/sdk/tw/DataTable'
import { Column } from 'react-table'
import { RouterOutput, trpc } from 'src/trpc'
import { StickyHeader } from '../styles'
import SearchBar from 'components/SearchBar/SearchBar'
import { icons } from 'components/Icon'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { ListHeader } from 'layout/ListHeader'
import { MobileContext } from 'src/Context'
import {
  getValidIteratorsOptions,
  getGiftCardPackageListName,
  formatPriceNumberWithDelimiter,
  resolveFullName,
  useOnClickOutside,
} from 'src/helpers'
import { TabContainer } from 'src/styles'
import { TabView, Tab } from 'components'
import { useFooter } from 'mobile/Footer'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { Select } from 'src/components/Select2/Select'
import { CheckMarkIcon } from 'src/components/Icon/Icons/Checkmark'
import { SelectRow, SelectOptionLabel } from 'src/views/AppointmentModal/styles'

export type TypeGiftCardItem = {
  description: string | null
  discountRate: any
  giftCardTemplateId: bigint
  id: bigint
  itemName: string | null
  serviceId: bigint
  serviceTypeId: bigint
  status: 'ACTIVE' | 'DELETED'
  quantity: number
  Service?: any
  ServiceType?: any
}

export const TemplateList = () => {
  const params = useParams<{
    selectedLocationId: string
    orgId: string
    voucherType: string
  }>()
  const { selectedLocationId, orgId, voucherType } = params
  const { navigateTo, t } = useSdk()
  const isMobile = useContext(MobileContext)

  const [filterText, setFilterText] = useState<string>('')

  const [statusFilter, setStatusFilter] = useState<
    { id: string; name: string }[]
  >([
    { id: 'ACTIVE', name: t('translation.GiftCardList.status-active') },
    { id: 'INACTIVE', name: t('translation.GiftCardList.status-inactive') },
  ])

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const org = organizationData?.organization?.get

  const queryInput = {
    orgId: orgId,
    locationId: BigInt(selectedLocationId),
    voucherType: ['giftcard', 'voucher'],
    searchTerm: filterText,
    status: statusFilter?.map(x => x?.id),
  }

  const { data, isLoading, refetch } =
    trpc.giftCardTemplate_findMany.useQuery(queryInput)

  const giftCardTemplates = data || []

  const trpcContext = trpc.useContext()

  useEffect(() => {
    trpcContext.giftCardTemplate_findMany.invalidate()
  }, [voucherType])

  useHeader(
    {
      content: getGiftCardPackageListName(voucherType, 'template'),
      onGoBack: navigateTo.mobileBranchSettings,
    },
    [voucherType]
  )

  useFooter({
    showAddButton: true,
    addButtonOptions: [
      {
        onClick: () => {
          navigateTo.newTemplate({ type: 'giftcard' })
        },
        title: 'Novi predložak za poklon bon',
        icon: <icons.Add size="smaller" />,
      },
      {
        onClick: () => {
          navigateTo.newTemplate({ type: 'voucher' })
        },
        title: 'Novi predložak za paket usluga',
        icon: <icons.Add size="smaller" />,
      },
      null as any,
    ],
  })

  const updateSequenceMutation = trpc.giftCard_updateSequence.useMutation()

  const changeTemplateSequence = async (index, direction: 'up' | 'down') => {
    const result = await updateSequenceMutation.mutateAsync({
      firstTemplateId: giftCardTemplates![index].id,
      secondTemplateId:
        giftCardTemplates![direction === 'up' ? index - 1 : index + 1].id,
    })
    refetch()
  }

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'sort',
        width: 40,
        minWidth: 40,
        Cell: props => (
          <div className="relative bottom-[3px]">
            <div
              className="h-[19px] mb-[2px] overflow-hidden cursor-pointer text-zoyya-secondaryDark hover:text-zoyya-text"
              onClick={async e => {
                e.stopPropagation()
                if (props.row.index === 0) return
                await changeTemplateSequence(props.row.index, 'up')
              }}
            >
              <icons.ArrowUpDrop stroke={2} size="large" />
            </div>

            <div
              className="h-[15px] overflow-hidden cursor-pointer text-zoyya-secondaryDark hover:text-zoyya-text"
              onClick={async e => {
                e.stopPropagation()
                if (props.row.index === giftCardTemplates?.length - 1) return
                await changeTemplateSequence(props.row.index, 'down')
              }}
            >
              <icons.ArrowDownDrop
                size="large"
                stroke={2}
                style={{ position: 'relative', top: '-9px' }}
              />
            </div>
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'image',
        minWidth: 50,
        width: 50,

        Cell: props => {
          const imageConfig = JSON.parse(
            props.cell.row.original?.giftCardImageConfig || 'null'
          )
          return (
            <div className="mr-3">
              <img
                src={
                  imageConfig?.fullImageId
                    ? `${
                        import.meta.env.VITE_API_URL
                      }/api/giftCardTemplate/${props.cell.row.original?.id}/${
                        imageConfig.fullImageId
                      }`
                    : '/assets/noImage.svg'
                }
              ></img>
            </div>
          )
        },
      },
      {
        Header: 'Naziv',
        accessor: 'name',
        minWidth: 200,
        width: 200,
        Cell: props => <p>{props.cell.row.original?.name}</p>,
      },
      {
        Header: 'Usluge',
        accessor: 'services',
        minWidth: 250,
        width: 250,
        Cell: props => (
          <div className="mr-[10px]">
            {props.cell.row.original?.items?.length ? (
              <span className="line-clamp-3 text-[12px]">
                {props.cell.row.original?.items?.map((item, idx) => {
                  return (
                    item?.itemName +
                    (idx < props.cell.row.original?.items?.length - 1
                      ? ', '
                      : '')
                  )
                })}
              </span>
            ) : (
              <span className="line-clamp-3 text-[12px]">{'Sve usluge'}</span>
            )}
          </div>
        ),
      },
      {
        Header: () => <div className="pr-[20px] text-end">{'Vrijednost'}</div>,
        accessor: 'value',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <p className="pr-[20px] text-end">{`${formatPriceNumberWithDelimiter(
            props.cell.row.original?.value
          )} ${org?.currency?.id}`}</p>
        ),
      },
      {
        Header: () => <div className="pr-[20px] text-end">{'Popust'}</div>,
        accessor: 'discount',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <p className="pr-[20px] text-end">
            {+props.cell.row.original?.discountRate !== 0
              ? formatPriceNumberWithDelimiter(
                  props.cell.row.original?.discountRate * 100 || 0,
                  false
                ) + '%'
              : ''}
          </p>
        ),
      },
      {
        Header: () => <div className="pr-[20px] text-end">{'Cijena'}</div>,
        accessor: 'price',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <p className="pr-[20px] text-end">{`${formatPriceNumberWithDelimiter(
            props.cell.row.original?.price
          )} ${org?.currency?.id}`}</p>
        ),
      },
      {
        Header: () => <div className="text-center">{'Valjanost'}</div>,
        accessor: 'validAmount',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <p className="text-center">
            {`${props.cell.row.original?.validAmount} ${
              getValidIteratorsOptions()?.find(
                x => x?.id === props.cell.row.original?.validIterator
              )?.name || ''
            }`}
          </p>
        ),
      },
      {
        Header: () => <div className="text-center">{'Vrsta'}</div>,
        accessor: 'type',
        minWidth: 80,
        width: 80,
        Cell: props => (
          <p className="text-center">
            {props.cell.row.original?.voucherType === 'GIFTCARD'
              ? 'Poklon bon'
              : props.cell.row.original?.voucherType === 'VOUCHER'
              ? 'Paket usluga'
              : ''}
          </p>
        ),
      },
      {
        Header: () => <div className="text-center">{'Online prodaja'}</div>,
        accessor: 'onlinePurchaseEnabled',
        minWidth: 80,
        width: 80,
        Cell: props => (
          <p className="text-center">
            {props.cell.row.original?.onlinePurchaseEnabled ? (
              <CheckMarkIcon size="smaller" />
            ) : null}
          </p>
        ),
      },
    ],

    [org, data]
  )

  const MobileOption = props => {
    const isSelected = props.selectProps.value?.find(
      e => e.id === props.data?.id
    )
    return (
      <SelectRow>
        <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <CheckMarkIcon color="grayDarker" />}
        </span>

        <SelectOptionLabel data-cy={'mobileSelect_value'}>
          {props.data?.title}
        </SelectOptionLabel>
      </SelectRow>
    )
  }

  const selectComponents = {
    MobileOption,
  }

  const ShowList = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const handleOptionClick = (type: 'giftcard' | 'voucher') => {
      setIsDropdownOpen(false)
      navigateTo.newTemplate({ type })
    }

    const dropdownRef = useRef<any>()
    useOnClickOutside(dropdownRef, () => {
      setIsDropdownOpen(false)
    })

    return (
      <div className="flex flex-col overflow-hidden lg:overflow-y-auto w[100%] p-[0] lg:p-4 h-full lg:h-[calc(100vh-55px)]">
        {!isMobile ? (
          <div className="flex w-full items-center sticky top-0 z-10 pb-3">
            <StickyHeader>
              <div className="">
                <Button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  iconComponent={<icons.Add size="smaller" stroke={2} />}
                  buttonType={'primary'}
                  label={'Novi predložak'}
                  name="addNew"
                />

                {isDropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="absolute left-0 mt-2 p-3 bg-white flex flex-col gap-1 border border-zoyya-outline rounded-lg shadow-xs z-10"
                  >
                    <button
                      onClick={() => handleOptionClick('giftcard')}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 rounded-md"
                    >
                      Predložak za poklon bon
                    </button>
                    <button
                      onClick={() => handleOptionClick('voucher')}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 rounded-md"
                    >
                      Predložak za paket usluga
                    </button>
                  </div>
                )}
              </div>
            </StickyHeader>
            <SearchBar
              messageList={false}
              clientsList={false}
              search={filterText}
              onChange={search => setFilterText(search?.toLowerCase())}
              isOpen
              placeholder={t('translation.Table.label-search')}
              styleWrapper={{
                marginRight: 10,
                marginLeft: 10,
              }}
            />
          </div>
        ) : (
          <div>
            <ListHeader
              hasSearch
              onSearchChange={search => setFilterText(search?.toLowerCase())}
              messageList
              clientsList
              styleWrapper={{ top: '0' }}
              placeholder={t('translation.Table.label-search')}
            />
          </div>
        )}

        <div className="flex flex-1 lg:pt-1">
          <div className="flex flex-row p-0 m-0 w-full">
            <DataTable
              data={giftCardTemplates}
              renderMobileCard={(item: any, index) => (
                <GiftCardMobileCard
                  item={item}
                  currency={org?.currency?.id}
                  index={index}
                  changeTemplateSequence={changeTemplateSequence}
                  len={giftCardTemplates?.length}
                />
              )}
              columns={columns}
              isLoading={isLoading || loadingOrgData}
              emptyListTitle={'Nema predloška'}
              onRowClick={item => navigateTo.editTemplate({ id: item?.id })}
              mobileCardHeight={120}
              tableRowHeight={70}
            />
          </div>
        </div>
      </div>
    )
  }

  if (isMobile)
    return (
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-col flex-1 lg:p-4">{ShowList()}</div>
      </div>
    )

  return (
    <TabContainer>
      <TabView isSingleTab={true} hasFooter={false}>
        <Tab
          hasFooter={false}
          title={t('translation.Sidebar.label-giftcardsTemplates')}
        >
          {ShowList()}
        </Tab>
      </TabView>
    </TabContainer>
  )
}

const GiftCardMobileCard = (props: {
  item: RouterOutput['giftCardTemplate_findMany'][0]
  currency: string
  changeTemplateSequence: (
    index: any,
    direction: 'up' | 'down'
  ) => Promise<void>
  index: number
  len: number
}) => {
  const { item, currency, changeTemplateSequence, index, len } = props
  const imageConfig = JSON.parse(item?.giftCardImageConfig || 'null')
  return (
    <div className="flex items-center  h-full py-4 mx-4 border-b border-zoyya-outline ">
      <div className="relative left-[-7px] bottom-[3px]">
        <div
          className="h-[19px] mb-[2px] overflow-hidden cursor-pointer text-zoyya-secondaryDark hover:text-zoyya-text"
          onClick={async e => {
            e.stopPropagation()
            if (index === 0) return
            await changeTemplateSequence(index, 'up')
          }}
        >
          <icons.ArrowUpDrop stroke={2} size="large" />
        </div>

        <div
          className="h-[15px] overflow-hidden cursor-pointer text-zoyya-secondaryDark hover:text-zoyya-text"
          onClick={async e => {
            e.stopPropagation()
            if (index === len - 1) return
            await changeTemplateSequence(index, 'down')
          }}
        >
          <icons.ArrowDownDrop
            size="large"
            stroke={2}
            style={{ position: 'relative', top: '-9px' }}
          />
        </div>
      </div>

      <div className="flex h-full flex-1 flex-col ">
        <div className="flex justify-between gap-2">
          <p className="line-clamp-1 font-medium mr-2">{item?.name}</p>
          <p className="text-sm  shrink-0">
            {item?.voucherType === 'GIFTCARD' ? 'Poklon bon' : 'Paket usluga'}
          </p>
        </div>

        <div className="flex mt-1 items-center">
          <div className="h-[50px] w-[80px] mr-3 shrink-0">
            {imageConfig?.fullImageId ? (
              <img
                className="h-[50px] w-fit"
                src={`${
                  import.meta.env.VITE_API_URL
                }/api/giftCardTemplate/${item?.id}/${imageConfig.fullImageId}`}
              ></img>
            ) : (
              <img
                className="h-[50px] relative top-[7px] w-[80px] object-cover"
                src={'/assets/noImage.svg'}
              ></img>
            )}
          </div>
          <div>
            {item?.items?.length ? (
              <p className="line-clamp-2 text-md">
                {`${item?.items?.length} ${
                  [2, 3, 4].includes(item?.items?.length) ? 'usluge' : 'usluga'
                } `}
              </p>
            ) : (
              'Sve usluge'
            )}
            <div className="flex text-sm items-center gap-2 mt-1">
              <p className="font-medium">{item?.price + ' ' + currency}</p>
              {+item?.discountRate !== 0 ? (
                <div className="flex items-center">
                  (
                  <span className="text-secondary-500/40 line-through ">
                    {item?.value + ' ' + currency}
                  </span>
                  <span className="rounded ml-2 text-[10px] font-semibold text-zoyya-accent5">
                    {`-${Math.round(+item?.discountRate * 100)}%`}
                  </span>
                  )
                </div>
              ) : null}
            </div>
            <div className="flex text-sm items-baseline gap-2 mt-1 text-gray-500">
              {/* <p className=" font-medium">{`${formatPriceNumberWithDelimiter(
                item?.price
              )} ${currency || 'EUR'}`}</p> */}

              {/* <span>•</span> */}
              <p className="text-center">
                {`${item?.validAmount} ${
                  getValidIteratorsOptions()?.find(
                    x => x?.id === item?.validIterator
                  )?.name || ''
                }`}
              </p>

              {item?.onlinePurchaseEnabled ? (
                <>
                  <span>•</span>
                  <span> Online prodaja</span>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateList
