import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormField } from '../../../components'
import { useSdk } from '../../../sdk'
import { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import { WorkHourRow } from '../../AppointmentModal/styles'
import transparentize from 'polished/lib/color/transparentize'
import { ThemeContext } from 'styled-components'
import { icons } from 'components/Icon'
import { formatPriceNumberWithDelimiter, resolveFullName } from 'src/helpers'
import { RouterOutput } from 'src/trpc'
import { format } from 'date-fns'
import { useFormikContext } from 'formik'

type GiftCardSelectProps = {
  name: string
  options: {
    label: string
    options: RouterOutput['giftCard_appointment_findMany']
  }[]
  isDisabled: boolean
  index?: any
  label?: string
  hideLabel?: boolean
  hideChevron?: boolean
  hideSeparator?: boolean
  isClearable?: boolean
  serviceId: string
  currency?: string
  allGiftCards?: RouterOutput['giftCard_findOne'][]
  filterGiftCardOptions: any
}

export const GiftCardSelect = (props: GiftCardSelectProps) => {
  const {
    options,
    index,
    isDisabled,
    name,
    hideLabel,
    hideChevron,
    hideSeparator,
    label,
    isClearable = true,
    serviceId,
    currency,
    allGiftCards,
    filterGiftCardOptions,
  } = props
  const { t } = useSdk()
  const formikContext = useFormikContext()

  const IndicatorsContainer = props => {
    return (
      <div style={{ cursor: 'pointer' }}>
        <components.IndicatorsContainer {...props} />
      </div>
    )
  }

  const MobileOption = props => {
    const { t } = useTranslation()
    const ref = useRef()
    const isSelected = props.selectProps.value?.id === props.data?.id

    const balance =
      props.data?.giftCardType === 'GIFTCARD'
        ? `${formatPriceNumberWithDelimiter(props.data?.balance)} ${currency}`
        : props.data?.giftCardType === 'VOUCHER'
        ? props.data?.items?.find(
            item => item?.serviceId === BigInt(serviceId || 0)
          )?.quantity
        : props.data?.giftCardType === 'MEMBER_CARD'
        ? 0
        : 0

    useEffect(() => {
      // @ts-ignore
      props.isSelected && ref?.current && ref?.current.scrollIntoView()
    }, [props])

    return (
      <WorkHourRow>
        <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <icons.CheckMarkIcon color="grayDarker" />}
        </span>
        <div className="flex flex-col truncate grow">
          <span className="text-md text-zoyya-text">{props.data?.name}</span>

          <span className="text-sm text-zoyya-text">
            Šifra: {props.data?.code}
          </span>
          <span className="text-sm text-zoyya-secondaryDark">
            {props.data?.giftCardType === 'MEMBER_CARD'
              ? 'Članska kartica'
              : props.data?.giftCardType === 'GIFTCARD'
              ? 'Poklon bon'
              : props.data?.giftCardType === 'VOUCHER'
              ? 'Paket usluga'
              : ''}
          </span>
        </div>

        {props.data?.giftCardType === 'MEMBER_CARD' ? null : (
          <span className="text-sm text-right text-zoyya-text w-[100px]">{`${balance}`}</span>
        )}
      </WorkHourRow>
    )
  }
  const Option = props => {
    const ref = useRef()
    const isSelected = props.selectProps.value?.id === props.data?.id
    const [isScrolled, setIsScrolled] = useState<boolean>(false)

    const balance =
      props.data?.giftCardType === 'GIFTCARD'
        ? `${formatPriceNumberWithDelimiter(props.data?.balance)} ${currency}`
        : props.data?.giftCardType === 'VOUCHER'
        ? props.data?.items?.find(
            item => item?.serviceId === BigInt(serviceId || 0)
          )?.quantity
        : props.data?.giftCardType === 'MEMBER_CARD'
        ? 0
        : 0

    useEffect(() => {
      if (!isScrolled) {
        // @ts-ignore
        isSelected && ref?.current.scrollIntoView()
        setIsScrolled(true)
      }
    }, [props, isScrolled])

    return (
      <components.Option {...props} innerRef={ref}>
        <div className="flex items-center w-full">
          <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
            {isSelected && <icons.CheckMarkIcon color="grayDarker" />}
          </span>

          <div className="flex flex-col truncate grow">
            <span className="text-md text-zoyya-text">
              <span className="font-semibold">{props.data?.code}</span>
              {props.data.client ? (
                <>
                  <span className="mx-2">|</span>
                  <span className="">
                    {resolveFullName(props.data?.client)}
                  </span>
                </>
              ) : null}
            </span>
            <span className="text-sm text-zoyya-text">{props.data?.name}</span>
            <span className="text-sm text-zoyya-secondaryDark">
              {props.data?.giftCardType === 'MEMBER_CARD'
                ? 'Članska kartica'
                : props.data?.giftCardType === 'GIFTCARD'
                ? 'Poklon bon'
                : props.data?.giftCardType === 'VOUCHER'
                ? 'Paket usluga'
                : ''}
            </span>
          </div>

          <span className="flex flex-col text-sm text-right text-zoyya-text w-[100px]">
            {props.data?.giftCardType === 'MEMBER_CARD' ? '' : `${balance}`}
            {props.data?.validTo ? (
              <span>{format(props.data?.validTo, 'dd.MM.yyyy.')}</span>
            ) : null}
          </span>
        </div>
      </components.Option>
    )
  }
  const SingleValue = props => {
    return (
      <components.SingleValue {...props}>
        <div className="flex items-center justify-between">
          <span className="text-zoyya-text text-md truncate">
            {props.data?.name}
          </span>
          <span className="text-zoyya-text text-md truncate">
            {props.data?.code}
          </span>
        </div>
      </components.SingleValue>
    )
  }

  const selectComponents = {
    IndicatorsContainer,
    Option,
    MobileOption,
    SingleValue,
  }

  const selectedTheme = useContext(ThemeContext)
  const selectStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: 'pointer',
        fontWeight: isSelected ? 500 : 400,
        backgroundColor: isDisabled
          ? null
          : isFocused
          ? transparentize(0.9, selectedTheme.colors.primary)
          : null,
        ':active': {
          ...styles[':active'],
          backgroundColor: transparentize(0.9, selectedTheme.colors.primary),
        },
      }
    },
    menu: styles => ({
      ...styles,
      minWidth: '130px',
    }),
    singleValue: styles => ({
      ...styles,
      width: '100%',
    }),
  }

  return (
    <FormField.Select
      closeMenuOnSelect
      label={hideLabel ? undefined : 'Program vjernosti'}
      hideSelectedOptions={false}
      options={options}
      name={name || `services[${index}].giftCard`}
      styles={selectStyles}
      isClearable={isClearable}
      isDisabled={isDisabled}
      components={selectComponents}
      parseValue={val => {
        return allGiftCards?.find(e => e?.id === val?.id)
      }}
      hideChevron={hideChevron}
      hideSeparator={hideSeparator}
      placeholder={t('translation.Dropdown.label-select')}
      isSearchable={true}
      filterOption={filterGiftCardOptions}
      mobileClearValue={() => {
        formikContext.setFieldValue(`services[${index}].giftCard`, null)
        formikContext.setFieldValue(`services[${index}].giftCardId`, null)
        formikContext.setFieldValue(`services[${index}].giftCardAmount`, 0)
      }}
    />
  )
}
