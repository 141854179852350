import moment from 'moment'

export const routeBuilder = {
  root: params => `/`,
  settings: params => `/${params.orgId}/${params.locationId}/settings`,
  businessPartners: params =>
    `/${params.orgId}/${params.locationId}/businessPartners`,
  businessPartnersNew: params =>
    `/${params.orgId}/${params.locationId}/businessPartners/new`,
  businessPartnersEdit: params =>
    `/${params.orgId}/${params.locationId}/businessPartners/edit/${params.businessPartnerId}`,
  newService: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/services/serviceType/${params.typeId}/new`,
  editService: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/services/serviceType/${params.typeId}/edit/${params.id}`,
  editServiceType: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/services/serviceType/${params.typeId}/edit`,
  servicesList: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${
      params.locationId
    }/services/serviceType/${params.typeId || ''}`,
  newProduct: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/products/productType/${params.typeId}/new`,
  editProduct: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/products/productType/${params.typeId}/edit/${params.id}`,
  editProductType: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/products/productType/${params.typeId}/edit`,
  newProductType: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/products/productType/newProductType`,
  productsList: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${
      params.locationId
    }/products/productType/${params.typeId || ''}`,
  holidayList: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/holidays`,
  newLocation: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/new`,
  newProfession: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/professions/new/profession/newProfession`,
  editProfession: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/edit/professions/edit/profession/${params.id}`,
  locationsList: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations`,
  editLocation: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/edit`,
  dashboard: params => `/${params.orgId}/${params.locationId}/dashboard`,
  reports: params => `/${params.orgId}/${params.locationId}/reports`,
  reportsOverview: params =>
    `/${params.orgId}/${params.locationId}/reports/overview/${params.overviewType}/${params.dateFrom}`,
  reportsServices: params =>
    `/${params.orgId}/${params.locationId}/reports/overview/${params.dateFrom}/${params.dateTo}`,
  reportsPeriods: params =>
    `/${params.orgId}/${params.locationId}/reports/periods/${params.overviewType}/${params.dateFrom}/${params.dateTo}`,
  missedAppointmentsPeriods: params =>
    `/${params.orgId}/${params.locationId}/reports/missedAppointments/${params.dateFrom}/${params.dateTo}`,
  appointmentListPeriods: params =>
    `/${params.orgId}/${params.locationId}/reports/appointmentList/${params.dateFrom}/${params.dateTo}`,
  reviewsPeriods: params =>
    `/${params.orgId}/${params.locationId}/reports/reviews/${params.dateFrom}/${params.dateTo}`,
  reviewsPeriodsAppointment: params =>
    `/${params.orgId}/${params.locationId}/reports/reviews/${params.dateFrom}/${params.dateTo}/appointment/${params.resourceBookingId}`,
  cashRegister: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/reports/${params.reportId}?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`,
  cashRegisterBalance: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/reports/${params.reportId}?journalId=${params.journalId}`,
  cashRegisterRevenueByPaymentMethod: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/reports/${params.reportId}?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}&deviceId=${params.deviceId}`,
  cashRegisterMonthRecapitulation: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/reports/${params.reportId}?year=${params.year}&month=${params.month}&deviceId=${params.deviceId}`,
  cashRegisterPrepaymentsInPeriod: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/reports/${params.reportId}?year=${params.year}&month=${params.month}`,
  cashRegisterReceipt: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/reports/${params.reportId}?receiptCid=${params.receiptCid}`,
  cashRegisterSalesLedger: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/reports/${params.reportId}?year=${params.year}`,
  reportsVisits: params =>
    `/${params.orgId}/${params.locationId}/reports/visits/${params.employeeId}/${params.dayId}`,
  editOrganization: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/edit`,
  orgBilling: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/billing`,
  billingModal: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/billing/modal`,
  myReservations: () => `/client/*/myAppointments`,
  newClient: params =>
    `/${params.orgId}/${params.locationId}/clients/list/new/newClient`,
  clientsList: params => `/${params.orgId}/${params.locationId}/clients/list`,
  messagesList: params => `/${params.orgId}/${params.locationId}/messages/list`,
  professionsList: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/professions`,
  employeesList: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/employees/${params.selectedTab}`,
  newEmployee: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/employees/user/new/newUser`,
  resourcesList: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/resources/${params.selectedTab}`,
  newResource: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/resources/resource/new`,
  editResource: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/resources/resource/edit/${params.resourceId}`,
  newResourceType: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/resources/resourceType/new`,
  editResourceType: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/resources/resourceType/edit/${params.resourceTypeId}`,
  calendar: params =>
    `/${params.orgId}/${params.locationId}/events/day/${params.date}/calendar/${
      params.view
    }${params.selectedEmployeeId ? '/' + params.selectedEmployeeId : ''}`,
  reservations: params => `/${params.orgId}/${params.locationId}/rezervations`,
  calendarWithAppointment: params =>
    `/${params.orgId}/${params.locationId}/events/day/${params.date}/calendar/${params.view}/appointment/view/${params.appointmentViewId}`,
  editAppointment: params =>
    `/${params.orgId}/${params.locationId}/events/day/${params.date}/calendar/${params.view}/appointment/${params.appointmentId}`,
  editAppointmentInDay: params =>
    `/${params.orgId}/${params.locationId}/events/day/${params.date}/calendar/${params.view}/appointment/${params.appointmentId}/${params.selectedHour}/${params.selectedMinute}`,
  openGroupAppointment: params =>
    `/${params.orgId}/${params.locationId}/events/day/${params.date}/calendar/${params.view}/appointment/${params.appointmentId}/${params.selectedHour}/${params.selectedMinute}`,
  openAppointment: params =>
    `/${params.orgId}/${params.locationId}/events/day/${params.date}/calendar/${params.view}/newAppointment/${params.resourceId}/${params.selectedHour}/${params.selectedMinute}`,

  editBooking: params =>
    `/${params.orgId}/${params.locationId}/events/day/${params.date}/calendar/${params.view}/booking/${params.bookingId}`,
  editBookingInDay: params =>
    `/${params.orgId}/${params.locationId}/events/day/${params.date}/calendar/${params.view}/booking/${params.bookingId}/${params.selectedHour}/${params.selectedMinute}`,
  newHoliday: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/holidays/new`,
  editHoliday: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/holidays/edit/holiday/${params.id}`,
  // giftcards
  editGiftCard: params =>
    `/${params.orgId}/${params.locationId}/giftCardPackages/edit/${params.id}`,
  editTemplate: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/giftCardPackageTemplates/edit/${params.id}`,
  newTemplate: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/giftCardPackageTemplates/new/${params.type}`,
  newGiftCard: params =>
    `/${params.orgId}/${params.locationId}/giftCardPackages/new/${params.type}`,
  listGiftCard: params =>
    `/${params.orgId}/${params.locationId}/giftCardPackages/list`,
  listTemplates: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/giftCardPackageTemplates/list`,
  //loyalty
  editMemberCard: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/loyalty/edit/${params.id}`,
  editMemberCardTemplate: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/loyaltyTemplates/edit/${params.id}`,
  newMemberCardTemplate: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/loyaltyTemplates/new/member_card`,
  newMemberCard: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/loyalty/new/member_card`,
  listMemberCards: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/loyalty/list/member_card`,
  listMemberCardTemplates: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/loyaltyTemplates/list/member_card`,
  dailyCalendar: params =>
    `/${params.orgId}/${params.locationId}/events/day/${params.date}/calendar/${
      params.view || 'day'
    }/appointment/view/${params.reservationId}/${params.hour}/${params.minute}`,
  dailyCalendarAppointment: params =>
    `/${params.orgId}/${params.locationId}/events/day/${params.date}/calendar/day/${params.type}/${params.reservationId}/${params.hour}/${params.minute}`,
  bookingWizard: params =>
    `/client/${params.orgId}/${params.locationId}/day/${params.day}/wizard/${
      params.stepId
    }${params.employeeId ? '/' + params.employeeId : ''}${
      params.timeId ? '/' + params.timeId : ''
    }${params.inviteId ? '/' + params.inviteId : ''}`,
  myAccount: params =>
    `/${params.orgId}/${params.locationId}/settings/account/general`,
  myAccountSecurity: params =>
    `/${params.orgId}/${params.locationId}/settings/account/security`,
  myAccountContact: params =>
    `/${params.orgId}/${params.locationId}/settings/account/contact`,
  myLocations: () => `/client/*/myLocations`,
  selectedOrg: params => `/client/${params.orgId}`,
  selectedLocation: params => `/client/${params.orgId}/${params.locationId}`,
  partnerLogin: () => `/login`,
  partnerRegister: () => `/signup`,
  signupFlow: params =>
    `/signup/${params.orgId ? params.orgId + '/' : ''}${params.stepId}`,
  companyRegister: () => `/signup/partner`,
  newOrgRegister: () => `/client/*/register`,
  passwordReset: () => `/passwordReset`,
  myAccountClient: () => `/client/*/account`,
  mySecurityClient: () => `/client/*/account/security`,
  myContactClient: () => `/client/*/account/contact`,
  myAccountClientSecurity: () => `/client/*/account/security`,
  myAccountClientContact: () => `/client/*/account/contact`,
  myAccountClientSettings: () => `/client/*/account/accountSettings`,
  myOrganizationAccount: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/account/general`,
  myOrganizationSecurity: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/account/security`,
  myOrganizationContact: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/account/contact`,
  myOrganizationSettings: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/account/settings`,
  myOrganizationAccountSettings: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/account/accountSettings`,
  bookingSettings: () => `/client/*/settings`,
  myBooking: params =>
    `/client/${params.orgId}/${params.locationId}/appointment/${params.bookingId}`,
  workHours: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/workHours/${params.dayId}`,
  locationSettings: params => `/${params.orgId}/${params.locationId}/settings`,
  thankYou: () => `/thankYou`,
  orgLocations: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations`,
  onlineBooking: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/onlineBooking`,
  permissions: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/permissions`,
  clientDetails: params =>
    `/${params.orgId}/${params.locationId}/clients/details/${params.id}`,
  clientAttachments: params =>
    `/${params.orgId}/${params.locationId}/clients/details/${params.id}/attachments`,
  clientReminders: params =>
    `/${params.orgId}/${params.locationId}/clients/details/${params.id}/reminders`,
  clientGiftCards: params =>
    `/${params.orgId}/${params.locationId}/clients/details/${params.id}/giftCards`,
  clientProducts: params =>
    `/${params.orgId}/${params.locationId}/clients/details/${params.id}/products`,
  editClientReminder: params =>
    `/${params.orgId}/${params.locationId}/clients/details/${params.id}/reminders/edit/${params.reminderId}`,
  newClientReminder: params =>
    `/${params.orgId}/${params.locationId}/clients/details/${params.id}/reminders/new`,
  editClient: params =>
    `/${params.orgId}/${params.locationId}/clients/list/edit/${params.id}`,
  newServiceType: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/services/serviceType/newServiceType`,
  editEmployee: params =>
    `/${params.orgId}/${params.locationId}/organization/locations/${params.locationId}/employees/user/edit`,
  locationNotificationSettings: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/onlineBooking/notifications`,
  locationPrepaymentSettings: params => {
    return `/${params.orgId}/${
      params.locationId
    }/settings/organization/locations/${
      params.locationId
    }/onlineBooking/prepayments${params.isStripeProvider ? '/stripe' : ''}`
  },
  locationChangelog: params => {
    return `/${params.orgId}/${
      params.locationId
    }/settings/organization/locations/${params.locationId}/changelog/${
      params.dateFrom ? params.dateFrom : moment().format('YYYY-MM-DD')
    }/${params.dateTo ? params.dateTo : moment().format('YYYY-MM-DD')}`
  },
  cashRegisterSettingsDevices: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/settings/devices${
      params.deviceId ? '/' + params.deviceId : ''
    }`,
  cashRegisterSettingsOperators: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/settings/operators${
      params.operatorId ? '/' + params.operatorId : ''
    }`,
  cashRegisterReceiptList: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/receiptList`,
  cashRegisterJournalList: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/journalList`,
  posScreen: params =>
    `/${params.orgId}/${params.locationId}/cashRegister/journal`,
  goodsReceived: params =>
    `/${params.orgId}/${params.locationId}/wms/goodsReceived`,
  goodsReleased: params =>
    `/${params.orgId}/${params.locationId}/wms/goodsReleased`,
  goodsReport: params =>
    `/${params.orgId}/${params.locationId}/wms/report/${params.report}/${params.warehouseId}/${params.dateFrom}/${params.dateTo}`,
  productStockReport: params =>
    `/${params.orgId}/${params.locationId}/wms/goodsReceived/productStock/${params.warehouseId}/${params.productId}`,
  newGoodsReceived: params =>
    `/${params.orgId}/${params.locationId}/wms/goodsReceived/new`,
  newGoodsRemoved: params =>
    `/${params.orgId}/${params.locationId}/wms/goodsRemoved/new`,
  salesInPeriodReport: params =>
    `/${params.orgId}/${params.locationId}/wms/salesInPeriod/${params.warehouseId}/${params.dateFrom}/${params.dateTo}`,
  editGoodsReceived: params =>
    `/${params.orgId}/${params.locationId}/wms/goodsReceived/edit/${params.id}`,
  editGoodsReleased: params =>
    `/${params.orgId}/${params.locationId}/wms/goodsReleased/edit/${params.id}`,
  editGoodsRemoved: params =>
    `/${params.orgId}/${params.locationId}/wms/goodsRemoved/edit/${params.id}`,
  priceChangeReport: params =>
    `/${params.orgId}/${params.locationId}/wms/report/priceChange/${params.id}`,
  currentStock: params =>
    `/${params.orgId}/${params.locationId}/wms/currentStock/${params.warehouseId}`,
  mobileBranchSettings: params =>
    `/${params.orgId}/${params.locationId}/settings/branch`,
  mobileReportsMenu: params =>
    `/${params.orgId}/${params.locationId}/settings/reports`,
}
