import { ApolloClient } from '@apollo/client'
import gql from 'graphql-tag'
import { History } from 'history'
import { Toast } from './Toast'
// @ts-ignore
import { OrgBookingSettingsInput } from '../../../kiamki-server/src/schema/types'
import { Crisp } from 'crisp-sdk-web'

const loginMutationGql = gql`
  mutation loginUser($userName: String, $password: String) {
    user {
      login(input: { userName: $userName, password: $password }) {
        success
        payload {
          token
          user {
            id
            firstName
            lastName
            email
            address
            role
            gender
            phone
            avatarUrl
            userName
            nickName
            isEmailConfirmed
            isMobilePhoneConfirmed
          }
        }
      }
    }
  }
`

const sendConfirmEmail = gql`
  mutation sendConfirmEmail($id: String!) {
    user {
      sendConfirmEmail(input: { id: $id }) {
        success
        payload {
          token
          user {
            id
            firstName
          }
        }
      }
    }
  }
`

const mutateSignup = gql`
  mutation userRegister(
    $userName: String!
    $password: String!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
  ) {
    user {
      register(
        input: {
          userName: $userName
          password: $password
          firstName: $firstName
          lastName: $lastName
          email: $email
          phone: $phone
        }
      ) {
        success
        payload {
          token
          user {
            id
            role
            firstName
            lastName
            email
            phone
            nickName
            isEmailConfirmed
            isMobilePhoneConfirmed
          }
        }
      }
    }
  }
`

const confirmInviteGql = gql`
  mutation invitationConfirm(
    $id: ID
    $firstName: String
    $lastName: String
    $userName: String
    $password: String
    $email: String
    $address: String
    $phone: String
    $gender: String
    $language: String
  ) {
    invitation {
      confirm(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          userName: $userName
          password: $password
          address: $address
          phone: $phone
          email: $email
          gender: $gender
          language: $language
        }
      ) {
        status {
          success
          errorMessage
        }
        payload {
          token
          role
          user {
            id
            role
            lastName
            firstName
            isEmailConfirmed
            isMobilePhoneConfirmed
          }
        }
      }
    }
  }
`

const queryOrganization = gql`
  query organizationMy {
    organization {
      my {
        id
        orgName
        description
        mobilePhone
        language
        vatId
        address
        myRole
        orgFeatures {
          planId
          hasClients
          hasCustomTimeSlotDuration
          hasEmployees
          hasFreeSms
          hasHolidays
          hasInvitations
          hasLocations
          hasOrgUrlName
          hasProfessions
          hasReminders
          hasReporting
          hasResources
          hasSchedule
          hasPos
          hasOnlineBooking
        }
        settings {
          calendar {
            timeSlotDuration
          }
          booking {
            bookingEnabled
            bookingRequired
          }
          client {
            memberInviteRequired
          }
        }
        myLocations {
          id
          code
          name
        }
      }
    }
  }
`

const queryOrganizationUsers = gql`
  query organizationUsers($orgId: ID!) {
    organization {
      users(orgId: $orgId) {
        id
        firstName
        lastName
        userName
        email
        nickName
      }
    }
  }
`

export const getUserGql = gql`
  query userMe {
    user {
      me {
        id
        firstName
        language
        lastName
        avatarUrl
        google_profilePicUrl
        fb_profilePicUrl
        email
        phone
        address
        role
        gender
        userName
        nickName
        isEmailConfirmed
        isMobilePhoneConfirmed
        organizations {
          id
          orgName
          myRole
        }
      }
    }
  }
`

const getInvitation = gql`
  query invitationGetByHash($hash: String!) {
    invitation {
      getByHash(hash: $hash) {
        id
        orgId
        firstName
        lastName
        email
      }
    }
  }
`

const mutateOrganization = gql`
  mutation organizationCreate(
    $id: ID!
    $orgName: String!
    $bookingSettings: OrgBookingSettingsInput
    $currencyId: ID
    $countryId: ID
    $language: String
    $email: String
    $mobilePhone: String
    $planId: String
    $address: String
    $city: String
    $description: String
    $zipCode: String
    $file: Upload
    $businessCategories: [ID!]
  ) {
    organization {
      create(
        input: {
          id: $id
          orgName: $orgName
          bookingSettings: $bookingSettings
          currencyId: $currencyId
          countryId: $countryId
          language: $language
          email: $email
          mobilePhone: $mobilePhone
          planId: $planId
          address: $address
          city: $city
          zipCode: $zipCode
          description: $description
        }
        file: $file
        businessCategories: $businessCategories
      ) {
        payload {
          id
          orgName
        }
      }
    }
  }
`

export class Auth {
  _history: History

  /**Holds a reference to apollo client instance */
  _apolloClient: ApolloClient<any>

  /** True if user is signed in */
  _isSignedIn: Boolean

  /** Current signed on user */
  _user?

  /** Current signed on organization */
  _organization?

  /** Current invitation user */
  _invitation?

  _isInitialized: Boolean
  _toast: Toast
  constructor(apolloClient: ApolloClient<any>, history: History, toast: Toast) {
    this._apolloClient = apolloClient
    this._isSignedIn = false
    this._history = history
    this._isInitialized = false
    this._toast = toast
  }
  init = async () => {
    const isPublicPath =
      !this._history.location.pathname?.startsWith('/signup') &&
      !this._history.location.pathname?.startsWith('/invite') &&
      !this._history.location.pathname?.startsWith('/login') &&
      !this._history.location.pathname?.startsWith('/register') &&
      !this._history.location.pathname?.startsWith('/client')

    const isPublicClientPath =
      this._history.location.pathname?.startsWith('/client')
    try {
      const user = await this.getMyUser()
      this._isSignedIn = !!user
    } catch {
      this._isSignedIn = false
    }

    if (isPublicPath || isPublicClientPath) {
      try {
        const getUserResult = await this._apolloClient.query({
          query: getUserGql,
        })

        if (getUserResult.data) {
          this._isSignedIn = true
          this._user = getUserResult.data.user.me
          return await this.getMyOrgs()
        } else if (getUserResult.errors?.length) {
          this._isSignedIn = false
        }
      } catch (error) {
      } finally {
        this._isInitialized = true
      }
    }
  }
  /**Returns true if the user is signed in */
  isSignedIn = () => this._isSignedIn

  /**Returns the current signed on user data */
  user = () => this._user

  /**Sets the current signed on user data */
  setUser = userData => (this._user = userData)

  /**Returns the current signed on organization data */
  organization = () => this._organization

  /**Returns the invited user data */
  invitation = () => this._invitation

  signIn = async (credentials: { userName: string; password: string }) => {
    try {
      const loginResult = await this._apolloClient.mutate({
        mutation: loginMutationGql,
        variables: credentials,
      })
      if (loginResult.errors?.length) return

      this._isSignedIn = true
      this._user = loginResult.data.user.login.payload.user
      localStorage.setItem('token', loginResult.data.user.login.payload.token)

      return await this.getMyOrgs()
    } catch (error) {
      const message = error?.graphQLErrors?.length
        ? error.graphQLErrors[0].message
        : error.message
      this._toast.danger(message)
    }
  }
  externalSignIn = async token => {
    localStorage.setItem('token', token)
    const user = await this.getMyUser()
    this._isSignedIn = true
    return user
  }
  getInvitatonByHash = async hashId => {
    try {
      const inviteResults = await this._apolloClient.mutate({
        mutation: getInvitation,
        variables: { hash: hashId },
      })
      if (inviteResults.errors?.length) {
        this._toast.danger(inviteResults.errors[0].message)
        return
      }
      this._invitation = inviteResults.data.invitation.getByHash
      return this._invitation
    } catch (error) {
      const message = error?.graphQLErrors?.length
        ? error.graphQLErrors[0].message
        : error.message
      this._toast.danger(message)
    }
  }

  confirmInvitation = async (credentials: {
    id: String
    firstName: String
    lastName: String
    userName: String
    password: String
    email: String
    address: String
    phone: String
    gender: String
  }) => {
    try {
      const inviteResults = await this._apolloClient.mutate({
        mutation: confirmInviteGql,
        variables: credentials,
      })
      if (inviteResults.errors?.length) {
        this._toast.danger(inviteResults.errors[0].message)
        return { error: inviteResults.errors[0].message }
      }

      this._isSignedIn = true
      this._user = {
        ...inviteResults.data.invitation.confirm.payload.user,
        role: inviteResults.data.invitation.confirm.payload.role,
      }
      localStorage.setItem(
        'token',
        inviteResults.data.invitation.confirm.payload.token
      )
      return inviteResults
    } catch (error) {
      const message = error?.graphQLErrors?.length
        ? error.graphQLErrors[0].message
        : error.message
      this._toast.danger(message)
    }
  }

  signUp = async (credentials: {
    userName: String
    password: String
    email: String
    phone: String
    firstName: String
    lastName: String
    role: String
  }) => {
    try {
      const signupResults = await this._apolloClient.mutate({
        mutation: mutateSignup,
        variables: credentials,
      })
      if (signupResults.errors?.length) {
        this._toast.danger(signupResults.errors[0].message)
        return
      }

      this._isSignedIn = true
      this._user = signupResults.data.user.register.payload.user
      localStorage.setItem(
        'token',
        signupResults.data.user.register.payload.token
      )
      return signupResults.data.user.register.payload.user.id
    } catch (error) {
      const message = error?.graphQLErrors?.length
        ? error.graphQLErrors[0].message
        : error.message
      this._toast.danger(message)
    }
  }

  sendConfirmEmail = async (credentials: { id: String }) => {
    try {
      const confirmResults = await this._apolloClient.mutate({
        mutation: sendConfirmEmail,
        variables: credentials,
      })
      if (confirmResults.errors?.length) {
        this._toast.danger(confirmResults.errors[0].message)
        return
      }

      return true
    } catch (error) {
      const message = error?.graphQLErrors?.length
        ? error.graphQLErrors[0].message
        : error.message
      this._toast.danger(message)
    }
  }

  createMyOrg = async (
    credentials: {
      orgName: string
      id: string
      bookingSettings: OrgBookingSettingsInput
      currencyId: string
      countryId: string
      language: string
      planId: string
      address?: string
      email?: string
      mobilePhone?: string
      city?: string
      zipCode?: string
      description?: string
    },
    businessCategories: string[],
    file?: File,
    workHours?: any
  ) => {
    try {
      const orgResult = await this._apolloClient.mutate({
        mutation: mutateOrganization,
        variables: { ...credentials, file, businessCategories },
      })
      if (orgResult.errors?.length) {
        this._toast.danger(orgResult.errors[0].message)
        return
      }
      const orgId = orgResult.data.organization.create.payload.id
      localStorage.setItem('orgId', orgId)
      return orgId
    } catch (error) {
      const message = error?.graphQLErrors?.length
        ? error.graphQLErrors[0].message
        : error.message
      this._toast.danger(message)
    }
  }

  getMyOrgs = async () => {
    try {
      const orgResult = await this._apolloClient.query({
        query: queryOrganization,
        fetchPolicy: 'network-only',
      })
      if (orgResult.errors?.length) {
        return
      }
      return orgResult.data.organization.my
    } catch (error) {
      const message = error?.graphQLErrors?.length
        ? error.graphQLErrors[0].message
        : error.message
      this._toast.danger(message)
    }
  }

  getMyOrg = async orgId => {
    if (!orgId) return
    try {
      const orgResult = await this._apolloClient.query({
        query: queryOrganizationUsers,
        variables: { orgId },
        fetchPolicy: 'network-only',
      })
      this._organization = orgResult.data.organization
      localStorage.setItem('orgId', orgId)
    } catch (error) {
      const message = error?.graphQLErrors?.length
        ? error.graphQLErrors[0].message
        : error.message
      this._toast.danger(message)
    }
  }

  getMyUser = async () => {
    try {
      const getUserResult = await this._apolloClient.query({
        query: getUserGql,
        fetchPolicy: 'network-only',
      })
      if (getUserResult.data) {
        this._user = getUserResult.data.user.me
        return this._user
      }
    } catch (error) {
      const message = error?.graphQLErrors?.length
        ? error.graphQLErrors[0].message
        : error.message
      this._toast.danger(message)
    }
  }

  signOut = () => {
    Crisp.setTokenId()
    Crisp.session.reset()

    document.cookie = `zoyya-is-signedIn=; Path=/; domain=${getAuthDomain()}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    document.cookie = `zoyya-auth-token=; Path=/; domain=${getAuthDomain()}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    localStorage.removeItem('token')
    localStorage.removeItem('orgId')
    localStorage.removeItem('locationId')
    sessionStorage.removeItem('locationId') // was this left out intentionally?
    localStorage.removeItem('user')
    localStorage.removeItem('partnerOrgId')

    this._user = null
    this._isSignedIn = false
  }
}

export const getAuthToken = () => {
  const token = localStorage.getItem('token')

  const cookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('zoyya-auth-token='))

  if (!cookie) return token
  const cookieValue = cookie.split('=')[1]
  return cookieValue
}
export const getAuthDomain = () => {
  return import.meta.env.VITE_AUTH_DOMAIN || '.zoyya.com'
}
