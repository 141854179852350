import styled from 'styled-components'
import { fontFamily } from '../style/fonts'
import { device } from '../../constants'

export const ErrorContainer = styled.div`
  font-family: ${fontFamily};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: radial-gradient(circle closest-side, #f8f9ff, #fff);
  position: relative;
  text-align: center;
  padding: 0px 30px;
  height: 95vh;

  img {
    width: 500px;
  }

  @media ${device.tablet} {
    overflow-y: auto;
    overflow-x: hidden;
  }
`

export const ErrorTitle = styled.h1`
  font-weight: 500;
  color: #363c40;
  font-size: 1.714rem;
  font-weight: 700;
  margin-bottom: 1.429rem;
`

export const ErrorText = styled.p`
  color: #898989;
  font-size: 1.143rem;
  margin-bottom: 1.429rem;
  line-height: 26px;
`
