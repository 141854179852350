import React, { useState } from 'react'
import { useParams } from 'react-router'
import {
  resourceUpdateGql,
  resourceGetGql,
  inviteUserGql,
  getResourceLocationSettingsGql,
  updateResourceLocationSettingsGql,
  locationGetGql,
  resourceSearchGql,
} from 'state/gql'
import { EmployeeEditorForm } from 'views/Settings/Employees/EmployeeEditor/EmployeeEditorForm'
import { useMutation, useQuery } from '@apollo/client'
import { useSdk } from 'sdk'
import { formatPhone } from 'src/helpers'
import { deleteResourceGql } from 'state/gql'
import * as Yup from 'yup'
import {
  useProfessionAllQuery,
  useGetResourceFutureAppointmentsQuery,
  useDisableResourceMutation,
} from 'state/graphql'
import { queryLocation } from 'state/queries'

const EmployeeValidationSchema = t =>
  Yup.object().shape({
    firstName: Yup.string().required(
      t('translation.NewResource.validation-name-required')
    ),
    lastName: Yup.string().required(
      t('translation.NewResource.validation-last-name-required')
    ),
    role: Yup.string().required(
      t('translation.NewResource.validation-role-required')
    ),
    gender: Yup.string().required(
      t('translation.NewResource.validation-gender-required')
    ),
    email: Yup.string()
      .email(t('translation.NewClientContainer.validation-invalidEmail'))
      .nullable(),
  })

export const EditEmployee = ({ setOpenBillingModal, hasOnlineBooking }) => {
  let { id, selectedLocationId, orgId } = useParams<any>()
  const { data, loading } = useQuery(resourceGetGql, {
    variables: { id: id },
    skip: !id,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [blob, setBlob] = useState<string | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)

  const { data: professionsData } = useProfessionAllQuery()
  const [updateResourceMutation, { loading: loadingUpdateResource }] =
    useMutation(resourceUpdateGql)
  const [inviteUserMutation, { loading: loadingInviteUser }] =
    useMutation(inviteUserGql)
  const { data: futureAppointmentsData, loading: futureAppointmentsLoading } =
    useGetResourceFutureAppointmentsQuery({
      variables: {
        resourceId: id,
      },
    })
  const hasFutureAppointments =
    Number(futureAppointmentsData?.resource?.getFutureAppointments) > 0

  const { navigateTo, appServices, t } = useSdk()
  const resource = data?.resource?.get

  const inviteUser = async values => {
    const invitationInput = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: !values.email ? null : values?.email?.trim(),
      mobilePhone: formatPhone(values.mobilePhone),
      address: values.address,
      gender: values.gender,
      emailInvite: true,
      smsInvite: false,
      addToLocations: [],
      role: values.role,
      nickName: values.nickName,
      resourceId: resource?.id,
    }
    await inviteUserMutation({ variables: { input: invitationInput } })
    appServices.toast.success(
      t('translation.EditResource.toast-employee-invited', {
        firstName: values.firstName,
        lastName: values.lastName,
      })
    )
  }

  const bookingData = useQuery(getResourceLocationSettingsGql, {
    variables: { resourceId: id, locationId: selectedLocationId },
  })

  const [
    updateOnlineBookingSettingsMutation,
    { loading: loadingUpdateBooking },
  ] = useMutation(updateResourceLocationSettingsGql)

  const [deleteResourceMutation, { loading: loadingDeleteMutation }] =
    useMutation(deleteResourceGql)

  const handleDelete = async () => {
    const result = await deleteResourceMutation({
      variables: { id: id },
      refetchQueries: [
        { query: locationGetGql, variables: { id: selectedLocationId } },
        { query: resourceSearchGql },
      ],
      awaitRefetchQueries: true,
    })
    appServices
      .handleMutationResult(
        result,
        t('translation.EditResource.toast-employee-deleted')
      )
      .onSuccess(() =>
        navigateTo.employeesList({ selectedLocationId, selectedTab: 0 })
      )
  }

  const [disableEmployee, { loading: loadingDisableMutation }] =
    useDisableResourceMutation()

  const handleDisable = async () => {
    try {
      let result = await disableEmployee({
        variables: {
          input: {
            orgId: orgId,
            userId: resource?.user?.id,
            resourceId: resource?.id,
          },
        },
        refetchQueries: [
          {
            query: queryLocation,
            variables: { id: selectedLocationId },
          },
        ],
      })

      if (result) {
        appServices.toast.success(
          t('translation.EditResource.toast-employee-disabled')
        )
        navigateTo.employeesList({ selectedLocationId, selectedTab: 0 })
      }
    } catch (e) {
      console.log(e)
    }
  }

  if (loading) return null
  const onlineBooking =
    bookingData?.data?.resource?.getResourceLocationSettings?.onlineBooking

  const handleSubmitBookingChange = async values => {
    await updateOnlineBookingSettingsMutation({
      variables: {
        resourceId: id,
        locationId: selectedLocationId,
        onlineBooking: hasOnlineBooking ? values.onlineBooking : 'DISABLED',
      },
      refetchQueries: [
        {
          query: getResourceLocationSettingsGql,
          variables: { resourceId: id, locationId: selectedLocationId },
        },
        { query: locationGetGql, variables: { id: selectedLocationId } },
      ],
    })
  }

  const handleSubmit = async values => {
    try {
      setIsBusy(true)
      const input = {
        id: values.id,
        code: values.code || '*',
        name: `${values.firstName} ${values.lastName}`,
        firstName: values.firstName,
        lastName: values.lastName,
        email: !values.email ? null : values?.email?.trim(),
        mobilePhone: formatPhone(values.mobilePhone),
        color: values.color,
        address: values.address,
        gender: values.gender,
        role: values.role,
        nickName: values.nickName,
        services: values?.services?.map(e => e.id),
        showInCalendar: values.showInCalendar,
        receiveAppointmentEmails: values.receiveAppointmentEmails,
        professions: values?.professions
          ? values?.professions?.map(e => e.id)
          : [],
        description: values.description,
      }

      const result = await updateResourceMutation({
        variables: { input },
        refetchQueries: [{ query: resourceSearchGql }],
        awaitRefetchQueries: true,
      })
      if (values.sendInviteViaEMail) {
        inviteUser(values)
      }
      if (!result.errors) {
        if (blob) {
          const formData = new FormData()
          formData.append('locationId', selectedLocationId)
          formData.append('resourceId', values?.id)
          formData.append('file', blob)

          const response = await fetch(
            `${
              import.meta.env.VITE_API_URL
            }/api/upload/${orgId}/employeeAvatarUpload`,
            {
              method: 'POST',
              body: formData,
              credentials: 'include',
            }
          )
          const resultJson = await response.json()

          if (!response.ok || !resultJson?.success)
            appServices.toast.danger(
              t('translation.AppointmentAttachments.uploadWarning')
            )
        }
        await handleSubmitBookingChange(values)
        navigateTo.employeesList({ selectedLocationId, selectedTab: 0 })
        appServices.toast.success(
          t('translation.EditResource.toast-changes-saved', {
            firstName: values.firstName,
            lastName: values.lastName,
          })
        )
      } else {
        appServices.toast.danger(
          t('translation.EditResource.toast-changes-not-saved')
        )
      }
    } finally {
      setIsBusy(false)
    }
  }

  const professionsAll = professionsData?.profession?.all
  const professions = professionsAll?.filter(x => x?.kind === 'USER')

  return (
    <EmployeeEditorForm
      onSubmit={handleSubmit}
      initialValues={{
        ...resource,
        professions: resource?.professions?.filter(
          x => professions?.map(x => x?.id).includes(x?.id)
        ),
        onlineBooking,
      }}
      isEdit
      inviteUser={inviteUser}
      hasOnlineBooking={hasOnlineBooking}
      setOpenBillingModal={setOpenBillingModal}
      onDelete={handleDelete}
      onRemove={handleDisable}
      validationSchema={EmployeeValidationSchema(t)}
      hasFutureAppointments={hasFutureAppointments}
      setBlob={setBlob}
      previewUrl={previewUrl}
      setPreviewUrl={setPreviewUrl}
      loading={
        loadingUpdateBooking ||
        loadingInviteUser ||
        loadingUpdateResource ||
        loadingDeleteMutation ||
        futureAppointmentsLoading ||
        loadingDisableMutation ||
        isBusy
      }
    />
  )
}
export default EditEmployee
