import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { useInviteUser } from 'state/queries'
import { formatPhone, getRatingStars } from 'src/helpers'
import { useAppServices } from 'sdk/appServices'
import { useSdk } from 'sdk'
import { useParams } from 'react-router'
import { useHeader } from 'mobile/Header'
import styled from 'styled-components'
import {
  useClientGetAppointmentsFromResourceBookingsQuery,
  useClientGetQuery,
} from 'state/graphql'
import { orderBy } from 'lodash'
import { sumBy } from 'lodash'
import { useFooter } from 'mobile/Footer'
import { Loader } from 'components'
import { Avatar } from 'components'
import { EmptyList } from 'components/EmptyList/EmptyList'
import { icons } from 'components/Icon'
import { device } from '../../constants'
import { Badge } from 'src/styles'
import AppointmentCardGroupMobile from './AppointmentCardGroupMobile'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'

function useOutsideAlerter(ref, setIsMoreOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsMoreOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setIsMoreOpen])
}

const ClientDetailsGroupMobile = () => {
  const { id, orgId } = useParams<any>()
  const { t, navigateTo, useQuery } = useSdk()
  const loadOrg = useQuery(getOrganizationByIdGql, { variables: { id: orgId } })
  const { data: organizationData, loading: loadingOrg } = loadOrg
  const org = organizationData?.organization?.get
  const currency = org?.currency?.currencyCode

  const [isMoreOpen, setIsMoreOpen] = useState(false)
  const appServices = useAppServices()
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, setIsMoreOpen)
  const { data: clientData, loading: loadingClient } = useClientGetQuery({
    variables: {
      id,
    },
    skip: !id,
  })
  const client = clientData?.client?.get

  const { showPopup } = usePopup()

  useHeader({
    content: t('translation.ClientDetailsMobile.header-title'),
    onGoBack: navigateTo.clientsList,
  })

  useFooter(
    {
      showAddButton: true,
      /*  addButtonOptions: [
        {
          onClick: () => navigateTo.editClient({ id: id }),
          title: t('translation.ClientDetailsMobile.edit-label'),
          icon: <Edit size={'smaller'} />,
        },
        !client?.user?.id && client?.email
          ? {
              onClick: () => {
                if (client) {
                  setUserToSendInvite(client)
                }
              },
              title: t('translation.ClientDetailsMobile.sendInvite-label'),
              icon: <SendEmail size={'smaller'} />,
            }
          : null,
      ], */
    },
    [client]
  )

  const { data: appointmentsData, loading: loadingAppointments } =
    useClientGetAppointmentsFromResourceBookingsQuery({
      variables: { clientId: client?.id },
      skip: !client?.id,
    })

  /* // failed to fetch group appointments since they are represented with resource bookings
  const clientData = useClientGetAppointmentsQuery({
    variables: { clientId: client?.id },
    skip: !client?.id,
  }) */

  const allData = appointmentsData?.client?.getAppointmentsFromResourceBookings
  const sortedAllEvents = orderBy(allData, 'endTimeUtc', ['desc'])
  const futureEvents = orderBy(allData, ['date', 'startTime'], ['asc']).filter(
    x =>
      moment(x?.endTimeUtc).isAfter(moment().toDate()) &&
      x?.status !== 'canceled'
  )
  const sortedFutureEvents = orderBy(futureEvents, 'endTimeUtc', ['asc'])
  const sortedPastEvents = sortedAllEvents.filter(
    x =>
      moment(x?.endTimeUtc).isBefore(moment().toDate()) &&
      x?.status !== 'canceled'
  )
  const noShows = sortedAllEvents?.filter(x => x?.status === 'canceled')

  const [filter, setFilter] = useState('future')
  const [isInitialFilter, setIsInitialFilter] = useState(true)

  useEffect(() => {
    if (isInitialFilter) {
      setFilter(sortedFutureEvents.length > 0 ? 'future' : 'past')
    }
  }, [sortedFutureEvents])

  const [inviteUser] = useInviteUser()

  async function sendInvitation(values) {
    await inviteUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          gender: values.gender,
          mobilePhone: formatPhone(values.mobilePhone),
          role: 'CLIENT',
          clientId: values?.id,
        },
      },
    })
    appServices.toast.success(
      t('translation.EditClient.toast-employeeInvited', {
        firstName: values.firstName,
        lastName: values.lastName,
      })
    )
  }
  const allClientEvents = sortedAllEvents?.length - noShows?.length
  const reviewsTotalSum = sumBy(sortedAllEvents, 'reviewRating')
  const reviewsTotalCount = sortedAllEvents.filter(item => item?.reviewRating)
    ?.length
  const reviewNumber =
    reviewsTotalSum && sortedAllEvents[0]
      ? Math.round(reviewsTotalSum / reviewsTotalCount)
      : 0
  const totalBookingsPrice = allData
    ?.filter(item => item?.status !== 'canceled' && item?.status !== 'noShow')
    ?.map(item => item?.services?.reduce((n, { price }: any) => n + price, 0))
    ?.reduce((a: any, b: any) => a + b, 0)

  if (loadingAppointments || loadingClient || loadingOrg)
    return <Loader isComponent={false} />

  return (
    <>
      <ClientDetailsContainer>
        <ClientDetailsHeader>
          <ClientDetailsHeaderTop>
            <ClientDetailsAvatarContainer
              onClick={() => navigateTo.editClient({ id: id })}
            >
              {client?.user?.avatarUrl &&
              !client?.user?.avatarUrl?.endsWith('null') ? (
                <ClientsDetailsAvatar src={client?.user?.avatarUrl} />
              ) : !!client?.user?.google_profilePicUrl ? (
                <ClientsDetailsAvatar
                  src={client?.user?.google_profilePicUrl}
                />
              ) : !!client?.user?.fb_profilePicUrl ? (
                <ClientsDetailsAvatar src={client?.user?.fb_profilePicUrl} />
              ) : (
                <ClientsListNoAvatar background={'#c7beee'}>
                  {client?.firstName
                    ? client?.firstName?.charAt(0).toUpperCase() +
                      client?.lastName?.charAt(0).toUpperCase()
                    : 'NN'}
                </ClientsListNoAvatar>
              )}
            </ClientDetailsAvatarContainer>
            <ClientInfo>
              {client?.user?.id ? (
                <ClientZoyyaAvatar>
                  <Avatar src={'/zoyyaLetter.png'} alt={''} size="smaller" />
                </ClientZoyyaAvatar>
              ) : null}

              <ClientName>
                {`${client?.firstName} ${client?.lastName}`}
              </ClientName>
              <ClientReview>{getRatingStars(reviewNumber)}</ClientReview>
              {client?.status === 'BLOCKED' ? (
                <Badge style={{ marginLeft: '0', marginTop: '5px' }}>
                  {t('translation.ClientsList.label-blocked')}
                </Badge>
              ) : null}
            </ClientInfo>
          </ClientDetailsHeaderTop>
          <ClientDetailsHeaderBottom>
            <ActionButtons>
              <ActionButtonContainer
                style={
                  {
                    opacity: `${client?.mobilePhone ? 1 : 0.5}`,
                    pointerEvents: `${client?.mobilePhone ? 1 : 'none'}`,
                  } as any
                }
                href={'sms:' + client?.mobilePhone}
                target="_blank"
              >
                <ActionButtonIconContainer>
                  <icons.Sms size={'small'} />
                </ActionButtonIconContainer>
                <ActionButtonLabel>
                  {t('translation.ClientDetailsMobile.sms-label')}
                </ActionButtonLabel>
              </ActionButtonContainer>
              <ActionButtonContainer
                style={
                  {
                    opacity: `${client?.mobilePhone ? 1 : 0.5}`,
                    pointerEvents: `${client?.mobilePhone ? 1 : 'none'}`,
                  } as any
                }
                href={'tel:' + client?.mobilePhone}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ActionButtonIconContainer>
                  <icons.PhoneBold size={'small'} />
                </ActionButtonIconContainer>
                <ActionButtonLabel>
                  {t('translation.ClientDetailsMobile.call-label')}
                </ActionButtonLabel>
              </ActionButtonContainer>

              <ActionButtonContainer
                style={
                  {
                    opacity: `${client?.mobilePhone ? 1 : 0.5}`,
                    pointerEvents: `${client?.mobilePhone ? 1 : 'none'}`,
                  } as any
                }
                href={`https://wa.me/${client?.mobilePhone}`}
                target="_blank"
              >
                <ActionButtonIconContainer>
                  <icons.WhatsApp size={'small'} />
                </ActionButtonIconContainer>
                <ActionButtonLabel>WhatsApp</ActionButtonLabel>
              </ActionButtonContainer>

              <ActionButtonContainer
                style={
                  {
                    opacity: `${client?.email ? 1 : 0.5}`,
                    pointerEvents: `${client?.email ? 1 : 'none'}`,
                  } as any
                }
                href={'mailto:' + client?.email}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ActionButtonIconContainer>
                  <icons.Email size={'small'} />
                </ActionButtonIconContainer>
                <ActionButtonLabel>Email</ActionButtonLabel>
              </ActionButtonContainer>

              <ActionButtonContainer onClick={() => setIsMoreOpen(true)}>
                <ActionButtonIconContainer>
                  <icons.ThreeDotsVertical
                    size={'small'}
                    style={{ color: '#333', fill: '#333', strokeWidth: 1 }}
                  />
                </ActionButtonIconContainer>
                <ActionButtonLabel>
                  {t('translation.FooterNavigation.more')}
                </ActionButtonLabel>
              </ActionButtonContainer>
            </ActionButtons>
          </ClientDetailsHeaderBottom>
        </ClientDetailsHeader>
        <ClientDetailsOverview>
          <ClientDetailsSales>
            <ClientDetailsSalesNumber
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                {(totalBookingsPrice || '0') + ' ' + (currency ? currency : '')}
              </div>
              {client?.membershipExpirationDate ? (
                <div
                  style={{
                    color: moment().isAfter(
                      moment(client?.membershipExpirationDate)
                    )
                      ? '#fe9060'
                      : '#333',
                  }}
                >
                  {moment(client?.membershipExpirationDate).format(
                    'DD.MM.YYYY.'
                  )}
                </div>
              ) : null}
            </ClientDetailsSalesNumber>
            <ClientDetailsTextSmall
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>{t('translation.ClientDetailsMobile.total-sales')}</div>
              {client?.membershipExpirationDate ? (
                <div>
                  {t(
                    'translation.NewClientContainer.label-membershipExpirationDate'
                  )}
                </div>
              ) : null}
            </ClientDetailsTextSmall>
          </ClientDetailsSales>
          <ClientDetailsBookings>
            <ClientDetailsBookingsColumn>
              <ClientDetailsText>{sortedAllEvents.length}</ClientDetailsText>
              <ClientDetailsTextSmall>
                {t('translation.ClientDetailsMobile.total-bookings')}
              </ClientDetailsTextSmall>
            </ClientDetailsBookingsColumn>
            <ClientDetailsBookingsColumn>
              <ClientDetailsText>{allClientEvents}</ClientDetailsText>
              <ClientDetailsTextSmall>
                {t('translation.ClientDetails.arrivals')}
              </ClientDetailsTextSmall>
            </ClientDetailsBookingsColumn>
            <ClientDetailsBookingsColumn>
              <ClientDetailsText>{noShows.length}</ClientDetailsText>
              <ClientDetailsTextSmall>
                {t('translation.ClientDetails.noShows')}
              </ClientDetailsTextSmall>
            </ClientDetailsBookingsColumn>
          </ClientDetailsBookings>
        </ClientDetailsOverview>
        <AppointmentsTitle>
          {t('translation.NewClientContainer.title-appointments')}
        </AppointmentsTitle>

        <ButtonsWrapper>
          <AppointmentCircleButton
            isActive={filter === 'future'}
            onClick={() => {
              setIsInitialFilter(false)
              setFilter('future')
            }}
          >
            <MyClientDetailsTextSmall>
              {t('translation.ClientDetailsMobile.future-dates') +
                ': ' +
                sortedFutureEvents?.length}
            </MyClientDetailsTextSmall>
          </AppointmentCircleButton>
          <AppointmentCircleButton
            isActive={filter === 'past'}
            onClick={() => {
              setIsInitialFilter(false)
              setFilter('past')
            }}
          >
            <MyClientDetailsTextSmall>
              {t('translation.ClientDetailsMobile.past-dates') +
                ': ' +
                sortedPastEvents?.length}
            </MyClientDetailsTextSmall>
          </AppointmentCircleButton>
          <AppointmentCircleButton
            isOrange={filter === 'missed'}
            isActive={filter === 'missed'}
            onClick={() => {
              setIsInitialFilter(false)
              setFilter('missed')
            }}
          >
            <MyClientDetailsTextSmall>
              {t('translation.ClientDetailsMobile.missed-dates') +
                ': ' +
                noShows?.length}
            </MyClientDetailsTextSmall>
          </AppointmentCircleButton>
        </ButtonsWrapper>

        <ClientDetailsAppointments>
          <Appointments>
            {(filter === 'future' && !sortedFutureEvents.length) ||
            (filter === 'past' && !sortedPastEvents.length) ||
            (filter === 'missed' && !noShows.length) ? (
              <EmptyList
                title={
                  filter === 'future'
                    ? t(
                        'translation.RezervationsDesktop.title-no-future-appointments',
                        {
                          clientName:
                            client?.firstName + ' ' + client?.lastName,
                        }
                      )
                    : filter === 'past'
                    ? t(
                        'translation.RezervationsDesktop.title-no-past-appointments',
                        {
                          clientName:
                            client?.firstName + ' ' + client?.lastName,
                        }
                      )
                    : t(
                        'translation.RezervationsDesktop.title-no-missed-appointments',
                        {
                          clientName:
                            client?.firstName + ' ' + client?.lastName,
                        }
                      )
                }
              />
            ) : (
              <>
                {filter === 'future'
                  ? sortedFutureEvents?.map((event, idx) => (
                      <AppointmentCardGroupMobile
                        key={idx}
                        event={event}
                        org={org}
                      />
                    ))
                  : filter === 'past'
                  ? sortedPastEvents.map((event, idx) => (
                      <AppointmentCardGroupMobile
                        key={idx}
                        event={event}
                        org={org}
                      />
                    ))
                  : noShows.map((event, idx) => (
                      <AppointmentCardGroupMobile
                        key={idx}
                        event={event}
                        org={org}
                      />
                    ))}
              </>
            )}
          </Appointments>
        </ClientDetailsAppointments>
        {isMoreOpen ? (
          <MoreOptionsOverlay>
            <MoreOptions ref={wrapperRef}>
              <MoreOption onClick={() => navigateTo.editClient({ id: id })}>
                <IconWrapper>
                  <icons.Edit size={'smaller'} />
                </IconWrapper>
                <MoreOptionTitle>
                  {t('translation.ClientDetailsMobile.edit-label')}
                </MoreOptionTitle>
              </MoreOption>
              <MoreOption
                onClick={() => navigateTo.clientAttachments({ id: id })}
              >
                <IconWrapper>
                  <icons.Download size={'smaller'} />
                </IconWrapper>
                <MoreOptionTitle>
                  {t('translation.Appointment.privitci')}
                </MoreOptionTitle>
              </MoreOption>
              {!client?.user?.id && client?.email ? (
                <MoreOption
                  onClick={() => {
                    if (client) {
                      showPopup({
                        onConfirm: () => sendInvitation(client),
                        titleText: t(
                          'translation.ClientsList.title-confirmSend',
                          {
                            firstName: client?.firstName,
                            lastName: client?.lastName,
                          }
                        ),
                        contentText: t(
                          'translation.ClientsList.label-sendInviteInfo'
                        ),
                      })
                    }
                  }}
                >
                  <IconWrapper>
                    <icons.SendEmail size={'smaller'} />
                  </IconWrapper>
                  <MoreOptionTitle>
                    {t('translation.ClientDetailsMobile.sendInvite-label')}
                  </MoreOptionTitle>
                </MoreOption>
              ) : null}
              {/* <MoreOption
              onClick={() =>
                navigateTo.calendar({
                  date: getISODate(),
                  view: 'day',
                  selectedEmployeeId: 5,
                  clientId: client?.id,
                })
              }
              >
                <IconWrapper>
                  <Calendar size={'smaller'} />
                </IconWrapper>
                <MoreOptionTitle>Book an appointment</MoreOptionTitle>
              </MoreOption> */}
            </MoreOptions>
          </MoreOptionsOverlay>
        ) : null}
      </ClientDetailsContainer>
    </>
  )
}

export default ClientDetailsGroupMobile

export const PhoneRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

export const Appointments = styled.div``

export const ClientDetailsAppointments = styled.div`
  margin: 20px;
`
export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

export const ClientDetailsBookingsColumn = styled.div`
  display: flex;
  flex-direction: column;
  :not(:last-of-type) {
    margin-right: 30px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
  @media ${device.mobileS} {
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }
`

export const AppointmentCircleButton = styled.div<{
  isActive?: boolean
  isOrange?: boolean
}>`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  border-radius: 20px;
  background: ${props =>
    props.isActive
      ? props.isOrange
        ? props.theme.colors.accent5
        : props.theme.colors.primary
      : props.theme.colors.light};
  color: ${props =>
    props.isActive
      ? `${props.theme.colors.light}!important`
      : props.theme.colors.secondaryDark};
  border: 1px solid
    ${props => (props.isOrange ? props.theme.colors.accent5 : '#d1d1d1')};
  :nth-of-type(3n) {
    border: 1px solid ${props => props.theme.colors.accent5};
    color: ${props => props.theme.colors.accent5};
  }
`
export const MyClientDetailsTextSmall = styled.span`
  font-size: 12px;
`

export const ClientDetailsBookings = styled.div`
  display: flex;
`
export const ClientDetailsSalesNumber = styled.span`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
`
export const ClientDetailsTextSmall = styled.span`
  font-size: 12px;
  color: #555;
`
export const ClientDetailsText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
`
export const ClientDetailsSales = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`
export const ClientDetailsOverview = styled.div`
  margin: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #cecdcd;
`

export const ClientDetailsHeaderBottom = styled.div``
export const ClientDetailsHeaderRow = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`
export const Circle = styled.div`
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ClientDetailsHeaderTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
`

export const ClientDetailsHeader = styled.div`
  background: #f5f5f5;
  padding: 20px;
`
export const ClientDetailsContainer = styled.div`
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const ClientsListNoAvatar = styled.div<{ background?: string }>`
  width: 75px;
  height: 75px;
  border: 1px solid ${props => props.theme.colors.light};
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.143rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6a4ee1;
  background: #fff;
  flex-shrink: 0;
`

export const ClientsDetailsAvatar = styled.img`
  width: 75px;
  height: 75px;
  border: 1px solid ${props => props.theme.colors.light};
  border-radius: 4px;
  object-fit: cover;
  border-radius: 50px;
  flex-shrink: 0;
`
export const ClientInfo = styled.div`
  margin-left: 15px;
`

export const ClientName = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ClientReview = styled.div`
  font-size: 10px;
  color: #000;
  padding-left: 1px;
  margin-top: -10px;
`

export const ClientZoyyaAvatar = styled.div`
  position: absolute;
  left: 83px;
  bottom: -6px;
  width: fit-content;
  @media ${device.tablet} {
    bottom: 0px;
    left: 50px;
  }
`
export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ActionButtonContainer = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const ActionButtonIconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: ${props => props.theme.colors.light};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ActionButtonLabel = styled.p`
  font-size: 10px;
  color: #555;
  text-align: center;
  margin-top: 4px;
  font-weight: 500;
`

export const MoreOptions = styled.div`
  display: flex;
  flex-direction: column;
  /* width: calc(100% - 85px); */
  height: auto;
  position: absolute;
  top: 248px;
  /* right: 20px; */
  background-color: ${props => props.theme.colors.light};
  border-radius: 10px;
  box-shadow: 0px 1px 8px 0px #00000026;
  padding: 10px 10px;
`

export const MoreOptionsOverlay = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 9;
`
export const MoreOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
`

export const MoreOptionTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 25px;
  height: 25px;
`
export const ClientDetailsAvatarContainer = styled.div`
  height: 75px;
`
export const AppointmentsTitle = styled.div`
  padding: 0 20px;
  font-size: 24px;
  font-weight: 500;
`
