import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import moment from 'moment'
import { useSdk } from 'sdk'
import { useParams } from 'react-router'
import { Appointment as AppointmentModal } from 'views/Appointment/Appointment'
import { ShoppingCart } from 'components/Icon/Icons/ShoppingCart'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { getRatingStars, getStatusColor, getStatusLabel } from 'src/helpers'
import { TAppointmentCardProps } from '../AppointmentModal/components/AppointmentCard'

const AppointmentCardMobile = (props: TAppointmentCardProps) => {
  const {
    id,
    notes,
    status,
    date,
    startTime,
    endTime,
    reason,
    reviewRating,
    reviewComment,
    isOnlineBooking,
    services,
    isGroup,
  } = props

  const selectedTheme = useContext(ThemeContext)
  const { t, useQuery } = useSdk()
  const { orgId } = useParams<{ orgId: string }>()
  const [resourceBookingId, setResourceBookingId] = useState<string | null>(
    null
  )

  const loadOrg = useQuery(getOrganizationByIdGql, { variables: { id: orgId } })
  const { data: organizationData } = loadOrg
  const currency = organizationData?.organization?.get?.currency?.currencyCode

  return (
    <div
      className="p-[20px_0] flex items-center relative [&:not(:last-of-type)]:border-b-zoyya-outline [&:not(:last-of-type)]:border-b-[1px]"
      key={id}
      onClick={() => setResourceBookingId(id || null)}
    >
      <div className="flex flex-col mr-[15px] mt-[1px] font-medium self-baseline">
        <p className="text-[14px]">{moment(date).format('DD')}</p>
        <span className="text-[12px]">{moment(date).format('MMM')}</span>
        <span className="text-[12px]">{"'" + moment(date).format('YY')}</span>
      </div>

      <div className="flex flex-col w-full">
        {services.map(service => (
          <div
            className="flex w-full [&:not(:first-child)]:mt-[10px]"
            key={service.id}
          >
            <div className="flex flex-col flex-1 mr-[10px]">
              <p className="text-[14px] font-medium">
                {service?.service?.name}
              </p>

              <span className="text-[12px] leading-loose text-zoyya-text">
                {service?.startTime + ' - ' + service?.endTime} |{' '}
                <div
                  className="inline text-[11px] font-medium"
                  style={{
                    color:
                      reason === 'ORG_CANCELED' || reason === 'CLIENT_CANCELED'
                        ? selectedTheme.colors.accent1
                        : getStatusColor(status, selectedTheme),
                  }}
                >
                  {getStatusLabel(status, t, false, reason)}
                </div>
              </span>
              <span className="text-[12px] leading-loose text-zoyya-text">
                {isGroup
                  ? service?.durationMinutes +
                    ' min | ' +
                    service?.resource?.name
                  : service?.durationMinutes +
                    ' ' +
                    t('translation.ClientDetailsMobile.min-with') +
                    ' ' +
                    service?.employee?.name}
              </span>
            </div>

            <span
              className="text-[16px] font-medium leading-tight flex items-center"
              style={isOnlineBooking ? { marginTop: '25px' } : {}}
            >
              {!service?.service
                ? '0 ' + (currency ? currency : '')
                : service?.service?.price + ' ' + (currency ? currency : '')}
            </span>
          </div>
        ))}
        <div className="flex justify-between">
          {notes ? (
            <div className="text-zoyya-accent5 text-[12px] flex items-center mr-[5px]">
              {notes}
            </div>
          ) : (
            <div className="flex-grow" />
          )}
          {!!reviewRating
            ? getRatingStars(reviewRating, reviewComment || null)
            : null}
        </div>
      </div>

      {resourceBookingId ? (
        <AppointmentModal
          isDashboard={false}
          handleOnCloseEvent={() => setResourceBookingId(null)}
          eventId={resourceBookingId}
        />
      ) : null}

      {isOnlineBooking ? (
        <div className="absolute top-[20px] right-0">
          <ShoppingCart size="smaller" style={{ strokeWidth: 1.5 }} />
        </div>
      ) : null}
    </div>
  )
}

export default AppointmentCardMobile
