import * as Yup from 'yup'
import { useACL } from './acl'
import { t } from 'src/localization'
import { isValidNumber } from 'libphonenumber-js'
import { getServiceDurationInMinutes } from 'src/helpers'

export const useValidationSchema = (
  args: {
    serviceFieldNamePrefix?: string
    groupAppointmentFieldNamePrefix?: string
    clientFieldNamePrefix?: string
  } = {}
) => {
  const {
    serviceFieldNamePrefix,
    clientFieldNamePrefix,
    groupAppointmentFieldNamePrefix,
  } = args
  const { hasPermission } = useACL()

  const canAccessClientPhone = hasPermission('ACCESS_CLIENT_PHONE')
  const canAccessClientEmail = hasPermission('ACCESS_CLIENT_EMAIL')

  const ClientSchema = clientFieldNamePrefix
    ? Yup.object().shape({
        [clientFieldNamePrefix]: Yup.object().shape({
          firstName: Yup.string().required(
            t('translation.AppointmentModal.validation-firstNameRequired')
          ),
          //@ts-ignore
          email: canAccessClientEmail
            ? Yup.string()
                .email(
                  t('translation.NewClientContainer.validation-invalidEmail')
                )
                .nullable()
            : undefined,
          //@ts-ignore
          mobilePhone: canAccessClientPhone
            ? Yup.string()
                .nullable()
                .test(
                  'validNumber',
                  t(
                    'translation.NewClientContainer.validation-invalidPhoneNUmber'
                  ),
                  function (value) {
                    return !value ? true : isValidNumber(value)
                  }
                )
            : undefined,
        }),
      })
    : Yup.object().shape({
        firstName: Yup.string().required(
          t('translation.AppointmentModal.validation-firstNameRequired')
        ),
        //@ts-ignore
        email: canAccessClientEmail
          ? Yup.string()
              .email(
                t('translation.NewClientContainer.validation-invalidEmail')
              )
              .nullable()
          : undefined,
        //@ts-ignore
        mobilePhone: canAccessClientPhone
          ? Yup.string()
              .nullable()
              .test(
                'validNumber',
                t(
                  'translation.NewClientContainer.validation-invalidPhoneNUmber'
                ),
                function (value) {
                  return !value ? true : isValidNumber(value)
                }
              )
          : undefined,
      })

  const ServiceSchema = serviceFieldNamePrefix
    ? Yup.object().shape({
        [serviceFieldNamePrefix]: Yup.object().shape({
          name: Yup.string().required(
            t('translation.AppointmentModal.validation-nameRequired')
          ),
          type: Yup.object()
            .nullable()
            .required(
              t('translation.AppointmentModal.validation-service-type-required')
            ),
          durationMinutes: Yup.string().required(
            t('translation.AppointmentModal.validation-duration-required')
          ),
          netDurationMinutes: Yup.string()
            .required(
              t('translation.ServiceEditorForm.validation-duration-required')
            )
            .test(
              'validDurationMinutes',
              t('translation.ServiceEditorForm.validation-net-duration'),
              function (value) {
                const { parent } = this
                const durationMinutes = Number.isInteger(
                  Number(parent?.durationMinutes)
                )
                  ? Number(parent?.durationMinutes)
                  : getServiceDurationInMinutes(parent?.durationMinutes)

                return (
                  Number.isInteger(Number(value)) &&
                  Number(value) <= durationMinutes
                )
              }
            ),
          price: Yup.string().matches(
            /^-?(?:\d+)(?:(\.|,)\d+)?$/,
            t('translation.ServiceEditorForm.validation-numeric-data-only')
          ),
          vatRate: Yup.string().matches(
            /^-?(?:\d+)(?:(\.|,)\d+)?$/,
            t('translation.ServiceEditorForm.validation-numeric-data-only')
          ),
        }),
      })
    : Yup.object().shape({
        name: Yup.string().required(
          t('translation.AppointmentModal.validation-nameRequired')
        ),
        type: Yup.object()
          .nullable()
          .required(
            t('translation.AppointmentModal.validation-service-type-required')
          ),
        durationMinutes: Yup.string().required(
          t('translation.AppointmentModal.validation-duration-required')
        ),
        netDurationMinutes: Yup.string()
          .required(
            t('translation.ServiceEditorForm.validation-duration-required')
          )
          .test(
            'validDurationMinutes',
            t('translation.ServiceEditorForm.validation-net-duration'),
            function (value) {
              const { parent } = this
              const durationMinutes = Number.isInteger(
                Number(parent?.durationMinutes)
              )
                ? Number(parent?.durationMinutes)
                : getServiceDurationInMinutes(parent?.durationMinutes)

              return (
                Number.isInteger(Number(value)) &&
                Number(value) <= durationMinutes
              )
            }
          ),
        price: Yup.string().matches(
          /^-?(?:\d+)(?:(\.|,)\d+)?$/,
          t('translation.ServiceEditorForm.validation-numeric-data-only')
        ),
        vatRate: Yup.string().matches(
          /^-?(?:\d+)(?:(\.|,)\d+)?$/,
          t('translation.ServiceEditorForm.validation-numeric-data-only')
        ),
      })

  const GroupAppointmentSchema = groupAppointmentFieldNamePrefix
    ? Yup.object().shape({
        [groupAppointmentFieldNamePrefix]: Yup.object().shape({
          service: Yup.string().required('Usluga je obvezno polje'),
          resource: Yup.object().required('Resurs je obvezno polje').nullable(),
          capacity: Yup.string().matches(
            /^-?(?:\d+)(?:(\.|,)\d+)?$/,
            t('translation.ServiceEditorForm.validation-numeric-data-only')
          ),
        }),
      })
    : Yup.object().shape({
        service: Yup.string().required('Usluga je obvezno polje'),
        resource: Yup.object().required('Resurs je obvezno polje').nullable(),
        capacity: Yup.string().matches(
          /^-?(?:\d+)(?:(\.|,)\d+)?$/,
          t('translation.ServiceEditorForm.validation-numeric-data-only')
        ),
      })

  return { ClientSchema, GroupAppointmentSchema, ServiceSchema }
}
