import React from 'react'
import { useTranslation } from 'react-i18next'
import { GroupBookingSlot } from '../types'
import { getStatusColor } from 'src/helpers'
import { clsx } from 'clsx'
import { RouterOutput } from 'src/trpc'

export const GroupSlotStatusPresenter = (props: {
  slot: RouterOutput['groupBooking_find']['occupiedSlots'][0]
  className?: string
  onlyText?: boolean
}) => {
  const { slot } = props
  const { t } = useTranslation()

  const reason = slot?.reason
  const status =
    slot?.paymentStatus === 'PAID'
      ? 'PAID'
      : slot?.status !== 'DRAFT' && slot?.status !== 'CONFIRMED'
      ? slot?.status
      : slot?.posPaymentRequest?.id
      ? slot?.posPaymentRequest?.status
      : slot?.status

  const paymentType =
    slot?.paymentStatus === 'PAID'
      ? 'appointment'
      : slot?.posPaymentRequest?.id
      ? 'prepayment'
      : 'appointment'

  const statusLabel = () => {
    switch (status) {
      case 'CONFIRMED':
        return t('translation.status.confirmed')
      case 'new':
        return t('translation.status.new')
      case 'DRAFT':
        return t('translation.status.draft')
      case 'PENDING_PAYMENT':
        return t('translation.status.payment-pending')
      case 'PAID':
        return paymentType === 'appointment'
          ? 'Termin plaćen'
          : paymentType === 'prepayment'
          ? 'Predujam plaćen'
          : t('translation.status.paid')
      case 'CANCELED':
        return t('translation.status.payment-canceled')
      case 'REFUNDED':
        return 'Predujam refundiran'
      case 'canceled':
        return reason === 'CLIENT_NO_SHOW'
          ? t('translation.helpers.noShow')
          : reason === 'CLIENT_CANCELED'
          ? t('translation.helpers.clientCanceled')
          : t('translation.status.canceled')
      case 'concluded':
        return t('translation.helpers.concluded')
      default:
        return t('translation.status.new')
    }
  }

  const className = props.onlyText
    ? clsx(
        `flex flex-col  text-sm leading-[16px] items-center  justify-center  p-1 pb-0  rounded-sm`,
        { 'text-green-500': status === 'CONFIRMED' },
        { 'text-zoyya-primary': status === 'new' },
        { 'text-zoyya-accent5': status === 'DRAFT' },
        { 'text-zoyya-accent5': status === 'PENDING_PAYMENT' },
        { 'text-green-500': status === 'PAID' },
        { 'text-zoyya-accent1': status === 'CANCELED' },
        { 'text-zoyya-accent5': status === 'REFUNDED' },
        { 'text-zoyya-accent1': status === 'canceled' },
        { 'text-zoyya-accent1': status === 'noShow' },
        { 'text-zoyya-primary': status === 'concluded' },
        props.className ? props.className : undefined
      )
    : clsx(
        `flex flex-col  text-sm leading-[16px] items-center  justify-center  p-1 pb-0  rounded-sm`,
        { 'bg-green-500 text-white': status === 'CONFIRMED' },
        { 'bg-zoyya-primary text-white': status === 'new' },
        { 'bg-zoyya-accent5 text-white': status === 'DRAFT' },
        { 'bg-zoyya-accent5 text-white': status === 'PENDING_PAYMENT' },
        { 'bg-green-500 text-white': status === 'PAID' },
        { 'bg-zoyya-accent1 text-white': status === 'CANCELED' },
        { 'bg-zoyya-accent5 text-white': status === 'REFUNDED' },
        { 'bg-zoyya-accent1 text-white': status === 'canceled' },
        { 'bg-zoyya-accent1 text-white': status === 'noShow' },
        { 'bg-zoyya-primary text-white': status === 'concluded' },
        props.className ? props.className : undefined
      )

  return <div className={className}>{statusLabel()}</div>
}
