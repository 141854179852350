import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Modal,
  ModalWrapper,
  FormWrapper,
  StyledFieldRow,
  StyledFieldColumn,
} from 'views/Appointment/Appointment'
import {
  Button,
  FormFooterContainer,
  Loader,
  Form,
  FormField,
} from 'components'
import { Tab, TabView } from 'components/TabView/TabView'
import { HeaderTitle } from 'views/Appointment/components/Header'
import moment from 'moment'
import { Badge, Row } from 'src/styles'
import { icons } from 'components/Icon'
import { ThemeContext } from 'styled-components'
import {
  ControlContainer,
  ControlAvatar,
  ControlRow,
  ControlLabel,
  ControlColumn,
  ControlName,
} from 'views/Appointment/components/Client'
import {
  formatPriceNumberWithDelimiter,
  getFullReportSrc,
  getReceiptStatusLabel,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import {
  PosActiveJournalGetDocument,
  PosReceipt_SearchDocument,
  usePosOperatorsAllQuery,
  usePosReceiptFiscalizeUnfiscalizedReceiptHrMutation,
} from 'state/graphql'
import {
  printReceiptToPosPrinter,
  printPDF,
  usePosStore,
} from 'state/local/posReceiptStore'
import { assert } from 'sdk/assert'
import { withErrorHandler } from 'sdk/widthErrorHandler'
import { useGetMyOrganization } from 'state/queries'
import { useParams } from 'react-router'
import { PosReceiptStore } from 'state/local/posReceiptStore'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { useAppServices } from 'sdk/appServices'
import { ServiceRowWrapper } from './PosSettingsDevices'
import { MobileContext } from 'src/Context'
import { useFeatureFlags } from 'src/state/local/appStore'
import * as Yup from 'yup'
import { t } from 'src/localization'
import { usePaymentMethods } from 'src/sdk/helperHooks'
import { FormFooterButtonsContainer } from 'src/sdk/tw/Form'
import { trpc } from 'src/trpc'
import { useSdk } from 'src/sdk'
import { WarningTriangle } from 'src/components/Icon/Icons/WarningTriangle'
import * as JSPM from 'jsprintmanager'

const TipSchema = Yup.object().shape({
  /* tipAmount: Yup.string().matches(
    /^-?(?:\d+)(?:(\.|,)\d+)?$/,
    t('translation.ServiceEditorForm.validation-numeric-data-only')
  ), */
  tipAmount: Yup.string().test(
    'test-name',
    t('translation.ServiceEditorForm.validation-numeric-data-only'),
    (value): any => {
      if (!value) return true

      return value.match(/^-?(?:\d+)(?:(\.|,)\d+)?$/)
    }
  ),
})

type TReceiptDetailsModalProps = {
  selectedReceipt: TReceipt
  setSelectedReceipt: (arg0: TReceipt | null) => void
  setIssuedReceipt?: (arg0: TReceipt | null) => void
  setOpenNewReceiptModal?: (arg0: boolean) => void
  handleOnCloseEvent?: any
  setIssueState?: (arg0: { state: 'WAITING' | 'INPROGRESS' | 'ISSUED' }) => void
  isAppointment?: boolean
}

export type TReceipt = {
  amount: number
  appointmentId: string | null
  cid: string
  client: any
  clientId: string | null
  clientName: string | null
  createdAt: string
  currencyId: string
  date: string
  fisDeviceId: string | null
  fisJIR: string | null
  fisLocationId: string | null
  fisZKI: string | null
  id: string
  issuedByOperatorId: string
  items: any
  journalId: string
  journalNumber: string
  locationId: string
  operatorName: string
  orgId: string
  paymentMethod: string
  paymentRequestId: string | null
  paymentRequestOrderCode: string | null
  receiptComment: string | null
  receiptInvoicePdfUrl: string | null
  receiptNo: string
  receiptSequence: string
  receiptType: string
  resourceBookingId: string | null
  status: string
  totalAmount: number
  vatAmount: number
  fisQR: string | null
  tipAmount?: number
  tipPaymentMethod?: string
  tipFiscalized?: boolean
}

const ReceiptDetailsModal = (props: TReceiptDetailsModalProps) => {
  const {
    selectedReceipt,
    setSelectedReceipt,
    setOpenNewReceiptModal,
    handleOnCloseEvent,
    setIssueState,
    isAppointment,
    setIssuedReceipt,
  } = props
  const { orgId } = useParams<{ orgId: string }>()
  const { apolloClient } = useSdk()
  const { t } = useTranslation()
  const selectedDevice = usePosStore(state => state.pos.selectedPosDevice)
  const { initPosStoreReceipt, setReceiptResourceBookingId } = PosReceiptStore
  const isMobile = useContext(MobileContext)

  const paymentMethods = usePaymentMethods()

  const [tipEditFormOpen, setTipEditFormOpen] = React.useState(false)

  useEffect(() => {
    if (selectedReceipt) initPosStoreReceipt(selectedReceipt, false)
  }, [initPosStoreReceipt, selectedReceipt])

  const receipt = usePosStore(store => store.pos.receipt)

  const orgData = useGetMyOrganization()
  const org = orgData?.data?.organization?.my?.find(x => x.id === orgId)

  const appServices = useAppServices()
  const [fiscalizeMutation, { loading: fiscalizeMutationLoading }] =
    usePosReceiptFiscalizeUnfiscalizedReceiptHrMutation()

  const selectedTheme = useContext(ThemeContext)
  const formRef = useRef<any>()
  const client = selectedReceipt?.client

  const { data: posOperators, loading: loadingPosOperators } =
    usePosOperatorsAllQuery()

  const editTipMutation = trpc.posReceipt_editTip.useMutation()

  const featuresFlags = useFeatureFlags()
  const printClient = featuresFlags.printClient

  const [isPrinterInstalled, setIsPrinterInstalled] = useState<boolean>(false)

  const getPrinters = useCallback(async () => {
    try {
      if (printClient === 'neodynamicPrintService') {
        if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open)
          setIsPrinterInstalled(true)
      } else {
        const printResponse = await fetch(
          'http://localhost:7969/print/printers',
          {
            method: 'GET',
          }
        )
        const result:
          | { name: string; isDefault: boolean; status: string }[]
          | [] = await printResponse.json()

        setIsPrinterInstalled(true)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    getPrinters()
  }, [getPrinters])

  const handlePrintToPosPrinter = withErrorHandler(async () => {
    assert(selectedDevice, 'No device selected')
    assert(selectedReceipt.id, 'No receipt selected')

    const result =
      selectedDevice?.printerType === 'ROLL'
        ? await printReceiptToPosPrinter(selectedReceipt.id, printClient)
        : await printPDF(
            printClient,
            getFullReportSrc(`racun&receiptCid=${selectedReceipt?.cid}`, true),
            null,
            'receipt.pdf',
            true,
            'Račun je uspješno isprintan!'
          )
    result?.success
      ? appServices.toast.success(result?.text)
      : appServices.toast.danger(result?.text)
  })

  const handleFiscalizeReceipt = async () => {
    try {
      const res = await fiscalizeMutation({
        variables: {
          input: {
            receiptCid: selectedReceipt?.cid || '',
          },
        },
        refetchQueries: [
          {
            query: PosReceipt_SearchDocument,
            variables: {
              pageNumber: 1,
              pageSize: 50,
              searchTerm: '',
            },
          },
        ],
      })

      if (!res.errors) {
        setSelectedReceipt({
          ...selectedReceipt,
          tipFiscalized:
            res.data?.posReceipt_fiscalizeUnfiscalizedReceipt_hr
              ?.tipFiscalized || false,
          fisJIR:
            res.data?.posReceipt_fiscalizeUnfiscalizedReceipt_hr?.fisJIR ||
            null,
          fisQR:
            res.data?.posReceipt_fiscalizeUnfiscalizedReceipt_hr?.fisQr || null,
        })
        appServices.toast.success('Račun je fiskaliziran!')
      } else appServices.toast.danger(res.errors[0].message)
    } catch (e) {
      appServices.toast.danger('Dogodila se pogreška!')
    }
  }

  const handleEditTip = async values => {
    try {
      const res = await editTipMutation.mutateAsync({
        posReceiptCid: selectedReceipt?.cid,
        tipAmount: roundNumberWithTwoDecimals(
          parseFloat(values?.tipAmount?.toString()?.replace(',', '.') || '0')
        ),
        tipPaymentMethod: values?.tipPaymentMethod,
      })

      await apolloClient.query({
        query: PosReceipt_SearchDocument,
        variables: {
          pageNumber: 1,
          pageSize: 50,
          searchTerm: '',
        },
        fetchPolicy: 'network-only',
      })

      await apolloClient.query({
        query: PosActiveJournalGetDocument,
        variables: {
          input: {
            fisLocationId: selectedDevice?.fisLocationId as any,
            fisDeviceId: selectedDevice?.fisDeviceId as any,
          },
        },
        fetchPolicy: 'network-only',
      })

      setSelectedReceipt({
        ...selectedReceipt,
        tipAmount: res.tipAmount,
        tipPaymentMethod: res.tipPaymentMethod,
        tipFiscalized: res.tipFiscalized,
      })

      // for when receiptDetailsModal is opened from appointment modal issuing receipt screen
      setIssuedReceipt?.({
        ...selectedReceipt,
        tipAmount: res.tipAmount,
        tipPaymentMethod: res.tipPaymentMethod,
        tipFiscalized: res.tipFiscalized,
      })

      setTipEditFormOpen(false)
      appServices.toast.success('Napojnica uspješno ažurirana!')
    } catch (e: any) {
      appServices.toast.danger(e?.message || 'Dogodila se pogreška!')
      setTipEditFormOpen(false)
    }
  }

  return (
    <Container>
      <Modal>
        <ModalWrapper>
          <FormWrapper>
            {loadingPosOperators ? (
              <div style={{ minHeight: '500px' }}>
                <Loader isComponent />
              </div>
            ) : (
              <Form
                onValueChange={() => {}}
                initialValues={{
                  receiptComment: selectedReceipt?.receiptComment,
                }}
                isInitialValid={true}
                onSubmit={values => null}
              >
                {form => {
                  formRef.current = form
                  return (
                    <div className="h-full flex flex-col">
                      <TabView
                        selectedTabIndex={0}
                        setSelectedTabIndex={() => {}}
                        isSingleTab={false}
                        hasFooter={false}
                      >
                        <Tab
                          hasFooter={false}
                          title={t(
                            'translation.ReceiptDetailsModal.label-recipt-detail'
                          )}
                        >
                          <div className="p-[15px] lg:p-[20px] flex flex-col h-full">
                            <div className="pb-[15px] flex flex-col lg:flex-row  items-start justify-between border-b-[1px] border-zoyya-outline">
                              {/* {isBusy && <Loader isComponent />} */}
                              {/* @ts-ignore */}
                              <HeaderTitle isDisabled>
                                <span>
                                  {`${t(
                                    'translation.ReceiptDetailsModal.label-recipt-number'
                                  )} ${selectedReceipt?.receiptNo}`}
                                </span>
                              </HeaderTitle>
                              <div className="flex flex-row justify-between w-full lg:w-auto">
                                <HeaderTitle isDisabled>
                                  <span>
                                    {moment(selectedReceipt?.createdAt).format(
                                      t(
                                        'regionalFormats.date.momentFormatDateTime'
                                      )
                                    )}
                                  </span>
                                </HeaderTitle>
                                <p
                                  className={`pl-[14px] text-end font-medium ${
                                    selectedReceipt?.receiptType ===
                                      'CANCELLATION' ||
                                    selectedReceipt?.status === 'CANCELLED'
                                      ? 'text-zoyya-accent1'
                                      : 'text-zoyya-accent2'
                                  }`}
                                >
                                  {selectedReceipt?.status === 'CANCELLED'
                                    ? t(
                                        'translation.ReceiptListScreen.label-status-cancelled'
                                      )
                                    : selectedReceipt?.receiptType ===
                                      'CANCELLATION'
                                    ? t(
                                        'translation.ReceiptListScreen.label-status-storno'
                                      )
                                    : t(
                                        'translation.ReceiptListScreen.label-status-issued'
                                      )}
                                </p>
                              </div>
                            </div>

                            <ControlContainer
                              style={{ width: '100%', margin: '20px 0' }}
                              hasValue
                            >
                              {client?.user?.avatarUrl &&
                              !client?.user?.avatarUrl?.endsWith('null') ? (
                                <ControlAvatar
                                  image={client?.user?.avatarUrl}
                                  hasValue
                                />
                              ) : !!client?.user?.google_profilePicUrl ? (
                                <ControlAvatar
                                  image={client?.user?.google_profilePicUrl}
                                  hasValue
                                />
                              ) : !!client?.user?.fb_profilePicUrl ? (
                                <ControlAvatar
                                  image={client?.user?.fb_profilePicUrl}
                                  hasValue
                                />
                              ) : (
                                <ControlAvatar hasValue />
                              )}
                              <ControlRow style={{}}>
                                {!client ? (
                                  <ControlName
                                    style={{
                                      color: selectedTheme.colors.secondaryDark,
                                    }}
                                    hasValue
                                  >
                                    {t(
                                      'translation.ReceiptDetailsModal.label-no-client-selected'
                                    )}
                                  </ControlName>
                                ) : (
                                  <>
                                    <ControlColumn>
                                      <ControlRow>
                                        <ControlName hasValue>
                                          {client?.fullName}
                                        </ControlName>
                                        {client?.status === 'BLOCKED' ? (
                                          <Badge style={{ marginLeft: '10px' }}>
                                            {t(
                                              'translation.ClientsList.label-blocked'
                                            )}
                                          </Badge>
                                        ) : null}
                                      </ControlRow>
                                      <ControlLabel hasValue>
                                        {client?.email}
                                      </ControlLabel>
                                    </ControlColumn>

                                    <ControlColumn>
                                      <ControlRow>
                                        {client?.address ? (
                                          <ControlLabel
                                            hasValue
                                          >{`${client?.address}, ${client?.city}`}</ControlLabel>
                                        ) : null}
                                      </ControlRow>
                                      {client?.vatId ? (
                                        <ControlLabel hasValue>
                                          {client?.vatId}
                                        </ControlLabel>
                                      ) : null}
                                    </ControlColumn>
                                  </>
                                )}
                              </ControlRow>
                            </ControlContainer>

                            <ServiceRowWrapper style={{ padding: '0 10px' }}>
                              <div className="grid grid-cols-[60%_20%_auto] border-b-[1px] border-zoyya-light p-[5px_0] items-center text-zoyya-secondaryDark">
                                <span>
                                  {t(
                                    'translation.ReceiptDetailsModal.label-service-name'
                                  )}
                                </span>
                                <span className="text-center">
                                  {t(
                                    'translation.ReceiptDetailsModal.label-quantity'
                                  )}
                                </span>
                                <span style={{ textAlign: 'end' }}>
                                  {t(
                                    'translation.ReceiptListScreen.label-amount'
                                  )}
                                </span>
                              </div>
                              {selectedReceipt?.items?.map((x, idx) => (
                                <div
                                  className="grid grid-cols-[60%_20%_auto] border-b-[1px] border-zoyya-light p-[5px_0] items-center"
                                  key={idx}
                                >
                                  <div>
                                    <span>{x?.itemDescription}</span>
                                    {x?.paymentRequestId &&
                                    x?.paymentRequestReceiptNo ? (
                                      <span className="italic">{` (br: ${x?.paymentRequestReceiptNo})`}</span>
                                    ) : null}
                                  </div>
                                  <span className="text-center">{x?.qty}</span>
                                  <div className="flex flex-col text-end">
                                    <span>
                                      {`${formatPriceNumberWithDelimiter(
                                        x?.totalAmount
                                      )} ${selectedReceipt?.currencyId}`}
                                    </span>
                                    {x?.discountRate > 0 ||
                                    x?.discountAmount > 0 ? (
                                      <span
                                        className={`text-zoyya-secondaryDark text-[12px] line-through`}
                                      >
                                        {`${formatPriceNumberWithDelimiter(
                                          x?.price * x?.qty
                                        )} ${selectedReceipt?.currencyId}`}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                              <ControlRow
                                style={{
                                  margin: '10px 0',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <ControlColumn>
                                  {org?.vatStatus === 'Y' ? (
                                    <>
                                      <div className="grid grid-cols-[45px_80px_90px]">
                                        <span className="font-semibold">
                                          PDV
                                        </span>
                                        <span className="font-semibold justify-self-end">
                                          Osnovica
                                        </span>
                                        <span className="font-semibold justify-self-end">
                                          Iznos PDV
                                        </span>
                                      </div>

                                      {receipt?.vatItems?.map(vatItem => (
                                        <div
                                          key={vatItem.vatRate.toString()}
                                          className="grid grid-cols-[45px_80px_90px]"
                                        >
                                          <div>{vatItem?.vatRate * 100}% </div>
                                          <div className="text-right">
                                            {`${formatPriceNumberWithDelimiter(
                                              vatItem?.baseAmount < 0
                                                ? vatItem?.baseAmount * -1
                                                : vatItem?.baseAmount
                                            )} ${selectedReceipt?.currencyId}`}
                                          </div>
                                          <div className="text-right">
                                            {`${formatPriceNumberWithDelimiter(
                                              vatItem?.vatAmount < 0
                                                ? vatItem?.vatAmount * -1
                                                : vatItem?.vatAmount
                                            )} ${selectedReceipt?.currencyId}`}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  ) : null}
                                </ControlColumn>
                                {receipt?.paymentMethodDiscountAmount &&
                                receipt?.paymentMethodDiscountRate &&
                                receipt?.paymentMethodDiscountRate !== 0 ? (
                                  <>
                                    <div className="flex flex-col mr-auto">
                                      <span className="font-semibold text-end">
                                        {`${
                                          receipt?.paymentMethodDiscountRate < 0
                                            ? 'Naknada'
                                            : 'Popust'
                                        } - ${getReceiptStatusLabel(
                                          receipt?.paymentMethod!
                                        )}`}
                                      </span>
                                      <span className="text-right">
                                        {formatPriceNumberWithDelimiter(
                                          -receipt.paymentMethodDiscountRate *
                                            100
                                        ) + '%'}
                                      </span>
                                    </div>
                                  </>
                                ) : null}
                                <ControlColumn
                                  style={{ marginRight: '0', textAlign: 'end' }}
                                >
                                  <p className="font-semibold">
                                    {t(
                                      'translation.ReceiptDetailsModal.label-total'
                                    )}
                                  </p>
                                  <span>
                                    {`${formatPriceNumberWithDelimiter(
                                      selectedReceipt?.totalAmount
                                    )} ${selectedReceipt?.currencyId}`}
                                  </span>
                                </ControlColumn>
                              </ControlRow>
                            </ServiceRowWrapper>
                            <Row
                              style={{
                                justifyContent: 'flex-start',
                                marginBottom: '5px',
                              }}
                            >
                              <p className="font-semibold mr-[10px]">
                                Operater:
                              </p>
                              <span>
                                {selectedReceipt?.operatorName ||
                                  posOperators?.posOperators_all?.find(
                                    x =>
                                      x?.id ===
                                      selectedReceipt?.issuedByOperatorId
                                  )?.firstName}
                              </span>
                            </Row>

                            <Row
                              style={{
                                justifyContent: 'flex-start',
                                marginBottom: '5px',
                              }}
                            >
                              <p className="font-semibold mr-[10px]">
                                {t(
                                  'translation.ReceiptDetailsModal.label-payment-method'
                                )}
                              </p>
                              <span>
                                {getReceiptStatusLabel(
                                  selectedReceipt?.paymentMethod
                                )}
                              </span>
                            </Row>

                            <Row
                              style={{
                                justifyContent: 'flex-start',
                                marginBottom: '5px',
                              }}
                            >
                              <p className="font-semibold mr-[10px]">
                                {t('translation.ReceiptDetailsModal.label-JIR')}
                              </p>
                              <span>{selectedReceipt?.fisJIR}</span>
                            </Row>

                            <Row
                              style={{
                                justifyContent: 'flex-start',
                                marginBottom: '5px',
                              }}
                            >
                              <p className="font-semibold mr-[10px]">
                                {t('translation.ReceiptDetailsModal.label-ZKI')}
                              </p>
                              <span>{selectedReceipt?.fisZKI}</span>
                            </Row>

                            {!tipEditFormOpen ? (
                              <Row
                                style={{
                                  justifyContent: 'flex-start',
                                  marginBottom: '5px',
                                }}
                              >
                                <p className="font-semibold mr-[10px]">
                                  {'Napojnica:'}
                                </p>
                                <span>
                                  {`${formatPriceNumberWithDelimiter(
                                    selectedReceipt?.tipAmount
                                  )} ${selectedReceipt?.currencyId} ${
                                    selectedReceipt?.tipAmount
                                      ? `(${paymentMethods.find(
                                          x =>
                                            x?.id ===
                                            selectedReceipt?.tipPaymentMethod
                                        )?.name})`
                                      : ''
                                  }`}
                                </span>
                                {selectedReceipt?.receiptType === 'RECEIPT' &&
                                selectedReceipt?.status === 'ISSUED' &&
                                selectedReceipt?.fisJIR &&
                                !(
                                  selectedReceipt?.tipAmount &&
                                  !selectedReceipt?.tipFiscalized
                                ) ? (
                                  <span
                                    onClick={() => {
                                      setTipEditFormOpen(true)
                                    }}
                                    className="text-zoyya-primary bg-transparent hover:text-zoyya-primaryDark cursor-pointer ml-[10px]"
                                  >
                                    Promijeni
                                  </span>
                                ) : null}
                              </Row>
                            ) : null}

                            {selectedReceipt?.fisJIR &&
                            selectedReceipt?.tipAmount &&
                            !selectedReceipt?.tipFiscalized ? (
                              <Row
                                style={{
                                  justifyContent: 'flex-start',
                                  marginBottom: '5px',
                                }}
                              >
                                <WarningTriangle color="orange" size="small" />
                                <p className="mx-[10px]">
                                  {'Napojnica nije fiskalizirana'}
                                </p>

                                <span
                                  onClick={async () => {
                                    await handleEditTip({
                                      tipAmount: selectedReceipt?.tipAmount,
                                      tipPaymentMethod:
                                        selectedReceipt?.tipPaymentMethod,
                                    })
                                  }}
                                  className="text-zoyya-primary bg-transparent hover:text-zoyya-primaryDark cursor-pointer ml-[10px]"
                                >
                                  Fiskaliziraj
                                </span>
                              </Row>
                            ) : null}

                            {tipEditFormOpen ? (
                              <Form
                                initialValues={{
                                  tipAmount: selectedReceipt?.tipAmount || 0,
                                  tipPaymentMethod:
                                    selectedReceipt?.tipPaymentMethod ||
                                    selectedReceipt?.paymentMethod ||
                                    'CASH',
                                  tipCurrency: org?.currency?.id,
                                }}
                                validationSchema={TipSchema}
                                isInitialValid={true}
                                onSubmit={handleEditTip}
                              >
                                {form => {
                                  const { values, errors, setFieldValue } = form
                                  formRef.current = form
                                  return (
                                    <>
                                      <div className="flex lg-flex-col flex-row justify-between ">
                                        <div className="flex mr-2 flex-1">
                                          <FormField.Text
                                            label={'Napojnica'}
                                            name="tipAmount"
                                            shouldDisplayZeroAsValue
                                          />
                                          <div className="w-[50px] ml-1">
                                            <FormField.Text
                                              name="tipCurrency"
                                              label=" "
                                              disabled={true}
                                            />
                                          </div>
                                        </div>
                                        <div className="flex flex-1">
                                          <FormField.Select
                                            label={'Način plaćanja napojnice'}
                                            name="tipPaymentMethod"
                                            options={paymentMethods}
                                            parseValue={val =>
                                              paymentMethods.find(
                                                e => e?.id === val
                                              )
                                            }
                                            formatValue={val => val?.id}
                                            isSearchable={false}
                                            styles={{
                                              control: (provided, state) => ({
                                                ...provided,
                                                minHeight: '47px !important',
                                              }),
                                            }}
                                            isClearable={false}
                                            /* customChange={setTipPaymentMethod}
                                              isClearable={false}
                                              isDisabled={receiptCreateLoading} */
                                          />
                                        </div>
                                      </div>
                                      <FormFooterButtonsContainer className="!justify-end mt-2">
                                        <Button
                                          footerButton
                                          buttonType={'text'}
                                          name={'close'}
                                          type={'button'}
                                          label={'Odustani'}
                                          onClick={() => {
                                            setTipEditFormOpen(false)
                                          }}
                                        />
                                        <Button
                                          type={'submit'}
                                          name={'saveChanges'}
                                          buttonType={'primary'}
                                          label={t(
                                            'translation.NewClientContainer.button-save'
                                          )}
                                        />
                                      </FormFooterButtonsContainer>
                                    </>
                                  )
                                }}
                              </Form>
                            ) : null}
                            {!selectedReceipt?.fisJIR ? (
                              <Row className="mt-[10px]">
                                <ButtonTW
                                  variant={'primaryOutline'}
                                  size="medium"
                                  label={'Fiskaliziraj'}
                                  onClick={handleFiscalizeReceipt}
                                />
                              </Row>
                            ) : null}

                            {selectedReceipt?.receiptComment ? (
                              <Row
                                style={{
                                  justifyContent: 'flex-start',
                                  marginTop: '5px',
                                }}
                              >
                                <p className="font-semibold mr-[10px] text-zoyya-accent5">
                                  {t(
                                    'translation.ReceiptDetailsModal.label-comment'
                                  )}
                                </p>
                                <span>{selectedReceipt.receiptComment}</span>
                              </Row>
                            ) : null}
                          </div>

                          {!isPrinterInstalled ? (
                            <div className="p-[20px] flex flex-row">
                              <div className="flex flex-row items-center mr-[10px]">
                                <WarningTriangle color={'orange'} />
                                <p className="text-zoyya-secondaryDark">
                                  Podrška za ispis nije instalirana ili pisač
                                  nije spojen
                                </p>
                              </div>
                              <Button
                                onClick={e => {
                                  e.preventDefault()
                                  JSPM.JSPrintManager.start()
                                    .then(() => {
                                      getPrinters()
                                    })
                                    .catch(error => {
                                      console.error(
                                        'JPM failed to start',
                                        error
                                      )
                                    })
                                }}
                                buttonType={'primaryAccent'}
                                size={'medium'}
                                label="Osvježi"
                                iconComponent={
                                  <icons.Refresh size={'smaller'} />
                                }
                                name={'downloadBalance'}
                              />
                            </div>
                          ) : null}

                          {/* MOBILE */}
                          {isMobile ? (
                            <StyledFieldRow
                              style={{
                                padding: '15px',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              <StyledFieldColumn
                                style={{ marginBottom: '8px' }}
                              >
                                <Button
                                  onClick={handlePrintToPosPrinter}
                                  buttonType={'secondaryLink'}
                                  size={'large'}
                                  fullWidth
                                  label="Ispis"
                                  disabled={!isPrinterInstalled}
                                  iconComponent={
                                    <icons.Print size={'smaller'} />
                                  }
                                  name={'printBalance'}
                                />
                              </StyledFieldColumn>
                              {selectedReceipt?.status === 'CANCELLED' ||
                              selectedReceipt?.receiptType ===
                                'CANCELLATION' ? null : (
                                <StyledFieldColumn
                                  style={{ marginBottom: '8px' }}
                                >
                                  <Button
                                    onClick={e => {
                                      e.preventDefault()
                                      initPosStoreReceipt(selectedReceipt, true)

                                      if (selectedReceipt?.resourceBookingId) {
                                        setReceiptResourceBookingId(
                                          selectedReceipt?.resourceBookingId
                                        )
                                      }

                                      if (isAppointment) {
                                        setSelectedReceipt(selectedReceipt)
                                        setIssueState?.({ state: 'WAITING' })
                                      } else {
                                        setOpenNewReceiptModal?.(true)
                                      }
                                    }}
                                    buttonType={'primaryAccent'}
                                    size={'large'}
                                    fullWidth
                                    label="Storniraj"
                                    iconComponent={
                                      <icons.Cancellation size={'smaller'} />
                                    }
                                    name={'downloadBalance'}
                                  />
                                </StyledFieldColumn>
                              )}
                              <StyledFieldColumn
                                style={{ marginBottom: '8px' }}
                              >
                                <Button
                                  onClick={e => {
                                    e.preventDefault()

                                    window.open(
                                      selectedReceipt?.receiptInvoicePdfUrl
                                        ? selectedReceipt?.receiptInvoicePdfUrl
                                        : getFullReportSrc(
                                            `racun&receiptCid=${selectedReceipt?.cid}`
                                          ),
                                      '_blank'
                                    )
                                  }}
                                  buttonType={'secondaryLink'}
                                  size={'large'}
                                  fullWidth
                                  label="Preuzmi"
                                  iconComponent={<icons.PDF size={'smaller'} />}
                                  name={'cashRegisterRevenue'}
                                />
                              </StyledFieldColumn>
                            </StyledFieldRow>
                          ) : null}
                          {/* END MOBILE */}
                        </Tab>
                      </TabView>
                    </div>
                  )
                }}
              </Form>
            )}
          </FormWrapper>

          <FormFooterContainer
            hasShadow
            style={{ alignItems: 'center', gap: '7px' }}
          >
            {!isMobile ? (
              <>
                <Button
                  onClick={handlePrintToPosPrinter}
                  buttonType={'secondaryLink'}
                  size={'medium'}
                  label="Ispis"
                  disabled={!isPrinterInstalled}
                  iconComponent={<icons.Print size={'smaller'} />}
                  name={'printBalance'}
                />
                {selectedReceipt?.status === 'CANCELLED' ||
                selectedReceipt?.receiptType === 'CANCELLATION' ? null : (
                  <Button
                    onClick={e => {
                      e.preventDefault()
                      initPosStoreReceipt(selectedReceipt, true)
                      if (selectedReceipt?.resourceBookingId) {
                        setReceiptResourceBookingId(
                          selectedReceipt?.resourceBookingId
                        )
                      }

                      if (isAppointment) {
                        setSelectedReceipt(selectedReceipt)
                        setIssueState?.({ state: 'WAITING' })
                      } else setOpenNewReceiptModal?.(true)
                    }}
                    buttonType={'primaryAccent'}
                    size={'medium'}
                    label="Storniraj"
                    iconComponent={<icons.Cancellation size={'smaller'} />}
                    name={'downloadBalance'}
                  />
                )}

                <Button
                  onClick={e => {
                    e.preventDefault()

                    window.open(
                      selectedReceipt?.receiptInvoicePdfUrl
                        ? selectedReceipt?.receiptInvoicePdfUrl
                        : getFullReportSrc(
                            `racun&receiptCid=${selectedReceipt?.cid}`
                          ),
                      '_blank'
                    )
                  }}
                  buttonType={'secondaryLink'}
                  size={'medium'}
                  label="Preuzmi"
                  iconComponent={<icons.PDF size={'smaller'} />}
                  name={'cashRegisterRevenue'}
                />

                <Button
                  buttonType={'primary'}
                  name={'close'}
                  label={t('translation.ReceiptDetailsModal.label-close')}
                  footerButton
                  onClick={() => {
                    handleOnCloseEvent && handleOnCloseEvent()
                    setSelectedReceipt(null)
                  }}
                  style={{ marginLeft: 'auto' }}
                />
              </>
            ) : (
              <Button
                buttonType={'primary'}
                name={'close'}
                label={t('translation.ReceiptDetailsModal.label-close')}
                footerButton
                onClick={() => {
                  handleOnCloseEvent && handleOnCloseEvent()
                  setSelectedReceipt(null)
                }}
                style={{ margin: '0' }}
              />
            )}
          </FormFooterContainer>
        </ModalWrapper>
      </Modal>
    </Container>
  )
}

export default ReceiptDetailsModal
