import React, { useEffect } from 'react'

const HeadwayWidget = () => {
  useEffect(() => {
    let script
    const config = {
      account: '7ONWdx',
      selector: '#headway_badge',
      translations: {
        title: 'Najnovije izmjene',
        readMore: 'Pročitaj više',
        labels: {
          new: 'Novosti',
          improvement: 'Ažuriranja',
          fix: 'Popravci',
        },
        footer: 'Pročitaj više 👉',
      },
    }

    if (document) {
      script = document.createElement('script')
      script.async = true
      script.src = 'https://cdn.headwayapp.co/widget.js'
      document.head.appendChild(script)

      script.onload = function () {
        window.Headway.init(config)
      }
    }
  }, [])

  return <div className="hidden" id="headway_badge"></div>
}

export default HeadwayWidget
