import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { formatPriceNumberWithDelimiter } from 'src/helpers'
import { useSdk } from 'src/sdk'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { ClientSelect } from 'src/sdk/tw/components/ClientSelect'
import { FieldColumn, FieldRow } from 'src/sdk/tw/Form'
import {
  GetOrganizationByIdQuery,
  GetServiceTypesQuery,
  LocationGetQuery,
} from 'src/state/graphql'
import { Row } from 'src/styles'
import { PopupContainer } from 'src/views/CashRegister/NewReceipt/styles'
import { ThemeContext } from 'styled-components'
import { FormField, ToolTip } from '../../../components'

type TServiceTypes = NonNullable<
  NonNullable<GetServiceTypesQuery['serviceType']>
>['search']

type TGroupAddClientFormFields = {
  form: FormikSharedConfig<{}> &
    FormikState<any> &
    FormikHelpers<any> &
    FormikHandlers &
    FormikComputedProps<any> &
    FormikRegistration & { submitForm: () => Promise<any> }
  onNewClient: () => void
  onClose: () => void
  onAdd: () => void
  isPrepaymentDisabled: boolean
  className?: string
  service?:
    | NonNullable<NonNullable<NonNullable<TServiceTypes>[0]>['services']>[0]
    | null
  location: NonNullable<NonNullable<LocationGetQuery['location']>>['get']
  org: NonNullable<NonNullable<GetOrganizationByIdQuery['organization']>>['get']
  smsBalance: number
}

export const GroupAddClientFormFields = (props: TGroupAddClientFormFields) => {
  const {
    form,
    onNewClient,
    className,
    onClose,
    onAdd,
    isPrepaymentDisabled,
    service,
    org,
    location,
    smsBalance,
  } = props
  const { values } = form
  const { isMobile } = useSdk()
  const selectedTheme = useContext(ThemeContext)

  const { t } = useTranslation()
  const popupRef = useRef<any>()

  const showSendNotificationEmailCheckBox =
    !!values.newClientSlot?.client?.email &&
    !values?.newClientSlot?.sendPaymentRequest
  const showSendNotificationSmsCheckBox =
    !!values.newClientSlot?.client?.mobilePhone &&
    !values?.newClientSlot?.sendPaymentRequest

  /*  useOnClickOutsideWithoutTriggeringAction(popupRef, () => {
    !isMobile && onClose()
  }) */

  return (
    <PopupContainer className="">
      <div
        className="lg:w-11/12 lg:h-4/6 h-[80dvh] w-[90dvw] bg-white rounded-lg flex flex-col overflow-auto p-4 "
        ref={popupRef}
      >
        <Row>
          <ClientSelect
            onItemSelected={client => {
              form.setFieldValue('newClientSlot.client', client)
              if (!isPrepaymentDisabled && (service?.totalAmount || 0) > 0) {
                form.setFieldValue(
                  'newClientSlot.sendPaymentRequest',
                  client?.paymentRequired
                )
              }

              form.setFieldValue(
                'newClientSlot.sendAppointmentEmail',
                location?.sendAppointmentEmailsToClients && !!client?.email
              )
              form.setFieldValue(
                'newClientSlot.sendAppointmentSms',
                location?.sendAppointmentSmsToClients && !!client?.mobilePhone
              )
            }}
            filterClients={client =>
              !form?.values?.clientSlots?.find(
                slot =>
                  client?.id === slot?.client?.id?.toString() &&
                  (slot?.resourceBookingId ? slot?.status !== 'canceled' : true)
              )
            }
            selectedItem={values.newClientSlot?.client}
            //disabled={values.status === 'canceled' || values.status === 'DRAFT'}
            onNewClient={onNewClient}
          />
        </Row>

        <div className="flex flex-col lg:flex-row mt-6">
          {values?.newClientSlot?.client?.email ? (
            <FieldRow style={{ marginTop: 0 }}>
              <FieldColumn>
                <FormField.Checkbox
                  label={t(
                    'translation.AppointmentModal.sendPaymentNotification'
                  )}
                  name="newClientSlot.sendPaymentRequest"
                  type={'checkbox'}
                  disabled={isPrepaymentDisabled}
                  helpText={t(
                    'translation.AppointmentModal.sendPaymentNotification-hint'
                  )}
                />
              </FieldColumn>
              {values?.newClientSlot?.sendPaymentRequest &&
              !isPrepaymentDisabled ? (
                <FieldColumn>
                  <FormField.Checkbox
                    label={t(
                      'translation.AppointmentModal.cancelBookingOnExpired'
                    )}
                    name="newClientSlot.cancelAppointmentOnExpiredPaymentRequest"
                    type={'checkbox'}
                    helpText={t(
                      'translation.AppointmentModal.cancelBookingOnExpiredTooltip'
                    )}
                  />
                </FieldColumn>
              ) : null}
            </FieldRow>
          ) : null}
        </div>
        {values?.newClientSlot?.sendPaymentRequest && service ? (
          <div className="flex flex-col mt-2 mb-6 gap-1">
            <div className="flex gap-2">
              <span className="font-medium w-[120px]">{'Cijena:'}</span>
              <span>{`${formatPriceNumberWithDelimiter(service?.price)} ${org
                ?.currency?.id}`}</span>
            </div>

            {service?.discountAmount! > 0 ? (
              <div className="flex gap-2">
                <span className="font-medium w-[120px]">{'Popust:'}</span>
                <span>{`${formatPriceNumberWithDelimiter(
                  service?.discountAmount
                )} ${org?.currency?.id}`}</span>
              </div>
            ) : null}
            <div className="flex gap-2">
              <span className="font-medium w-[120px]">{'Ukupno:'}</span>
              <span>{`${formatPriceNumberWithDelimiter(
                service?.totalAmount
              )} ${org?.currency?.id}`}</span>
            </div>
            <div className="flex gap-2">
              <span className="font-medium w-[120px]">{'Iznos predujma:'}</span>
              <span>{`${formatPriceNumberWithDelimiter(
                service?.totalAmount *
                  (1 -
                    Number(
                      location?.prepaymentsConfig?.advanceAmountPercentage
                    ) /
                      100)
              )} ${org?.currency?.id}`}</span>
              <span className="text-zoyya-secondaryDark">{`(${location?.prepaymentsConfig?.advanceAmountPercentage}%)`}</span>
            </div>
          </div>
        ) : null}
        <div className="flex flex-col lg:flex-row">
          {showSendNotificationEmailCheckBox ? (
            <FieldRow style={{ flex: 1 }}>
              <FieldColumn>
                <FormField.Checkbox
                  label={t('translation.UpdateStatusModal.sendClientEmail')}
                  name="newClientSlot.sendAppointmentEmail"
                  type={'checkbox'}
                  helpText={t('translation.UpdateStatusModal.sendEmailHintNew')}
                />
              </FieldColumn>
            </FieldRow>
          ) : null}

          {showSendNotificationSmsCheckBox ? (
            <FieldRow style={{ flex: 1 }}>
              <FieldColumn>
                {smsBalance > 0 ? (
                  <FormField.Checkbox
                    label={t('translation.UpdateStatusModal.sendClientSms')}
                    name="newClientSlot.sendAppointmentSms"
                    disabled={smsBalance <= 0}
                    type={'checkbox'}
                    helpText={t('translation.UpdateStatusModal.sendSmsHintNew')}
                  />
                ) : (
                  <ToolTip
                    label={t('translation.Appointment.no-sms-available')}
                  >
                    <FormField.Checkbox
                      label={t('translation.UpdateStatusModal.sentClientSms')}
                      name="newClientSlot.sendAppointmentSms"
                      disabled={smsBalance <= 0}
                      type={'checkbox'}
                      helpText={t(
                        'translation.UpdateStatusModal.sendSmsHintNew'
                      )}
                    />
                  </ToolTip>
                )}
              </FieldColumn>
            </FieldRow>
          ) : null}
        </div>
        <div
          style={{
            marginTop: 0,
            paddingTop: !isMobile ? 0 : 5,
          }}
        >
          <FormField.TextArea
            label={'Napomena'}
            placeholder={t(
              'translation.Appointment.vidljivo-samo-zaposlenicima'
            )}
            name="newClientSlot.internalNote"
            rows={2}
          />
        </div>

        {showSendNotificationEmailCheckBox &&
        values?.newClientSlot?.sendAppointmentEmail ? (
          <div
            style={{
              marginTop: !isMobile ? 10 : 5,
              paddingTop: !isMobile ? 0 : 5,
            }}
          >
            <FormField.TextArea
              label={t('translation.AppointmentModal.messageToClient')}
              placeholder={t(
                'translation.Appointment.vidljivo-klijentima-upis'
              )}
              name="newClientSlot.messageToClient"
              rows={2}
            />
          </div>
        ) : null}
        <div className="flex justify-end mt-auto">
          <div className="flex gap-2">
            <ButtonTW
              label={t('translation.AppointmentModal.button-cancelText')}
              variant="secondary"
              type="button"
              onClick={onClose}
            />

            <ButtonTW
              label={t('translation.AppointmentModal.button-okText')}
              variant="primary"
              type="button"
              disabled={!form?.values?.newClientSlot?.client?.id}
              onClick={() => {
                onAdd()
                onClose()
              }}
            />
          </div>
        </div>
      </div>
    </PopupContainer>
  )
}
