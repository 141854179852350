import { format } from 'date-fns'
import { hr } from 'date-fns/locale'
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { BsFillPersonFill } from 'react-icons/bs'
import { FiMail, FiPhone } from 'react-icons/fi'
import { LuClock4 } from 'react-icons/lu'
import { Add } from 'src/components/Icon/Icons/Add'
import { Edit } from 'src/components/Icon/Icons/Edit'
import { WarningTriangle } from 'src/components/Icon/Icons/WarningTriangle'
import { Tooltip2 } from 'src/components/Tooltip2/Tooltip2'
import {
  formatPriceNumberWithDelimiter,
  getFirstScrollableParent,
  getServiceDurationInMinutes,
  resolveFullName,
} from 'src/helpers'
import { useSdk } from 'src/sdk'
import { useACL } from 'src/sdk/acl'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { Divider } from 'src/sdk/tw/styles'
import {
  GetServiceTypesQuery,
  LocationGetQuery,
  useGetSmsBalanceQuery,
} from 'src/state/graphql'
import { FormField, Loader, ToolTip } from '../../../components'
import { GroupSlotStatusPresenter } from './GroupSlotStatusPresenter'

type TGroupClientSlotDetailsProps = {
  form: FormikSharedConfig<{}> &
    FormikState<any> &
    FormikHelpers<any> &
    FormikHandlers &
    FormikComputedProps<any> &
    FormikRegistration & { submitForm: () => Promise<any> }
  location: NonNullable<NonNullable<LocationGetQuery['location']>>['get']
  org: any
  isPrepaymentDisabled: boolean
  setActiveForm: React.Dispatch<
    React.SetStateAction<
      | `clientSlot_${string}`
      | 'appointment'
      | 'client'
      | 'service'
      | 'paymentNotification'
    >
  >
  className?: string
  service: NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<
            NonNullable<GetServiceTypesQuery['serviceType']>
          >['search']
        >[0]
      >
    >['services']
  >[0]
}

export const GroupClientSlotDetails = (props: TGroupClientSlotDetailsProps) => {
  const {
    form,
    location,
    org,
    className,
    setActiveForm,
    service,
    isPrepaymentDisabled,
  } = props
  const { values } = form
  const { isMobile, t } = useSdk()
  const { hasPermission } = useACL()
  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')
  const [formView, setFormView] = useState<
    null | 'confirm' | 'cancel' | 'conclude' | 'editInternalNote'
  >(null)
  const tmpRef = useRef<any>()

  const clientSlot = form?.values?.clientSlotInEdit

  const durationMinutes = getServiceDurationInMinutes(values?.duration!)
  const startTimeUtc = moment(`${values.date} ${values.startTime?.id}`)
    .utc()
    .toDate()

  const endTimeUtc = moment(`${values.date} ${values.startTime?.id}`)
    .add(durationMinutes, 'minutes')
    .utc()
    .toDate()

  const { data: smsBalanceData, loading: smsBalanceLoading } =
    useGetSmsBalanceQuery({ fetchPolicy: 'network-only' })

  const smsBalance = smsBalanceData?.payment?.getSmsBalance?.balance || 0

  const cancelReasonOptions =
    clientSlot?.status === 'DRAFT'
      ? [
          {
            id: 'ORG_CANCELED',
            name: t('translation.UpdateStatusModal.salonCanceled'),
          },
        ]
      : [
          {
            id: 'CLIENT_CANCELED',
            name: t('translation.UpdateStatusModal.clientCanceled'),
          },

          {
            id: 'CLIENT_NO_SHOW',
            name: t('translation.UpdateStatusModal.clientNoShow'),
          },
        ]
  const showSendNotificationSmsCheckBox = !!clientSlot?.client?.mobilePhone
  const showSendPaymentNotificationButton = clientSlot?.posPaymentRequest?.id
    ? clientSlot?.posPaymentRequest?.status === 'PENDING_PAYMENT' ||
      clientSlot?.posPaymentRequest?.status === 'CANCELED'
    : !isPrepaymentDisabled && !!clientSlot?.client?.email

  const shouldShowRefundCheckbox =
    clientSlot?.posPaymentRequest &&
    clientSlot?.posPaymentRequest?.status === 'PAID'
  const isLoading = smsBalanceLoading

  const scrollToBottom = () => {
    const element = getFirstScrollableParent(tmpRef?.current)

    if (element) {
      setTimeout(() => {
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'smooth',
        })
      }, 0)
    }
  }

  return (
    <div
      className={`py-3 px-4 lg:py-4 lg:px-6 ${className || ''}`}
      ref={tmpRef}
    >
      {isLoading ? <Loader isComponent /> : null}

      <header className="bg-zoyya-grayLighter border-1 p-4 relative rounded-md">
        <div className="flex flex-col">
          <div className="flex justify-between text-2xl">
            <div className="flex flex-col ">
              <div className="font-medium lg:text-2xl text-lg">
                {format(new Date(form?.values?.date), 'iiii, dd.MM.yyyy', {
                  locale: hr,
                })}
              </div>
              <div className="text-zoyya-secondaryDarker lg:text-lg text-md flex justify-between items-center">
                <div>{service?.name}</div>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex flex-row gap-2 self-center lg:text-2xl text-lg items-center font-medium">
                <LuClock4
                  size={isMobile ? 16 : 22}
                  className="text-zoyya-primary"
                />
                <div className="flex flex-row">
                  <div className="">{format(startTimeUtc, 'HH:mm')}</div>
                  <div>-</div>
                  <div className="opacity-90">
                    {format(endTimeUtc, 'HH:mm')}
                  </div>
                </div>
              </div>
              <div className="flex justify-end mb-1">
                <div className=" text-md flex  gap-1 bg-zoyya-mainBackground text-zoyya-primary rounded-sm p-1 ">
                  <div className="mt-1">
                    <BsFillPersonFill />
                  </div>
                  <div>
                    {form?.values?.clientSlots?.filter(slot => {
                      return slot?.resourceBookingId
                        ? slot?.status !== 'canceled'
                        : true
                    })?.length || 0}
                  </div>
                  <div>/</div>
                  <div>{form?.values?.capacity}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-2 lg:hidden">
            <GroupSlotStatusPresenter
              slot={clientSlot as any}
              className="lg:!p-2 !p-1 font-medium"
            />
          </div>
          <div className="mt-4 flex justify-between">
            <div className={`flex gap-2 `} key={clientSlot?.client?.id}>
              <div className=" rounded-md overflow-hidden border-1  shrink-1 h-fit">
                {clientSlot?.client?.defaultAvatarUrl &&
                !clientSlot?.client?.defaultAvatarUrl?.endsWith('null') ? (
                  <img
                    src={clientSlot?.client.defaultAvatarUrl}
                    className="lg:w-[70px] lg:h-[70px] w-[40px] h-[40px] rounded-md"
                  ></img>
                ) : (
                  <div className="lg:w-[70px] lg:h-[70px] w-[40px] h-[40px] bg-zoyya-background flex items-center justify-center text-zoyya-primary lg:text-2xl  text-lg font-medium ">
                    {clientSlot?.client?.firstName &&
                    clientSlot?.client?.lastName
                      ? clientSlot?.client?.firstName.charAt(0).toUpperCase() +
                        clientSlot?.client?.lastName.charAt(0).toUpperCase()
                      : clientSlot?.client?.firstName
                      ? clientSlot?.client?.firstName.charAt(0).toUpperCase()
                      : 'NN'}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-[2px] flex-1">
                <div className="flex font-medium lg:text-lg text-md gap-2">
                  <span>{resolveFullName(clientSlot?.client)}</span>
                  {clientSlot?.client?.status === 'BLOCKED' ? (
                    <div className="bg-zoyya-accent5 pt-0.5 px-1 text-sm rounded-sm uppercase text-white w-fit flex items-center">
                      {t('translation.ClientsList.label-blocked')}
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-row gap-2 text-zoyya-secondaryDark items-center lg:text-md text-sm">
                  <FiMail size={isMobile ? 14 : 16} />

                  <div>{clientSlot?.client?.email}</div>
                  {clientSlot?.client?.mailboxStatus === 'DISABLED' ? (
                    <p className="line-clamp-1 ">
                      <Tooltip2
                        helpText={
                          clientSlot?.client?.mailboxDisabledReason?.includes(
                            'spamreport'
                          )
                            ? t(
                                'translation.NewClientContainer.email-spamreported'
                              )
                            : t('translation.NewClientContainer.email-blocked')
                        }
                      >
                        <span>
                          <WarningTriangle
                            color="orange"
                            size={isMobile ? 'size14' : 'size16'}
                          />{' '}
                        </span>
                      </Tooltip2>
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-row gap-2 text-zoyya-secondaryDark items-center lg:text-md text-sm">
                  <FiPhone size={isMobile ? 14 : 16} />
                  <div>{clientSlot?.client?.mobilePhone}</div>
                </div>
              </div>
            </div>
            <div className="hidden lg:block">
              <GroupSlotStatusPresenter
                slot={clientSlot as any}
                className="lg:!p-2 !p-1 font-medium"
              />
            </div>
          </div>

          {clientSlot?.client?.membershipExpirationDate ? (
            <div className="flex flex-col gap-1  mt-6 lg:text-md text-sm">
              <div className="flex justify-between gap-4">
                <span className="font-medium">{'Članstvo vrijedi do'}</span>
              </div>
              <span
                className={
                  moment().isAfter(
                    moment(clientSlot?.client?.membershipExpirationDate)
                  )
                    ? 'text-zoyya-accent5/80'
                    : 'text-zoyya-text/80'
                }
              >
                {`${moment(clientSlot?.client?.membershipExpirationDate).format(
                  'DD.MM.YYYY.'
                )}${
                  moment().isAfter(
                    moment(clientSlot?.client?.membershipExpirationDate)
                  )
                    ? ' - ISTEKLO'
                    : ''
                }`}
              </span>
            </div>
          ) : null}

          {clientSlot?.client?.comment ? (
            <div className="flex flex-col gap-1  mt-6 text-sm lg:text-md">
              <div className="flex justify-between gap-4">
                <span className="font-medium">{'Napomena za klijenta'}</span>
                {canEditAppointment ? (
                  <div className="flex gap-2 items-center text-zoyya-primary hover:text-zoyya-primaryDark cursor-pointer"></div>
                ) : null}
              </div>
              <span className="italic text-zoyya-text/70">
                {clientSlot?.client?.comment}
              </span>
            </div>
          ) : null}

          {clientSlot?.internalNote ? (
            <div className="flex flex-col gap-1  mt-6 text-sm lg:text-md">
              <div className="flex justify-between gap-4">
                <span className="font-medium">{'Napomena za termin'}</span>
                {canEditAppointment ? (
                  <div
                    className="flex gap-2 items-center text-zoyya-primary hover:text-zoyya-primaryDark cursor-pointer"
                    onClick={() => {
                      setFormView('editInternalNote')
                      setActiveForm('clientSlot_editInternalNote')
                      form?.setFieldValue(
                        'clientSlotInternalNote',
                        clientSlot?.internalNote
                      )

                      scrollToBottom()
                    }}
                  >
                    <span>
                      <Edit size="extraSmall" stroke={1.7} />
                    </span>
                    <span>{'Uredi napomenu'}</span>
                  </div>
                ) : null}
              </div>
              <span className="italic text-zoyya-text/70">
                {clientSlot?.internalNote}
              </span>
            </div>
          ) : canEditAppointment ? (
            <div
              className="flex w-fit gap-2 items-center mt-6 text-zoyya-primary hover:text-zoyya-primaryDark cursor-pointer text-sm lg:text-md"
              onClick={() => {
                setFormView('editInternalNote')
                setActiveForm('clientSlot_editInternalNote')
                form?.setFieldValue(
                  'clientSlotInternalNote',
                  clientSlot?.internalNote
                )
                scrollToBottom()
              }}
            >
              <span>
                <Add size="extraSmall" stroke={1.7} />
              </span>
              <span>{'Dodaj napomenu'}</span>
            </div>
          ) : null}
          {service && service.totalAmount > 0 ? (
            <div className="flex flex-col lg:flex-row justify-between mt-6 ">
              <div className="flex flex-col gap-1">
                {service?.discountAmount! > 0 ? (
                  <>
                    <div className="flex gap-2">
                      <span
                        className={`font-medium ${
                          clientSlot?.posPaymentRequest
                            ? 'w-[120px]'
                            : 'w-[65px]'
                        }`}
                      >
                        {'Cijena:'}
                      </span>
                      <span>{`${formatPriceNumberWithDelimiter(
                        service?.price
                      )} ${org?.currency?.id}`}</span>
                    </div>

                    <div className="flex gap-2">
                      <span
                        className={`font-medium ${
                          clientSlot?.posPaymentRequest
                            ? 'w-[120px]'
                            : 'w-[65px]'
                        }`}
                      >
                        {'Popust:'}
                      </span>
                      <span>{`${formatPriceNumberWithDelimiter(
                        service?.discountAmount
                      )} ${org?.currency?.id}`}</span>
                    </div>
                  </>
                ) : null}
                <div className="flex gap-2">
                  <span
                    className={`font-medium ${
                      clientSlot?.posPaymentRequest ? 'w-[120px]' : 'w-[65px]'
                    }`}
                  >
                    {'Ukupno:'}
                  </span>
                  <span>{`${formatPriceNumberWithDelimiter(
                    service?.totalAmount
                  )} ${org?.currency?.id}`}</span>
                </div>

                {clientSlot?.posPaymentRequest ? (
                  <div className="flex gap-2">
                    <span className="font-medium w-[120px]">
                      {'Iznos predujma:'}
                    </span>
                    <span>{`${formatPriceNumberWithDelimiter(
                      service?.totalAmount *
                        (1 -
                          Number(
                            location?.prepaymentsConfig?.advanceAmountPercentage
                          ) /
                            100)
                    )} ${org?.currency?.id}`}</span>
                    <span className="text-zoyya-secondaryDark">{`(${location?.prepaymentsConfig?.advanceAmountPercentage}%)`}</span>
                  </div>
                ) : null}
              </div>

              {showSendPaymentNotificationButton ? (
                <div className="flex lg:items-end justify-end lg:justify-start lg:mt-0 mt-2">
                  <ButtonTW
                    size="small"
                    label="Pošalji link za plaćanje"
                    variant="primary"
                    onClick={() => {
                      form.setFieldValue(
                        'cancelAppointmentOnExpiredPaymentRequest',
                        location?.prepaymentsConfig
                          ?.cancelAppointmentOnExpiredPaymentRequest
                      )
                      setActiveForm('paymentNotification')
                    }}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {canEditAppointment &&
          clientSlot?.status !== 'concluded' &&
          clientSlot?.status !== 'canceled' ? (
            <>
              <div className="mt-4">
                <Divider />
              </div>
              <div className="flex justify-between gap-10">
                {clientSlot?.status === 'DRAFT' ? (
                  <ButtonTW
                    label="Potvrdi"
                    variant={
                      formView === 'confirm' ? 'primary' : 'primaryOutline'
                    }
                    type="button"
                    className={
                      '!w-full mx-5 focus:ring-[none] focus:outline-none focus:shadow-none'
                    }
                    onClick={() => {
                      if (formView === 'confirm') {
                        setFormView(null)
                        setActiveForm('clientSlot_preview')
                        form?.setFieldValue(
                          'clientSlotInEdit.sendEmail',
                          undefined
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.sendSms',
                          undefined
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.messageToClient',
                          undefined
                        )
                      } else {
                        setFormView('confirm')
                        setActiveForm('clientSlot_confirm')
                        form?.setFieldValue(
                          'clientSlotInEdit.sendEmail',
                          location?.sendAppointmentEmailsToClients
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.sendSms',
                          location?.sendAppointmentSmsToClients
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.messageToClient',
                          undefined
                        )
                        scrollToBottom()
                      }
                    }}
                  />
                ) : clientSlot?.status === 'CONFIRMED' ? (
                  <ButtonTW
                    label="Zaključi"
                    variant={
                      formView === 'conclude' ? 'primary' : 'primaryOutline'
                    }
                    type="button"
                    className={
                      '!w-full mx-5 focus:ring-[none] focus:outline-none focus:shadow-none'
                    }
                    onClick={() => {
                      if (formView === 'conclude') {
                        setFormView(null)
                        setActiveForm('clientSlot_preview')
                        form?.setFieldValue(
                          'clientSlotInEdit.requestReviewByEmail',
                          undefined
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.requestReviewBySms',
                          undefined
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.messageToClient',
                          undefined
                        )
                      } else {
                        setFormView('conclude')
                        setActiveForm('clientSlot_conclude')
                        form?.setFieldValue(
                          'clientSlotInEdit.requestReviewByEmail',
                          location?.sendAppointmentEmailsToClients
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.messageToClient',
                          undefined
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.requestReviewBySms',
                          location?.sendAppointmentSmsToClients
                        )
                        scrollToBottom()
                      }
                    }}
                  />
                ) : null}

                {clientSlot?.status === 'DRAFT' ||
                clientSlot?.status === 'CONFIRMED' ? (
                  <ButtonTW
                    label="Otkaži"
                    variant={formView === 'cancel' ? 'orange' : 'orangeOutline'}
                    type="button"
                    className={
                      '!w-full mx-5 focus:ring-[none] focus:outline-none focus:shadow-none'
                    }
                    onClick={() => {
                      if (formView === 'cancel') {
                        setFormView(null)
                        setActiveForm('clientSlot_preview')
                        form?.setFieldValue(
                          'clientSlotInEdit.sendEmail',
                          undefined
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.sendSms',
                          undefined
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.messageToClient',
                          undefined
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.cancelGroupClientBookingReason',
                          undefined
                        )
                      } else {
                        setFormView('cancel')
                        setActiveForm('clientSlot_cancel')
                        form?.setFieldValue(
                          'clientSlotInEdit.sendEmail',
                          location?.sendAppointmentEmailsToClients
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.sendSms',
                          location?.sendAppointmentSmsToClients
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.messageToClient',
                          undefined
                        )
                        form?.setFieldValue(
                          'clientSlotInEdit.cancelGroupClientBookingReason',
                          cancelReasonOptions.length === 1
                            ? cancelReasonOptions[0].id
                            : undefined
                        )
                        scrollToBottom()
                      }
                    }}
                  />
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </header>

      {formView === 'cancel' ? (
        <div className="mt-4 flex bg-zoyya-grayLighter gap-4 p-4 flex-col border rounded-sm">
          <FormField.Select
            closeMenuOnSelect
            parseValue={val => cancelReasonOptions?.find(e => e.id === val)}
            formatValue={val => val?.id}
            options={cancelReasonOptions}
            isClearable={false}
            // onChange={item => {}}
            label={t('translation.UpdateStatusModal.cancelReason')}
            name={'clientSlotInEdit.cancelGroupClientBookingReason'}
          />
          <div className="flex justify-between lg:flex-row flex-col gap-2 lg:gap-0">
            <FormField.Checkbox
              label="Pošalji email"
              name="clientSlotInEdit.sendEmail"
              type={'checkbox'}
            />
            {showSendNotificationSmsCheckBox && !smsBalanceLoading ? (
              <>
                {smsBalance > 0 ? (
                  <FormField.Checkbox
                    label={t('translation.UpdateStatusModal.sendClientSms')}
                    name="clientSlotInEdit.sendSms"
                    disabled={smsBalance <= 0}
                    type={'checkbox'}
                    helpText={t('translation.UpdateStatusModal.sendSmsHintNew')}
                  />
                ) : (
                  <ToolTip
                    label={t('translation.Appointment.no-sms-available')}
                  >
                    <FormField.Checkbox
                      label={t('translation.UpdateStatusModal.sentClientSms')}
                      name="clientSlotInEdit.sendSms"
                      disabled={smsBalance <= 0}
                      type={'checkbox'}
                      helpText={t(
                        'translation.UpdateStatusModal.sendSmsHintNew'
                      )}
                    />
                  </ToolTip>
                )}
              </>
            ) : null}
          </div>

          {shouldShowRefundCheckbox ? (
            <FormField.Checkbox
              helpText={t('translation.UpdateStatusModal.refund-checkbox-help')}
              label={t('translation.UpdateStatusModal.refund-checkbox-label')}
              type={'checkbox'}
              name={'clientSlotInEdit.shouldRefundPaymentRequest'}
            />
          ) : null}
          <FormField.TextArea
            label="Vaša poruka"
            name="clientSlotInEdit.messageToClient"
          />

          <div className="mt-8 flex gap-2 justify-end flex-row">
            <ButtonTW
              variant="text"
              onClick={() => setFormView(null)}
              label="Odustani"
            />
            <ButtonTW
              variant="primary"
              type="button"
              label="Otkaži"
              onClick={async () => {
                const errors = await form?.validateForm()
                if (isEmpty(errors)) {
                  form?.submitForm()
                }
              }}
            />
          </div>
        </div>
      ) : null}

      {formView === 'confirm' ? (
        <div className="flex bg-zoyya-grayLighter gap-4 p-4 flex-col border rounded-sm mt-4">
          <div className="flex justify-between lg:flex-row flex-col gap-2 lg:gap-0">
            <FormField.Checkbox
              label="Pošalji email"
              name="clientSlotInEdit.sendEmail"
              type={'checkbox'}
            />
            {showSendNotificationSmsCheckBox && !smsBalanceLoading ? (
              <>
                {smsBalance > 0 ? (
                  <FormField.Checkbox
                    label={t('translation.UpdateStatusModal.sendClientSms')}
                    name="clientSlotInEdit.sendSms"
                    disabled={smsBalance <= 0}
                    type={'checkbox'}
                    helpText={t('translation.UpdateStatusModal.sendSmsHintNew')}
                  />
                ) : (
                  <ToolTip
                    label={t('translation.Appointment.no-sms-available')}
                  >
                    <FormField.Checkbox
                      label={t('translation.UpdateStatusModal.sentClientSms')}
                      name="clientSlotInEdit.sendSms"
                      disabled={smsBalance <= 0}
                      type={'checkbox'}
                      helpText={t(
                        'translation.UpdateStatusModal.sendSmsHintNew'
                      )}
                    />
                  </ToolTip>
                )}
              </>
            ) : null}
          </div>
          <FormField.TextArea
            label="Vaša poruka"
            name="clientSlotInEdit.messageToClient"
          />
          <div className="mt-8 flex gap-2 justify-end flex-row">
            <ButtonTW
              variant="text"
              onClick={() => setFormView(null)}
              label="Odustani"
            />
            <ButtonTW variant="primary" type="submit" label="Potvrdi" />
          </div>
        </div>
      ) : null}

      {formView === 'conclude' ? (
        <div className="flex bg-zoyya-grayLighter gap-4 p-4 flex-col border rounded-sm mt-4">
          <div className="flex justify-between lg:flex-row flex-col gap-2 lg:gap-0">
            <FormField.Checkbox
              label={
                <div className="text-[13px]">
                  {' '}
                  {t(
                    'translation.UpdateStatusModal.sendReviewRequestClientEmail'
                  )}
                </div>
              }
              name="clientSlotInEdit.requestReviewByEmail"
              type={'checkbox'}
            />
            {showSendNotificationSmsCheckBox && !smsBalanceLoading ? (
              <>
                {smsBalance > 0 ? (
                  <FormField.Checkbox
                    label={
                      <div className="text-[13px]">
                        {t(
                          'translation.UpdateStatusModal.sendReviewRequestClientSms'
                        )}
                      </div>
                    }
                    name="clientSlotInEdit.requestReviewBySms"
                    disabled={smsBalance <= 0}
                    type={'checkbox'}
                    helpText={t('translation.UpdateStatusModal.sendSmsHintNew')}
                  />
                ) : (
                  <ToolTip
                    label={t('translation.Appointment.no-sms-available')}
                  >
                    <FormField.Checkbox
                      label={t(
                        'translation.UpdateStatusModal.sendReviewRequestClientSms'
                      )}
                      name="clientSlotInEdit.requestReviewBySms"
                      className="!text-[13px]"
                      disabled={smsBalance <= 0}
                      type={'checkbox'}
                      helpText={t(
                        'translation.UpdateStatusModal.sendSmsHintNew'
                      )}
                    />
                  </ToolTip>
                )}
              </>
            ) : null}
          </div>
          <FormField.TextArea
            label="Vaša poruka"
            name="clientSlotInEdit.messageToClient"
          />
          <div className="mt-8 flex gap-2 justify-end flex-row">
            <ButtonTW
              variant="text"
              onClick={() => setFormView(null)}
              label="Odustani"
            />
            <ButtonTW variant="primary" type="submit" label="Potvrdi" />
          </div>
        </div>
      ) : null}

      {formView === 'editInternalNote' ? (
        <div className="flex bg-zoyya-grayLighter gap-4 p-4 flex-col border rounded-sm mt-4">
          <FormField.TextArea
            label="Napomena"
            placeholder="Vidljivo samo zaposlenicima"
            name="clientSlotInternalNote"
          />
          <div className="mt-8 flex gap-2 justify-end flex-row">
            <ButtonTW
              variant="text"
              onClick={() => setFormView(null)}
              label="Odustani"
            />
            <ButtonTW variant="primary" type="submit" label="Potvrdi" />
          </div>
        </div>
      ) : null}
    </div>
  )
}
