import React, { useContext, useEffect, useRef, useState } from 'react'
import { getEmployeeAvatarUrl, themeAccentColors } from 'src/helpers'
import {
  FieldColumn,
  FieldRow,
  SettingsAccountAvatar,
} from 'views/Settings/styles'
import {
  FormPageContainer,
  FormContainer,
  FormFooterButtonsContainerMobile,
} from 'src/styles'
import {
  Button,
  DeleteButton,
  Form,
  FormField,
  FormFooterButtonsContainer,
  Loader,
} from 'components'
import { useProfessionAllQuery } from 'state/graphql'
import { useHeader } from 'mobile/Header'
import { useSdk } from 'sdk'
import { SelectOptionLabel, SelectRow } from 'views/AppointmentModal/styles'
import { FormFieldsWrapper } from 'components/Form/Form'
import { MobileContext } from 'src/Context'
import { PlanUpgradeField } from 'components/PlanUpgradeField/PlanUpgradeField'
import { ButtonWithConfirmation } from 'components'
import { icons } from 'components/Icon'
import styled from 'styled-components'
import { device } from '../../../../constants'
import { OrganizationAvatarRow } from 'views/Settings/Organization/styles'
import { useParams } from 'react-router'
import { useGenders } from 'src/sdk/helperHooks'
import { trpc } from 'src/trpc'
import { FormikProps } from 'formik'
import * as Yup from 'yup'
import ImageCropEditor from '../../../../components/ImageCropEditor/ImageCropEditor'

type EmployeeEditorFormProps = {
  onSubmit: (values: any) => void
  initialValues: any
  validationSchema: Yup.ObjectSchema<any>
  loading: boolean
  setOpenBillingModal: (values: any) => void
  hasOnlineBooking: boolean
  hasFutureAppointments?: boolean
  inviteUser?: (values: any) => void
  isEdit?: boolean
  onDelete?: () => void
  onRemove?: () => void
  showEmailInvitationHint?: boolean
  emailInvitationHintWarning?: string
  setBlob?: any
  previewUrl?: any
  setPreviewUrl?: any
}

export const EmployeeEditorForm = (props: EmployeeEditorFormProps) => {
  const {
    onSubmit,
    initialValues,
    validationSchema,
    isEdit,
    inviteUser,
    loading,
    onDelete,
    onRemove,
    setOpenBillingModal,
    hasOnlineBooking,
    hasFutureAppointments,
    setBlob,
    previewUrl,
    setPreviewUrl,
  } = props

  const [showEmailField, setShowEmailField] = useState(!isEdit ? true : false)

  const genders = useGenders()
  const { t, navigateTo } = useSdk()
  const params = useParams<{ orgId: string }>()
  const isMobile = useContext(MobileContext)
  let formRef = useRef<FormikProps<any>>()
  useHeader({
    content: t('translation.ResourceEditorForm.label-employee'),
    onGoBack: () =>
      navigateTo.employeesList({
        ...params,
        selectedTab: 0,
      }),
  })

  const { data: roles } = trpc.getOrgRoles.useQuery()

  const { data: professionsData, loading: loadingProfessions } =
    useProfessionAllQuery()

  if (loadingProfessions) return null
  const professionsAll = professionsData?.profession?.all
  const professions = professionsAll?.filter(x => x?.kind === 'USER')

  const bookingSettings = [
    {
      id: 'ALLOWED',
      title: t('translation.ResourceEditorForm.label-booking-allowed'),
    },
    {
      id: 'DISABLED',
      title: t('translation.ResourceEditorForm.label-booking-disabled'),
    },
  ]

  const calendarSettings = [
    {
      id: true,
      title: t('translation.ResourceEditorForm.showInCalendar'),
    },
    {
      id: false,
      title: t('translation.ResourceEditorForm.dontShowInCalendar'),
    },
  ]

  const emailSettings = [
    {
      id: true,
      title: t('translation.ResourceEditorForm.sendEmailNotifications'),
    },
    {
      id: false,
      title: t('translation.ResourceEditorForm.dontSendEmailNotifications'),
    },
  ]

  const handleValueChange = (name, value, values, form) => {
    if (
      name === 'onlineBooking' &&
      value === 'ALLOWED' &&
      !values.showInCalendar
    )
      form.setFieldValue('showInCalendar', true)
    else if (
      name === 'showInCalendar' &&
      !value &&
      values.onlineBooking === 'ALLOWED'
    )
      form.setFieldValue('onlineBooking', 'DISABLED')
  }

  const userAvatar = getEmployeeAvatarUrl(initialValues)
  const resourceImageUrl = !initialValues?.id
    ? 'tmp/null'
    : userAvatar
    ? userAvatar
    : initialValues?.avatarUrl

  const imageURL = previewUrl
    ? previewUrl
    : resourceImageUrl
    ? resourceImageUrl.endsWith('null')
      ? '/assets/default-avatar.svg'
      : resourceImageUrl
    : '/assets/default-avatar.svg'

  const MobileOption = props => {
    const isSelected = props.selectProps.value?.find(
      e => e.id === props.data?.id
    )
    return (
      <SelectRow>
        <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <icons.CheckMarkIcon color="grayDarker" />}
        </span>

        <SelectOptionLabel data-cy={'mobileSelect_value'}>
          {props.data?.name}
        </SelectOptionLabel>
      </SelectRow>
    )
  }

  const selectComponents = {
    MobileOption,
  }
  return (
    <>
      <FormPageContainer>
        {loading && <Loader isComponent />}
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onValueChange={handleValueChange}
        >
          {form => {
            formRef.current = form
            return (
              <FormFieldsWrapper>
                <FormContainer>
                  <div>
                    <FieldRow style={{ alignItems: 'flex-start' }}>
                      <FieldColumn
                        style={{
                          maxWidth: isMobile ? 'unset' : '180px',
                          width: isMobile ? '100%' : undefined,
                          justifyContent: isMobile ? 'center' : undefined,
                          marginRight: 20,
                          paddingTop: 5,
                          paddingRight: 0,
                        }}
                      >
                        <OrganizationAvatarRow>
                          <SettingsAccountAvatar
                            src={imageURL}
                            style={{ width: 170, height: 170, marginRight: 10 }}
                          />
                          <ImageCropEditor
                            setBlob={setBlob}
                            setPreviewUrl={setPreviewUrl}
                            aspect={1}
                          />
                        </OrganizationAvatarRow>
                      </FieldColumn>
                      <FieldColumn style={{ flexDirection: 'column' }}>
                        <FieldRow>
                          <FieldColumn>
                            <FormField.Text
                              label={t(
                                'translation.ResourceEditorForm.label-first-name'
                              )}
                              name="firstName"
                              autoFocus={!!form.errors.firstName}
                            />
                          </FieldColumn>
                        </FieldRow>
                        <FieldRow>
                          <FieldColumn>
                            <FormField.Text
                              label={t(
                                'translation.ResourceEditorForm.label-last-name'
                              )}
                              name="lastName"
                              autoFocus={
                                !!form.errors.lastName && !form.errors.firstName
                              }
                            />
                          </FieldColumn>
                        </FieldRow>
                      </FieldColumn>
                    </FieldRow>

                    <FieldRow>
                      <FieldColumn className="mt-auto !flex-col">
                        <label
                          htmlFor="email"
                          className="font-medium leading-[28px] mb-1.5"
                        >
                          {t('translation.ResourceEditorForm.label-email')}
                        </label>
                        {!showEmailField && !form.values.user?.id ? (
                          <div className="h-[47px]">
                            <Button
                              label={t(
                                'translation.ResourceEditorForm.allow-access'
                              )}
                              buttonType={'primaryOutline'}
                              fullWidth
                              tooltip={t(
                                'translation.ResourceEditorForm.tooltip-send-invite'
                              )}
                              onClick={event => {
                                event.preventDefault()
                                event.stopPropagation()
                                setShowEmailField(true)
                              }}
                            />
                          </div>
                        ) : (
                          <FormField.Text
                            name="email"
                            helpText={t(
                              'translation.ResourceEditorForm.tooltip-email-notification'
                            )}
                          />
                        )}
                      </FieldColumn>
                      {isMobile ? (
                        showEmailField && !form.values.user?.id ? (
                          isEdit && initialValues.email ? (
                            <FieldColumn style={{ marginBottom: '16px' }}>
                              <Button
                                label={t(
                                  'translation.ResourceEditorForm.label-send-email-invite'
                                )}
                                buttonType={'secondary'}
                                tooltip={t(
                                  'translation.ResourceEditorForm.tooltip-send-invite'
                                )}
                                onClick={event => {
                                  event.preventDefault()
                                  event.stopPropagation()
                                  inviteUser?.(form.values)
                                }}
                                disabled={
                                  !form.values.email || form.errors.email
                                }
                              />
                            </FieldColumn>
                          ) : (
                            <FieldColumn>
                              <FormField.Checkbox
                                label={t(
                                  'translation.ResourceEditorForm.label-send-email-invite'
                                )}
                                name="sendInviteViaEMail"
                                type={'checkbox'}
                              />
                            </FieldColumn>
                          )
                        ) : null
                      ) : (
                        <FieldColumn>
                          <FormField.PhoneInput
                            label={t(
                              'translation.ResourceEditorForm.label-mobile-phone'
                            )}
                            name="mobilePhone"
                            helpText={t(
                              'translation.ResourceEditorForm.tooltip-mobile-notifications'
                            )}
                          />
                        </FieldColumn>
                      )}
                    </FieldRow>
                    {isMobile ? (
                      <FieldRow>
                        <FieldColumn>
                          <FormField.PhoneInput
                            label={t(
                              'translation.ResourceEditorForm.label-mobile-phone'
                            )}
                            name="mobilePhone"
                            helpText={t(
                              'translation.ResourceEditorForm.tooltip-mobile-notifications'
                            )}
                          />
                        </FieldColumn>
                      </FieldRow>
                    ) : showEmailField && !form.values.user?.id ? (
                      isEdit && initialValues.email ? (
                        <FieldRow>
                          <FieldColumn style={{ marginBottom: '16px' }}>
                            <Button
                              label={t(
                                'translation.ResourceEditorForm.label-send-email-invite'
                              )}
                              buttonType={'secondary'}
                              tooltip={t(
                                'translation.ResourceEditorForm.tooltip-send-invite'
                              )}
                              onClick={event => {
                                event.preventDefault()
                                event.stopPropagation()
                                inviteUser?.(form.values)
                              }}
                              disabled={!form.values.email || form.errors.email}
                            />
                          </FieldColumn>
                        </FieldRow>
                      ) : (
                        <FieldRow>
                          <FieldColumn>
                            <FormField.Checkbox
                              label={t(
                                'translation.ResourceEditorForm.label-send-email-invite'
                              )}
                              name="sendInviteViaEMail"
                              type={'checkbox'}
                            />
                          </FieldColumn>
                        </FieldRow>
                      )
                    ) : null}

                    <FieldRow>
                      <FieldColumn>
                        <FormField.Select
                          label={t(
                            'translation.ResourceEditorForm.label-gender'
                          )}
                          name="gender"
                          parseValue={val => genders.find(e => e.id === val)}
                          formatValue={val => val?.id}
                          options={genders}
                        />
                      </FieldColumn>
                      <FieldColumn>
                        <FormField.Select
                          label={t('translation.ResourceEditorForm.label-role')}
                          name="role"
                          parseValue={val => roles?.find(e => e.role === val)}
                          formatValue={val => val?.role}
                          formatOptionLabel={val => val?.roleTitle}
                          options={roles || []}
                          helpText={t(
                            'translation.ResourceEditorForm.tooltip-role-info'
                          )}
                          helpLink={t(
                            'translation.HelpLinks.managing-permissions'
                          )}
                        />
                      </FieldColumn>
                    </FieldRow>
                    <FieldRow>
                      <FieldColumn>
                        <FormField.Text
                          label={t(
                            'translation.ResourceEditorForm.label-nickname'
                          )}
                          helpText={t(
                            'translation.ResourceEditorForm.tooltip-info-nickname'
                          )}
                          name="nickName"
                        />
                      </FieldColumn>
                      <FieldColumn>
                        <FormField.ColorPicker
                          label={t(
                            'translation.ResourceEditorForm.label-color'
                          )}
                          name="color"
                          parseValue={val =>
                            themeAccentColors.find(e => e.id === val)
                          }
                          formatValue={val => val.title}
                          helpText={t(
                            'translation.ResourceEditorForm.tooltip-color-info'
                          )}
                          options={themeAccentColors}
                        />
                      </FieldColumn>
                    </FieldRow>
                    <FieldRow>
                      <FieldColumn>
                        <FormField.Select
                          label={t(
                            'translation.ResourceEditorForm.label-profession'
                          )}
                          helpText={t(
                            'translation.ResourceEditorForm.tooltip-profession-info'
                          )}
                          isMulti
                          name="professions"
                          options={
                            isMobile
                              ? professions || []
                              : professions?.filter(
                                  x =>
                                    !initialValues.professions
                                      ?.map(x => x.id)
                                      .includes(x?.id)
                                ) || []
                          }
                          components={selectComponents}
                        />
                      </FieldColumn>
                      {hasOnlineBooking ? (
                        <FieldColumn>
                          <FormField.Select
                            label={t(
                              'translation.ResourceEditorForm.label-online-booking'
                            )}
                            name="onlineBooking"
                            options={bookingSettings}
                            parseValue={val =>
                              bookingSettings.find(e => e.id === val)
                            }
                            formatValue={val => val.id}
                            helpText={t(
                              'translation.ResourceEditorForm.tooltip-booking-info'
                            )}
                          />
                        </FieldColumn>
                      ) : (
                        <PlanUpgradeField
                          title={t(
                            'translation.LocationBooking.label-online-booking-select'
                          )}
                          text={t('translation.App.online-booking-disabled')}
                          handleClick={() => setOpenBillingModal(true)}
                        />
                      )}
                    </FieldRow>
                    <FieldRow>
                      <FieldColumn>
                        <FormField.Select
                          label={t(
                            'translation.ResourceEditorForm.showInCalendar'
                          )}
                          name="showInCalendar"
                          options={calendarSettings}
                          parseValue={val =>
                            calendarSettings.find(e => e.id === val)
                          }
                          formatValue={val => val.id}
                          helpText={t(
                            'translation.ResourceEditorForm.showInCalendar-hint'
                          )}
                        />
                      </FieldColumn>
                      <FieldColumn>
                        <FormField.Select
                          label={t(
                            'translation.ResourceEditorForm.receiveEmailNotifications'
                          )}
                          name="receiveAppointmentEmails"
                          options={emailSettings}
                          parseValue={val =>
                            emailSettings.find(e => e.id === val)
                          }
                          formatValue={val => val.id}
                          helpText={t(
                            'translation.ResourceEditorForm.receiveEmailNotificationsHint'
                          )}
                        />
                      </FieldColumn>
                    </FieldRow>
                    <FieldRow>
                      <FieldColumn>
                        <FormField.TextArea
                          label={t(
                            'translation.AppointmentModal.label-description'
                          )}
                          name="description"
                          helpText={t(
                            'translation.ResourceEditorForm.description-hint'
                          )}
                        />
                      </FieldColumn>
                    </FieldRow>
                    {isEdit ? (
                      <ButtonsWrapper>
                        {initialValues?.user?.id &&
                        initialValues?.hasOrgMembership ? (
                          <ButtonWithConfirmation
                            buttonLabel={t(
                              'translation.NewClientContainer.label-disable'
                            )}
                            buttonIcon={
                              <icons.Cross size="smaller" stroke={2} />
                            }
                            withConfirm
                            onConfirm={onRemove}
                            confirmationMessage={t(
                              'translation.NewClientContainer.title-confirmResourceDisable'
                            )}
                          />
                        ) : null}
                        <DeleteButton
                          withConfirm
                          onConfirm={onDelete}
                          confirmationMessage={
                            !hasFutureAppointments
                              ? t(
                                  'translation.NewClientContainer.title-confirmResourceDelete'
                                )
                              : t(
                                  'translation.NewClientContainer.title-confirmEmployeeDelete',
                                  {
                                    employeeName: !form?.values?.nickName
                                      ? form?.values?.firstName +
                                        ' ' +
                                        form?.values?.lastName
                                      : form?.values?.nickName,
                                  }
                                )
                          }
                        />
                      </ButtonsWrapper>
                    ) : null}
                  </div>
                  {!isMobile ? (
                    <FormFooterButtonsContainer>
                      <Button
                        footerButton
                        buttonType={'text'}
                        name="closeForm"
                        type={'button'}
                        label={t('translation.ResourceEditorForm.button-close')}
                        onClick={() =>
                          navigateTo.employeesList({
                            ...params,
                            selectedTab: 0,
                          })
                        }
                      />
                      <Button
                        type={'submit'}
                        buttonType={'primary'}
                        name="saveChanges"
                        label={t('translation.ResourceEditorForm.button-save')}
                      />
                    </FormFooterButtonsContainer>
                  ) : null}
                </FormContainer>
              </FormFieldsWrapper>
            )
          }}
        </Form>
      </FormPageContainer>
      {isMobile ? (
        <FormFooterButtonsContainerMobile>
          <Button
            footerButton
            buttonType={'text'}
            name="closeForm"
            type={'button'}
            label={t('translation.ResourceEditorForm.button-close')}
            onClick={() =>
              navigateTo.employeesList({
                ...params,
                selectedTab: 0,
              })
            }
          />
          <Button
            onClick={() => formRef?.current?.submitForm()}
            buttonType={'primary'}
            name="saveChanges"
            label={t('translation.ResourceEditorForm.button-save')}
          />
        </FormFooterButtonsContainerMobile>
      ) : null}
    </>
  )
}

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  row-gap: 20px;

  @media ${device.tablet} {
    flex-direction: column;
  }
`
