import React, {
  useState,
  useEffect,
  useMemo,
  lazy,
  Suspense,
  useCallback,
  useRef,
} from 'react'
import { AppServices } from 'sdk/appServices'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { AppContainer } from './styles'
import { ApolloProvider } from '@apollo/client'
import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import { ThemeProvider } from 'styled-components'
import './themes.scss'
import { themes } from './themes'
import './index.css'
import { AppContext } from 'sdk/appServices'
import { PrivateRoute } from 'sdk/PrivateRoute'
import { createBrowserHistory } from 'history'
import 'moment/dist/locale/ro'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/de'
import 'moment/dist/locale/hr'
import 'moment/dist/locale/es'
import 'moment/dist/locale/sr'
import { useLocation, useParams } from 'react-router'
import i18n, { getCurrentLanguage } from './localization'
import './localization'
import { getISODate, useViewportDimensions } from './helpers'
import GlobalStyles from './GlobalStyles'
import 'react-toastify/dist/ReactToastify.css'
import { GetPartnerRoutes } from './partnerRoutes'
import { CodeVerification } from 'views/Signup/CodeVerification'
import { useSdk } from 'sdk'
import { useTranslation } from 'react-i18next'
import { apolloClient } from './Apollo'
import { Loader } from 'components'
import Helmet from 'react-helmet'
import moment from 'moment'
import {
  usePaymentCreateFreePlanMutation,
  usePaymentGetPaymentIntentQuery,
  useGetSmsPackagesQuery,
  useLocationGetLazyQuery,
  useOrganizationMyLazyQuery,
  useUserMeLazyQuery,
} from 'state/graphql'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { GetStartedPopup } from 'components/GetStartedPopup/GetStartedPopup'
import BuySmsModal from 'views/Settings/Billing/Modals/BuySmsModal'
import 'react-quill/dist/quill.snow.css'
import { OnlineChecker } from './OnlineChecker'
import { PosReceiptStore } from 'state/local/posReceiptStore'
import {
  WizardContext,
  MobileContext,
  PromptToInstall,
  UserContext,
} from './Context'
import {
  browserLogs,
  removeLogContextProperty,
  setLogContextProperty,
} from 'sdk/browserLogs'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpBatchLink, createWSClient, wsLink, splitLink } from '@trpc/client'
import { PopupComponent } from './sdk/tw/Popup/PopupComponent'
import { trpc } from './trpc'
import { AppRouter } from '../../server/src/trpc/router'
import { getSessionId } from './sdk/session'
import superjson from 'superjson'
import { useHotkeys } from 'react-hotkeys-hook'
import { I18nProvider } from 'react-aria'
import { ScreenLockProvider } from './sdk/useScreenLock'
import { GoogleAnalytics } from './components/GoogleAnalyticsScript/GoogleAnalyticsScript'
import { Crisp } from 'crisp-sdk-web'
import Hashids from 'hashids'
import { MSClarity } from './components/MSClarity/MSClarityScript'
import { DiscountPopup } from './components/PromotionalPopups/DiscountPopup'
import { Refetcher } from './components/Refetcher/Refetcher'

const hashids = new Hashids()

const Login = lazy(() => import('views/Login/Login'))

const SignupContainer = lazy(() => import('./views/SignupFlow/SignupContainer'))
const RegisterEmployee = lazy(
  () => import('./views/RegisterEmployee/RegisterEmployee')
)
const EmailConfirmation = lazy(
  () => import('views/EmailConfirmation/EmailConfirmation')
)
const NotFound = lazy(() => import('components/NotFound/NotFound'))
const ForgotPassword = lazy(() => import('views/PasswordReset/ForgotPassword'))
const RedirectLanguage = lazy(() => import('./RedirectLanguage'))
const BillingForm = lazy(
  () => import('views/Settings/Billing/Modals/BillingForm')
)
const ReviewPage = lazy(() => import('views/ReviewPage/ReviewPage'))
const ThankYouPage = lazy(() => import('components/ThankYouPage/ThankYouPage'))
const NotificationPreferences = lazy(
  () => import('views/Settings/NotificationPreferences/NotificationPreferences')
)

const history = createBrowserHistory()
history.listen(e => {
  browserLogs.log(`Route change ${e?.pathname}`, {
    pathname: e.pathname,
    search: e.search,
    state: e.state,
  })
})
OfflinePluginRuntime.install()

const appServices = new AppServices(apolloClient, history)

moment.locale(getCurrentLanguage())

//zoyya
const googleClientId =
  '687616046414-tosav169mahectvb4jon1ud6p4iqvuis.apps.googleusercontent.com'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
})

const App = () => {
  const appLanguage = localStorage.getItem('i18nextLng')
  const changeLanguage = () => {
    i18n.changeLanguage(appLanguage?.substring(0, 2))
  }

  changeLanguage()
  const [selectedTheme] = useState('default')
  const { isMobile } = useViewportDimensions()

  const [initInProgress, setInitInProgress] = useState(true)
  localStorage.removeItem('selectedEmployeeIds')

  const [deferredPrompt, setDeferredPrompt] = useState(null)

  const hidePrompt = useCallback(() => {
    setDeferredPrompt(null)
  }, [])
  const { initPosStore } = PosReceiptStore

  const [newMessage, setNewMessage] = useState(false)

  useEffect(() => {
    Crisp.configure(import.meta.env.VITE_CRISP_CHAT_API_KEY, {
      autoload: false,
      locale: getCurrentLanguage(),
    })

    Crisp.setZIndex(999999999)

    $crisp.push(['on', 'chat:opened', () => Crisp.chat.show()])
    $crisp.push([
      'on',
      'chat:closed',
      () => {
        Crisp.chat.hide()
      },
    ])
  }, [i18n.language])

  useEffect(() => {
    const handleNewMessage = () => {
      setNewMessage(true)
      Crisp.chat.show()
    }

    $crisp.push(['on', 'message:received', handleNewMessage])

    return () => {
      $crisp.push(['off', 'message:received', handleNewMessage])
    }
  }, [newMessage])

  useEffect(() => {
    if (newMessage) {
      setNewMessage(false)
    }
  }, [newMessage])

  useEffect(() => {
    const ready = e => {
      setDeferredPrompt(e)
    }

    window.addEventListener('beforeinstallprompt', ready)
    return () => {
      window.removeEventListener('beforeinstallprompt', ready)
    }
  }, [])

  useEffect(() => {
    localStorage.removeItem('selectedHour')
    localStorage.removeItem('selectedMinute')
    const initApp = async () => {
      await appServices.init()

      setInitInProgress(false)
    }
    initApp()
  }, [setInitInProgress])

  useEffect(() => {
    initPosStore()
  }, [])
  if (initInProgress)
    return (
      <>
        <Helmet>
          <link rel="icon" href={`/favicon.ico`} />
          <meta property="og:locale" content="en_GB" />
          <meta property="og:locale:alternate" content="hr_HR" />
          <meta
            name="Zoyya"
            content="Appointment management and online booking app | Zoyya"
          />
          <meta
            property="og:title"
            content="Appointment management and online booking app | Zoyya"
          />
          <meta
            property="og:description"
            content="All in one application for appointment management and online appointment booking"
          />
          <title>{'Appointment management app - Zoyya'}</title>
          <meta property="og:image" content="%PUBLIC_URL%/assets/zoyya.png" />
        </Helmet>
        <Loader isComponent />
      </>
    )

  return (
    <I18nProvider locale={appLanguage || 'hr'}>
      <PromptToInstall.Provider value={{ deferredPrompt, hidePrompt }}>
        <AppContext.Provider value={appServices}>
          <ApolloProvider client={apolloClient}>
            <MobileContext.Provider value={isMobile}>
              <ThemeProvider
                theme={{ ...themes[selectedTheme], name: selectedTheme }}
              >
                <GlobalStyles
                  theme={selectedTheme}
                  selectedTheme={themes[selectedTheme]}
                />
                <Helmet>
                  <link rel="icon" href={`/favicon.ico`} />
                  <meta property="og:locale" content="en_GB" />
                  <meta property="og:locale:alternate" content="hr_HR" />
                  <meta
                    name="Zoyya"
                    content="Appointment management and online booking app | Zoyya"
                  />
                  <meta
                    property="og:title"
                    content="Appointment management and online booking app | Zoyya"
                  />
                  <meta
                    property="og:description"
                    content="All in one application for appointment management and online appointment booking"
                  />
                  <title>{'Appointment management app - Zoyya'}</title>
                  <meta
                    property="og:image"
                    content="%PUBLIC_URL%/assets/zoyya.png"
                  />
                </Helmet>
                <Router history={history}>
                  <Suspense fallback={<Loader />}>
                    <OnlineChecker>
                      <GoogleOAuthProvider clientId={googleClientId}>
                        <Switch>
                          <Route exact path={'/review/:token'}>
                            <ReviewPage />
                          </Route>
                          <Route exact path={'/thankYou'}>
                            <ThankYouPage />
                          </Route>
                          <Route exact path={'/login'}>
                            <Login />
                          </Route>
                          <Route exact path={'/passwordReset/:token'}>
                            <ForgotPassword />
                          </Route>
                          <Route exact path={'/passwordReset'}>
                            <ForgotPassword />
                          </Route>
                          <Route exact path={'/notificationPreferences/:token'}>
                            <NotificationPreferences />
                          </Route>
                          <Route path={'/en/signup'}>
                            <RedirectLanguage />
                          </Route>

                          <Route path={'/signup/:orgId/:stepId'}>
                            <SignupContainer />
                          </Route>
                          <Route path={'/signup/:stepId'}>
                            <SignupContainer />
                          </Route>
                          <Route path={'/signup'}>
                            <SignupContainer />
                          </Route>
                          <Route exact path={'/emailConfirmationSuccess'}>
                            <EmailConfirmation />
                          </Route>
                          <Route exact path={'/invite'}>
                            <RegisterEmployee />
                          </Route>

                          <Route exact path={'/register/:inviteId'}>
                            <CodeVerification />
                          </Route>

                          <Route path={'/en/client/:orgId/:locationId'}>
                            <RedirectLanguage />
                          </Route>
                          <Route path={'/registerEmployee'}>
                            <RegisterEmployee />
                          </Route>
                          <Route path={`/client/:orgId/:locationId`}>
                            <AppComponent isMobile={isMobile} />
                          </Route>
                          <Route path={'/en/client/:orgId'}>
                            <RedirectLanguage />
                          </Route>
                          <Route path={`/client/:orgId`}>
                            <AppComponent isMobile={isMobile} />
                          </Route>
                          <PrivateRoute path={`/:orgId/:locationId`}>
                            <AppComponent isMobile={isMobile} />
                          </PrivateRoute>

                          <Route path="/">
                            <AppComponent isMobile={isMobile} />
                          </Route>
                          <Route component={NotFound} />
                        </Switch>
                      </GoogleOAuthProvider>
                    </OnlineChecker>
                  </Suspense>
                </Router>
              </ThemeProvider>
            </MobileContext.Provider>
          </ApolloProvider>
        </AppContext.Provider>
      </PromptToInstall.Provider>
    </I18nProvider>
  )
}
superjson.registerCustom<Buffer, number[]>(
  {
    isApplicable: (v): v is Buffer => {
      return v instanceof Buffer
    },
    serialize: v => [...v],
    deserialize: v => Buffer.from(v),
  },
  'buffer'
)

function AppComponent({ isMobile }) {
  const { orgId, locationId } = useParams<{ [key: string]: string }>()
  const { apolloClient } = useSdk()
  const { t } = useTranslation()

  const isSignedIn = appServices.auth.isSignedIn()
  const [storeReady, setStoreReady] = useState(false)

  // trpc.featureFlags_get.useQuery(
  //   {
  //     orgId,
  //     locationId: BigInt(locationId || 0),
  //   },
  //   {
  //     enabled: !!orgId && !!locationId,
  //     onSuccess(data) {
  //       setFeatureFlags(data)
  //     },
  //   }
  // )

  // skip variable doesnt work on lazy queries
  const [
    loadOrgs,
    { data: myOrgsData, loading: loadingMyOrgs, refetch: refetchOrg },
  ] = useOrganizationMyLazyQuery()

  const [loadUser, { data: userData, loading: loadingUser }] =
    useUserMeLazyQuery()

  const [loadLocation, { data: locationData, refetch: refetchLocation }] =
    useLocationGetLazyQuery({
      variables: { locationId },
      fetchPolicy: 'network-only',
    })

  const resources = locationData?.location?.get?.resources || []
  const location = locationData?.location?.get
  const myOrgs = myOrgsData?.organization?.my

  const org =
    myOrgs && myOrgs.length && orgId
      ? myOrgs.find(x => x.id === orgId && x.myRole !== 'CLIENT')
      : !orgId
      ? myOrgs?.find(x => x.myRole !== 'CLIENT')
      : null

  const user = userData?.user?.me

  const languageRef = useRef(i18n.language)

  useEffect(() => {
    if (location && location.localizationNamespace) {
      i18n.loadNamespaces(location.localizationNamespace, (error, t) => {
        if (error) {
          console.log('failed to load namespace', {
            nms: location.localizationNamespace,
            error,
          })
        } else {
          i18n.setDefaultNamespace(location.localizationNamespace)
        }
      })
    }
  }, [location, i18n])

  useEffect(() => {
    if (user) {
      setLogContextProperty('userId', user?.id)
      setLogContextProperty('user', user)
    } else {
      removeLogContextProperty('userId')
      removeLogContextProperty('user')
    }
    if (user?.language) {
      languageRef.current = user.language
      appServices.updateUserLanguage(user?.language, false)
    }
  }, [user])

  useHotkeys(
    'shift+ctrl+i',
    () => {
      if (i18n.language === 'cimode') {
        i18n.changeLanguage(languageRef.current)
      } else {
        i18n.changeLanguage('cimode')
      }
    },
    {
      enableOnFormTags: true,
    },
    [i18n, user]
  )

  const hasMollieData =
    org?.subscriptionMandateId?.startsWith('mdt_') ||
    org?.subscriptionCustomerId?.startsWith('cst_')

  //const organization = useGetOrganizationUsers(orgId || '')
  const [applicationLoaded, setApplicationLoaded] = useState(false)

  const appUser = useMemo(
    () => ({
      ...(user || {}),
      user_id: user?.id,
      OrganizationID: orgId,
      LocationID: locationId,
      Phone: user?.phone,
      RoleID: user?.role,
      StartDate: getISODate(),
      IsOrganizationOwner: user?.role === 'OWNER',
      Subscription: 'Trial',
      gender: user?.gender,
      name: user?.firstName + ' ' + user?.lastName,
      isMobile: isMobile,
      language: user?.language || getCurrentLanguage(),
      Isapp: !!navigator['standalone'],
      UserType:
        !user?.role || user?.role === 'CLIENT'
          ? 'B2C - Client'
          : 'B2B - Partner',
    }),
    [isMobile, locationId, user, orgId]
  )

  useEffect(() => {
    if (user?.id) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          id: user.id,
          userName: user.userName,
          avatarUrl: user.avatarUrl,
          isEmailConfirmed: user.isEmailConfirmed,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          role: user.role,
        })
      )
    }
  }, [user])

  useEffect(() => {
    if (!orgId && org) {
      localStorage.setItem('orgId', org.id)
    }
  }, [org, orgId])

  useEffect(() => {
    orgId && localStorage.setItem('orgId', orgId)
    locationId && sessionStorage.setItem('locationId', locationId)
    locationId && localStorage.setItem('locationId', locationId)
    if (orgId) {
      setLogContextProperty('orgId', orgId)
    } else {
      removeLogContextProperty('orgId')
    }
    if (locationId) {
      setLogContextProperty('locationId', locationId)
    } else {
      removeLogContextProperty('locationId')
    }
    apolloClient.cache.reset().then(() => {
      if (isSignedIn) {
        locationId && loadLocation()
        loadOrgs()
        loadUser()
      }
      setStoreReady(true)
    })
  }, [
    orgId,
    locationId,
    apolloClient,
    isSignedIn,
    loadOrgs,
    loadUser,
    loadLocation,
  ])

  useEffect(() => {
    if (!loadingUser && !loadingMyOrgs && storeReady) {
      setApplicationLoaded(true)
    }
  }, [loadingUser, loadingMyOrgs, storeReady])

  useEffect(() => {
    if (user) {
      Crisp.setTokenId(hashids.encode(user.id))
      Crisp.user.setEmail(user?.email || '')
      Crisp.user.setNickname(
        `${user?.firstName} ${user?.lastName}` || 'Visitor'
      )
      Crisp.user.setPhone(user?.phone || '')
      Crisp.user.setAvatar(user?.avatarUrl || '')
    }
    if (location) {
      //@ts-ignore
      Crisp.user.setCompany(location?.name || '')
    }

    $crisp.push([
      'set',
      'session:data',
      [
        [
          ['orgId', orgId || ''],
          ['locationId', location?.id || ''],
          ['userId', user?.id || ''],
          ['Location', location?.name || ''],
          ['Organization', org?.orgName || ''],
          ['Subscription', org?.subscriptionType || ''],
        ],
      ],
    ])
  }, [user, location, orgId, org])

  const [isOpen, setIsOpen] = useState(true)

  const [openBillingModal, setOpenBillingModal] = useState(false)
  const [openBuySmsPackageModal, setOpenBuySmsPackageModal] = useState(false)
  const [purchasedPackageName, setPurchasedPackageName] = useState('')

  const { selectedEmployeeId } = useParams<{ [key: string]: string }>()
  const [selectedEmployeesInWeek, setSelectedEmployeesInWeek] = useState<
    typeof resources
  >([])
  const [selectedEmployeesInMonth, setSelectedEmployeesInMonth] = useState<
    typeof resources
  >([])
  const [showActive, setShowActive] = useState({
    id: 'showAll',
    name: t('translation.App.label-show-all-employees'),
  })

  const storedOffHours = localStorage.getItem('showOffHours')
  const [showOffHours, setShowOffHours] = useState(
    storedOffHours === 'true'
      ? {
          id: '1',
          name: t('translation.Sidebar.showOffHours'),
        }
      : {
          id: '2',
          name: t('translation.Sidebar.showWorkHours'),
        }
  )

  useEffect(() => {
    const resourcesInCalendar = resources?.filter(x => x.showInCalendar)
    const selectedEmployee = resourcesInCalendar?.find(
      x => x.id === selectedEmployeeId
    )

    if (
      selectedEmployeesInWeek &&
      !selectedEmployeesInWeek[0] &&
      resourcesInCalendar?.length === 1
    ) {
      const resource = resourcesInCalendar[0]
      setSelectedEmployeesInWeek([
        {
          ...resource,
          id: resource.id,
          name: !resource?.nickName
            ? resource?.firstName + ' ' + resource?.lastName
            : resource?.nickName,
          color: resource.color,
        },
      ])
    } else if (
      selectedEmployeeId &&
      selectedEmployeesInWeek &&
      !selectedEmployeesInWeek[0] &&
      selectedEmployee
    ) {
      setSelectedEmployeesInWeek([
        {
          ...selectedEmployee,
          id: selectedEmployee.id,
          name: !selectedEmployee?.nickName
            ? selectedEmployee?.firstName + ' ' + selectedEmployee?.lastName
            : selectedEmployee?.nickName,
          color: selectedEmployee.color,
        },
      ])
    }

    // if there are no selected employees, always set to current user
    else if (
      user &&
      selectedEmployeesInWeek &&
      !selectedEmployeesInWeek[0] &&
      user?.resource &&
      resourcesInCalendar?.find(x => x.id === user?.resource?.id)
    ) {
      setSelectedEmployeesInWeek([
        {
          ...user?.resource,
          id: user?.resource?.id,
          name: !user?.resource?.nickName
            ? user?.resource?.firstName + ' ' + user?.resource?.lastName
            : user?.resource?.nickName,
          color: user?.resource?.color,
        },
      ])
    } else {
      //if user isnt available for calendar, select first employee
      const resource = resourcesInCalendar?.find(x => x.kind === 'USER')

      if (resource && selectedEmployeesInWeek && !selectedEmployeesInWeek[0]) {
        setSelectedEmployeesInWeek([
          {
            ...resource,
            id: resource.id,
            name: !resource?.nickName
              ? resource?.firstName + ' ' + resource?.lastName
              : resource?.nickName,
            color: resource.color,
          },
        ])
      }
    }
  }, [user, resources, selectedEmployeesInWeek, selectedEmployeeId])

  const analytics = (window['analytics'] || {}) as any

  useEffect(() => {
    const resourcesInCalendar = resources?.filter(x => x.showInCalendar)
    if (
      resourcesInCalendar &&
      resourcesInCalendar[0] &&
      selectedEmployeesInMonth &&
      !selectedEmployeesInMonth[0]
    ) {
      setSelectedEmployeesInMonth(
        resourcesInCalendar?.map(x => {
          return {
            ...x,
            id: x.id,
            name: x.firstName,
            color: x.color,
          }
        })
      )
    }
  }, [resources, selectedEmployeesInMonth])

  /* const Smartlook = window?.smartlook
  if (Smartlook) {
    Smartlook('consentIP', 'true')
    Smartlook('consentAPI', 'true')
    Smartlook('consentForms', 'true')
  } */

  useEffect(() => {
    if (
      !org?.id ||
      !user?.id ||
      !analytics ||
      // !Smartlook ||
      typeof analytics.identify !== 'function'
    )
      return

    const userData = {
      email: user.email,
      ...appUser,
      company: {
        name: org?.orgName,
        CompanyEmail: org.email,
        CompanyAddress: org.address,
        CompanyCity: org.city,
        CompanyPhone: org.phone,
        CompanyMobilePhone: org.mobilePhone,
        CompanyWebUrl: org.webUrl,
        CompanyOrgUrlName: org.orgUrlName,
        ...org,
      },
    }
    // Smartlook('identify', user?.id, userData)
    analytics.identify(user.id, userData)
  }, [user, appUser, org, orgId, analytics /* , Smartlook */])

  useEffect(() => {
    if (!org?.id || !user?.id || !location?.id || !(window as any).clarity) {
      return
    }

    ;(window as any).clarity('set', 'userId', user.id.toString())
    ;(window as any).clarity('set', 'orgId', org.id)
    ;(window as any).clarity('set', 'locationId', location.id.toString())
  }, [user, org, location])

  const [paymentCreateFreePlanMutation] = usePaymentCreateFreePlanMutation()

  const createFreePlan = async () => {
    const result = await paymentCreateFreePlanMutation({
      refetchQueries: [
        {
          query: getOrganizationByIdGql,
          variables: { id: orgId },
        },
      ],
    })

    if (
      !result.errors &&
      result.data?.payment?.createFreePlan?.status?.success
    ) {
      await refetchLocation()
    } else {
      appServices.toast.danger(
        t(
          'translation.BillingForm.oh-izgleda-da-nismu-uspjeli-izvrsiti-naplatu-molimo-provjerite-unesene-podatke'
        )
      )
    }
  }

  const daysRemaining = moment(getISODate(org?.subscriptionEndDate)).diff(
    moment(getISODate()),
    'days'
  )

  const downgradeRemaining = moment(getISODate(org?.downgradeDate)).diff(
    moment(getISODate()),
    'days'
  )

  const trialDaysRemaining = daysRemaining > 0 ? daysRemaining : 0
  const downgradeDaysRemaining = downgradeRemaining > 0 ? downgradeRemaining : 0

  const onPlanChange = () => {
    //if the trial has expired, move to free
    if (trialExpired && org?.subscriptionType === 'trial') {
      setOpenBillingModal(false)
      return createFreePlan()
    }
    //else if we're on a paid plan that has expired, downgrade to free
    else if (
      org?.downgradePlan === 'free' &&
      downgradeDaysRemaining === 0 &&
      org?.subscriptionPlan !== 'free'
    ) {
      setOpenBillingModal(false)
      return createFreePlan()
    }
    //else if we've selected to downgrade to a business plan and the advanced plan expired
    else if (
      org?.downgradePlan === 'business' &&
      downgradeDaysRemaining === 0 &&
      org?.subscriptionPlan === 'advanced'
    ) {
      setOpenBillingModal(false)
      // downgrade to business happens in the stripeSubscription webhook on the first billing of new subscription (business)
      //return changeSubscriptionPlan('business')
    }
  }

  const hideTrial3DayExpired = localStorage.getItem(
    `hideTrial3DayExpired-${orgId}`
  )
  const trial3DayExpire =
    org?.subscriptionType === 'trial' &&
    trialDaysRemaining < 4 &&
    trialDaysRemaining > 0 &&
    !hideTrial3DayExpired
  const trialExpired =
    org?.subscriptionType === 'trial' && trialDaysRemaining === 0

  const downgradeExpired =
    !!org?.downgradePlan &&
    org?.downgradePlan !== org?.subscriptionPlan &&
    org?.downgradePlan !== 'advanced' && // case when we change mind and upgrade after downgrade -> downgrade plan is advanced for displaying in the card
    downgradeDaysRemaining === 0

  const query = useQueryString()
  const paymentIntentId = query.get('payment_intent')
  const paymentItem = query.get('paymentItem')

  const showModal =
    !!paymentIntentId || trial3DayExpire || trialExpired || downgradeExpired

  const { data: paymentIntentData, loading: paymentIntentLoading } =
    usePaymentGetPaymentIntentQuery({
      variables: { id: paymentIntentId || '' },
      skip: !paymentIntentId,
    })

  const paymentStatus =
    paymentIntentData?.payment?.getPaymentIntent?.status || ''
  const hasOrg = !!org?.id

  const { data: smsPackageData, loading: smsPackageLoading } =
    useGetSmsPackagesQuery({
      skip: !org,
    })
  const smsPackages = smsPackageData?.payment?.getSmsPackages

  useEffect(() => {
    if (showModal && hasOrg) {
      if (paymentItem === 'sms' && !smsPackageLoading) {
        const smsPackage = smsPackages?.find(
          elem =>
            elem.stripePriceId ===
            paymentIntentData?.payment?.getPaymentIntent?.stripePriceId
        )
        setPurchasedPackageName(!smsPackage ? '' : smsPackage?.packageName)
        setOpenBuySmsPackageModal(!!smsPackage)
        setOpenBillingModal(!smsPackage)
      } else if (paymentItem === 'plan' || paymentItem === 'cardChange') {
        setOpenBuySmsPackageModal(false)
        setOpenBillingModal(true)
      } else {
        setOpenBillingModal(true)
      }
    }
  }, [
    showModal,
    hasOrg,
    smsPackageLoading,
    paymentItem,
    paymentIntentData,
    paymentIntentLoading,
  ])

  function getHelmet() {
    return (
      <Helmet>
        {import.meta.env.VITE_ENV === 'development' ? (
          <link rel="icon" href={`/faviconDev/favicon.ico`} />
        ) : (
          <link rel="icon" href={`/favicon.ico`} />
        )}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:locale:alternate" content="hr_HR" />
        <meta property="og:locale:alternate" content="ro_RO" />
        <meta name="Zoyya" content={t('translation.App.appTitle')} />
        <meta property="og:title" content={t('translation.App.appTitle')} />
        <meta
          property="og:description"
          content={t('translation.App.appDesc')}
        />
        {location?.name ? (
          <title>{`${location?.name} | ${t(
            'translation.App.bookingTitle'
          )}`}</title>
        ) : (
          <title>{t('translation.App.bookingDesc')}</title>
        )}
        {location?.imageUrl ? (
          <meta property="og:image" content={location?.imageUrl} />
        ) : (
          <meta property="og:image" content="%PUBLIC_URL%/assets/zoyya.png" />
        )}
      </Helmet>
    )
  }
  const trpcClient = useMemo(() => {
    const client = trpc.createClient({
      transformer: superjson,
      links: [
        splitLink({
          condition(op) {
            return op.type === 'subscription'
          },
          true: wsLink<AppRouter>({
            client: createWSClient({
              url: import.meta.env.PROD
                ? `wss://${window.location.host}/trpc`
                : import.meta.env.VITE_TRPC_SUBSCRIPTIONS_URL,
              onClose: () => {},
              onOpen: () => {},
            }),
          }),
          false: httpBatchLink({
            url: import.meta.env.PROD
              ? `https://${window.location.host}/trpc`
              : import.meta.env.VITE_TRPC_CLIENT_URL,
            fetch(url, options) {
              return fetch(url, {
                ...options,
                credentials: 'include',
              })
            },
            // optional
            headers() {
              const orgId = localStorage.getItem('orgId')
              const language = localStorage.getItem('i18nextLng')
              const locationId =
                sessionStorage.getItem('locationId') ||
                localStorage.getItem('locationId')

              return {
                authorization: '',
                'x-orgId': orgId || '',
                'x-locationId': locationId || '',
                'x-session-id': getSessionId() || '',
                'accept-culture': language || '',
                'x-integration-source': `web-b2b${
                  import.meta.env.VITE_VERSION_NUMBER !== '#{version}#'
                    ? `-v${import.meta.env.VITE_VERSION_NUMBER}`
                    : ''
                }`,
              }
            },
          }),
        }),
      ],
    })

    return client
  }, [appUser])

  const [showDiscountPopup, setShowDiscountPopup] = useState(false)

  useEffect(() => {
    localStorage.removeItem('loyaltyPopupShown')
    const isDiscountPopupShown = localStorage.getItem('discountPopupShown')
    if (!isDiscountPopupShown) {
      const timer = setTimeout(() => {
        setShowDiscountPopup(true)
        localStorage.setItem('discountPopupShown', 'true')
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [])

  const handleClosePopup = () => {
    setShowDiscountPopup(false)
  }

  return (
    <ScreenLockProvider>
      <QueryClientProvider client={queryClient}>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <UserContext.Provider value={{ ...user, setOpenBillingModal }}>
            <WizardContext.Provider
              value={{
                isOpen: isOpen,
                setIsOpen: setIsOpen,
              }}
            >
              {getHelmet()}
              {applicationLoaded && (
                <AppContainer>
                  <GoogleAnalytics
                    trackingId={import.meta.env.VITE_GOOGLE_TAG}
                    enabled
                  />
                  <MSClarity />
                  <PopupComponent />
                  <Refetcher
                    refetchLocation={refetchLocation}
                    refetchOrg={refetchOrg}
                    orgId={orgId}
                  />
                  {user?.id && openBillingModal ? (
                    <BillingForm
                      closeModal={async () => {
                        if (hasMollieData) {
                          setOpenBillingModal(false)
                          return
                        }
                        if (trial3DayExpire && !paymentIntentId) {
                          localStorage.setItem(
                            `hideTrial3DayExpired-${orgId}`,
                            'true'
                          )
                        } else if (
                          !paymentIntentId &&
                          (trialExpired || downgradeExpired)
                        ) {
                          localStorage.setItem(
                            `removedTrialBanner-${orgId}`,
                            'true'
                          )
                          return onPlanChange()
                        }
                        setOpenBillingModal(false)
                      }}
                      showStep={showModal ? 5 : 1}
                      showUpdatePayment={false}
                      paymentStatus={
                        (paymentStatus === 'succeeded' &&
                          paymentItem === 'cardChange') ||
                        (paymentStatus === 'processing' &&
                          paymentItem === 'cardChange')
                          ? 'cardChangedSuccessfully'
                          : paymentStatus
                      }
                      trial3DayExpire={trial3DayExpire}
                      trialExpired={trialExpired}
                      downgradeExpired={downgradeExpired}
                      loading={paymentIntentLoading}
                    />
                  ) : null}
                  {user?.id && openBuySmsPackageModal ? (
                    <BuySmsModal
                      purchasedPackageName={purchasedPackageName}
                      showStep={showModal ? 4 : 1}
                      closeModal={async () => {
                        setOpenBuySmsPackageModal(false)
                      }}
                      loading={smsPackageLoading || paymentIntentLoading}
                    />
                  ) : null}
                  {loadingUser ? (
                    <Loader isComponent />
                  ) : hasPartnerAppAccess(user, myOrgs, orgId || org?.id) ? (
                    <GetPartnerRoutes
                      selectedEmployeesInWeek={selectedEmployeesInWeek}
                      setSelectedEmployeesInWeek={setSelectedEmployeesInWeek}
                      showActive={showActive}
                      setShowActive={setShowActive}
                      setShowOffHours={setShowOffHours}
                      showOffHours={showOffHours}
                      myOrgs={myOrgs}
                      orgFeatures={org?.orgFeatures}
                      setOpenBillingModal={setOpenBillingModal}
                    />
                  ) : (
                    <>
                      <Redirect from="/" to="/login" />
                      <Route component={NotFound} />
                    </>
                  )}
                  <GetStartedPopup />
                  {showDiscountPopup && (
                    <DiscountPopup onClose={handleClosePopup} />
                  )}
                </AppContainer>
              )}
            </WizardContext.Provider>
          </UserContext.Provider>
        </trpc.Provider>
      </QueryClientProvider>
    </ScreenLockProvider>
  )
}

function useQueryString() {
  return new URLSearchParams(useLocation().search)
}

const hasPartnerAppAccess = (user, myOrgs, orgId) => {
  return (
    (user && user.role && user.role !== 'CLIENT') ||
    (myOrgs && myOrgs.find(e => e.id === orgId && e.myRole !== 'CLIENT'))
  )
}
export default App
